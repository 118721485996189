import React from 'react';
import {Button, Row, Col, Modal, ModalBody, ModalHeader} from 'reactstrap';
import LogoSvg from '../images/logo-header';
import OrderCardsIconSVG from '../images/order-cards-icon';
import {Element} from 'react-scroll';
import ReactToPrint from 'react-to-print';
import PrintCard from './PrintCard';
import ModalCloseIcon from '../images/modal-close';
import {BrokerDetails} from '../../dataServices/app.generated';
import {Config} from '../../config';

type Props = {
  card: BrokerDetails;
};
type State = {printModalVisible: boolean};

class CardInfo extends React.Component<Props, State> {
  state = {
    printModalVisible: false,
  };

  togglePrintModal = () => {
    this.setState({
      printModalVisible: !this.state.printModalVisible,
    });
  };

  private componentRef: any;

  render() {
    const {card} = this.props;
    const {printModalVisible} = this.state;
    return (
      <Element name="Card">
        <section className="card-bg d-print-none">
          <div className="card-wrap">
            <div className="text-center logo">
              <LogoSvg />
              <div className="text-primary text-uppercase">DIGITAL PHARMACY SAVINGS CARD</div>
            </div>

            <div className="inner-card">
              <Row className="no-gutters">
                <Col>Group:</Col>
                <Col className="text-right">{card.group_number}</Col>
              </Row>
              <Row className="no-gutters">
                <Col>Member ID:</Col>
                <Col className="text-right">{card.member_id}</Col>
              </Row>
              <Row className="no-gutters">
                <Col>RxBIN:</Col>
                <Col className="text-right">{Config.rxBin}</Col>
              </Row>
              <Row className="no-gutters">
                <Col>PCN:</Col>
                <Col className="text-right">{Config.pcn}</Col>
              </Row>
            </div>

            {card.company_name && (
              <div className="text-center company text-uppercase">
                <div className="text-small">COMPLIMENTS OF</div>
                <div className="text-company">{card.company_name}</div>
              </div>
            )}

            <div className="inner-card">
              <Row className="no-gutters">
                <Col xs="12" sm="6" className="text-center text-sm-left">
                  Pharmacist Help Line:
                </Col>
                <Col xs="12" sm="6" className="text-center text-sm-right">
                  (800) 974-3135
                </Col>
              </Row>
              <Row className="no-gutters">
                <Col xs="12" sm="6" className="text-center text-sm-left">
                  Customer Help Line:
                </Col>
                <Col xs="12" sm="6" className="text-center text-sm-right">
                  (800) 873-1195
                </Col>
              </Row>
            </div>

            <div className="text-center text-uppercase">
              <div className="text-xsmall">THIS CARD IS NOT INSURANCE</div>
            </div>

            <Button block outline color="primary" size="lg" className="icon" onClick={this.togglePrintModal}>
              <span>Print Card</span> <OrderCardsIconSVG />
            </Button>

            <Modal isOpen={printModalVisible} toggle={this.togglePrintModal} size="lg">
              <ModalHeader>
                Print
                <a onClick={this.togglePrintModal} className="close-modal">
                  <ModalCloseIcon />
                </a>
              </ModalHeader>
              <ModalBody>
                <ReactToPrint
                  trigger={() => (
                    <Button block outline color="primary" size="lg" className="icon">
                      <span>Print Card</span> <OrderCardsIconSVG />
                    </Button>
                  )}
                  content={() => this.componentRef}
                />
                <PrintCard ref={(el) => (this.componentRef = el)} card={card} />
              </ModalBody>
            </Modal>
          </div>
          <style jsx>{`
            .card-bg {
              background: #24292d;
              padding: 0 0 60px 0;
              width: 100%;
              display: block;
              position: relative;
              z-index: 1;
              margin: 0 0 60px 0;
            }
            .card-bg:after {
              background: inherit;
              bottom: 0;
              content: '';
              display: block;
              height: 50%;
              left: 0;
              position: absolute;
              right: 0;
              transform: skewY(-4deg);
              transform-origin: 100%;
              z-index: -1;
              -webkit-backface-visibility: hidden;
              box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
            }
            .card-wrap {
              width: 100%;
              background: #1e2126;
              box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.15);
              padding: 40px 20px;
              margin: 0 auto;
            }
            .inner-card {
              margin: 0 auto;
              width: 100%;
              border-radius: 40px;
              background-color: #212429;
              box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.15);
              margin: 0 auto 75px auto;
              padding: 10px 30px 30px 30px;
              color: #8c99a7;
              font-size: 18px;
              font-weight: 400;
            }
            .inner-card :global(.row) {
              border-bottom: dashed 2px #3b4045;
              padding-top: 20px;
              padding-bottom: 5px;
              line-height: 1.5;
            }
            .card-wrap :global(.btn) {
              max-width: 380px;
              margin: 0 auto;
            }
            .logo :global(svg) {
              max-width: 80%;
              display: block;
              margin: 0 auto 3px auto;
            }
            .text-primary {
              font-size: 16px;
              font-weight: 300;
              letter-spacing: 2px;
              margin-bottom: 30px;
            }
            .text-company {
              font-size: 26px;
              letter-spacing: 3.3px;
              font-weight: 600;
              margin: 0 0 30px 0;
            }
            .text-small {
              font-size: 16px;
              font-weight: 300;
              letter-spacing: 2px;
              line-height: normal;
              color: #8c99a7;
            }
            .text-xsmall {
              font-size: 14px;
              font-weight: 300;
              letter-spacing: 1.8px;
              color: #8c99a7;
              margin: 0 0 30px 0;
            }
            @media (min-width: 768px) {
              .card-bg {
                margin-bottom: 100px;
                padding: 60px 0;
              }
              .card-wrap {
                max-width: 940px;
                padding: 80px 120px;
                border-radius: 60px;
              }
              .inner-card {
                max-width: 700px;
                margin: 0 auto 75px auto;
                padding: 30px 60px 60px 60px;
                font-size: 22px;
              }
              .logo :global(svg) {
                width: 100%;
                max-width: 492px;
                height: 73px;
              }
              .text-primary {
                margin-bottom: 75px;
              }
            }
          `}</style>
        </section>
      </Element>
    );
  }
}

export default CardInfo;
