import React, {forwardRef, useState} from 'react';

export const PromiseButton = forwardRef(
  (
    {
      children,
      onClick,
      className,
      disabled,
      overrideClassName,
      isSubmit,
      spinnerClassName,
      spinnerSize,
    }: {
      className?: string;
      children: React.ReactNode;
      onClick: () => void;
      disabled?: boolean;
      overrideClassName?: string;
      isSubmit?: boolean;
      spinnerClassName?: string;
      spinnerSize?: string;
    },
    ref: any,
  ) => {
    const [loading, setLoading] = useState(false);

    const clickHandler = async (e: any) => {
      e.stopPropagation();
      if (loading) {
        return;
      }
      setLoading(true);
      try {
        await onClick();
      } catch (e) {
        console.error(e);
      }
      setLoading(false);
    };

    return (
      <button
        ref={ref}
        type={isSubmit ? 'submit' : 'button'}
        disabled={disabled}
        className={
          overrideClassName ||
          `clever-btn relative ${disabled && 'cursor-default'}${className || ''}`
        }
        onClick={clickHandler}
      >
        {loading && (
          <div className={spinnerClassName || 'absolute'}>
            <DotsSpinner size={spinnerSize || '3.5px'} />
          </div>
        )}
        <div className={`flex items-center ${loading ? 'opacity-0' : ''}`}>{children}</div>
      </button>
    );
  },
);

const DotsSpinner = ({size}: {size: string}) => (
  <div className={'DotsSpinner'} style={{fontSize: size}} />
);
