export class BaseClient {
  constructor(protected options: ControllerOptions) {}

  async transformOptions(options: RequestInit): Promise<RequestInit> {
    const headers = options.headers as any;
    return options;
  }
}

export interface ControllerOptions {
  baseUrl: string;
  getJwt: () => string | undefined;
  handleError: (error: string) => void;
  handleUnauthorized: (error: string) => void;
}
