import React, {useState, useEffect, MutableRefObject} from 'react';

export function useScrollIntoView(ref: MutableRefObject<HTMLDivElement>) {
  const [isVisible, setVisible] = useState(true);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => setVisible(entry.isIntersecting));
    });
    if (ref.current) {
      observer.observe(ref.current);
    }
    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, []);

  return {isVisible};
}
