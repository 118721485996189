import React from 'react';
export const other = (
  <svg width="128" height="128" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <g fill="none" className="nc-icon-wrapper">
      <path
        d="M21 5h-2.64l1.14-3.14L17.15 1l-1.46 4H3v2l2 6-2 6v2h18v-2l-2-6 2-6V5zm-5 9h-3v3h-2v-3H8v-2h3V9h2v3h3v2z"
        fill="currentColor"
      />
    </g>
  </svg>
);
