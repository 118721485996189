import {HttpDrug, HttpDrugResult, HttpDrugV2, HttpPickedDrug} from '../dataServices/app.generated';

export class DrugUtils {
  static getDefaultPicked(drug: HttpDrugV2, zipcode: string, forceBranded = false): HttpPickedDrug | undefined {
    for (const doseForm of drug.doseForms) {
      for (const dispensable of doseForm.dispensables) {
        const q = dispensable.dispensedQuantities[0];
        const ndc = q.ndc;
        return {
          zipcode,
          forceBranded,
          drugName: drug.name,
          strengthItem: dispensable.strengthItem,
          form: doseForm.form,
          ndc: ndc.ndc,
          quantity: q.ndc.quantity.toString(),
          displayQuantity: q.ndc.displayQuantity.toString(),
          isGeneric: ndc.isGeneric,
        };
      }
    }
  }

  static couponDescription(item: {
    closest?: boolean;
    cheapest?: boolean;
    preferredPharmacy?: boolean;
    preferredPharmacyType?: boolean;
  }) {
    switch (true) {
      case item.cheapest && item.closest && item.preferredPharmacyType:
        return 'your pharmacy\nnearest & lowest price';
      case item.cheapest && item.preferredPharmacyType:
        return 'your pharmacy\nlowest price';
      case item.closest && item.preferredPharmacyType:
        return 'your pharmacy\nnearest you';
      case item.preferredPharmacyType:
        return 'your pharmacy';
      case item.cheapest && item.closest:
        return 'nearest & lowest price';
      case item.closest:
        return 'nearest you';
      case item.cheapest:
        return 'lowest price';
    }
    return null;
  }

  static getFromPicked(drug: HttpDrugResult, pickedDrug: HttpPickedDrug): HttpDrug {
    if (pickedDrug.isGeneric) {
      return drug.generic;
    }
    return drug.brand;
  }

  static getPickedFromForm(drug: HttpDrugV2, pickedDrug: HttpPickedDrug, form: string): HttpPickedDrug | undefined {
    for (const doseForm of drug.doseForms) {
      if (doseForm.form === form) {
        for (const dispensables of doseForm.dispensables) {
          for (const quant of dispensables.dispensedQuantities) {
            return {
              zipcode: pickedDrug.zipcode,
              form,
              strengthItem: dispensables.strengthItem,
              ndc: quant.ndc.ndc,
              isGeneric: quant.ndc.isGeneric,
              quantity: quant.ndc.quantity.toString(),
              displayQuantity: quant.ndc.displayQuantity.toString(),
              drugName: quant.ndc.name,
              forceBranded: pickedDrug.forceBranded,
            };
          }
        }
      }
    }
  }

  static getPickedFromStrengthItem(
    drug: HttpDrugV2,
    pickedDrug: HttpPickedDrug,
    strengthItem: string
  ): HttpPickedDrug | undefined {
    for (const doseForm of drug.doseForms) {
      for (const dispensables of doseForm.dispensables) {
        if (dispensables.strengthItem === strengthItem) {
          for (const quant of dispensables.dispensedQuantities) {
            return {
              zipcode: pickedDrug.zipcode,
              form: doseForm.form,
              strengthItem,
              ndc: quant.ndc.ndc,
              isGeneric: quant.ndc.isGeneric,
              quantity: quant.quantity.toString(),
              displayQuantity: (quant.ndc.displayQuantity || quant.ndc.quantity).toString(),
              drugName: quant.ndc.name,
              forceBranded: pickedDrug.forceBranded,
            };
          }
        }
      }
    }
  }

  static pharmacyNames = [
    'bashas united drug',
    'costco pharmacy',
    'costco',
    'cvs pharmacy',
    'fred meyer pharmacy',
    'frys pharmacy',
    'frys food and drug',
    "fry's pharmacy",
    'kroger pharmacy',
    'ralphs pharmacy',
    'rite aid pharmacy',
    'smiths pharmacy',
    'target',
    'walgreens',
    'walmart pharmacy',
    'safeway pharmacy',
    'qfc pharmacy',
    'albertsons pharmacy',
  ];

  static cleanPharmacyName(pharmacy: string) {
    const hasLogo = this.pharmacyNames.includes(pharmacy.toLowerCase());
    return hasLogo ? pharmacy.toLowerCase().replace(`'`, '').split(' ').join('-') : 'other';
  }
}

export interface DrugFormType {
  label: string;
  key: 'Generic' | 'Brand';
  pickedDrug?: HttpPickedDrug;
}
