export class Utils {
  static smallUUID() {
    let dt = new Date().getTime();
    return 'xxxxxxxxxxxxxxxxxxxxxxxx'.replace(/[x]/g, (c) => {
      const r = (dt + Math.random() * 16) % 16 | 0;
      dt = Math.floor(dt / 16);
      return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
    });
  }

  static timeout(timeout: number): Promise<void> {
    return new Promise<void>((res) => {
      setTimeout(() => {
        res();
      }, timeout);
    });
  }
}
