import React from 'react';
import SiteHeader from '../components/Header';
import {isSignedIn} from '../lib/session';

class SiteHeaderContainer extends React.PureComponent {
  state = {
    isSideMenuOpen: false,
  };

  toggleSideMenu = () => {
    this.setState({isSideMenuOpen: !this.state.isSideMenuOpen});
  };

  sideMenuStateChange = (event) => {
    this.setState({isSideMenuOpen: event.isOpen});
  };

  componentDidMount() {
    const {retrieveUser} = this.props;

    if (isSignedIn()) {
      retrieveUser();
    }
  }

  render() {
    return (
      <SiteHeader
        {...this.props}
        isSideMenuOpen={this.state.isSideMenuOpen}
        toggleSideMenu={this.toggleSideMenu}
        sideMenuStateChange={this.sideMenuStateChange}
      />
    );
  }
}

export default SiteHeaderContainer;
