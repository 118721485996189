import React from 'react';
import MediaQuery from 'react-responsive';
import {Link} from 'react-router-dom';
import LogoSvg from './images/logo-header';
import {Link as ScrollLink} from 'react-scroll';
import {slide as Menu} from 'react-burger-menu';

const SiteHeader = ({showHomeLinks, headerStyle, toggleSideMenu, isSideMenuOpen, sideMenuStateChange}) => {
  return (
    <header className={`header-${headerStyle} d-print-none`} style={{position: 'static'}}>
      {headerStyle === 'none' && null}

      {headerStyle === 'logo' && (
        <a href={'https://cleverrx.com'} className="brand-logo">
          <LogoSvg />
        </a>
      )}

      {(headerStyle === 'normal' || headerStyle === 'dashboard' || headerStyle === 'filled') && (
        <React.Fragment>
          <a href={'https://cleverrx.com'} className="brand-logo">
            <LogoSvg />
          </a>

          {showHomeLinks && (
            <MediaQuery minWidth={992}>
              <div className="menu scroll text-center">
                <ScrollLink to="How" smooth>
                  How it Works
                </ScrollLink>
                <ScrollLink to="Pharmacies" smooth>
                  Pharmacies
                </ScrollLink>
                <ScrollLink to="FAQ" smooth>
                  FAQs
                </ScrollLink>
              </div>
            </MediaQuery>
          )}

          <React.Fragment>
            {/* Signed out */}
            <MediaQuery maxWidth={767}>
              <Menu right>
                {/*<a href="https://blog.cleverrx.com/blog" className="bm-item">
                  Blog
                </a>*/}
                <Link to="/drug-price-lookup" className="bm-item">
                  Drug Price Lookup
                </Link>

                {/*<a href="https://portal.cleverrx.com/" title="Login" className="login bm-item">
                  Login
                </a>*/}
                <div className="bm-item">
                  <Link to="/for-partners" title="For Partners" className="btn btn-primary btn-sm">
                    For Partners
                  </Link>
                </div>
              </Menu>
            </MediaQuery>
            <MediaQuery minWidth={768}>
              <div className="menu">
                {/*<a href="https://blog.cleverrx.com/blog" >
                  Blog
                </a>*/}
                <Link to="/drug-price-lookup">Drug Price Lookup</Link>
                {/*
                <a href="https://portal.cleverrx.com/" title="Login" className="login">
                  Login
                </a>
*/}
                <Link to="/for-partners" title="For Partners" className="btn btn-primary btn-sm">
                  For Partners
                </Link>
              </div>
            </MediaQuery>
          </React.Fragment>
        </React.Fragment>
      )}

      {headerStyle === 'card' && (
        <React.Fragment>
          <div className="brand-logo">
            <LogoSvg />
          </div>
          <MediaQuery maxWidth={767}>
            <Menu right isOpen={isSideMenuOpen} onStateChange={sideMenuStateChange}>
              {/*<Link route={isSignedIn ? ('dashboard/index') : ('index')} >*/}
              {/*<a title="Home" className="bm-item">{isSignedIn ? ('Dashboard') : ('Home')}</a>*/}
              {/*</Link>*/}
              {/*<a href="https://blog.cleverrx.com/blog" >
                Blog
              </a>*/}
              <ScrollLink onClick={toggleSideMenu} to="How" smooth>
                How It Works
              </ScrollLink>
              <ScrollLink onClick={toggleSideMenu} to="Lookup" smooth>
                Drug Price Lookup
              </ScrollLink>
              <ScrollLink onClick={toggleSideMenu} to="Card" smooth>
                Digital Savings Card
              </ScrollLink>
              <ScrollLink onClick={toggleSideMenu} to="More" smooth>
                More Info
              </ScrollLink>
            </Menu>
          </MediaQuery>
          <MediaQuery minWidth={768}>
            <div className="menu scroll text-right">
              {/*<a href="https://blog.cleverrx.com/blog" >
                Blog
              </a>*/}
              <ScrollLink to="How" smooth>
                How It Works
              </ScrollLink>
              <ScrollLink to="Lookup" smooth>
                Drug Price Lookup
              </ScrollLink>
              <ScrollLink to="Card" smooth>
                Digital Savings Card
              </ScrollLink>
              <ScrollLink to="More" smooth>
                More Info
              </ScrollLink>
            </div>
          </MediaQuery>
        </React.Fragment>
      )}
    </header>
  );
};

export default SiteHeader;
