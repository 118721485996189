import {Utils} from './utils/utils';

export class Config {
  static rxBin = '020529';
  static pcn = 'CLEVR';

  static sessionId = Utils.smallUUID();

  static env: 'local' | 'dev' | 'prod' = process.env.REACT_APP_ENV as 'local' | 'dev' | 'prod';
  static get host() {
    const localHost = 'http://127.0.0.1:3003';
    const devHost = 'https://dev-api.cleverrx.com';
    const prodHost = 'https://api.cleverrx.com';
    switch (this.env) {
      case 'local':
        return localHost;
      case 'dev':
        return devHost;
      case 'prod':
        return prodHost;
    }
  }

  static start() {}

  private static userId: string;

  static get webUserId() {
    if (this.userId) return this.userId;
    const existing = localStorage.getItem('webUserId');
    if (existing) {
      this.userId = existing;
      return existing;
    }
    const newId = Utils.smallUUID() + Utils.smallUUID();
    localStorage.setItem('webUserId', newId);
    this.userId = newId;
    return newId;
  }
}
