import React, {ReactNode, useState} from 'react';
import {TextFormats, TextFormatter} from '../utils/textFormatter';
import {AppService} from '../dataServices/appService';
import {StateSwitch} from './StateSwitch';
import {PromiseButton} from './PromiseButton';
import {stateCodes} from '../utils/stateCodes';
import {RxValetUtils} from '../utils/rxValetUtils';
import {AnimatePresence, motion} from 'framer-motion';
import {RxValetPapFormDataV2} from '../dataServices/app.generated';
declare const gtag: any;

export const PapSubmission = ({
  ndc,
  drugName,
  goBack,
}: {
  ndc: string;
  drugName: string;
  goBack: (submitted: boolean) => void;
}) => {
  const [formData, setFormData] = useState<RxValetPapFormDataV2>({
    physicianName: '',
    physicianPhone: '',
    physicianCity: '',
    physicianState: '',
    firstName: '',
    lastName: '',
    email: '',
    contactNumber: '',
    agreeToTerms: false,
    bestTimeToCall: '',
    annualIndividualIncome: '',
    totalHouseholdIncome: '',
    householdMembers: '',
    dateOfBirth: '',
    memberZip: '',
    memberState: '',
    memberCity: '',
    memberAddress2: '',
    memberAddress1: '',
  });
  const [[step, direction], setStep] = useState([0, 1]);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const onSubmit = async () => {
    setIsSubmitting(true);
    gtag && gtag('event', 'pap-submit', {});
    const res = await AppService.drugV2Client.submitPapFormV2(
      {
        ndc,
        formData: {
          physicianCity: formData.physicianCity,
          agreeToTerms: formData.agreeToTerms,
          email: formData.email,
          bestTimeToCall: formData.bestTimeToCall,
          annualIndividualIncome: formData.annualIndividualIncome,
          firstName: formData.firstName,
          householdMembers: formData.householdMembers,
          lastName: formData.lastName,
          physicianName: formData.physicianName,
          physicianPhone: TextFormatter.clean(TextFormats.phoneNumber, formData.physicianPhone),
          contactNumber: TextFormatter.clean(TextFormats.phoneNumber, formData.contactNumber),
          physicianState: formData.physicianState,
          totalHouseholdIncome: formData.totalHouseholdIncome,
          dateOfBirth: formData.dateOfBirth,
          memberAddress1: formData.memberAddress1,
          memberAddress2: formData.memberAddress2,
          memberCity: formData.memberCity,
          memberState: formData.memberState,
          memberZip: formData.memberZip,
        },
        platform: 'clx-web',
        url: window.location.href,
      },
      {}
    );
    if (res) {
      onNext(5);
    }
    setIsSubmitting(false);
  };

  const isFormValid = () => {
    return (
      !!formData.householdMembers &&
      !!formData.email &&
      formData.email.includes('@') &&
      !!formData.totalHouseholdIncome &&
      !!formData.annualIndividualIncome &&
      !!formData.bestTimeToCall &&
      !!formData.firstName &&
      !!formData.physicianState &&
      !!formData.physicianCity &&
      !!formData.physicianPhone &&
      !!formData.lastName &&
      !!formData.memberAddress1 &&
      !!formData.memberState &&
      !!formData.memberCity &&
      !!formData.memberZip &&
      TextFormatter.valid(TextFormats.phoneNumber, formData.contactNumber) &&
      TextFormatter.valid(TextFormats.phoneNumber, formData.physicianPhone) &&
      TextFormatter.valid(TextFormats.dob, formData.dateOfBirth)
    );
  };

  const onBack = () => {
    if (step === 0) {
      goBack(false);
    } else {
      setStep([step - 1, -1]);
    }
  };

  const onNext = (nextStep: number) => {
    setStep([nextStep, 1]);
  };

  return (
    <div className={'text-custom-text max-w-6xl p-5 mx-auto'} style={{zIndex: 101}}>
      <div className={'md:mt-5 p-5 bg-custom-background'}>
        <AnimatePresence exitBeforeEnter onExitComplete={() => window.scrollTo(0, 0)}>
          <motion.div key={step} initial={{opacity: 0}} animate={{opacity: 1}} exit={{opacity: 0}}>
            <StateSwitch state={() => step}>
              {{
                0: () => (
                  <div>
                    <h1 className={'text-center mb-5'}>Patient Assistance Program</h1>
                    <p>How does it work?</p>
                    <ArrowInfo>
                      Our patient assistance program is drug manufacture-based, which offers more{' '}
                      <span className={'font-bold'}>affordable</span> pricing on over{' '}
                      <span className={'font-bold'}>1,400</span> higher-cost medication formularies
                    </ArrowInfo>
                    <ArrowInfo>
                      <span className={'font-bold'}>Qualification</span> for the patient assistance program is based on
                      income
                    </ArrowInfo>
                    <ArrowInfo>
                      Let us do the heavy lifting! We work with you, your prescribing provider, and the drug
                      manufacturer to <span className={'font-bold'}>process</span> your medication order
                    </ArrowInfo>
                    <ArrowInfo>
                      Upon <span className={'font-bold'}>approval</span>, you'll receive your medication via mail-order,
                      delivered to your provider or right to your front door!
                    </ArrowInfo>
                    <div className={'mt-10 mb-5'}>
                      Our patient assistance program has helped <span className={'font-bold'}>thousands</span> save{' '}
                      <span className={'font-bold'}>millions of dollars</span> on their higher-cost medications. Let's
                      get started now to see if you qualify!
                    </div>
                    <button className={'clever-btn mx-auto flex px-10'} onClick={() => onNext(1)}>
                      Get Started Now
                    </button>
                    <div
                      className={'cursor-pointer text-cleverGray-200 text-center font-thin text-base mt-3'}
                      onClick={onBack}
                    >
                      go back
                    </div>
                  </div>
                ),
                1: () => (
                  <div className={'max-w-lg mx-auto'}>
                    <h1 className={'text-center mb-5'}>Patient Assistance Program</h1>
                    <h1 className={'font-thin text-center'} style={{fontSize: '16px'}}>
                      Physician Information
                    </h1>
                    <div className={'mb-5'}>
                      <label htmlFor={'pap-physician-name'} className={'text-sm text-cleverGray-100 m-0'}>
                        Physician Name
                      </label>
                      <input
                        id={'pap-physician-name'}
                        className={'clh-input'}
                        value={formData.physicianName}
                        onChange={(e) => setFormData((x) => ({...x, physicianName: e.target.value}))}
                      />
                    </div>
                    <div className={'mb-5'}>
                      <label htmlFor={'pap-physician-phone'} className={'text-sm text-cleverGray-100 m-0'}>
                        Physician Phone
                      </label>
                      <input
                        id={'pap-physician-phone'}
                        className={'clh-input'}
                        value={formData.physicianPhone}
                        onChange={(e) =>
                          setFormData((x) => ({
                            ...x,
                            physicianPhone: TextFormatter.format(TextFormats.phoneNumber, e.target.value),
                          }))
                        }
                      />
                    </div>
                    <div className={'mb-5'}>
                      <label htmlFor={'pap-physician-city'} className={'text-sm text-cleverGray-100 m-0'}>
                        Physician City
                      </label>
                      <input
                        id={'pap-physician-city'}
                        className={'clh-input'}
                        value={formData.physicianCity}
                        onChange={(e) => setFormData((x) => ({...x, physicianCity: e.target.value}))}
                      />
                    </div>
                    <div className={'mb-5'}>
                      <label htmlFor={'pap-physician-state'} className={'text-sm text-cleverGray-100 m-0'}>
                        Physician State
                      </label>
                      <select
                        id={'pap-physician-state'}
                        className={'clh-input'}
                        value={formData.physicianState}
                        onChange={(e) => setFormData((x) => ({...x, physicianState: e.target.value}))}
                      >
                        {stateCodes.map((state) => (
                          <option value={state.value} key={state.value}>
                            {state.label}
                          </option>
                        ))}
                        <option hidden disabled value={''}>
                          select
                        </option>
                      </select>
                    </div>
                    <h1 className={'font-thin text-center'} style={{fontSize: '16px'}}>
                      Household Information
                    </h1>
                    <div className={'mb-5'}>
                      <label htmlFor={'pap-first-name'} className={'text-sm text-cleverGray-100 m-0'}>
                        First Name
                      </label>
                      <input
                        id={'pap-first-name'}
                        className={'clh-input'}
                        value={formData.firstName}
                        onChange={(e) => setFormData((x) => ({...x, firstName: e.target.value}))}
                      />
                    </div>
                    <div className={'mb-5'}>
                      <label htmlFor={'pap-last-name'} className={'text-sm text-cleverGray-100 m-0'}>
                        Last Name
                      </label>
                      <input
                        id={'pap-last-name'}
                        className={'clh-input'}
                        value={formData.lastName}
                        onChange={(e) => setFormData((x) => ({...x, lastName: e.target.value}))}
                      />
                    </div>
                    <div className={'mb-5'}>
                      <label htmlFor={'pap-email'} className={'text-sm text-cleverGray-100 m-0'}>
                        Email Address
                      </label>
                      <input
                        id={'pap-email'}
                        className={'clh-input'}
                        value={formData.email}
                        onChange={(e) => setFormData((x) => ({...x, email: e.target.value}))}
                      />
                    </div>
                    <div className={'mb-5'}>
                      <label htmlFor={'pap-phone'} className={'text-sm text-cleverGray-100 m-0'}>
                        Contact Phone Number
                      </label>
                      <input
                        id={'pap-phone'}
                        className={'clh-input'}
                        value={formData.contactNumber}
                        onChange={(e) =>
                          setFormData((x) => ({
                            ...x,
                            contactNumber: TextFormatter.format(TextFormats.phoneNumber, e.target.value),
                          }))
                        }
                      />
                    </div>
                    <div className={'mb-5'}>
                      <label htmlFor={'pap-dob'} className={'text-sm text-cleverGray-100 m-0'}>
                        Date of Birth
                      </label>
                      <input
                        id={'pap-dob'}
                        className={'clh-input'}
                        placeholder={'MM / DD / YYYY'}
                        value={formData.dateOfBirth}
                        onChange={(e) =>
                          setFormData((x) => ({
                            ...x,
                            dateOfBirth: TextFormatter.format(TextFormats.dob, e.target.value),
                          }))
                        }
                      />
                    </div>
                    <div className={'mb-5'}>
                      <label htmlFor={'pap-address1'} className={'text-sm text-cleverGray-100 m-0'}>
                        Street Address
                      </label>
                      <input
                        id={'pap-address1'}
                        className={'clh-input'}
                        value={formData.memberAddress1}
                        onChange={(e) => setFormData((x) => ({...x, memberAddress1: e.target.value}))}
                      />
                    </div>
                    <div className={'mb-5'}>
                      <label htmlFor={'pap-address2'} className={'text-sm text-cleverGray-100 m-0'}>
                        Street Address Line 2 <span className={'text-cleverGray-200'}>(optional)</span>
                      </label>
                      <input
                        id={'pap-address2'}
                        className={'clh-input'}
                        value={formData.memberAddress2}
                        onChange={(e) => setFormData((x) => ({...x, memberAddress2: e.target.value}))}
                      />
                    </div>
                    <div className={'mb-5'}>
                      <label htmlFor={'pap-city'} className={'text-sm text-cleverGray-100 m-0'}>
                        City
                      </label>
                      <input
                        id={'pap-city'}
                        className={'clh-input'}
                        value={formData.memberCity}
                        onChange={(e) => setFormData((x) => ({...x, memberCity: e.target.value}))}
                      />
                    </div>
                    <div className={'mb-5'}>
                      <label htmlFor={'pap-state'} className={'text-sm text-cleverGray-100 m-0'}>
                        State
                      </label>
                      <select
                        id={'pap-state'}
                        className={'clh-input'}
                        value={formData.memberState}
                        onChange={(e) => setFormData((x) => ({...x, memberState: e.target.value}))}
                      >
                        {stateCodes.map((state) => (
                          <option value={state.value} key={state.value}>
                            {state.label}
                          </option>
                        ))}
                        <option hidden disabled value={''}>
                          select
                        </option>
                      </select>
                    </div>
                    <div className={'mb-5'}>
                      <label htmlFor={'pap-zip'} className={'text-sm text-cleverGray-100 m-0'}>
                        Zip Code
                      </label>
                      <input
                        id={'pap-zip'}
                        className={'clh-input'}
                        value={formData.memberZip}
                        onChange={(e) =>
                          setFormData((x) => ({
                            ...x,
                            memberZip: TextFormatter.format(TextFormats.zipcode, e.target.value),
                          }))
                        }
                      />
                    </div>
                    <div className={'mb-5'}>
                      <label htmlFor={'pap-time-to-call'} className={'text-sm text-cleverGray-100 m-0'}>
                        Best time to call (EST)
                      </label>
                      <select
                        id={'pap-time-to-call'}
                        className={'clh-input'}
                        value={formData.bestTimeToCall}
                        onChange={(e) => setFormData((x) => ({...x, bestTimeToCall: e.target.value}))}
                      >
                        {RxValetUtils.bestTimesToCall.map((option) => (
                          <option value={option.value} key={option.value}>
                            {option.label}
                          </option>
                        ))}
                        <option hidden disabled value={''}>
                          select
                        </option>
                      </select>
                    </div>
                    <div className={'mb-5'}>
                      <label htmlFor={'pap-income'} className={'text-sm text-cleverGray-100 m-0'}>
                        Annual Income
                      </label>
                      <select
                        id={'pap-income'}
                        className={'clh-input'}
                        value={formData.annualIndividualIncome}
                        onChange={(e) => setFormData((x) => ({...x, annualIndividualIncome: e.target.value}))}
                      >
                        {RxValetUtils.annualIncomeLevels.map((option) => (
                          <option value={option.value} key={option.value}>
                            {option.label}
                          </option>
                        ))}
                        <option hidden disabled value={''}>
                          select
                        </option>
                      </select>
                    </div>
                    <div className={'mb-5'}>
                      <label htmlFor={'pap-household-income'} className={'text-sm text-cleverGray-100 m-0'}>
                        Total Household Income
                      </label>
                      <select
                        id={'pap-household-income'}
                        className={'clh-input'}
                        value={formData.totalHouseholdIncome}
                        onChange={(e) => setFormData((x) => ({...x, totalHouseholdIncome: e.target.value}))}
                      >
                        {RxValetUtils.annualIncomeLevels.map((option) => (
                          <option value={option.value} key={option.value}>
                            {option.label}
                          </option>
                        ))}
                        <option hidden disabled value={''}>
                          select
                        </option>
                      </select>
                    </div>
                    <div className={'mb-5'}>
                      <label htmlFor={'pap-household-members'} className={'text-sm text-cleverGray-100 m-0'}>
                        Number of Household Members
                      </label>
                      <select
                        id={'pap-household-members'}
                        className={'clh-input'}
                        value={formData.householdMembers}
                        onChange={(e) => setFormData((x) => ({...x, householdMembers: e.target.value}))}
                      >
                        {RxValetUtils.householdMembers.map((option) => (
                          <option value={option.value} key={option.value}>
                            {option.label}
                          </option>
                        ))}
                        <option hidden disabled value={''}>
                          select
                        </option>
                      </select>
                    </div>
                    <button
                      className={'clever-btn mx-auto flex px-10'}
                      disabled={!isFormValid()}
                      onClick={() => onNext(2)}
                    >
                      Next
                    </button>
                    <div
                      className={'cursor-pointer text-cleverGray-200 text-center font-thin text-base mt-3'}
                      onClick={onBack}
                    >
                      go back
                    </div>
                  </div>
                ),
                2: () => (
                  <div>
                    <h1 className={'text-center mb-5'}>Terms of Service</h1>
                    <div className={'text-cleverBlue-400 font-thin mb-5 text-base'}>
                      I authorize RxManage, and agents and entities authorized by RxManage, to contact me for Patient
                      Assistance Program (PAP) administrative and advocacy services at any time for the following:
                      mailing address, telephone number(s), email addresses, or any other electronic address I provided
                      or may provide in the future.
                      <br />I understand and agree that my mailing address, telephone number(s), email address, or any
                      other electronic address I provided or may provide in the future to RxManage, is my private
                      information and will not be shared, bartered, sold, or in any way used by RxManage, any agents or
                      entities not referenced above, without my authorization.
                    </div>
                    <div className={'flex items-center justify-center mb-10'}>
                      <div
                        className={`cursor-pointer rounded shadow-sm border w-6 h-6 flex items-center justify-center text-white transition-colors duration-200 ${
                          formData.agreeToTerms
                            ? 'bg-primary-500 border-primary-600'
                            : 'bg-transparentBlack border-cleverGray-250'
                        }`}
                        onClick={() => setFormData((x) => ({...x, agreeToTerms: !x.agreeToTerms}))}
                      >
                        {formData.agreeToTerms && (
                          <svg className={'w-4 h-4 fill-current'} viewBox="0 0 16 16">
                            <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
                          </svg>
                        )}
                      </div>
                      <div className={'text-base ml-2'}>I agree to the Terms of Service</div>
                    </div>
                    <button
                      className={'clever-btn mx-auto flex px-10'}
                      disabled={!formData.agreeToTerms}
                      onClick={() => onNext(3)}
                    >
                      Next
                    </button>
                    <div
                      className={'cursor-pointer text-cleverGray-200 text-center font-thin text-base mt-3'}
                      onClick={onBack}
                    >
                      go back
                    </div>
                  </div>
                ),
                3: () => (
                  <div>
                    <h1 className={'text-center mb-5'}>Confirmation</h1>
                    <div className={'text-cleverBlue-400 font-thin mb-5 text-base'}>
                      Thank you for your interest in participating in our Patient Assistance Program (PAP). You have
                      indicated that you wish to submit a request for Patient Assistance for your current medication,{' '}
                      {drugName}.
                      <br />
                      Additional information may be required during the application process. An Rx Specialist will
                      contact you by phone and email to provide assistance and coordination through the RxManage
                      application/enrollment process. The Rx Specialist can also answer any questions you may have.
                      Please call (855) 798-2538 if you have any questions now.
                      <br />
                      Your completed RxManage request will be sent to your prescribing physician for their approval.
                      Please download and/or print a copy of your RxManage request confirmation for your records, as
                      this will include additional information for you and your prescribing physician.
                    </div>
                    <button className={'clever-btn mx-auto flex px-10'} onClick={() => onNext(4)}>
                      Next
                    </button>
                    <div
                      className={'cursor-pointer text-cleverGray-200 text-center font-thin text-base mt-3'}
                      onClick={onBack}
                    >
                      go back
                    </div>
                  </div>
                ),
                4: () => (
                  <div>
                    <h1 className={'text-center mb-5'}>Submit Request</h1>
                    <div className={'text-cleverBlue-400 font-thin mb-5 text-base'}>
                      After you submit your request, you will be contacted within 48 hours by an RxManage
                      representative.
                      <br />
                      Please note that the RxManage process can take up to 30 days to qualify. If you need medication
                      immediately, we encourage you to please take your own steps to order and pay for it, until you are
                      approved.
                    </div>
                    <PromiseButton overrideClassName={'relative clever-btn mx-auto flex px-10'} onClick={onSubmit}>
                      Submit
                    </PromiseButton>
                    {!isSubmitting && (
                      <div
                        className={'cursor-pointer text-cleverGray-200 text-center font-thin text-base mt-3'}
                        onClick={onBack}
                      >
                        go back
                      </div>
                    )}
                  </div>
                ),
                5: () => (
                  <div>
                    <div className={'text-base text-center'}>We received your application request!</div>
                    <div className={'text-base text-center'}>You will receive email communication shortly.</div>
                    <div className={'font-bold text-lg text-center mb-10'}>Stay Clever!</div>
                    <button className={'clever-btn mx-auto flex px-10'} onClick={() => goBack(true)}>
                      Finish
                    </button>
                  </div>
                ),
              }}
            </StateSwitch>
          </motion.div>
        </AnimatePresence>
      </div>
    </div>
  );
};

const ArrowInfo = ({children}: {children: ReactNode}) => {
  return (
    <div className={'text-cleverGray-100 flex items-center my-2'}>
      <svg className={'w-4 h-4 fill-current mr-4'} viewBox="0 0 16 16">
        <path
          fillRule="evenodd"
          d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"
        />
      </svg>
      <div className={'flex-1 font-thin'}>{children}</div>
    </div>
  );
};
