/* This file was generated by https://github.com/dested/serverless-client-builder */
/* eslint-disable */
/* tslint:disable */
import {BaseClient, ControllerOptions} from './baseClient';
declare type ObjectId = string;

export class AdminAccountClient extends BaseClient {
  constructor(options: ControllerOptions) {
    super(options);
  }

  async updateAccount<TPromise = GetAccountResponse>(
    model: UpdateAccountsRequest,
    handle: {
      200?: (result: GetAccountResponse) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/account/?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async searchAccounts<TPromise = SearchAccountsResponse>(
    model: SearchAccountsRequest,
    handle: {
      200?: (result: SearchAccountsResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/account/search?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getAccount<TPromise = GetAccountResponse>(
    model: GetAccountRequest,
    handle: {
      200?: (result: GetAccountResponse) => void;
      500?: (result: string) => void;
      404: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/account/:accountId?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .filter((key) => key !== 'accountId')

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      url = url.replace(':accountId', model.accountId).replace('{accountId}', model.accountId);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 404) {
        await handle[404](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async deleteAccount<TPromise = VoidResponse>(
    model: GetAccountRequest,
    handle: {
      200?: (result: VoidResponse) => void;
      500?: (result: string) => void;
      404: (result: {error: string}) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/account/:accountId?';

      const options = {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      url = url.replace(':accountId', model.accountId).replace('{accountId}', model.accountId);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 404) {
        await handle[404](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async activateAccount<TPromise = GetAccountResponse>(
    model: GetAccountRequest,
    handle: {
      200?: (result: GetAccountResponse) => void;
      500?: (result: string) => void;
      404: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/account/:accountId/activate?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      url = url.replace(':accountId', model.accountId).replace('{accountId}', model.accountId);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 404) {
        await handle[404](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async createPartnerAccount<TPromise = CreatePartnerAccountResponse>(
    model: CreatePartnerAccountRequest,
    handle: {
      200?: (result: CreatePartnerAccountResponse) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      404: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/account/partner-account?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 404) {
        await handle[404](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }
}

export class AdminCardOrderClient extends BaseClient {
  constructor(options: ControllerOptions) {
    super(options);
  }

  async cardOrderValidation<TPromise = CardOrderValidationResponse>(
    model: CardOrderValidationRequest,
    handle: {
      200?: (result: CardOrderValidationResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/partner/card-order/validate?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getAllCardOrders<TPromise = GetAllCardOrdersResponse>(
    model: GetAllCardOrdersRequest,
    handle: {
      200?: (result: GetAllCardOrdersResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/partner/card-order/all-orders?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getAllCardOrdersCSV<TPromise = GetAllCardOrdersCSVResponse>(
    model: GetAllCardOrdersCSVRequest,
    handle: {
      200?: (result: GetAllCardOrdersCSVResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/partner/card-order/all-orders/csv?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getPartnerCardOrder<TPromise = GetPartnerCardOrderResponse>(
    model: GetPartnerCardOrderRequest,
    handle: {
      200?: (result: GetPartnerCardOrderResponse) => void;
      500?: (result: string) => void;
      404: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/partner/card-order/?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 404) {
        await handle[404](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async updatePartnerCardOrderStatus<TPromise = GetPartnerCardOrderResponse>(
    model: UpdatePartnerCardOrderStatusRequest,
    handle: {
      200?: (result: GetPartnerCardOrderResponse) => void;
      500?: (result: string) => void;
      404: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/partner/card-order/status?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 404) {
        await handle[404](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async updatePartnerCardOrder<TPromise = GetPartnerCardOrderResponse>(
    model: UpdatePartnerCardOrderRequest,
    handle: {
      200?: (result: GetPartnerCardOrderResponse) => void;
      500?: (result: string) => void;
      404: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/partner/card-order/?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 404) {
        await handle[404](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async uploadCardOrderProof<TPromise = GetPartnerCardOrderResponse>(
    model: UploadCardOrderProofRequest,
    handle: {
      200?: (result: GetPartnerCardOrderResponse) => void;
      500?: (result: string) => void;
      404: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/partner/card-order/upload-proof?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 404) {
        await handle[404](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async updatePartnerCardOrderTracking<TPromise = GetPartnerCardOrderResponse>(
    model: UpdatePartnerCardOrderTrackingRequest,
    handle: {
      200?: (result: GetPartnerCardOrderResponse) => void;
      500?: (result: string) => void;
      404: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/partner/card-order/tracking?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 404) {
        await handle[404](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async requestPartnerCardOrderLogo<TPromise = GetPartnerCardOrderResponse>(
    model: GetPartnerCardOrderRequest,
    handle: {
      200?: (result: GetPartnerCardOrderResponse) => void;
      500?: (result: string) => void;
      404: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/partner/card-order/request-logo?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 404) {
        await handle[404](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }
}

export class AdminClaimClient extends BaseClient {
  constructor(options: ControllerOptions) {
    super(options);
  }

  async getUnfoundClaims<TPromise = GetUnfoundClaimsResponse>(
    model: GetUnfoundClaimsRequest,
    handle: {
      200?: (result: GetUnfoundClaimsResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/claim/unfound?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async resolveClaim<TPromise = SuccessResponse>(
    model: ResolveClaimRequest,
    handle: {
      200?: (result: SuccessResponse) => void;
      500?: (result: string) => void;
      404: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/claim/resolve-unfound?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 404) {
        await handle[404](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getPayoutRequest<TPromise = ResolveClaimResponse>(
    model: VoidRequest,
    handle: {
      200?: (result: ResolveClaimResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/claim/payout/requests?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async fulfillPayoutRequest<TPromise = SuccessResponse>(
    model: FulfillPayoutRequestRequest,
    handle: {
      200?: (result: SuccessResponse) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/claim/payout/fulfill?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getCommissionResultMonths<TPromise = AdminMonthReportMonthsResponse>(
    model: VoidRequest,
    handle: {
      200?: (result: AdminMonthReportMonthsResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/claim/reports/commission-result-months?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getCommissionResult<TPromise = AdminMonthReportResponse>(
    model: AdminMonthReportRequest,
    handle: {
      200?: (result: AdminMonthReportResponse) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/claim/reports/commission-result?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }
}

export class AdminClient extends BaseClient {
  constructor(options: ControllerOptions) {
    super(options);
  }

  async login<TPromise = AdminLoginResponse>(
    model: AdminLoginRequest,
    handle: {200?: (result: AdminLoginResponse) => void; 500?: (result: string) => void; 401?: (error: string) => void}
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/login?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async report<TPromise = AdminReportResponse>(
    model: VoidRequest,
    handle: {200?: (result: AdminReportResponse) => void; 500?: (result: string) => void; 401?: (error: string) => void}
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/report?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async generateBinPcnReport<TPromise = AdminStringReportResponse>(
    model: AdminGenerateBinPcnReportRequest,
    handle: {
      200?: (result: AdminStringReportResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/bin-pcn-report?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }
}

export class AdminDashboardClient extends BaseClient {
  constructor(options: ControllerOptions) {
    super(options);
  }

  async getClaimsByDay<TPromise = GetClaimsByDayResponse>(
    model: GetClaimsByDayRequest,
    handle: {
      200?: (result: GetClaimsByDayResponse) => void;
      500?: (result: string) => void;
      404: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/dashboard/claims-by-day?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 404) {
        await handle[404](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async slugsByDay<TPromise = SlugsByDayResponse>(
    model: VoidRequest,
    handle: {200?: (result: SlugsByDayResponse) => void; 500?: (result: string) => void; 401?: (error: string) => void}
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/dashboard/slugs-by-day?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }
}

export class AdminFaqClient extends BaseClient {
  constructor(options: ControllerOptions) {
    super(options);
  }

  async getFaqDrafts<TPromise = AdminGetFaqsResponse>(
    model: VoidRequest,
    handle: {
      200?: (result: AdminGetFaqsResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/faq/drafts?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async updateFaqDraft<TPromise = AdminGetFaqsResponse>(
    model: AdminUpdateFaqRequest,
    handle: {
      200?: (result: AdminGetFaqsResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/faq/draft?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async publishFaqDraft<TPromise = VoidResponse>(
    model: AdminPublishFaqDraftRequest,
    handle: {200?: (result: VoidResponse) => void; 500?: (result: string) => void; 401?: (error: string) => void}
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/faq/publish?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }
}

export class AdminHealthReportsClient extends BaseClient {
  constructor(options: ControllerOptions) {
    super(options);
  }

  async getConsultsMonths<TPromise = AccountCommissionResultMonthsResponse>(
    model: PartnerRequest,
    handle: {
      200?: (result: AccountCommissionResultMonthsResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/partner/health/reports/consults-months?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getConsultsResult<TPromise = AccountClaimCommissionResultResponse>(
    model: AccountMonthReportRequest,
    handle: {
      200?: (result: AccountClaimCommissionResultResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/partner/health/reports/consults?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getConsultsResultCsv<TPromise = CsvResponse>(
    model: PartnerRequest,
    handle: {200?: (result: CsvResponse) => void; 500?: (result: string) => void; 401?: (error: string) => void}
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/partner/health/reports/consults-csv?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getHealthReports<TPromise = GetHealthReportsResponse>(
    model: GetHealthReportsRequest,
    handle: {
      200?: (result: GetHealthReportsResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/partner/health/reports/?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getHealthReportsUserDemographics<TPromise = GetHealthReportsUserDemographicsResponse>(
    model: GetHealthReportsRequest,
    handle: {
      200?: (result: GetHealthReportsUserDemographicsResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/partner/health/reports/demographics?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getHealthReportsCompletedCalls<TPromise = GetHealthReportsDataResponse>(
    model: GetHealthReportsDataRequest,
    handle: {
      200?: (result: GetHealthReportsDataResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/partner/health/reports/completed-calls?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getHealthReportsRedirectedCalls<TPromise = GetHealthReportsDataResponse>(
    model: GetHealthReportsDataRequest,
    handle: {
      200?: (result: GetHealthReportsDataResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/partner/health/reports/redirected-calls?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getHealthReportsUsersCreated<TPromise = GetHealthReportsDataResponse>(
    model: GetHealthReportsDataRequest,
    handle: {
      200?: (result: GetHealthReportsDataResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/partner/health/reports/users-created?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getHealthReportsVisitsStarted<TPromise = GetHealthReportsDataResponse>(
    model: GetHealthReportsDataRequest,
    handle: {
      200?: (result: GetHealthReportsDataResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/partner/health/reports/visits-started?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getHealthReportsVisitsCompleted<TPromise = GetHealthReportsDataResponse>(
    model: GetHealthReportsDataRequest,
    handle: {
      200?: (result: GetHealthReportsDataResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/partner/health/reports/visits-completed?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getHealthReportsVisitsTimeCompleted<TPromise = GetHealthReportsDataResponse>(
    model: GetHealthReportsDataRequest,
    handle: {
      200?: (result: GetHealthReportsDataResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/partner/health/reports/visits-time-completed?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getHealthReportsPrescriptionsSent<TPromise = GetHealthReportsDataResponse>(
    model: GetHealthReportsDataRequest,
    handle: {
      200?: (result: GetHealthReportsDataResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/partner/health/reports/prescriptions-sent?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getHealthReportsMedicineCabinet<TPromise = GetHealthReportsMedicineCabinetResponse>(
    model: GetHealthReportsMedicineCabinetRequest,
    handle: {
      200?: (result: GetHealthReportsMedicineCabinetResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/partner/health/reports/medicine-cabinet?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getHealthReportsUsersByCard<TPromise = GetHealthReportsUsersByCardResponse>(
    model: GetHealthReportsUsersByCardRequest,
    handle: {
      200?: (result: GetHealthReportsUsersByCardResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/partner/health/reports/users-by-card?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getHealthDownloadsByPartner<TPromise = GetDownloadsByPartnerResponse>(
    model: PartnerRequest,
    handle: {
      200?: (result: GetDownloadsByPartnerResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/partner/health/reports/downloads-by-partner?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getHealthReportsChargesByCard<TPromise = GetHealthReportsChargesByCardResponse>(
    model: GetHealthReportsUsersByCardRequest,
    handle: {
      200?: (result: GetHealthReportsChargesByCardResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/partner/health/reports/charges-by-card?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getHealthReportsTopPricedDrugs<TPromise = GetHealthReportsTopPricedDrugsResponse>(
    model: GetHealthReportsTopPricedDrugsRequest,
    handle: {
      200?: (result: GetHealthReportsTopPricedDrugsResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/partner/health/reports/top-priced-drugs?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getHealthReportsSurveys<TPromise = GetHealthReportsSurveysResponse>(
    model: GetHealthReportsSurveysRequest,
    handle: {
      200?: (result: GetHealthReportsSurveysResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/partner/health/reports/surveys?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getHealthReportsSurveyStats<TPromise = GetHealthReportsSurveyStatsResponse>(
    model: GetHealthReportsSurveyStatsRequest,
    handle: {
      200?: (result: GetHealthReportsSurveyStatsResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/partner/health/reports/survey-stats?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getHealthPartnerKPIsPartners<TPromise = GetHealthPartnerKPIsPartnersResponse>(
    model: GetHealthPartnerKPIsPartnersRequest,
    handle: {
      200?: (result: GetHealthPartnerKPIsPartnersResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/partner/health/reports/kpis/partners?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getHealthPartnerKPIsPartnersCsv<TPromise = CsvResponse>(
    model: GetHealthPartnerKPIsPartnersCsvRequest,
    handle: {200?: (result: CsvResponse) => void; 500?: (result: string) => void; 401?: (error: string) => void}
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/partner/health/reports/kpis/partners-csv?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getHealthPartnerKPIsPartner<TPromise = GetHealthPartnerKPIsPartnerResponse>(
    model: PartnerRequest,
    handle: {
      200?: (result: GetHealthPartnerKPIsPartnerResponse) => void;
      500?: (result: string) => void;
      404: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/partner/health/reports/kpis/partner?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 404) {
        await handle[404](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getHealthPartnerKPIsReports<TPromise = GetHealthPartnerKPIsReportsResponse>(
    model: GetHealthPartnerKPIsReportsRequest,
    handle: {
      200?: (result: GetHealthPartnerKPIsReportsResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/partner/health/reports/kpis/reports?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getVisitsByGroup<TPromise = GetVisitsByGroupResponse>(
    model: GetVisitsByGroupRequest,
    handle: {
      200?: (result: GetVisitsByGroupResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/partner/health/reports/visits-by-group?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getGroupVisitCsv<TPromise = CsvResponse>(
    model: GetGroupVisitCsvRequest,
    handle: {200?: (result: CsvResponse) => void; 500?: (result: string) => void; 401?: (error: string) => void}
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/partner/health/reports/group-visit-csv?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }
}

export class AdminHelpCenterClient extends BaseClient {
  constructor(options: ControllerOptions) {
    super(options);
  }

  async getCategories<TPromise = GetAdminHelpCenterCategoriesResponse>(
    model: VoidRequest,
    handle: {
      200?: (result: GetAdminHelpCenterCategoriesResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/help-center/categories?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getResources<TPromise = GetAdminHelpCenterResourcesResponse>(
    model: GetAdminHelpCenterResourcesRequest,
    handle: {
      200?: (result: GetAdminHelpCenterResourcesResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/help-center/resources?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getResource<TPromise = GetAdminHelpCenterResourceResponse>(
    model: GetAdminHelpCenterResourceBySlugRequest,
    handle: {
      200?: (result: GetAdminHelpCenterResourceResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/help-center/resource?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async updateResource<TPromise = GetAdminHelpCenterResourceResponse>(
    model: UpdateAdminHelpCenterResourceRequest,
    handle: {
      200?: (result: GetAdminHelpCenterResourceResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/help-center/resource?';

      const options = {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async deleteResource<TPromise = VoidResponse>(
    model: GetAdminHelpCenterResourceRequest,
    handle: {200?: (result: VoidResponse) => void; 500?: (result: string) => void; 401?: (error: string) => void}
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/help-center/resource?';

      const options = {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async createResource<TPromise = CreateAdminHelpCenterResourceResponse>(
    model: CreateAdminHelpCenterResourceRequest,
    handle: {
      200?: (result: CreateAdminHelpCenterResourceResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/help-center/resource?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async createAttachment<TPromise = CreateAdminHelpCenterResourceAttachmentResponse>(
    model: CreateAdminHelpCenterResourceAttachmentRequest,
    handle: {
      200?: (result: CreateAdminHelpCenterResourceAttachmentResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/help-center/attachment?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getAttachmentUrl<TPromise = GetAdminHelpCenterResourceAttachmentResponse>(
    model: GetAdminHelpCenterResourceAttachmentRequest,
    handle: {
      200?: (result: GetAdminHelpCenterResourceAttachmentResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/help-center/attachment?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }
}

export class AdminMarketingClient extends BaseClient {
  constructor(options: ControllerOptions) {
    super(options);
  }

  async allMaterials<TPromise = GetAllMarketingResponse>(
    model: VoidRequest,
    handle: {
      200?: (result: GetAllMarketingResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/marketing/materials?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async addMaterials<TPromise = AddMaterialResponse>(
    model: AddMaterialRequest,
    handle: {200?: (result: AddMaterialResponse) => void; 500?: (result: string) => void; 401?: (error: string) => void}
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/marketing/material?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async deleteMaterials<TPromise = VoidResponse>(
    model: MaterialRequest,
    handle: {200?: (result: VoidResponse) => void; 500?: (result: string) => void; 401?: (error: string) => void}
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/marketing/material?';

      const options = {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getCampaignTemplates<TPromise = GetCampaignTemplatesResponse>(
    model: VoidRequest,
    handle: {
      200?: (result: GetCampaignTemplatesResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/marketing/campaign-templates?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async submitMarketingCampaign<TPromise = VoidResponse>(
    model: SubmitMarketingCampaignRequest,
    handle: {
      200?: (result: VoidResponse) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/marketing/submit-marketing-campaign?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getCHMarketingCampaignStats<TPromise = GetCHMarketingCampaignStatsResponse>(
    model: GetCHMarketingCampaignStatsRequest,
    handle: {
      200?: (result: GetCHMarketingCampaignStatsResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/marketing/marketing-reports/stats?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getCHMarketingCampaigns<TPromise = GetCHMarketingCampaignsResponse>(
    model: GetCHMarketingCampaignsRequest,
    handle: {
      200?: (result: GetCHMarketingCampaignsResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/marketing/marketing-reports/campaigns?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async sendTestEmail<TPromise = VoidResponse>(
    model: SendTestEmailRequest,
    handle: {
      200?: (result: VoidResponse) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/marketing/send-test-email?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async uploadVoluntaryCensus<TPromise = UploadVoluntaryCensusResponse>(
    model: UploadVoluntaryCensusRequest,
    handle: {
      200?: (result: UploadVoluntaryCensusResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/marketing/voluntary-census?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }
}

export class AdminPartnerCmsClient extends BaseClient {
  constructor(options: ControllerOptions) {
    super(options);
  }

  async getAllPendingContent<TPromise = AdminCmsGetAllContentResponse>(
    model: VoidRequest,
    handle: {
      200?: (result: AdminCmsGetAllContentResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/partner/clh-cms/pending-content?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getPendingContent<TPromise = AdminCmsGetContentResponse>(
    model: AdminCmsGetContentRequest,
    handle: {
      200?: (result: AdminCmsGetContentResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/partner/clh-cms/pending-content/:versionId?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .filter((key) => key !== 'versionId')

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      url = url.replace(':versionId', model.versionId).replace('{versionId}', model.versionId);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async publishContent<TPromise = VoidResponse>(
    model: AdminCmsGetContentRequest,
    handle: {200?: (result: VoidResponse) => void; 500?: (result: string) => void; 401?: (error: string) => void}
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/partner/clh-cms/publish-content?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }
}

export class AdminPartnerClient extends BaseClient {
  constructor(options: ControllerOptions) {
    super(options);
  }

  async createPartner<TPromise = GetPartnerResponse>(
    model: CreatePartnersRequest,
    handle: {
      200?: (result: GetPartnerResponse) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/partner/?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async breakOffPartner<TPromise = GetPartnerResponse>(
    model: BreakPartnerOffRequestRequest,
    handle: {
      200?: (result: GetPartnerResponse) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/partner/break-off?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async updatePartner<TPromise = GetPartnerResponse>(
    model: UpdatePartnerRequest,
    handle: {
      200?: (result: GetPartnerResponse) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/partner/?';

      const options = {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getAllAmountsDue<TPromise = GetAllAmountsDueResponse>(
    model: VoidRequest,
    handle: {
      200?: (result: GetAllAmountsDueResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/partner/all-amounts-due?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getAllAmountsDueCsv<TPromise = CsvResponse>(
    model: VoidRequest,
    handle: {200?: (result: CsvResponse) => void; 500?: (result: string) => void; 401?: (error: string) => void}
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/partner/all-amounts-due-csv?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async linkStripe<TPromise = LinkStripeResponse>(
    model: LinkStripeRequest,
    handle: {
      200?: (result: LinkStripeResponse) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      404: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/partner/link-stripe?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 404) {
        await handle[404](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async resendStripeLink<TPromise = VoidResponse>(
    model: PartnerRequest,
    handle: {
      200?: (result: VoidResponse) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      404: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/partner/resend-stripe-link?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 404) {
        await handle[404](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async updateCard<TPromise = GetCardResponse>(
    model: UpdateCardRequest,
    handle: {
      200?: (result: GetCardResponse) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/partner/card?';

      const options = {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async addManualDebit<TPromise = GetLedgerResponse>(
    model: AddManualDebitRequest,
    handle: {
      200?: (result: GetLedgerResponse) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/partner/manual-debit?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async addManualCredit<TPromise = GetLedgerResponse>(
    model: AddManualDebitRequest,
    handle: {
      200?: (result: GetLedgerResponse) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/partner/manual-credit?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async deleteCard<TPromise = VoidResponse>(
    model: CardRequest,
    handle: {
      200?: (result: VoidResponse) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/partner/card/:cardId?';

      const options = {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      url = url.replace(':cardId', model.cardId).replace('{cardId}', model.cardId);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async deletePartner<TPromise = VoidResponse>(
    model: PartnerRequest,
    handle: {
      200?: (result: VoidResponse) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/partner/partner/:partnerId?';

      const options = {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      url = url.replace(':partnerId', model.partnerId).replace('{partnerId}', model.partnerId);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async sendToDocusign<TPromise = GetPartnerResponse>(
    model: PartnerRequest,
    handle: {
      200?: (result: GetPartnerResponse) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/partner/docusign?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async createCard<TPromise = GetCardResponse>(
    model: CreateCardRequest,
    handle: {
      200?: (result: GetCardResponse) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/partner/card?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async startCreatePartner<TPromise = StartCreatePartnersResponse>(
    model: StartCreatePartnersRequest,
    handle: {
      200?: (result: StartCreatePartnersResponse) => void;
      500?: (result: string) => void;
      404: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/partner/start?';

      const options = {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 404) {
        await handle[404](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async startCreateCard<TPromise = StartCreatePartnersResponse>(
    model: StartCreatePartnersRequest,
    handle: {
      200?: (result: StartCreatePartnersResponse) => void;
      500?: (result: string) => void;
      404: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/partner//card/start?';

      const options = {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 404) {
        await handle[404](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async validatePartnerNameUnique<TPromise = ValidateUniqueResponse>(
    model: ValidatePartnerNameUniqueRequest,
    handle: {
      200?: (result: ValidateUniqueResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/partner/validate-partner-name?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async validateUrlSlugUnique<TPromise = ValidateUniqueResponse>(
    model: ValidateUrlSlugUniqueRequest,
    handle: {
      200?: (result: ValidateUniqueResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/partner/validate-url-slug?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async searchPartners<TPromise = SearchPartnersResponse>(
    model: SearchPartnersRequest,
    handle: {
      200?: (result: SearchPartnersResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/partner/search?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getPartner<TPromise = GetPartnerResponse>(
    model: GetPartnerRequest,
    handle: {
      200?: (result: GetPartnerResponse) => void;
      500?: (result: string) => void;
      404: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/partner/:partnerId?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .filter((key) => key !== 'partnerId')

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      url = url.replace(':partnerId', model.partnerId).replace('{partnerId}', model.partnerId);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 404) {
        await handle[404](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getCard<TPromise = GetCardResponse>(
    model: GetCardRequest,
    handle: {
      200?: (result: GetCardResponse) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/partner/card/:cardId?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .filter((key) => key !== 'cardId')

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      url = url.replace(':cardId', model.cardId).replace('{cardId}', model.cardId);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getReferrals<TPromise = GetReferralPartnersResponse>(
    model: GetPartnerRequest,
    handle: {
      200?: (result: GetReferralPartnersResponse) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/partner/referrals?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getPayouts<TPromise = GetPayoutsResponse>(
    model: GetPartnerRequest,
    handle: {
      200?: (result: GetPayoutsResponse) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/partner/payouts?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getAllPartnerRequests<TPromise = AdminPartnerRequestsResponse>(
    model: PageRequest,
    handle: {
      200?: (result: AdminPartnerRequestsResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/partner/partner-requests?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async adjudicatePartnerRequest<TPromise = AdminAdjudicatePartnerResponse>(
    model: AdminAdjudicatePartnerRequest,
    handle: {
      200?: (result: AdminAdjudicatePartnerResponse) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/partner/partner-requests?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async makePharmacist<TPromise = GetPartnerResponse>(
    model: GetPartnerRequest,
    handle: {
      200?: (result: GetPartnerResponse) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/partner/make-pharmacist?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async makeParentPharmacist<TPromise = GetPartnerResponse>(
    model: GetPartnerRequest,
    handle: {
      200?: (result: GetPartnerResponse) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/partner/make-parent-pharmacist?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getClientUsers<TPromise = GetClientUsersResponse>(
    model: GetClientUsersRequest,
    handle: {
      200?: (result: GetClientUsersResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/partner/client-users?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getHealthClient<TPromise = GetHealthClientResponse>(
    model: GetHealthClientRequest,
    handle: {
      200?: (result: GetHealthClientResponse) => void;
      500?: (result: string) => void;
      404: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/partner/client?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 404) {
        await handle[404](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async createClient<TPromise = GetClientResponse>(
    model: CreateClientRequest,
    handle: {
      200?: (result: GetClientResponse) => void;
      500?: (result: string) => void;
      404: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/partner/client?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 404) {
        await handle[404](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async updateClient<TPromise = GetHealthClientResponse>(
    model: UpdateClientRequest,
    handle: {
      200?: (result: GetHealthClientResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/partner/client?';

      const options = {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async createClientUser<TPromise = GetClientUserResponse>(
    model: CreateClientUserRequest,
    handle: {
      200?: (result: GetClientUserResponse) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/partner/client-user?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async editClientUser<TPromise = GetClientUserResponse>(
    model: EditClientUserRequest,
    handle: {
      200?: (result: GetClientUserResponse) => void;
      500?: (result: string) => void;
      404: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/partner/edit-client-user?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 404) {
        await handle[404](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async deleteClientUser<TPromise = VoidResponse>(
    model: GetClientUserRequest,
    handle: {
      200?: (result: VoidResponse) => void;
      500?: (result: string) => void;
      404: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/partner/delete-client-user?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 404) {
        await handle[404](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async activateClientUser<TPromise = GetClientUserResponse>(
    model: ActivateClientUserRequest,
    handle: {
      200?: (result: GetClientUserResponse) => void;
      500?: (result: string) => void;
      404: (result: {error: string}) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/partner/activate-user?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 404) {
        await handle[404](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async deactivateClientUser<TPromise = GetClientUserResponse>(
    model: ActivateClientUserRequest,
    handle: {
      200?: (result: GetClientUserResponse) => void;
      500?: (result: string) => void;
      404: (result: {error: string}) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/partner/deactivate-client-user?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 404) {
        await handle[404](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async setClaimReconcileRule<TPromise = VoidResponse>(
    model: SetClaimReconcileRuleRequest,
    handle: {
      200?: (result: VoidResponse) => void;
      500?: (result: string) => void;
      404: (result: {error: string}) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/partner/claim-reconcile-rule?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 404) {
        await handle[404](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async autoSignDocument<TPromise = GetPartnerResponse>(
    model: AutoSignDocumentRequest,
    handle: {
      200?: (result: GetPartnerResponse) => void;
      500?: (result: string) => void;
      404: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/partner/auto-sign-document?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 404) {
        await handle[404](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getPartnerAccounts<TPromise = GetPartnerAccountsResponse>(
    model: GetPartnerRequest,
    handle: {
      200?: (result: GetPartnerAccountsResponse) => void;
      500?: (result: string) => void;
      404: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/partner/partner-accounts?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 404) {
        await handle[404](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async updatePartnerPrimaryAccount<TPromise = GetPartnerAndAccountsResponse>(
    model: GetAccountRequest,
    handle: {
      200?: (result: GetPartnerAndAccountsResponse) => void;
      500?: (result: string) => void;
      404: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/partner/primary-account?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 404) {
        await handle[404](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getPharmacistDownloads<TPromise = GetPharmacistDownloadsResponse>(
    model: GetPartnerRequest,
    handle: {
      200?: (result: GetPharmacistDownloadsResponse) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/partner/pharmacist-downloads?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getRxUsers<TPromise = GetRxUsersResponse>(
    model: GetRxUsersRequest,
    handle: {200?: (result: GetRxUsersResponse) => void; 500?: (result: string) => void; 401?: (error: string) => void}
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/partner/rx-users?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getRxUser<TPromise = GetRxUserResponse>(
    model: GetRxUserRequest,
    handle: {
      200?: (result: GetRxUserResponse) => void;
      500?: (result: string) => void;
      404: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/partner/rx-user?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 404) {
        await handle[404](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async updateRxUser<TPromise = GetRxUserResponse>(
    model: UpdateRxUserRequest,
    handle: {
      200?: (result: GetRxUserResponse) => void;
      500?: (result: string) => void;
      404: (result: {error: string}) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/partner/rx-user?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 404) {
        await handle[404](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getPartnerCardCsv<TPromise = CsvResponse>(
    model: GetPartnerRequest,
    handle: {200?: (result: CsvResponse) => void; 500?: (result: string) => void; 401?: (error: string) => void}
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/partner/partner-card-csv?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }
}

export class AdminPartnerHealthClient extends BaseClient {
  constructor(options: ControllerOptions) {
    super(options);
  }

  async makeHealthPartner<TPromise = GetPartnerResponse>(
    model: GetPartnerRequest,
    handle: {
      200?: (result: GetPartnerResponse) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/partner/health/make-health-partner?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getHealthConfig<TPromise = HealthConfigResponse>(
    model: GetCardRequest,
    handle: {
      200?: (result: HealthConfigResponse) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      404: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/partner/health/health-config?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 404) {
        await handle[404](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async updateHealthConfig<TPromise = HealthConfigResponse>(
    model: UpdateHealthConfigRequest,
    handle: {
      200?: (result: HealthConfigResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/partner/health/health-config?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async updateCommission<TPromise = GetPartnerResponse>(
    model: UpdatePartnerHealthCommissionRequest,
    handle: {
      200?: (result: GetPartnerResponse) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/partner/health/update-commission?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getReferrals<TPromise = GetReferralPartnersResponse>(
    model: GetPartnerRequest,
    handle: {
      200?: (result: GetReferralPartnersResponse) => void;
      500?: (result: string) => void;
      404: (result: {error: string}) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/partner/health/referrals?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 404) {
        await handle[404](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getHealthAdminClientUsers<TPromise = GetAdminHealthClientUsersResponse>(
    model: GetAdminHealthClientUsersRequest,
    handle: {
      200?: (result: GetAdminHealthClientUsersResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/partner/health/client-users?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getHealthVisits<TPromise = GetHealthVisitsResponse>(
    model: GetHealthVisitsRequest,
    handle: {
      200?: (result: GetHealthVisitsResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/partner/health/visits?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getHealthUsers<TPromise = GetHealthUsersResponse>(
    model: GetHealthUsersRequest,
    handle: {
      200?: (result: GetHealthUsersResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/partner/health/users?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async downloadUserCsv<TPromise = CsvResponse>(
    model: DownloadUserCsvRequest,
    handle: {200?: (result: CsvResponse) => void; 500?: (result: string) => void; 401?: (error: string) => void}
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/partner/health/download-user-csv?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getHealthUser<TPromise = GetHealthUserResponse>(
    model: GetHealthUserRequest,
    handle: {
      200?: (result: GetHealthUserResponse) => void;
      500?: (result: string) => void;
      404: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/partner/health/user?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 404) {
        await handle[404](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async updateHealthUser<TPromise = GetHealthUserResponse>(
    model: UpdateHealthUserRequest,
    handle: {
      200?: (result: GetHealthUserResponse) => void;
      500?: (result: string) => void;
      404: (result: {error: string}) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/partner/health/user?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 404) {
        await handle[404](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getHealthIvrAnalytics<TPromise = GetHealthIvrAnalyticsResponse>(
    model: GetHealthIvrAnalyticsRequest,
    handle: {
      200?: (result: GetHealthIvrAnalyticsResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/partner/health/ivr-analytics?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getHealthVisitData<TPromise = GetHealthVisitDataResponse>(
    model: GetHealthVisitDataRequest,
    handle: {
      200?: (result: GetHealthVisitDataResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/partner/health/health/visit?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getAdminEDIMembers<TPromise = GetAdminEDIMembersResponse>(
    model: GetAdminEDIMembersRequest,
    handle: {
      200?: (result: GetAdminEDIMembersResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/partner/health/health/eligibility/members?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getAdminEDIMember<TPromise = GetAdminEDIMemberResponse>(
    model: GetAdminEDIMemberRequest,
    handle: {
      200?: (result: GetAdminEDIMemberResponse) => void;
      500?: (result: string) => void;
      404: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/partner/health/health/eligibility/member?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 404) {
        await handle[404](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async updateAdminEDIMember<TPromise = UpdateAdminEDIMemberResponse>(
    model: UpdateAdminEDIMemberRequest,
    handle: {
      200?: (result: UpdateAdminEDIMemberResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/partner/health/health/eligibility/member?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getAdminEDIMemberFamilyTree<TPromise = GetAdminEDIMemberTreeResponse>(
    model: GetAdminEDIMemberTreeRequest,
    handle: {
      200?: (result: GetAdminEDIMemberTreeResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/partner/health/health/eligibility/family-tree?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getHealthClientInvoices<TPromise = GetHealthClientInvoicesResponse>(
    model: GetHealthClientInvoicesRequest,
    handle: {
      200?: (result: GetHealthClientInvoicesResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/partner/health/invoices?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getHealthClientInvoice<TPromise = GetHealthClientInvoiceResponse>(
    model: GetHealthClientInvoiceRequest,
    handle: {
      200?: (result: GetHealthClientInvoiceResponse) => void;
      500?: (result: string) => void;
      404: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/partner/health/invoice?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 404) {
        await handle[404](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getInvoicePdf<TPromise = VoidResponse>(
    model: GetHealthClientInvoiceRequest,
    handle: {200?: (result: VoidResponse) => void; 500?: (result: string) => void; 401?: (error: string) => void}
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/partner/health/invoice/pdf?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async markInvoiceAsPaid<TPromise = GetHealthClientInvoiceResponse>(
    model: GetHealthClientInvoiceRequest,
    handle: {
      200?: (result: GetHealthClientInvoiceResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/partner/health/invoice/mark-paid?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getHealthClientRequests<TPromise = GetHealthClientRequestsResponse>(
    model: GetHealthClientRequestsRequest,
    handle: {
      200?: (result: GetHealthClientRequestsResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/partner/health/client-requests?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getHealthClientRequest<TPromise = GetHealthClientRequestResponse>(
    model: GetHealthClientRequestRequest,
    handle: {
      200?: (result: GetHealthClientRequestResponse) => void;
      500?: (result: string) => void;
      404: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/partner/health/client-request?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 404) {
        await handle[404](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async updateHealthClientRequest<TPromise = GetHealthClientRequestResponse>(
    model: UpdateHealthClientRequestRequest,
    handle: {
      200?: (result: GetHealthClientRequestResponse) => void;
      500?: (result: string) => void;
      404: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/partner/health/client-request?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 404) {
        await handle[404](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async adjudicateHealthClientRequest<TPromise = GetHealthClientRequestResponse>(
    model: AdjudicateHealthClientRequestRequest,
    handle: {
      200?: (result: GetHealthClientRequestResponse) => void;
      500?: (result: string) => void;
      404: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/partner/health/adjudicate-client-request?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 404) {
        await handle[404](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async createAdminGroup<TPromise = CreateAdminGroupResponse>(
    model: CreateAdminGroupRequest,
    handle: {
      200?: (result: CreateAdminGroupResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/partner/health/create-group?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getHealthGroups<TPromise = GetHealthGroupsResponse>(
    model: GetHealthGroupsRequest,
    handle: {
      200?: (result: GetHealthGroupsResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/partner/health/groups?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getHealthGroupsCsv<TPromise = CsvResponse>(
    model: GetHealthGroupsCsvRequest,
    handle: {200?: (result: CsvResponse) => void; 500?: (result: string) => void; 401?: (error: string) => void}
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/partner/health/groups/csv?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async sendClientAgreement<TPromise = GetHealthClientResponse>(
    model: AdminUpdateHealthClientContactsRequest,
    handle: {
      200?: (result: GetHealthClientResponse) => void;
      500?: (result: string) => void;
      404: (result: {error: string}) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/partner/health/send-client-agreement?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 404) {
        await handle[404](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async sendVoluntaryClientAgreement<TPromise = GetHealthClientResponse>(
    model: AdminUpdateHealthClientContactsRequest,
    handle: {
      200?: (result: GetHealthClientResponse) => void;
      500?: (result: string) => void;
      404: (result: {error: string}) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/partner/health/send-voluntary-client-agreement?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 404) {
        await handle[404](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async overrideClientAgreement<TPromise = GetHealthClientResponse>(
    model: AdminSendClientAgreementRequest,
    handle: {
      200?: (result: GetHealthClientResponse) => void;
      500?: (result: string) => void;
      404: (result: {error: string}) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/partner/health/override-client-agreement?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 404) {
        await handle[404](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async updateHealthClientContacts<TPromise = VoidResponse>(
    model: AdminUpdateHealthClientContactsRequest,
    handle: {
      200?: (result: VoidResponse) => void;
      500?: (result: string) => void;
      404: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/partner/health/client/contacts?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 404) {
        await handle[404](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async searchGroups<TPromise = SearchGroupsResponse>(
    model: SearchGroupsRequest,
    handle: {
      200?: (result: SearchGroupsResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/partner/health/search-groups?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async setupAsOpenGroup<TPromise = VoidResponse>(
    model: CardRequest,
    handle: {200?: (result: VoidResponse) => void; 500?: (result: string) => void; 401?: (error: string) => void}
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/partner/health/client/setup-open?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async setupStripeForGroup<TPromise = GetHealthClientResponse>(
    model: CardRequest,
    handle: {
      200?: (result: GetHealthClientResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/partner/health/setup-stripe?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async setGroupInvoiceOptions<TPromise = GetHealthClientResponse>(
    model: SetGroupInvoiceOptionsRequest,
    handle: {
      200?: (result: GetHealthClientResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/partner/health/invoice-options?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getCompanyName<TPromise = GetCompanyNameResponse>(
    model: GetCompanyNameRequest,
    handle: {
      200?: (result: GetCompanyNameResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/partner/health/company-name?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async createGroupProductCode<TPromise = CreateProductCodeResponse>(
    model: CreateGroupProductCodeRequest,
    handle: {
      200?: (result: CreateProductCodeResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/partner/health/group-product-code?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getGroupProductCode<TPromise = GetGroupProductCodeResponse>(
    model: GetProductCodeRequest,
    handle: {
      200?: (result: GetGroupProductCodeResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/partner/health/group-product-code?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async updateGroupProductCode<TPromise = GetGroupProductCodeResponse>(
    model: UpdateGroupProductCodeRequest,
    handle: {
      200?: (result: GetGroupProductCodeResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/partner/health/group-product-code?';

      const options = {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async validateGroupProductCodeUnique<TPromise = ValidateUniqueResponse>(
    model: ValidateGroupProductCodeUniqueRequest,
    handle: {
      200?: (result: ValidateUniqueResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/partner/health/validate-group-product-code?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getGroupProductCodeStartingNetworks<TPromise = GetProductCodeStartingNetworksResponse>(
    model: ClientRequest,
    handle: {
      200?: (result: GetProductCodeStartingNetworksResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/partner/health/group-product-code/starting-networks?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getIvrEnabledClients<TPromise = GetIvrEnabledClientsResponse>(
    model: VoidRequest,
    handle: {
      200?: (result: GetIvrEnabledClientsResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/partner/health/ivr-enabled-clients?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async processVpcUserCsv<TPromise = ProcessVpcUserCsvResponse>(
    model: ProcessVpcUserCsvRequest,
    handle: {
      200?: (result: ProcessVpcUserCsvResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/partner/health/process-vpc-user-csv?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async search<TPromise = AdminSearchResponse>(
    model: AdminSearchRequest,
    handle: {200?: (result: AdminSearchResponse) => void; 500?: (result: string) => void; 401?: (error: string) => void}
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/partner/health/search?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }
}

export class AdminPepmClient extends BaseClient {
  constructor(options: ControllerOptions) {
    super(options);
  }

  async setupPepmConfig<TPromise = GetHealthClientOrTpaResponse>(
    model: AdminUpdatePepmRequest,
    handle: {
      200?: (result: GetHealthClientOrTpaResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/pepm/setup-config?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async updatePepmConfig<TPromise = GetHealthClientOrTpaResponse>(
    model: AdminUpdatePepmRequest,
    handle: {
      200?: (result: GetHealthClientOrTpaResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/pepm/update-config?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async updatePepmContacts<TPromise = GetHealthClientOrTpaResponse>(
    model: AdminUpdatePepmContactsRequest,
    handle: {
      200?: (result: GetHealthClientOrTpaResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/pepm/contacts?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getPepmInvoices<TPromise = GetAdminPepmInvoicesResponse>(
    model: GetAdminPepmInvoicesRequest,
    handle: {
      200?: (result: GetAdminPepmInvoicesResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/pepm/invoices?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getPepmInvoice<TPromise = GetAdminPepmInvoiceResponse>(
    model: GetAdminPepmInvoiceRequest,
    handle: {
      200?: (result: GetAdminPepmInvoiceResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/pepm/invoice?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async markInvoiceAsPaid<TPromise = GetAdminPepmInvoiceResponse>(
    model: GetAdminPepmInvoiceRequest,
    handle: {
      200?: (result: GetAdminPepmInvoiceResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/pepm/invoice/mark-paid?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }
}

export class AdminReportsClient extends BaseClient {
  constructor(options: ControllerOptions) {
    super(options);
  }

  async getDownloadsByPartner<TPromise = GetDownloadsByPartnerResponse>(
    model: GetDownloadsByPartnerRequest,
    handle: {
      200?: (result: GetDownloadsByPartnerResponse) => void;
      500?: (result: string) => void;
      404: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/reports/downloads-by-partner?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 404) {
        await handle[404](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getClaimsReport<TPromise = ClaimsReportResponse>(
    model: GetClaimsReportRequest,
    handle: {
      200?: (result: ClaimsReportResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/reports/claims?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getMasterClaimsReport<TPromise = MasterClaimReportResponse>(
    model: GetMasterClaimReportRequest,
    handle: {
      200?: (result: MasterClaimReportResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/reports/master-claims?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getAllTimeClaimsReport<TPromise = AllTimeClaimsReportResponse>(
    model: GetAllTimeClaimsReportRequest,
    handle: {
      200?: (result: AllTimeClaimsReportResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/reports/all-time-claims?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getClaimsByDayReport<TPromise = GetClaimsByDayReportResponse>(
    model: GetClaimsDayReportRequest,
    handle: {
      200?: (result: GetClaimsByDayReportResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/reports/claims-by-day?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getClaimsResultReport<TPromise = GetClaimsResultReportResponse>(
    model: GetClaimsReportRequest,
    handle: {
      200?: (result: GetClaimsResultReportResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/reports/claims-result-report?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getVoucherSurveyStats<TPromise = GetVoucherSurveyStatsResponse>(
    model: GetVoucherSurveyStatsRequest,
    handle: {
      200?: (result: GetVoucherSurveyStatsResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/reports/voucher-survey-stats?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getVoucherSurveys<TPromise = GetVoucherSurveysResponse>(
    model: GetVoucherSurveysRequest,
    handle: {
      200?: (result: GetVoucherSurveysResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/reports/voucher-surveys?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getPapSubmissions<TPromise = GetPapSubmissionsResponse>(
    model: VoidRequest,
    handle: {
      200?: (result: GetPapSubmissionsResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/reports/pap-submissions?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }
}

export class AdminScriptSaveClient extends BaseClient {
  constructor(options: ControllerOptions) {
    super(options);
  }

  async search<TPromise = AdminScriptSaveSearchResponse>(
    model: AdminScriptSaveSearchRequest,
    handle: {
      200?: (result: AdminScriptSaveSearchResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/scriptsave/search?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async findDrugs<TPromise = AdminScriptSaveFindDrugsResponse>(
    model: AdminScriptSaveFindDrugsRequest,
    handle: {
      200?: (result: AdminScriptSaveFindDrugsResponse) => void;
      500?: (result: string) => void;
      404: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/scriptsave/find-drugs?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 404) {
        await handle[404](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getDrugStructure<TPromise = AdminScriptSaveGetDrugStructureResponse>(
    model: AdminScriptSaveGetDrugStructureRequest,
    handle: {
      200?: (result: AdminScriptSaveGetDrugStructureResponse) => void;
      500?: (result: string) => void;
      404: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/scriptsave/drug-structure?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 404) {
        await handle[404](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async price<TPromise = AdminScriptSavePriceResponse>(
    model: AdminScriptSavePriceRequest,
    handle: {
      200?: (result: AdminScriptSavePriceResponse) => void;
      500?: (result: string) => void;
      404: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/scriptsave/price?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 404) {
        await handle[404](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async priceSinglecareOnly<TPromise = PricingV2Response>(
    model: PriceV2Request,
    handle: {
      200?: (result: PricingV2Response) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/scriptsave/price-singlecare-only?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async priceRxEdoOnly<TPromise = PricingV2Response>(
    model: PriceV2Request,
    handle: {
      200?: (result: PricingV2Response) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/scriptsave/price-rxedo-only?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }
}

export class AdminTpaClient extends BaseClient {
  constructor(options: ControllerOptions) {
    super(options);
  }

  async createTpa<TPromise = CreateTpaResponse>(
    model: CreateTpaRequest,
    handle: {200?: (result: CreateTpaResponse) => void; 500?: (result: string) => void; 401?: (error: string) => void}
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/partner/health/tpa/?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getTpa<TPromise = GetTpaResponse>(
    model: TpaRequest,
    handle: {200?: (result: GetTpaResponse) => void; 500?: (result: string) => void; 401?: (error: string) => void}
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/partner/health/tpa/?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getTpaSubGroups<TPromise = GetTpaSubGroupsResponse>(
    model: TpaRequest,
    handle: {
      200?: (result: GetTpaSubGroupsResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/partner/health/tpa/sub-groups?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async generateTpaSubGroups<TPromise = GetTpaResponse>(
    model: GenerateTpaSubGroupsRequest,
    handle: {200?: (result: GetTpaResponse) => void; 500?: (result: string) => void; 401?: (error: string) => void}
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/partner/health/tpa/generate-sub-groups?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async updateTpa<TPromise = GetTpaResponse>(
    model: UpdateTpaRequest,
    handle: {200?: (result: GetTpaResponse) => void; 500?: (result: string) => void; 401?: (error: string) => void}
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/partner/health/tpa/?';

      const options = {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async deleteTpa<TPromise = VoidResponse>(
    model: TpaRequest,
    handle: {200?: (result: VoidResponse) => void; 500?: (result: string) => void; 401?: (error: string) => void}
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/partner/health/tpa/?';

      const options = {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async setupStripe<TPromise = GetTpaResponse>(
    model: TpaRequest,
    handle: {200?: (result: GetTpaResponse) => void; 500?: (result: string) => void; 401?: (error: string) => void}
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/partner/health/tpa/stripe?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async createSubGroup<TPromise = CreateSubGroupResponse>(
    model: CreateSubGroupRequest,
    handle: {
      200?: (result: CreateSubGroupResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/partner/health/tpa/sub-group?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getTpaUsers<TPromise = GetTpaUsersResponse>(
    model: TpaRequest,
    handle: {200?: (result: GetTpaUsersResponse) => void; 500?: (result: string) => void; 401?: (error: string) => void}
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/partner/health/tpa/tpa-users?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async createTpaUser<TPromise = GetTpaUserResponse>(
    model: CreateTpaUserRequest,
    handle: {200?: (result: GetTpaUserResponse) => void; 500?: (result: string) => void; 401?: (error: string) => void}
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/partner/health/tpa/tpa-user?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async updateTpaUser<TPromise = GetTpaUserResponse>(
    model: UpdateTpaUserRequest,
    handle: {200?: (result: GetTpaUserResponse) => void; 500?: (result: string) => void; 401?: (error: string) => void}
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/partner/health/tpa/tpa-user?';

      const options = {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async deleteTpaUser<TPromise = VoidResponse>(
    model: GetTpaUserRequest,
    handle: {200?: (result: VoidResponse) => void; 500?: (result: string) => void; 401?: (error: string) => void}
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/partner/health/tpa/tpa-user?';

      const options = {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async activateTpaUser<TPromise = GetTpaUserResponse>(
    model: GetTpaUserRequest,
    handle: {200?: (result: GetTpaUserResponse) => void; 500?: (result: string) => void; 401?: (error: string) => void}
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/partner/health/tpa/tpa-user/activate?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async deactivateTpaUser<TPromise = GetTpaUserResponse>(
    model: GetTpaUserRequest,
    handle: {200?: (result: GetTpaUserResponse) => void; 500?: (result: string) => void; 401?: (error: string) => void}
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/partner/health/tpa/tpa-user/deactivate?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getTpaReport<TPromise = GetTpaReportResponse>(
    model: GetTpaReportRequest,
    handle: {
      200?: (result: GetTpaReportResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/partner/health/tpa/tpa-report?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getTpaReportCsv<TPromise = CsvResponse>(
    model: GetTpaReportCsvRequest,
    handle: {200?: (result: CsvResponse) => void; 500?: (result: string) => void; 401?: (error: string) => void}
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/partner/health/tpa/tpa-report/csv?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getProductCode<TPromise = GetTpaProductCodeResponse>(
    model: GetProductCodeRequest,
    handle: {
      200?: (result: GetTpaProductCodeResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/partner/health/tpa/product-code?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async updateProductCode<TPromise = GetTpaProductCodeResponse>(
    model: UpdateTpaProductCodeRequest,
    handle: {
      200?: (result: GetTpaProductCodeResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/partner/health/tpa/product-code?';

      const options = {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async createProductCode<TPromise = CreateProductCodeResponse>(
    model: CreateTpaProductCodeRequest,
    handle: {
      200?: (result: CreateProductCodeResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/partner/health/tpa/product-code?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async createSubgroupProductCode<TPromise = CreateProductCodeResponse>(
    model: CreateGroupProductCodeRequest,
    handle: {
      200?: (result: CreateProductCodeResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/partner/health/tpa/subgroup-product-code?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getProductCodeStartingNetworks<TPromise = GetProductCodeStartingNetworksResponse>(
    model: VoidRequest,
    handle: {
      200?: (result: GetProductCodeStartingNetworksResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/partner/health/tpa/product-code/starting-networks?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async validateProductCodeUnique<TPromise = ValidateUniqueResponse>(
    model: ValidateProductCodeUniqueRequest,
    handle: {
      200?: (result: ValidateUniqueResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/partner/health/tpa/validate-product-code?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }
}

export class AnalyticsClient extends BaseClient {
  constructor(options: ControllerOptions) {
    super(options);
  }

  async appOpen<TPromise = VoidResponse>(
    model: AppOpenRequest,
    handle: {200?: (result: VoidResponse) => void; 500?: (result: string) => void; 401?: (error: string) => void}
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/analytics/app-open?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async logEvent<TPromise = VoidResponse>(
    model: LogEventRequest,
    handle: {200?: (result: VoidResponse) => void; 500?: (result: string) => void; 401?: (error: string) => void}
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/analytics/log-event?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async logVoucherEvent<TPromise = VoidResponse>(
    model: LogVoucherEventRequest,
    handle: {200?: (result: VoidResponse) => void; 500?: (result: string) => void; 401?: (error: string) => void}
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/analytics/log-voucher-event?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async start<TPromise = VoidResponse>(
    model: TelemetryStartRequest,
    handle: {200?: (result: VoidResponse) => void; 500?: (result: string) => void; 401?: (error: string) => void}
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/analytics/start?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }
}

export class CardOrderClient extends BaseClient {
  constructor(options: ControllerOptions) {
    super(options);
  }

  async startCardOrderPayment<TPromise = StartCardOrderPaymentResponse>(
    model: StartCardOrderPaymentRequest,
    handle: {
      200?: (result: StartCardOrderPaymentResponse) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/partner/card-order/start?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async confirmCardOrderPayment<TPromise = ConfirmCardOrderPaymentResponse>(
    model: ConfirmCardOrderPaymentRequest,
    handle: {
      200?: (result: ConfirmCardOrderPaymentResponse) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/partner/card-order/confirm?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getAllPartnerCardOrders<TPromise = GetAllPartnerCardOrdersResponse>(
    model: PartnerRequest,
    handle: {
      200?: (result: GetAllPartnerCardOrdersResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/partner/card-order/all-orders?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getPartnerPendingCardOrder<TPromise = GetPartnerPendingCardOrderResponse>(
    model: GetPartnerPendingCardOrderRequest,
    handle: {
      200?: (result: GetPartnerPendingCardOrderResponse) => void;
      500?: (result: string) => void;
      404: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/partner/card-order/?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 404) {
        await handle[404](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async updatePartnerPendingCardOrderLogo<TPromise = GetPartnerPendingCardOrderResponse>(
    model: UpdatePartnerPendingCardOrderLogoRequest,
    handle: {
      200?: (result: GetPartnerPendingCardOrderResponse) => void;
      500?: (result: string) => void;
      404: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/partner/card-order/logo?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 404) {
        await handle[404](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async approvePartnerPendingCardOrder<TPromise = VoidResponse>(
    model: GetPartnerPendingCardOrderRequest,
    handle: {
      200?: (result: VoidResponse) => void;
      500?: (result: string) => void;
      404: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/partner/card-order/approve?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 404) {
        await handle[404](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }
}

export class DrugClient extends BaseClient {
  constructor(options: ControllerOptions) {
    super(options);
  }

  async price<TPromise = PricingResponse>(
    model: PriceRequest,
    handle: {
      200?: (result: PricingResponse) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/drug/price?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getDrugDetails<TPromise = GetDrugDetailsResponse>(
    model: GetDrugDetailsRequest,
    handle: {
      200?: (result: GetDrugDetailsResponse) => void;
      500?: (result: string) => void;
      404: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/drug/drug-details?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 404) {
        await handle[404](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getDrugStructure<TPromise = GetDrugStructureResponse>(
    model: GetDrugStructureRequest,
    handle: {
      200?: (result: GetDrugStructureResponse) => void;
      500?: (result: string) => void;
      404: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/drug/drug-structure?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 404) {
        await handle[404](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async search<TPromise = SearchResponse>(
    model: SearchRequest,
    handle: {200?: (result: SearchResponse) => void; 500?: (result: string) => void; 401?: (error: string) => void}
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/drug/search?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async legacySearch<TPromise = LegacySearchResponse>(
    model: SearchRequest,
    handle: {
      200?: (result: LegacySearchResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/drug/legacy-search?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }
}

export class DrugV2Client extends BaseClient {
  constructor(options: ControllerOptions) {
    super(options);
  }

  async price<TPromise = PricingV2Response>(
    model: PriceV2Request,
    handle: {
      200?: (result: PricingV2Response) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/drug-v2/price?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getDrugStructure<TPromise = HttpDrugResultV2>(
    model: GetDrugStructureV2Request,
    handle: {
      200?: (result: HttpDrugResultV2) => void;
      500?: (result: string) => void;
      404: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/drug-v2/drug-structure?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 404) {
        await handle[404](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getDrugInformation<TPromise = DrugInformationV2>(
    model: GetDrugInformationV2Request,
    handle: {
      200?: (result: DrugInformationV2) => void;
      500?: (result: string) => void;
      404: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/drug-v2/drug-information?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 404) {
        await handle[404](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async search<TPromise = SearchResponseV2>(
    model: SearchRequest,
    handle: {200?: (result: SearchResponseV2) => void; 500?: (result: string) => void; 401?: (error: string) => void}
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/drug-v2/search?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getVoucher<TPromise = GetVoucherResponse>(
    model: GetVoucherRequest,
    handle: {200?: (result: GetVoucherResponse) => void; 500?: (result: string) => void; 401?: (error: string) => void}
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/drug-v2/voucher?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getVoucherSurvey<TPromise = GetVoucherSurveyResponse>(
    model: GetVoucherRequest,
    handle: {
      200?: (result: GetVoucherSurveyResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/drug-v2/voucher-survey?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async submitVoucherSurvey<TPromise = VoidResponse>(
    model: SubmitVoucherSurveyRequest,
    handle: {200?: (result: VoidResponse) => void; 500?: (result: string) => void; 401?: (error: string) => void}
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/drug-v2/submit-voucher-survey?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async submitPapForm<TPromise = VoidResponse>(
    model: SubmitPapRequest,
    handle: {200?: (result: VoidResponse) => void; 500?: (result: string) => void; 401?: (error: string) => void}
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/drug-v2/submit-pap-form?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async submitPapFormV2<TPromise = VoidResponse>(
    model: SubmitPapRequestV2,
    handle: {200?: (result: VoidResponse) => void; 500?: (result: string) => void; 401?: (error: string) => void}
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/drug-v2/submit-pap-form/v2?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }
}

export class ExternalApiClient extends BaseClient {
  constructor(options: ControllerOptions) {
    super(options);
  }

  async authenticate<TPromise = AuthenticateResponse>(
    model: AuthenticateRequest,
    handle: {
      200?: (result: AuthenticateResponse) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/external/authenticate?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async searchDrugs<TPromise = SearchDrugsResponse>(
    model: SearchDrugsRequest,
    handle: {
      200?: (result: SearchDrugsResponse) => void;
      500?: (result: string) => void;
      404: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/external/search?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 404) {
        await handle[404](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async drugStructure<TPromise = DrugStructureResponse>(
    model: DrugStructureRequest,
    handle: {
      200?: (result: DrugStructureResponse) => void;
      500?: (result: string) => void;
      404: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/external/drug-structure?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 404) {
        await handle[404](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async drugInfo<TPromise = DrugInfoResponse>(
    model: DrugInfoRequest,
    handle: {
      200?: (result: DrugInfoResponse) => void;
      500?: (result: string) => void;
      404: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/external/drug-info?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 404) {
        await handle[404](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async priceDrug<TPromise = PriceDrugResponse>(
    model: PriceDrugRequest,
    handle: {
      200?: (result: PriceDrugResponse) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/external/price?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async searchDrugsV2<TPromise = SearchDrugsV2Response>(
    model: SearchDrugsV2Request,
    handle: {
      200?: (result: SearchDrugsV2Response) => void;
      500?: (result: string) => void;
      404: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/external/v2/search?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 404) {
        await handle[404](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async drugStructureV2<TPromise = DrugStructureV2Response>(
    model: DrugStructureRequest,
    handle: {
      200?: (result: DrugStructureV2Response) => void;
      500?: (result: string) => void;
      404: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/external/v2/drug-structure?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 404) {
        await handle[404](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async priceDrugV2<TPromise = PriceDrugV2Response>(
    model: PriceDrugV2Request,
    handle: {
      200?: (result: PriceDrugV2Response) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/external/v2/price?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }
}

export class GeofenceClient extends BaseClient {
  constructor(options: ControllerOptions) {
    super(options);
  }

  async getPharmacies<TPromise = GeofenceGetPharmaciesResponse>(
    model: GeofenceGetPharmaciesRequest,
    handle: {
      200?: (result: GeofenceGetPharmaciesResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/geofence/pharmacies?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }
}

export class PartnerCmsClient extends BaseClient {
  constructor(options: ControllerOptions) {
    super(options);
  }

  async getContent<TPromise = CmsGetContentResponse>(
    model: CmsGetContentRequest,
    handle: {
      200?: (result: CmsGetContentResponse) => void;
      500?: (result: string) => void;
      404: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/partner/clh-cms/content?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 404) {
        await handle[404](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async updateContent<TPromise = CmsGetContentResponse>(
    model: CmsUpdateContentRequest,
    handle: {
      200?: (result: CmsGetContentResponse) => void;
      500?: (result: string) => void;
      404: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/partner/clh-cms/content?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 404) {
        await handle[404](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async requestPublishContent<TPromise = VoidResponse>(
    model: CmsGetContentRequest,
    handle: {
      200?: (result: VoidResponse) => void;
      500?: (result: string) => void;
      404: (result: {error: string}) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/partner/clh-cms/request-publish?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 404) {
        await handle[404](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }
}

export class PartnerClient extends BaseClient {
  constructor(options: ControllerOptions) {
    super(options);
  }

  async login<TPromise = AccountLoginResponse>(
    model: AccountLoginRequest,
    handle: {
      200?: (result: AccountLoginResponse) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/partner/login?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getUser<TPromise = AccountResponse>(
    model: VoidRequest,
    handle: {200?: (result: AccountResponse) => void; 500?: (result: string) => void; 401?: (error: string) => void}
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/partner/?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getPartnerExtended<TPromise = GetPartnerSimpleResponse>(
    model: GetPartnerRequest,
    handle: {
      200?: (result: GetPartnerSimpleResponse) => void;
      500?: (result: string) => void;
      404: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/partner/partner/extended/:partnerId?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .filter((key) => key !== 'partnerId')

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      url = url.replace(':partnerId', model.partnerId).replace('{partnerId}', model.partnerId);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 404) {
        await handle[404](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async setStripeBankAccount<TPromise = AccountResponse>(
    model: SetStripeBankAccountRequest,
    handle: {
      200?: (result: AccountResponse) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/partner/bank-account?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async resendStripeLink<TPromise = VoidResponse>(
    model: PartnerRequest,
    handle: {
      200?: (result: VoidResponse) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/partner/resend-stripe-link?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async requestW9<TPromise = RequestW9AccountResponse>(
    model: RequestW9AccountRequest,
    handle: {
      200?: (result: RequestW9AccountResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/partner/w9?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getCommissionResultMonths<TPromise = AccountCommissionResultMonthsResponse>(
    model: PartnerRequest,
    handle: {
      200?: (result: AccountCommissionResultMonthsResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/partner/reports/commission-result-months?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getCommissionResult<TPromise = AccountCommissionResultResponse>(
    model: AccountMonthReportRequest,
    handle: {
      200?: (result: AccountCommissionResultResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/partner/reports/commission-result?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getCommissionResultCsv<TPromise = CsvResponse>(
    model: PartnerRequest,
    handle: {200?: (result: CsvResponse) => void; 500?: (result: string) => void; 401?: (error: string) => void}
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/partner/reports/commission-result-csv?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getClaimCommissionResultMonths<TPromise = AccountCommissionResultMonthsResponse>(
    model: PartnerRequest,
    handle: {
      200?: (result: AccountCommissionResultMonthsResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/partner/reports/claim-commission-result-months?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getClaimCommissionResult<TPromise = AccountClaimCommissionResultResponse>(
    model: AccountMonthReportRequest,
    handle: {
      200?: (result: AccountClaimCommissionResultResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/partner/reports/claim-commission-result?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async slugsByDay<TPromise = SlugsByDayResponse>(
    model: SlugsByDayRequest,
    handle: {200?: (result: SlugsByDayResponse) => void; 500?: (result: string) => void; 401?: (error: string) => void}
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/partner/slugs-by-day?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getLedger<TPromise = GetLedgerResponse>(
    model: PartnerRequest,
    handle: {200?: (result: GetLedgerResponse) => void; 500?: (result: string) => void; 401?: (error: string) => void}
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/partner/reports/ledger?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getDownloadsByPartner<TPromise = GetDownloadsByPartnerResponse>(
    model: AccountMonthReportRequest,
    handle: {
      200?: (result: GetDownloadsByPartnerResponse) => void;
      500?: (result: string) => void;
      404: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/partner/reports/downloads?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 404) {
        await handle[404](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async forgotPassword<TPromise = VoidResponse>(
    model: AccountForgotPasswordRequest,
    handle: {200?: (result: VoidResponse) => void; 500?: (result: string) => void; 401?: (error: string) => void}
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/partner/forgot-password?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async contactBroker<TPromise = VoidResponse>(
    model: PartnerContactBrokerRequest,
    handle: {
      200?: (result: VoidResponse) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/partner/contact-broker?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async requestPayout<TPromise = SuccessResponse>(
    model: PartnerRequest,
    handle: {
      200?: (result: SuccessResponse) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/partner/request-payout?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async ledgerDebitReport<TPromise = AdminStringReportResponse>(
    model: PartnerLedgerDebitReportRequest,
    handle: {
      200?: (result: AdminStringReportResponse) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/partner/ledger-debit-report?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async consultLedgerDebitReport<TPromise = AdminStringReportResponse>(
    model: PartnerLedgerDebitReportRequest,
    handle: {
      200?: (result: AdminStringReportResponse) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/partner/consult-ledger-debit-report?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async isPayoutViable<TPromise = PayoutViableResponse>(
    model: PartnerRequest,
    handle: {
      200?: (result: PayoutViableResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/partner/payout-viable?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async resetPassword<TPromise = VoidResponse>(
    model: AccountResetPasswordRequest,
    handle: {
      200?: (result: VoidResponse) => void;
      500?: (result: string) => void;
      404: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/partner/reset-password?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 404) {
        await handle[404](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async updateAccount<TPromise = AccountResponse>(
    model: PartnerUpdateAccountsRequest,
    handle: {
      200?: (result: AccountResponse) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/partner/update-account?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async createCard<TPromise = AccountResponse>(
    model: AccountCreateCardRequest,
    handle: {
      200?: (result: AccountResponse) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/partner/create-card?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getPartnerRequests<TPromise = PartnerRequestsResponse>(
    model: PartnerRequest,
    handle: {
      200?: (result: PartnerRequestsResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/partner/partner-requests?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async invitePartner<TPromise = VoidResponse>(
    model: AccountInvitePartnerRequest,
    handle: {
      200?: (result: VoidResponse) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/partner/invite-partner?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async downloadMaterial<TPromise = VoidResponse>(
    model: DownloadMaterialRequest,
    handle: {
      200?: (result: VoidResponse) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/partner/download-material?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getMarketingMaterials<TPromise = GetAllMarketingResponse>(
    model: VoidRequest,
    handle: {
      200?: (result: GetAllMarketingResponse) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/partner/marketing-materials?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getBrokerDetails<TPromise = WebBrokerDetailsResponse>(
    model: WebBrokerDetailsRequest,
    handle: {
      200?: (result: WebBrokerDetailsResponse) => void;
      500?: (result: string) => void;
      404: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/partner/web/brokerDetails?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 404) {
        await handle[404](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async submitBrokerContactForm<TPromise = VoidResponse>(
    model: WebBrokerContactFormRequest,
    handle: {
      200?: (result: VoidResponse) => void;
      500?: (result: string) => void;
      404: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/partner/web/brokerContactForm?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 404) {
        await handle[404](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async validateUrlSlugUnique<TPromise = ValidateUniqueResponse>(
    model: ValidateUrlSlugUniqueRequest,
    handle: {
      200?: (result: ValidateUniqueResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/partner/validate-url-slug?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getPartnerCards<TPromise = GetPartnerCardsResponse>(
    model: PartnerRequest,
    handle: {
      200?: (result: GetPartnerCardsResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/partner/cards?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }
}

export class PartnerHealthClient extends BaseClient {
  constructor(options: ControllerOptions) {
    super(options);
  }

  async getHealthConfig<TPromise = GetHealthConfigResponse>(
    model: GetPartnerAndCardRequest,
    handle: {
      200?: (result: GetHealthConfigResponse) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      404: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/partner/health/health-config?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 404) {
        await handle[404](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getStartingHealthConfig<TPromise = GetHealthConfigResponse>(
    model: GetPartnerRequest,
    handle: {
      200?: (result: GetHealthConfigResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/partner/health/starting-health-config?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getReferrals<TPromise = GetPartnerReferralsResponse>(
    model: GetPartnerRequest,
    handle: {
      200?: (result: GetPartnerReferralsResponse) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/partner/health/referrals?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getPartnerHealthClients<TPromise = GetPartnerHealthClientsResponse>(
    model: VoidRequest,
    handle: {
      200?: (result: GetPartnerHealthClientsResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/partner/health/clients?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getPartnerHealthClient<TPromise = GetPartnerHealthClientResponse>(
    model: GetPartnerHealthClientRequest,
    handle: {
      200?: (result: GetPartnerHealthClientResponse) => void;
      500?: (result: string) => void;
      404: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/partner/health/client?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 404) {
        await handle[404](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async updatePartnerHealthClient<TPromise = GetPartnerHealthClientResponse>(
    model: UpdatePartnerHealthClientRequest,
    handle: {
      200?: (result: GetPartnerHealthClientResponse) => void;
      500?: (result: string) => void;
      404: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/partner/health/client?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 404) {
        await handle[404](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getPartnerHealthClientUsers<TPromise = GetPartnerHealthClientUsersResponse>(
    model: GetPartnerHealthClientUsersRequest,
    handle: {
      200?: (result: GetPartnerHealthClientUsersResponse) => void;
      500?: (result: string) => void;
      404: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/partner/health/client-users?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 404) {
        await handle[404](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async createPartnerHealthClientUser<TPromise = GetPartnerHealthClientUserResponse>(
    model: CreatePartnerHealthClientUserRequest,
    handle: {
      200?: (result: GetPartnerHealthClientUserResponse) => void;
      500?: (result: string) => void;
      404: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/partner/health/client-user?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 404) {
        await handle[404](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async activatePartnerHealthClientUser<TPromise = GetPartnerHealthClientUserResponse>(
    model: ActivatePartnerHealthClientUserRequest,
    handle: {
      200?: (result: GetPartnerHealthClientUserResponse) => void;
      500?: (result: string) => void;
      404: (result: {error: string}) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/partner/health/activate-user?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 404) {
        await handle[404](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async deactivatePartnerHealthClientUser<TPromise = GetPartnerHealthClientUserResponse>(
    model: ActivatePartnerHealthClientUserRequest,
    handle: {
      200?: (result: GetPartnerHealthClientUserResponse) => void;
      500?: (result: string) => void;
      404: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/partner/health/deactivate-client-user?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 404) {
        await handle[404](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async submitHealthGroupRequest<TPromise = SubmitHealthGroupRequestResponse>(
    model: SubmitHealthGroupRequestRequest,
    handle: {
      200?: (result: SubmitHealthGroupRequestResponse) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/partner/health/group-request?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getPartnerHealthClientRequests<TPromise = GetPartnerHealthClientRequestsResponse>(
    model: PartnerRequest,
    handle: {
      200?: (result: GetPartnerHealthClientRequestsResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/partner/health/client-requests?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getPartnerHealthClientRequest<TPromise = GetPartnerHealthClientRequestResponse>(
    model: GetPartnerHealthClientRequestRequest,
    handle: {
      200?: (result: GetPartnerHealthClientRequestResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/partner/health/client-request?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async requestCHContract<TPromise = RequestPartnerContractResponse>(
    model: PartnerRequest,
    handle: {
      200?: (result: RequestPartnerContractResponse) => void;
      500?: (result: string) => void;
      404: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/partner/health/contract/request-contract?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 404) {
        await handle[404](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async requestCHW9Form<TPromise = RequestPartnerContractResponse>(
    model: PartnerRequest,
    handle: {
      200?: (result: RequestPartnerContractResponse) => void;
      500?: (result: string) => void;
      404: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/partner/health/contract/request-w9?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 404) {
        await handle[404](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async updatePartnerHealthClientContacts<TPromise = VoidResponse>(
    model: UpdatePartnerHealthClientContactsRequest,
    handle: {
      200?: (result: VoidResponse) => void;
      500?: (result: string) => void;
      404: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/partner/health/client/contacts?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 404) {
        await handle[404](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async sendClientAgreement<TPromise = GetPartnerHealthClientResponse>(
    model: UpdatePartnerHealthClientContactsRequest,
    handle: {
      200?: (result: GetPartnerHealthClientResponse) => void;
      500?: (result: string) => void;
      404: (result: {error: string}) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/partner/health/send-client-agreement?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 404) {
        await handle[404](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async sendVoluntaryClientAgreement<TPromise = GetPartnerHealthClientResponse>(
    model: UpdatePartnerHealthClientContactsRequest,
    handle: {
      200?: (result: GetPartnerHealthClientResponse) => void;
      500?: (result: string) => void;
      404: (result: {error: string}) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/partner/health/send-voluntary-client-agreement?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 404) {
        await handle[404](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getProductCodes<TPromise = GetTpaProductCodesResponse>(
    model: VoidRequest,
    handle: {
      200?: (result: GetTpaProductCodesResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/partner/health/product-codes?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getTpas<TPromise = GetPartnerTpasResponse>(
    model: VoidRequest,
    handle: {
      200?: (result: GetPartnerTpasResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/partner/health/tpas?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getTpa<TPromise = GetPartnerTpaResponse>(
    model: GetPartnerTpaRequest,
    handle: {
      200?: (result: GetPartnerTpaResponse) => void;
      500?: (result: string) => void;
      404: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/partner/health/tpa?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 404) {
        await handle[404](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }
}

export class PartnerHealthReportsClient extends BaseClient {
  constructor(options: ControllerOptions) {
    super(options);
  }

  async getHealthReports<TPromise = GetPartnerHealthReportsResponse>(
    model: GetPartnerHealthReportsRequest,
    handle: {
      200?: (result: GetPartnerHealthReportsResponse) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      404: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/partner/health/reports/?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 404) {
        await handle[404](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getHealthReportsTopReasons<TPromise = GetPartnerHealthReportsTopReasonsResponse>(
    model: GetPartnerHealthReportsRequest,
    handle: {
      200?: (result: GetPartnerHealthReportsTopReasonsResponse) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      404: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/partner/health/reports/top-reasons?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 404) {
        await handle[404](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getHealthReportsTopDrugs<TPromise = GetPartnerHealthReportsTopDrugsResponse>(
    model: GetPartnerHealthReportsRequest,
    handle: {
      200?: (result: GetPartnerHealthReportsTopDrugsResponse) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      404: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/partner/health/reports/top-drugs?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 404) {
        await handle[404](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getHealthReportsAppDownloads<TPromise = GetPartnerHealthReportsChartDataResponse>(
    model: GetPartnerHealthReportsRequest,
    handle: {
      200?: (result: GetPartnerHealthReportsChartDataResponse) => void;
      500?: (result: string) => void;
      404: (result: {error: string}) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/partner/health/reports/app-downloads?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 404) {
        await handle[404](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getHealthReportsVisitsCompleted<TPromise = GetPartnerHealthReportsChartDataResponse>(
    model: GetPartnerHealthReportsRequest,
    handle: {
      200?: (result: GetPartnerHealthReportsChartDataResponse) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      404: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/partner/health/reports/visits-completed?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 404) {
        await handle[404](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getHealthReportsTopStates<TPromise = GetPartnerHealthReportsTopStatesResponse>(
    model: GetPartnerHealthReportsRequest,
    handle: {
      200?: (result: GetPartnerHealthReportsTopStatesResponse) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      404: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/partner/health/reports/top-states?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 404) {
        await handle[404](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getHealthPartnerDownloadsByCard<TPromise = GetHealthPartnerDownloadsByCardResponse>(
    model: GetHealthPartnerDownloadsByCardRequest,
    handle: {
      200?: (result: GetHealthPartnerDownloadsByCardResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/partner/health/reports/downloads-by-card?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }
}

export class PartnerTaskClient extends BaseClient {
  constructor(options: ControllerOptions) {
    super(options);
  }

  async getPartnerTasks<TPromise = GetPartnerTasksResponse>(
    model: PartnerRequest,
    handle: {
      200?: (result: GetPartnerTasksResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/partner/tasks/?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async completePartnerTasks<TPromise = GetPartnerTasksResponse>(
    model: CompletePartnerTaskRequest,
    handle: {
      200?: (result: GetPartnerTasksResponse) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/partner/tasks/?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async completePartnerTaskRank<TPromise = VoidResponse>(
    model: CompletePartnerTaskRankRequest,
    handle: {
      200?: (result: VoidResponse) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/partner/tasks/complete-rank?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async checkPartnerContractsSigned<TPromise = CheckPartnerContractsSignedResponse>(
    model: PartnerRequest,
    handle: {
      200?: (result: CheckPartnerContractsSignedResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/partner/tasks/contracts/is-signed?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }
}

export class PharmacyClient extends BaseClient {
  constructor(options: ControllerOptions) {
    super(options);
  }

  async getPharmacies<TPromise = GetPharmacyResponse>(
    model: GetPharmacyRequest,
    handle: {200?: (result: GetPharmacyResponse) => void; 500?: (result: string) => void; 401?: (error: string) => void}
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/pharmacy/pharmacies?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }
}

export class PortalAnalyticsClient extends BaseClient {
  constructor(options: ControllerOptions) {
    super(options);
  }

  async logEvent<TPromise = VoidResponse>(
    model: PortalAnalyticLogEventRequest,
    handle: {200?: (result: VoidResponse) => void; 500?: (result: string) => void; 401?: (error: string) => void}
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/portal-analytics/?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }
}

export class PortalClient extends BaseClient {
  constructor(options: ControllerOptions) {
    super(options);
  }

  async login<TPromise = PortalLoginResponse>(
    model: PortalLoginRequest,
    handle: {
      200?: (result: PortalLoginResponse) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/portal/login?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async impersonateClient<TPromise = ImpersonateClientResponse>(
    model: ImpersonateClientRequest,
    handle: {
      200?: (result: ImpersonateClientResponse) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/portal/impersonate?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async resetPassword<TPromise = VoidResponse>(
    model: AccountResetPasswordRequest,
    handle: {
      200?: (result: VoidResponse) => void;
      500?: (result: string) => void;
      404: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/portal/reset-password?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 404) {
        await handle[404](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async forgotPassword<TPromise = VoidResponse>(
    model: PortalForgotPasswordRequest,
    handle: {200?: (result: VoidResponse) => void; 500?: (result: string) => void; 401?: (error: string) => void}
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/portal/forgot-password?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }
}

export class PricingIframeAnalyticsClient extends BaseClient {
  constructor(options: ControllerOptions) {
    super(options);
  }

  async logEvent<TPromise = VoidResponse>(
    model: PricingIframeLogEventRequest,
    handle: {200?: (result: VoidResponse) => void; 500?: (result: string) => void; 401?: (error: string) => void}
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/pricing-iframe-analytics/log-event?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }
}

export class ShareClient extends BaseClient {
  constructor(options: ControllerOptions) {
    super(options);
  }

  async messagePrice<TPromise = VoidResponse>(
    model: MessagePriceRequest,
    handle: {
      200?: (result: VoidResponse) => void;
      500?: (result: string) => void;
      404: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/share/message-price?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 404) {
        await handle[404](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async emailPrice<TPromise = VoidResponse>(
    model: EmailPriceRequest,
    handle: {
      200?: (result: VoidResponse) => void;
      500?: (result: string) => void;
      404: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/share/email-price?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 404) {
        await handle[404](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async messageApp<TPromise = VoidResponse>(
    model: MessageAppRequest,
    handle: {
      200?: (result: VoidResponse) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/share/message-app?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async submitContactDetails<TPromise = VoidResponse>(
    model: SubmitContactDetailsRequest,
    handle: {200?: (result: VoidResponse) => void; 500?: (result: string) => void; 401?: (error: string) => void}
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/share/submit-contact-details?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async messageAppCustomized<TPromise = VoidResponse>(
    model: MessageAppCustomizedRequest,
    handle: {200?: (result: VoidResponse) => void; 500?: (result: string) => void; 401?: (error: string) => void}
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/share/message-app-customized?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async shareCHFlyer<TPromise = VoidResponse>(
    model: ShareCHFlyerRequest,
    handle: {
      200?: (result: VoidResponse) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/share/share-ch-flyer?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async redirectToClhJoin<TPromise = VoidResponse>(
    model: RedirectToClhJoinRequest,
    handle: {200?: (result: VoidResponse) => void; 500?: (result: string) => void; 401?: (error: string) => void}
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/share/join/:urlSlug?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .filter((key) => key !== 'urlSlug')

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      url = url.replace(':urlSlug', model.urlSlug).replace('{urlSlug}', model.urlSlug);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }
}

export class TpaClient extends BaseClient {
  constructor(options: ControllerOptions) {
    super(options);
  }

  async getSubGroups<TPromise = GetTpaSubGroupsResponse>(
    model: GetTpaSubGroupsRequest,
    handle: {
      200?: (result: GetTpaSubGroupsResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/tpa/sub-groups?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getProductCodes<TPromise = GetTpaProductCodesResponse>(
    model: VoidRequest,
    handle: {
      200?: (result: GetTpaProductCodesResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/tpa/product-codes?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getImportReports<TPromise = GetHealthClientImportReportsResponse>(
    model: GetHealthClientImportReportsRequest,
    handle: {
      200?: (result: GetHealthClientImportReportsResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/tpa/import-reports?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getImportReport<TPromise = GetHealthClientImportReportResponse>(
    model: GetHealthClientImportReportRequest,
    handle: {
      200?: (result: GetHealthClientImportReportResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/tpa/import-report?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getMembers<TPromise = GetTpaMembersResponse>(
    model: GetTpaMembersRequest,
    handle: {
      200?: (result: GetTpaMembersResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/tpa/members?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getMember<TPromise = GetHealthClientMemberAndDependentsResponse>(
    model: GetHealthClientMemberRequest,
    handle: {
      200?: (result: GetHealthClientMemberAndDependentsResponse) => void;
      500?: (result: string) => void;
      404: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/tpa/member?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 404) {
        await handle[404](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getSFTPCredentials<TPromise = GetSFTPCredentialsResponse>(
    model: VoidRequest,
    handle: {
      200?: (result: GetSFTPCredentialsResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/tpa/sftp-credentials?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async createSFTPCredentials<TPromise = CreateSFTPCredentialsResponse>(
    model: CreateSFTPCredentialsRequest,
    handle: {
      200?: (result: CreateSFTPCredentialsResponse) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/tpa/sftp-credentials?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getTpaConfigColumns<TPromise = GetTpaConfigColumnsResponse>(
    model: VoidRequest,
    handle: {
      200?: (result: GetTpaConfigColumnsResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/tpa/tpa-config-columns?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async updateTpaConfigColumns<TPromise = VoidResponse>(
    model: UpdateTpaConfigColumnsRequest,
    handle: {200?: (result: VoidResponse) => void; 500?: (result: string) => void; 401?: (error: string) => void}
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/tpa/tpa-config-columns?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async manualUploadEligibility<TPromise = VoidResponse>(
    model: ManualUploadEligibilityRequest,
    handle: {
      200?: (result: VoidResponse) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/tpa/manual-upload?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getInvoices<TPromise = GetClientInvoicesResponse>(
    model: VoidRequest,
    handle: {
      200?: (result: GetClientInvoicesResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/tpa/invoices?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getInvoice<TPromise = GetClientInvoiceResponse>(
    model: GetClientInvoiceRequest,
    handle: {
      200?: (result: GetClientInvoiceResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/tpa/invoice?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async sendCorrespondence<TPromise = VoidResponse>(
    model: HealthClientSendCorrespondenceRequest,
    handle: {
      200?: (result: VoidResponse) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/tpa/send-correspondence?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async updateTpaUserDetails<TPromise = UpdateTpaUserDetailsResponse>(
    model: UpdateTpaUserDetailsRequest,
    handle: {
      200?: (result: UpdateTpaUserDetailsResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/tpa/tpa-user-details?';

      const options = {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async updateTpaBillingDetails<TPromise = UpdateTpaBillingDetailsResponse>(
    model: UpdateTpaBillingDetailsRequest,
    handle: {
      200?: (result: UpdateTpaBillingDetailsResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/tpa/billing-details?';

      const options = {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getTpaPaymentMethods<TPromise = GetClientPaymentMethodsResponse>(
    model: VoidRequest,
    handle: {
      200?: (result: GetClientPaymentMethodsResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/tpa/payment-methods?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async verifyACHPaymentMethod<TPromise = VerifyACHPaymentMethodResponse>(
    model: VerifyACHPaymentMethodRequest,
    handle: {
      200?: (result: VerifyACHPaymentMethodResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/tpa/verify-ach-payment?';

      const options = {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async deleteCardPaymentMethod<TPromise = DeleteCardPaymentMethodRequest>(
    model: DeleteCardPaymentMethodRequest,
    handle: {
      200?: (result: DeleteCardPaymentMethodRequest) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/tpa/delete-card?';

      const options = {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async deleteBankAccountPaymentMethod<TPromise = DeleteBankAccountPaymentMethodRequest>(
    model: DeleteBankAccountPaymentMethodRequest,
    handle: {
      200?: (result: DeleteBankAccountPaymentMethodRequest) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/tpa/delete-bank-account?';

      const options = {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async createStripeCustomer<TPromise = CreateHealthClientStripeCustomerResponse>(
    model: VoidRequest,
    handle: {
      200?: (result: CreateHealthClientStripeCustomerResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/tpa/stripe-customer?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async setupCardPaymentMethod<TPromise = VoidResponse>(
    model: SetupClientPaymentMethodRequest,
    handle: {
      200?: (result: VoidResponse) => void;
      500?: (result: string) => void;
      404: (result: {error: string}) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/tpa/card-payment-setup?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 404) {
        await handle[404](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async setupACHPaymentMethod<TPromise = VoidResponse>(
    model: SetupClientPaymentMethodRequest,
    handle: {
      200?: (result: VoidResponse) => void;
      500?: (result: string) => void;
      404: (result: {error: string}) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/tpa/ach-payment-setup?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 404) {
        await handle[404](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async payInvoice<TPromise = PayClientInvoiceResponse>(
    model: PayClientInvoiceRequest,
    handle: {
      200?: (result: PayClientInvoiceResponse) => void;
      500?: (result: string) => void;
      404: (result: {error: string}) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/tpa/pay-invoice?';

      const options = {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 404) {
        await handle[404](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async updateDefaultPaymentMethod<TPromise = UpdateClientDefaultPaymentMethodResponse>(
    model: UpdateClientDefaultPaymentMethodRequest,
    handle: {
      200?: (result: UpdateClientDefaultPaymentMethodResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/tpa/default-payment-method?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async updateBillingAutoPay<TPromise = VoidResponse>(
    model: UpdateClientBillingAutoPayRequest,
    handle: {200?: (result: VoidResponse) => void; 500?: (result: string) => void; 401?: (error: string) => void}
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/tpa/update-auto-pay?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async createMember<TPromise = VoidResponse>(
    model: CreateTpaMemberRequest,
    handle: {200?: (result: VoidResponse) => void; 500?: (result: string) => void; 401?: (error: string) => void}
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/tpa/member?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async createFamily<TPromise = VoidResponse>(
    model: CreateTpaFamilyRequest,
    handle: {200?: (result: VoidResponse) => void; 500?: (result: string) => void; 401?: (error: string) => void}
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/tpa/family?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async updateMember<TPromise = VoidResponse>(
    model: UpdateTpaMemberRequest,
    handle: {200?: (result: VoidResponse) => void; 500?: (result: string) => void; 401?: (error: string) => void}
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/tpa/member?';

      const options = {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getGroupDetails<TPromise = GetTpaGroupDetailsResponse>(
    model: CardRequest,
    handle: {
      200?: (result: GetTpaGroupDetailsResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/tpa/group/:cardId?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .filter((key) => key !== 'cardId')

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      url = url.replace(':cardId', model.cardId).replace('{cardId}', model.cardId);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getMembersCSV<TPromise = CsvResponse>(
    model: GetTpaMembersCSVRequest,
    handle: {200?: (result: CsvResponse) => void; 500?: (result: string) => void; 401?: (error: string) => void}
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/tpa/members/csv?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }
}

export class TpaReportsClient extends BaseClient {
  constructor(options: ControllerOptions) {
    super(options);
  }

  async getTpaReports<TPromise = GetTpaReportsResponse>(
    model: GetTpaReportsRequest,
    handle: {
      200?: (result: GetTpaReportsResponse) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      404: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/tpa/reports/?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 404) {
        await handle[404](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getTopReasons<TPromise = GetTpaReportsTopReasonsResponse>(
    model: GetTpaReportsRequest,
    handle: {
      200?: (result: GetTpaReportsTopReasonsResponse) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      404: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/tpa/reports/top-reasons?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 404) {
        await handle[404](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getTopDrugs<TPromise = GetTpaReportsTopDrugsResponse>(
    model: GetTpaReportsRequest,
    handle: {
      200?: (result: GetTpaReportsTopDrugsResponse) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      404: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/tpa/reports/top-drugs?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 404) {
        await handle[404](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getAppDownloads<TPromise = GetTpaReportsChartDataResponse>(
    model: GetTpaReportsRequest,
    handle: {
      200?: (result: GetTpaReportsChartDataResponse) => void;
      500?: (result: string) => void;
      404: (result: {error: string}) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/tpa/reports/app-downloads?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 404) {
        await handle[404](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getVisitsCompleted<TPromise = GetTpaReportsChartDataResponse>(
    model: GetTpaReportsRequest,
    handle: {
      200?: (result: GetTpaReportsChartDataResponse) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      404: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/tpa/reports/visits-completed?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 404) {
        await handle[404](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getTopStates<TPromise = GetTpaReportsTopStatesResponse>(
    model: GetTpaReportsRequest,
    handle: {
      200?: (result: GetTpaReportsTopStatesResponse) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      404: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/tpa/reports/top-states?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 404) {
        await handle[404](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }
}

export class TranslationClient extends BaseClient {
  constructor(options: ControllerOptions) {
    super(options);
  }

  async validateKey<TPromise = ValidateTranslationKeyResponse>(
    model: ValidateTranslationKeyRequest,
    handle: {
      200?: (result: ValidateTranslationKeyResponse) => void;
      500?: (result: string) => void;
      404: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/ch-translations/validate?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 404) {
        await handle[404](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getTranslationVersions<TPromise = GetTranslationVersionsResponse>(
    model: ValidateTranslationKeyRequest,
    handle: {
      200?: (result: GetTranslationVersionsResponse) => void;
      500?: (result: string) => void;
      404: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/ch-translations/?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 404) {
        await handle[404](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async updateTranslationVersion<TPromise = GetTranslationVersionsResponse>(
    model: UpdateTranslationVersionRequest,
    handle: {
      200?: (result: GetTranslationVersionsResponse) => void;
      500?: (result: string) => void;
      404: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/ch-translations/?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 404) {
        await handle[404](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }
}

export class UserClient extends BaseClient {
  constructor(options: ControllerOptions) {
    super(options);
  }

  async getBrokerDetails<TPromise = GetBrokerDetailsResponse>(
    model: GetBrokerDetailsRequest,
    handle: {
      200?: (result: GetBrokerDetailsResponse) => void;
      500?: (result: string) => void;
      404: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/user/broker-details?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 404) {
        await handle[404](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getBrokerDetailsBySlug<TPromise = GetBrokerDetailsResponse>(
    model: GetBrokerDetailsSlugRequest,
    handle: {
      200?: (result: GetBrokerDetailsResponse) => void;
      500?: (result: string) => void;
      404: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/user/broker-details-slug?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 404) {
        await handle[404](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async register<TPromise = LightJwtResponse>(
    model: RegisterRequest,
    handle: {
      200?: (result: LightJwtResponse) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/user/register?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async validate<TPromise = JwtGetUserResponse>(
    model: ValidateRequest,
    handle: {
      200?: (result: JwtGetUserResponse) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      404: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/user/validate?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 404) {
        await handle[404](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async setPushToken<TPromise = UserResponse>(
    model: SetPushTokenRequest,
    handle: {
      200?: (result: UserResponse) => void;
      500?: (result: string) => void;
      404: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/user/push-token?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 404) {
        await handle[404](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async setPharmacyPreferences<TPromise = UserResponse>(
    model: SetPharmacyPreferencesRequest,
    handle: {
      200?: (result: UserResponse) => void;
      500?: (result: string) => void;
      404: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/user/pharmacy-preferences?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 404) {
        await handle[404](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getUser<TPromise = JwtGetUserResponse>(
    model: GetUserRequest,
    handle: {
      200?: (result: JwtGetUserResponse) => void;
      500?: (result: string) => void;
      410: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/user/user?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 410) {
        await handle[410](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async watchDrug<TPromise = WatchDrugResponse>(
    model: WatchDrugRequest,
    handle: {
      200?: (result: WatchDrugResponse) => void;
      500?: (result: string) => void;
      404: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/user/watch-drug?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 404) {
        await handle[404](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async geoPing<TPromise = VoidResponse>(
    model: RadarGeoRequest,
    handle: {
      200?: (result: VoidResponse) => void;
      500?: (result: string) => void;
      404: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/user/geo-ping?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 404) {
        await handle[404](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async unwatchDrug<TPromise = UnwatchDrugResponse>(
    model: UnwatchDrugRequest,
    handle: {
      200?: (result: UnwatchDrugResponse) => void;
      500?: (result: string) => void;
      404: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/user/unwatch-drug?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 404) {
        await handle[404](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }
}

export class UserMailOrderClient extends BaseClient {
  constructor(options: ControllerOptions) {
    super(options);
  }

  async startMailOrderService<TPromise = StartMailOrderServiceResponse>(
    model: StartMailOrderServiceRequest,
    handle: {
      200?: (result: StartMailOrderServiceResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
      400: (result: {error: string}) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/user-mail-order/start-mail-order-service?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }
}

export class UserV2Client extends BaseClient {
  constructor(options: ControllerOptions) {
    super(options);
  }

  async getBrokerDetails<TPromise = GetBrokerDetailsV2Response>(
    model: GetBrokerDetailsV2Request,
    handle: {
      200?: (result: GetBrokerDetailsV2Response) => void;
      500?: (result: string) => void;
      404: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/user-v2/broker-details?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 404) {
        await handle[404](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getBrokerDetailsBySlug<TPromise = GetBrokerDetailsV2Response>(
    model: GetBrokerDetailsSlugV2Request,
    handle: {
      200?: (result: GetBrokerDetailsV2Response) => void;
      500?: (result: string) => void;
      404: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/user-v2/broker-details-slug?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 404) {
        await handle[404](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getUser<TPromise = JwtGetUserV2Response>(
    model: VoidRequest,
    handle: {
      200?: (result: JwtGetUserV2Response) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/user-v2/user?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async register<TPromise = LightJwtResponse>(
    model: RegisterV2Request,
    handle: {
      200?: (result: LightJwtResponse) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/user-v2/register?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async migrateUser<TPromise = JwtGetUserV2Response>(
    model: MigrateUserRequest,
    handle: {
      200?: (result: JwtGetUserV2Response) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/user-v2/migrate-user?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async setPushToken<TPromise = VoidResponse>(
    model: SetPushTokenRequest,
    handle: {200?: (result: VoidResponse) => void; 500?: (result: string) => void; 401?: (error: string) => void}
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/user-v2/push-token?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async validate<TPromise = JwtGetUserV2Response>(
    model: ValidateRequest,
    handle: {
      200?: (result: JwtGetUserV2Response) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/user-v2/validate?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async setPharmacyPreferences<TPromise = UserV2Response>(
    model: SetPharmacyPreferencesRequest,
    handle: {200?: (result: UserV2Response) => void; 500?: (result: string) => void; 401?: (error: string) => void}
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/user-v2/pharmacy-preferences?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getCabinet<TPromise = GetCabinetResponse>(
    model: GetCabinetRequest,
    handle: {200?: (result: GetCabinetResponse) => void; 500?: (result: string) => void; 401?: (error: string) => void}
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/user-v2/cabinet?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async saveToCabinet<TPromise = GetCabinetResponse>(
    model: SaveToCabinetRequest,
    handle: {200?: (result: GetCabinetResponse) => void; 500?: (result: string) => void; 401?: (error: string) => void}
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/user-v2/cabinet?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async deleteFromCabinet<TPromise = GetCabinetResponse>(
    model: DeleteFromCabinetRequest,
    handle: {200?: (result: GetCabinetResponse) => void; 500?: (result: string) => void; 401?: (error: string) => void}
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/user-v2/cabinet?';

      const options = {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getShareCount<TPromise = GetShareCountResponse>(
    model: VoidRequest,
    handle: {
      200?: (result: GetShareCountResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/user-v2/share-count?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async setPrescyptiveDetails<TPromise = UserV2Response>(
    model: SetPrescyptiveDetailsRequest,
    handle: {200?: (result: UserV2Response) => void; 500?: (result: string) => void; 401?: (error: string) => void}
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/user-v2/set-prescyptive-details?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getFaqs<TPromise = GetFaqsResponse>(
    model: VoidRequest,
    handle: {200?: (result: GetFaqsResponse) => void; 500?: (result: string) => void; 401?: (error: string) => void}
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/user-v2/faq?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async setZendeskUserDetails<TPromise = UserV2Response>(
    model: SetZendeskUserDetailsRequest,
    handle: {
      200?: (result: UserV2Response) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
      400: (result: {error: string}) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/user-v2/zendesk-user-details?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async submitPapForm<TPromise = VoidResponse>(
    model: SubmitPapFormRequest,
    handle: {200?: (result: VoidResponse) => void; 500?: (result: string) => void; 401?: (error: string) => void}
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/user-v2/submit-pap-form?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }
}

export class UserV2PharmacistClient extends BaseClient {
  constructor(options: ControllerOptions) {
    super(options);
  }

  async setPharmacistDetails<TPromise = UserV2Response>(
    model: SetPharmacistDetailsRequest,
    handle: {200?: (result: UserV2Response) => void; 500?: (result: string) => void; 401?: (error: string) => void}
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/user-v2-pharmacist/setPharmacistDetails?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getRewardsReport<TPromise = PharmacistGetRewardsResponse>(
    model: VoidRequest,
    handle: {
      200?: (result: PharmacistGetRewardsResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/user-v2-pharmacist/rewards-report?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getLedger<TPromise = GetLedgerResponse>(
    model: VoidRequest,
    handle: {200?: (result: GetLedgerResponse) => void; 500?: (result: string) => void; 401?: (error: string) => void}
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/user-v2-pharmacist/ledger?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async requestGiftCard<TPromise = GetLedgerResponse>(
    model: VoidRequest,
    handle: {
      200?: (result: GetLedgerResponse) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/user-v2-pharmacist/gift-card?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getStaticCards<TPromise = GetStaticCardsResponse>(
    model: VoidRequest,
    handle: {
      200?: (result: GetStaticCardsResponse) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/user-v2-pharmacist/static-cards?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }
}

export class UtilsClient extends BaseClient {
  constructor(options: ControllerOptions) {
    super(options);
  }

  async voucherEvent<TPromise = VoidResponse>(
    model: WebVoucherLogEventRequest,
    handle: {200?: (result: VoidResponse) => void; 500?: (result: string) => void; 401?: (error: string) => void}
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/utils/voucher?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getWebVoucherSurvey<TPromise = GetWebVoucherSurveyResponse>(
    model: GetWebVoucherSurveyRequest,
    handle: {
      200?: (result: GetWebVoucherSurveyResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/utils/voucher-survey?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async submitWebVoucherSurvey<TPromise = VoidResponse>(
    model: SubmitWebVoucherSurveyRequest,
    handle: {200?: (result: VoidResponse) => void; 500?: (result: string) => void; 401?: (error: string) => void}
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/utils/submit-voucher-survey?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getZipcode<TPromise = GetZipcodeResponse>(
    model: GetZipcodeRequest,
    handle: {
      200?: (result: GetZipcodeResponse) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/utils/zipcode?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async createPartnerContact<TPromise = VoidResponse>(
    model: CreatePartnerContactRequest,
    handle: {200?: (result: VoidResponse) => void; 500?: (result: string) => void; 401?: (error: string) => void}
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/utils/partner-contact?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getLatLng<TPromise = GetLatLngResponse>(
    model: GetLatLngRequest,
    handle: {
      200?: (result: GetLatLngResponse) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/utils/latlng?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getKrogerBrand<TPromise = GetKrogerBrandResponse>(
    model: GetKrogerBrandRequest,
    handle: {
      200?: (result: GetKrogerBrandResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/utils/kroger?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async meta<TPromise = MetaResponse>(
    model: VoidRequest,
    handle: {200?: (result: MetaResponse) => void; 500?: (result: string) => void; 401?: (error: string) => void}
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/utils/meta?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async partnerSlugRequest<TPromise = VoidResponse>(
    model: PartnerSlugRequest,
    handle: {200?: (result: VoidResponse) => void; 500?: (result: string) => void; 401?: (error: string) => void}
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/utils/partner-slug-request?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async submitWebContactForm<TPromise = VoidResponse>(
    model: WebContactFormRequest,
    handle: {200?: (result: VoidResponse) => void; 500?: (result: string) => void; 401?: (error: string) => void}
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/utils/web/contact?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async submitWebCalculatorAnalytics<TPromise = VoidResponse>(
    model: WebCalculatorRequest,
    handle: {200?: (result: VoidResponse) => void; 500?: (result: string) => void; 401?: (error: string) => void}
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/utils/web/calculator?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }
}

export class WebhookClient extends BaseClient {
  constructor(options: ControllerOptions) {
    super(options);
  }

  async docusign<TPromise = VoidResponse>(
    model: DocusignWebhookPostRequest,
    handle: {
      200?: (result: VoidResponse) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/webhook/docusign?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }
}

export class HealthAnalyticsClient extends BaseClient {
  constructor(options: ControllerOptions) {
    super(options);
  }

  async appOpen<TPromise = VoidResponse>(
    model: HealthAppOpenRequest,
    handle: {200?: (result: VoidResponse) => void; 500?: (result: string) => void; 401?: (error: string) => void}
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/analytics/app-open?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async zipUrl<TPromise = HealthGetZipUrlResponse>(
    model: VoidRequest,
    handle: {
      200?: (result: HealthGetZipUrlResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/analytics/zip-url?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async logEvent<TPromise = VoidResponse>(
    model: LogEventRequest,
    handle: {200?: (result: VoidResponse) => void; 500?: (result: string) => void; 401?: (error: string) => void}
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/analytics/log-event?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async logVoucherEvent<TPromise = VoidResponse>(
    model: LogVoucherEventRequest,
    handle: {200?: (result: VoidResponse) => void; 500?: (result: string) => void; 401?: (error: string) => void}
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/analytics/log-voucher-event?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async start<TPromise = VoidResponse>(
    model: TelemetryStartRequest,
    handle: {200?: (result: VoidResponse) => void; 500?: (result: string) => void; 401?: (error: string) => void}
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/analytics/start?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async meta<TPromise = MetaResponse>(
    model: VoidRequest,
    handle: {200?: (result: MetaResponse) => void; 500?: (result: string) => void; 401?: (error: string) => void}
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/analytics/meta?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }
}

export class HealthClientClient extends BaseClient {
  constructor(options: ControllerOptions) {
    super(options);
  }

  async getMembersFiltered<TPromise = GetHealthClientMembersResponse>(
    model: GetHealthClientMembersRequest,
    handle: {
      200?: (result: GetHealthClientMembersResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/client/members?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getMembersAdvancedSearch<TPromise = GetHealthClientMembersResponse>(
    model: GetHealthClientMembersAdvancedSearchRequest,
    handle: {
      200?: (result: GetHealthClientMembersResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/client/members/advancedSearch?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getMembersCSV<TPromise = CsvResponse>(
    model: GetHealthClientMembersCSVRequest,
    handle: {200?: (result: CsvResponse) => void; 500?: (result: string) => void; 401?: (error: string) => void}
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/client/members/csv?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getMember<TPromise = GetHealthClientMemberAndDependentsResponse>(
    model: GetHealthClientMemberRequest,
    handle: {
      200?: (result: GetHealthClientMemberAndDependentsResponse) => void;
      500?: (result: string) => void;
      404: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/client/member?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 404) {
        await handle[404](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async createMember<TPromise = GetHealthClientMemberResponse>(
    model: CreateHealthClientMemberRequest,
    handle: {
      200?: (result: GetHealthClientMemberResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/client/member?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async createFamily<TPromise = VoidResponse>(
    model: CreateHealthClientFamilyRequest,
    handle: {200?: (result: VoidResponse) => void; 500?: (result: string) => void; 401?: (error: string) => void}
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/client/family?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async updateMember<TPromise = GetHealthClientMemberResponse>(
    model: UpdateHealthClientMemberRequest,
    handle: {
      200?: (result: GetHealthClientMemberResponse) => void;
      500?: (result: string) => void;
      404: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/client/member?';

      const options = {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 404) {
        await handle[404](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async deleteMember<TPromise = VoidResponse>(
    model: DeleteHealthClientMemberRequest,
    handle: {
      200?: (result: VoidResponse) => void;
      500?: (result: string) => void;
      404: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/client/member?';

      const options = {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 404) {
        await handle[404](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getHealthReports<TPromise = GetHealthClientReportsResponse>(
    model: GetHealthClientReportsRequest,
    handle: {
      200?: (result: GetHealthClientReportsResponse) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/client/reports?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getHealthClientReportsTopReasons<TPromise = GetHealthClientReportsTopReasonsResponse>(
    model: GetHealthClientReportsRequest,
    handle: {
      200?: (result: GetHealthClientReportsTopReasonsResponse) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/client/reports/topReasons?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getHealthClientReportsTopDrugs<TPromise = GetHealthClientReportsTopDrugsResponse>(
    model: GetHealthClientReportsRequest,
    handle: {
      200?: (result: GetHealthClientReportsTopDrugsResponse) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/client/reports/topDrugs?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getHealthClientReportsAppDownloads<TPromise = GetHealthClientReportsAppDownloadsResponse>(
    model: GetHealthClientReportsRequest,
    handle: {
      200?: (result: GetHealthClientReportsAppDownloadsResponse) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/client/reports/appDownloads?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getHealthClientReportsVisitsCompleted<TPromise = GetHealthClientReportsChartDataResponse>(
    model: GetHealthClientReportsRequest,
    handle: {
      200?: (result: GetHealthClientReportsChartDataResponse) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/client/reports/visitsCompleted?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getHealthClientReportsVisitsStarted<TPromise = GetHealthClientReportsChartDataResponse>(
    model: GetHealthClientReportsRequest,
    handle: {
      200?: (result: GetHealthClientReportsChartDataResponse) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/client/reports/visitsStarted?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getHealthClientReportsVisitsTimeCompleted<TPromise = GetHealthClientReportsChartDataResponse>(
    model: GetHealthClientReportsRequest,
    handle: {
      200?: (result: GetHealthClientReportsChartDataResponse) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/client/reports/visitsTimeCompleted?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getHealthClientReportsTopStates<TPromise = GetHealthClientReportsTopStatesResponse>(
    model: GetHealthClientReportsRequest,
    handle: {
      200?: (result: GetHealthClientReportsTopStatesResponse) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/client/reports/topStates?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async sendCorrespondence<TPromise = VoidResponse>(
    model: HealthClientSendCorrespondenceRequest,
    handle: {
      200?: (result: VoidResponse) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/client/send-correspondence?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getImportReports<TPromise = GetHealthClientImportReportsResponse>(
    model: GetHealthClientImportReportsRequest,
    handle: {
      200?: (result: GetHealthClientImportReportsResponse) => void;
      500?: (result: string) => void;
      404: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/client/import-reports?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 404) {
        await handle[404](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getImportReport<TPromise = GetHealthClientImportReportResponse>(
    model: GetHealthClientImportReportRequest,
    handle: {
      200?: (result: GetHealthClientImportReportResponse) => void;
      500?: (result: string) => void;
      404: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/client/import-report?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 404) {
        await handle[404](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async updateClientUserDetails<TPromise = UpdateClientUserDetailsResponse>(
    model: UpdateClientUserDetailsRequest,
    handle: {
      200?: (result: UpdateClientUserDetailsResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/client/client-user-details?';

      const options = {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async updateClientBillingDetails<TPromise = UpdateClientBillingDetailsResponse>(
    model: UpdateClientBillingDetailsRequest,
    handle: {
      200?: (result: UpdateClientBillingDetailsResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/client/client-billing?';

      const options = {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getClientPaymentMethods<TPromise = GetClientPaymentMethodsResponse>(
    model: VoidRequest,
    handle: {
      200?: (result: GetClientPaymentMethodsResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/client/client-payment-methods?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async verifyACHPaymentMethod<TPromise = VerifyACHPaymentMethodResponse>(
    model: VerifyACHPaymentMethodRequest,
    handle: {
      200?: (result: VerifyACHPaymentMethodResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/client/verify-ach-payment?';

      const options = {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async deleteCardPaymentMethod<TPromise = DeleteCardPaymentMethodRequest>(
    model: DeleteCardPaymentMethodRequest,
    handle: {
      200?: (result: DeleteCardPaymentMethodRequest) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/client/delete-card?';

      const options = {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async deleteBankAccountPaymentMethod<TPromise = DeleteBankAccountPaymentMethodRequest>(
    model: DeleteBankAccountPaymentMethodRequest,
    handle: {
      200?: (result: DeleteBankAccountPaymentMethodRequest) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/client/delete-bank-account?';

      const options = {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async createStripeCustomer<TPromise = CreateHealthClientStripeCustomerResponse>(
    model: CreateHealthClientStripeCustomerRequest,
    handle: {
      200?: (result: CreateHealthClientStripeCustomerResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/client/create-stripe-customer?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async setupCardPaymentMethod<TPromise = VoidResponse>(
    model: SetupClientPaymentMethodRequest,
    handle: {
      200?: (result: VoidResponse) => void;
      500?: (result: string) => void;
      404: (result: {error: string}) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/client/card-payment-setup?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 404) {
        await handle[404](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async setupACHPaymentMethod<TPromise = VoidResponse>(
    model: SetupClientPaymentMethodRequest,
    handle: {
      200?: (result: VoidResponse) => void;
      500?: (result: string) => void;
      404: (result: {error: string}) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/client/ach-payment-setup?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 404) {
        await handle[404](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async payClientInvoice<TPromise = PayClientInvoiceResponse>(
    model: PayClientInvoiceRequest,
    handle: {
      200?: (result: PayClientInvoiceResponse) => void;
      500?: (result: string) => void;
      404: (result: {error: string}) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/client/pay-invoice?';

      const options = {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 404) {
        await handle[404](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getClientInvoices<TPromise = GetClientInvoicesResponse>(
    model: VoidRequest,
    handle: {
      200?: (result: GetClientInvoicesResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/client/invoices?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getClientInvoice<TPromise = GetClientInvoiceResponse>(
    model: GetClientInvoiceRequest,
    handle: {
      200?: (result: GetClientInvoiceResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/client/invoice?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getInvoicePdf<TPromise = VoidResponse>(
    model: GetClientInvoiceRequest,
    handle: {200?: (result: VoidResponse) => void; 500?: (result: string) => void; 401?: (error: string) => void}
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/client/invoice/pdf?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getSFTPCredentials<TPromise = GetSFTPCredentialsResponse>(
    model: VoidRequest,
    handle: {
      200?: (result: GetSFTPCredentialsResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/client/sftp/credentials?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async createSFTPCredentials<TPromise = CreateSFTPCredentialsResponse>(
    model: CreateSFTPCredentialsRequest,
    handle: {
      200?: (result: CreateSFTPCredentialsResponse) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/client/sftp/credentials?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getClientPartnerConfigColumns<TPromise = GetClientPartnerConfigColumnsResponse>(
    model: VoidRequest,
    handle: {
      200?: (result: GetClientPartnerConfigColumnsResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/client/partner-config-columns?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async updateClientPartnerConfigColumns<TPromise = VoidResponse>(
    model: UpdateClientPartnerConfigColumnsRequest,
    handle: {
      200?: (result: VoidResponse) => void;
      500?: (result: string) => void;
      404: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/client/partner-config-columns?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 404) {
        await handle[404](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async toggleEligibilityMarketing<TPromise = VoidResponse>(
    model: ToggleEligibilityMarketingRequest,
    handle: {
      200?: (result: VoidResponse) => void;
      500?: (result: string) => void;
      404: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/client/toggle-eligibility-marketing?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 404) {
        await handle[404](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async manualUploadEligibility<TPromise = VoidResponse>(
    model: ManualUploadEligibilityRequest,
    handle: {
      200?: (result: VoidResponse) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/client/manual-eligibility-upload?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async updateDefaultPaymentMethod<TPromise = UpdateClientDefaultPaymentMethodResponse>(
    model: UpdateClientDefaultPaymentMethodRequest,
    handle: {
      200?: (result: UpdateClientDefaultPaymentMethodResponse) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/client/default-payment-method?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async updateBillingAutoPay<TPromise = VoidResponse>(
    model: UpdateClientBillingAutoPayRequest,
    handle: {
      200?: (result: VoidResponse) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/client/update-auto-pay?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }
}

export class HealthDrugV2Client extends BaseClient {
  constructor(options: ControllerOptions) {
    super(options);
  }

  async price<TPromise = PricingV2Response>(
    model: PriceV2Request,
    handle: {
      200?: (result: PricingV2Response) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/drug-v2/price?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async priceNoFilter<TPromise = PricingV2Response>(
    model: PriceNoFilterRequest,
    handle: {
      200?: (result: PricingV2Response) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/drug-v2/price-no-filter?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getDrugStructure<TPromise = HttpDrugResultV2>(
    model: GetDrugStructureV2Request,
    handle: {
      200?: (result: HttpDrugResultV2) => void;
      500?: (result: string) => void;
      404: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/drug-v2/drug-structure?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 404) {
        await handle[404](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getDrugInformation<TPromise = DrugInformationV2>(
    model: GetDrugInformationV2Request,
    handle: {
      200?: (result: DrugInformationV2) => void;
      500?: (result: string) => void;
      404: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/drug-v2/drug-information?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 404) {
        await handle[404](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async search<TPromise = SearchResponseV2>(
    model: SearchRequest,
    handle: {200?: (result: SearchResponseV2) => void; 500?: (result: string) => void; 401?: (error: string) => void}
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/drug-v2/search?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getVoucher<TPromise = GetVoucherResponse>(
    model: GetVoucherRequest,
    handle: {200?: (result: GetVoucherResponse) => void; 500?: (result: string) => void; 401?: (error: string) => void}
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/drug-v2/voucher?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getVoucherSurvey<TPromise = GetVoucherSurveyResponse>(
    model: GetVoucherRequest,
    handle: {
      200?: (result: GetVoucherSurveyResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/drug-v2/voucher-survey?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async submitVoucherSurvey<TPromise = VoidResponse>(
    model: SubmitVoucherSurveyRequest,
    handle: {200?: (result: VoidResponse) => void; 500?: (result: string) => void; 401?: (error: string) => void}
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/drug-v2/submit-voucher-survey?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getDrugDetails<TPromise = GetDrugDetailsResponse>(
    model: GetDrugDetailsRequest,
    handle: {
      200?: (result: GetDrugDetailsResponse) => void;
      500?: (result: string) => void;
      404: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/drug-v2/drug-details?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 404) {
        await handle[404](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async submitPapFormV2<TPromise = VoidResponse>(
    model: SubmitPapRequestV2,
    handle: {200?: (result: VoidResponse) => void; 500?: (result: string) => void; 401?: (error: string) => void}
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/drug-v2/submit-pap-form/v2?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }
}

export class HealthIvrControllerClient extends BaseClient {
  constructor(options: ControllerOptions) {
    super(options);
  }

  async updateStatus<TPromise = VoidResponse>(
    model: HealthIvrUpdateStatusRequest,
    handle: {
      200?: (result: VoidResponse) => void;
      500?: (result: string) => void;
      404: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/ivr/update-status?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 404) {
        await handle[404](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async testEligibility<TPromise = HealthIvrTestEligibilityResponse>(
    model: HealthIvrTestEligibilityRequest,
    handle: {
      200?: (result: HealthIvrTestEligibilityResponse) => void;
      500?: (result: string) => void;
      404: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/ivr/test-eligibility?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 404) {
        await handle[404](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async sendDownloadLink<TPromise = VoidResponse>(
    model: HealthIvrUpdateStatusRequest,
    handle: {200?: (result: VoidResponse) => void; 500?: (result: string) => void; 401?: (error: string) => void}
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/ivr/send-download-link?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }
}

export class HealthLOCWebhookClient extends BaseClient {
  constructor(options: ControllerOptions) {
    super(options);
  }

  async sendPushNotification<TPromise = VoidResponse>(
    model: LOCSendPushNotificationRequest,
    handle: {
      200?: (result: VoidResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
      400: (result: {error: string}) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/listeners/push-notification?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async analyticsWebhook<TPromise = VoidResponse>(
    model: LOCAnalyticsRequest,
    handle: {
      200?: (result: VoidResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
      400: (result: {error: string}) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/listeners/analytics?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }
}

export class HealthMskClient extends BaseClient {
  constructor(options: ControllerOptions) {
    super(options);
  }

  async login<TPromise = HealthMskLoginResponse>(
    model: DependentRequest,
    handle: {
      200?: (result: HealthMskLoginResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/msk/login?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }
}

export class HealthUserClient extends BaseClient {
  constructor(options: ControllerOptions) {
    super(options);
  }

  async getBrokerDetails<TPromise = GetBrokerDetailsHealthResponse>(
    model: GetBrokerDetailsHealthRequest,
    handle: {
      200?: (result: GetBrokerDetailsHealthResponse) => void;
      500?: (result: string) => void;
      404: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/user/broker-details?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 404) {
        await handle[404](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async determineEligibility<TPromise = GetDetermineEligibilityResponse>(
    model: DetermineEligibilityRequest,
    handle: {
      200?: (result: GetDetermineEligibilityResponse) => void;
      500?: (result: string) => void;
      404: (result: {error: string}) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/user/determine-eligibility?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 404) {
        await handle[404](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async checkOpenEligibilityRelationship<TPromise = CheckOpenEligibilityRelationshipResponse>(
    model: CheckOpenEligibilityRelationshipRequest,
    handle: {
      200?: (result: CheckOpenEligibilityRelationshipResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/user/check-open-eligibility-relationship?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getBrokerDetailsBySlug<TPromise = GetBrokerDetailsHealthResponse>(
    model: GetBrokerDetailsSlugHealthRequest,
    handle: {
      200?: (result: GetBrokerDetailsHealthResponse) => void;
      500?: (result: string) => void;
      404: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/user/broker-details-slug?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 404) {
        await handle[404](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getUser<TPromise = JwtGetUserHealthResponse>(
    model: VoidRequest,
    handle: {
      200?: (result: JwtGetUserHealthResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/user/user?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async register<TPromise = HealthLightJwtResponse>(
    model: RegisterHealthRequest,
    handle: {
      200?: (result: HealthLightJwtResponse) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/user/register?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async setPushToken<TPromise = VoidResponse>(
    model: HealthSetPushTokenRequest,
    handle: {200?: (result: VoidResponse) => void; 500?: (result: string) => void; 401?: (error: string) => void}
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/user/push-token?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async startPaymentSetup<TPromise = StartStripeCustomerResponse>(
    model: VoidRequest,
    handle: {
      200?: (result: StartStripeCustomerResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/user/start-payment-setup?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async startSetupIntent<TPromise = StartSetupIntentResponse>(
    model: VoidRequest,
    handle: {
      200?: (result: StartSetupIntentResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/user/start-setup-intent?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async placeHold<TPromise = StripePlaceHoldResponse>(
    model: StripePlaceHoldRequest,
    handle: {
      200?: (result: StripePlaceHoldResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
      400: (result: {error: string}) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/user/place-hold?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async subsidizeVisit<TPromise = VoidRequest>(
    model: SubsidizeVisitRequest,
    handle: {200?: (result: VoidRequest) => void; 500?: (result: string) => void; 401?: (error: string) => void}
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/user/subsidize-visit?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async listCards<TPromise = StripeListCardsResponse>(
    model: VoidRequest,
    handle: {
      200?: (result: StripeListCardsResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/user/list-cards?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async setVpcPaymentMethod<TPromise = VoidResponse>(
    model: SetVpcPaymentMethodRequest,
    handle: {200?: (result: VoidResponse) => void; 500?: (result: string) => void; 401?: (error: string) => void}
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/user/vpc-payment-method?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async listCharges<TPromise = StripeListChargesResponse>(
    model: VoidRequest,
    handle: {
      200?: (result: StripeListChargesResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/user/list-charges?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async validate<TPromise = JwtGetUserHealthResponse>(
    model: HealthValidateRequest,
    handle: {
      200?: (result: JwtGetUserHealthResponse) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/user/validate?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async validatePinCode<TPromise = HealthValidatePinCodeResponse>(
    model: HealthValidatePinCodeRequest,
    handle: {
      200?: (result: HealthValidatePinCodeResponse) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/user/validate/pin-code?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async resetPinCode<TPromise = HealthValidatePinCodeResponse>(
    model: HealthResetPinCodeRequest,
    handle: {
      200?: (result: HealthValidatePinCodeResponse) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/user/validate/pin-code/reset?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async setPharmacyPreferences<TPromise = UserHealthResponse>(
    model: HealthSetPharmacyPreferencesRequest,
    handle: {200?: (result: UserHealthResponse) => void; 500?: (result: string) => void; 401?: (error: string) => void}
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/user/pharmacy-preferences?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getCabinet<TPromise = HealthGetCabinetResponse>(
    model: HealthGetCabinetRequest,
    handle: {
      200?: (result: HealthGetCabinetResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/user/cabinet?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async saveToCabinet<TPromise = HealthGetCabinetResponse>(
    model: HealthSaveToCabinetRequest,
    handle: {
      200?: (result: HealthGetCabinetResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/user/cabinet?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async saveOnboardingToCabinet<TPromise = VoidResponse>(
    model: HealthOnboardingSaveToCabinetRequest,
    handle: {200?: (result: VoidResponse) => void; 500?: (result: string) => void; 401?: (error: string) => void}
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/user/cabinet/onboarding?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getCabinetAllDependents<TPromise = HealthGetCabinetAllDependentsResponse>(
    model: VoidRequest,
    handle: {
      200?: (result: HealthGetCabinetAllDependentsResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/user/cabinet/all-dependents?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async deleteFromCabinet<TPromise = HealthGetCabinetResponse>(
    model: HealthDeleteFromCabinetRequest,
    handle: {
      200?: (result: HealthGetCabinetResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/user/cabinet?';

      const options = {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getDependents<TPromise = GetDependentsResponse>(
    model: GetDependentsRequest,
    handle: {
      200?: (result: GetDependentsResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/user/dependents?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async addDependents<TPromise = CreateDependentsResponse>(
    model: HealthAddDependentsRequest,
    handle: {
      200?: (result: CreateDependentsResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/user/dependents?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async updateUserDetails<TPromise = GetDependentsResponse>(
    model: HealthUpdateUserDetails,
    handle: {
      200?: (result: GetDependentsResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/user/update-user-details?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async removeDependent<TPromise = GetDependentsResponse>(
    model: HealthRemoveDependentsRequest,
    handle: {
      200?: (result: GetDependentsResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/user/remove-dependents?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getDefaultVoucher<TPromise = GetDefaultVoucherResponse>(
    model: VoidRequest,
    handle: {
      200?: (result: GetDefaultVoucherResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/user/default-voucher?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getNetworks<TPromise = GetNetworksResponse>(
    model: VoidRequest,
    handle: {
      200?: (result: GetNetworksResponse) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/user/networks?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getVisits<TPromise = GetVisitsResponse>(
    model: DependentRequest,
    handle: {200?: (result: GetVisitsResponse) => void; 500?: (result: string) => void; 401?: (error: string) => void}
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/user/visits?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getActionPendingVisits<TPromise = GetActionPendingVisitsResponse>(
    model: DependentRequest,
    handle: {
      200?: (result: GetActionPendingVisitsResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/user/visits/action-pending?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async setUserDetails<TPromise = GetDependentsResponse>(
    model: HealthSetUserDetailsRequest,
    handle: {
      200?: (result: GetDependentsResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/user/set-user-details?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async avatarUrl<TPromise = VoidResponse>(
    model: HealthGetAvatarRequest,
    handle: {200?: (result: VoidResponse) => void; 500?: (result: string) => void; 401?: (error: string) => void}
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/user/avatar/:dependentId.png?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .filter((key) => key !== 'dependentId')

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      url = url.replace(':dependentId', model.dependentId).replace('{dependentId}', model.dependentId);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async updateCommunicationConfig<TPromise = VoidResponse>(
    model: HealthSetCommunicationRequest,
    handle: {200?: (result: VoidResponse) => void; 500?: (result: string) => void; 401?: (error: string) => void}
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/user/update-communication-config?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async deleteDependent<TPromise = GetDependentsResponse>(
    model: HealthDeleteDependentRequest,
    handle: {
      200?: (result: GetDependentsResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/user/delete-dependent?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async checkIsStillEligible<TPromise = CheckIsStillEligibleResponse>(
    model: VoidRequest,
    handle: {
      200?: (result: CheckIsStillEligibleResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/user/check-eligibility?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getVpcEligibility<TPromise = GetVpcEligibilityResponse>(
    model: VoidRequest,
    handle: {
      200?: (result: GetVpcEligibilityResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/user/vpc-eligibility?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getShareCount<TPromise = GetShareCountResponse>(
    model: VoidRequest,
    handle: {
      200?: (result: GetShareCountResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/user/share-count?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getFaqs<TPromise = GetHealthFaqsResponse>(
    model: VoidRequest,
    handle: {
      200?: (result: GetHealthFaqsResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/user/faq?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async submitPapForm<TPromise = VoidResponse>(
    model: SubmitPapFormRequest,
    handle: {200?: (result: VoidResponse) => void; 500?: (result: string) => void; 401?: (error: string) => void}
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/user/submit-pap-form?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getGroupContent<TPromise = GetGroupContentResponse>(
    model: VoidRequest,
    handle: {
      200?: (result: GetGroupContentResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/user/group-content?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getJWTEligibility<TPromise = GetJWTEligibilityResponse>(
    model: GetJWTEligibilityRequest,
    handle: {
      200?: (result: GetJWTEligibilityResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/user/jwt-eligibility?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async sendDeleteAccountCode<TPromise = VoidResponse>(
    model: VoidRequest,
    handle: {
      200?: (result: VoidResponse) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/user/delete-account/send-code?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async validateDeleteAccountCode<TPromise = VoidResponse>(
    model: HealthValidateRequest,
    handle: {
      200?: (result: VoidResponse) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/user/delete-account/validate?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async confirmDeleteAccount<TPromise = VoidResponse>(
    model: VoidRequest,
    handle: {
      200?: (result: VoidResponse) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/user/delete-account/confirm?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getStaticValues<TPromise = GetHealthUserStaticValuesResponse>(
    model: VoidRequest,
    handle: {
      200?: (result: GetHealthUserStaticValuesResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/user/static-values?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }
}

export class HealthUserDialcareClient extends BaseClient {
  constructor(options: ControllerOptions) {
    super(options);
  }

  async login<TPromise = DialCareLoginResponse>(
    model: DialCareLoginRequest,
    handle: {
      200?: (result: DialCareLoginResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/dialcare/login?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async submitSurvey<TPromise = SubmitDialcareSurveyResponse>(
    model: SubmitDialcareSurveyRequest,
    handle: {
      200?: (result: SubmitDialcareSurveyResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/dialcare/survey?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async submitSurveyComments<TPromise = VoidResponse>(
    model: SubmitDialcareSurveyCommentsRequest,
    handle: {200?: (result: VoidResponse) => void; 500?: (result: string) => void; 401?: (error: string) => void}
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/dialcare/survey/comments?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }
}

export class HealthUserLOCClient extends BaseClient {
  constructor(options: ControllerOptions) {
    super(options);
  }

  async login<TPromise = LOCLoginResponse>(
    model: LOCLoginRequest,
    handle: {200?: (result: LOCLoginResponse) => void; 500?: (result: string) => void; 401?: (error: string) => void}
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/loc/create-user?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }
}

export class HealthUserPromoCodeClient extends BaseClient {
  constructor(options: ControllerOptions) {
    super(options);
  }

  async getPromoCodeBrokerDetails<TPromise = GetBrokerDetailsHealthResponse>(
    model: SavePromoCodeRequest,
    handle: {
      200?: (result: GetBrokerDetailsHealthResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/user/promo-code/broker-details?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async savePromoCode<TPromise = ListPromoCodesResponse>(
    model: SavePromoCodeRequest,
    handle: {
      200?: (result: ListPromoCodesResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/user/promo-code/save-promo-code?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async listPromoCodes<TPromise = ListPromoCodesResponse>(
    model: VoidRequest,
    handle: {
      200?: (result: ListPromoCodesResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/user/promo-code/list-promo-codes?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async applyPromoCode<TPromise = ListPromoCodesResponse>(
    model: ApplyPromoCodeRequest,
    handle: {
      200?: (result: ListPromoCodesResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/user/promo-code/apply-promo-code?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }
}

export class HealthUserTMClient extends BaseClient {
  constructor(options: ControllerOptions) {
    super(options);
  }

  async sign<TPromise = TMDependentResponse>(
    model: TMSignRequest,
    handle: {200?: (result: TMDependentResponse) => void; 500?: (result: string) => void; 401?: (error: string) => void}
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/user-tm/sign?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getTerms<TPromise = GetTermsResponse>(
    model: VoidRequest,
    handle: {200?: (result: GetTermsResponse) => void; 500?: (result: string) => void; 401?: (error: string) => void}
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/user-tm/terms?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async addDependent<TPromise = VoidResponse>(
    model: TMRequestPatientAddDependentRequest,
    handle: {200?: (result: VoidResponse) => void; 500?: (result: string) => void; 401?: (error: string) => void}
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/user-tm/addDependent?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async searchMedications<TPromise = SearchItemsResponse>(
    model: SearchItemsRequest,
    handle: {200?: (result: SearchItemsResponse) => void; 500?: (result: string) => void; 401?: (error: string) => void}
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/user-tm/search-medications?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async searchAllergies<TPromise = SearchItemsResponse>(
    model: SearchItemsRequest,
    handle: {200?: (result: SearchItemsResponse) => void; 500?: (result: string) => void; 401?: (error: string) => void}
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/user-tm/search-allergies?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getMedications<TPromise = GetMedicationsResponse>(
    model: DependentRequest,
    handle: {
      200?: (result: GetMedicationsResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/user-tm/medications?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async createMedications<TPromise = GetMedicationsResponse>(
    model: TMRequestPatientCreateMedicationRequest,
    handle: {
      200?: (result: GetMedicationsResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/user-tm/create-medications?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getAllergies<TPromise = GetAllergiesResponse>(
    model: DependentRequest,
    handle: {
      200?: (result: GetAllergiesResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/user-tm/allergies?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async createAllergy<TPromise = GetAllergiesResponse>(
    model: TMRequestPatientCreateAllergyRequest,
    handle: {
      200?: (result: GetAllergiesResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/user-tm/create-allergy?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getPharmacies<TPromise = TMGetPharmaciesResponse>(
    model: TMGetPharmaciesRequest,
    handle: {
      200?: (result: TMGetPharmaciesResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/user-tm/pharmacies?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getZipPharmacies<TPromise = TMGetZipPharmaciesResponse>(
    model: TMGetZipPharmaciesRequest,
    handle: {
      200?: (result: TMGetZipPharmaciesResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/user-tm/zip-pharmacies?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }
}

export class HealthUserTMVisitClient extends BaseClient {
  constructor(options: ControllerOptions) {
    super(options);
  }

  async startVisit<TPromise = TMVisitStartVisitResponse>(
    model: TMVisitStartVisitRequest,
    handle: {
      200?: (result: TMVisitStartVisitResponse) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/user-tm/visit/start-visit?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getActiveVisit<TPromise = TMVisitGetVisitResponse>(
    model: TMVisitStartVisitRequest,
    handle: {
      200?: (result: TMVisitGetVisitResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/user-tm/visit/active-visit?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getVisit<TPromise = TMVisitGetVisitResponse>(
    model: TMVisitGetVisitRequest,
    handle: {
      200?: (result: TMVisitGetVisitResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/user-tm/visit/visit?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getDisclaimer<TPromise = TMGetDisclaimerResponse>(
    model: DependentVisitRequest,
    handle: {
      200?: (result: TMGetDisclaimerResponse) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/user-tm/visit/disclaimer?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async acceptDisclaimer<TPromise = TMVisitGetScreenOutResponse>(
    model: TMVisitAcceptDisclaimerRequest,
    handle: {
      200?: (result: TMVisitGetScreenOutResponse) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/user-tm/visit/accept-disclaimer?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getScreenOut<TPromise = TMVisitGetScreenOutResponse>(
    model: DependentVisitRequest,
    handle: {
      200?: (result: TMVisitGetScreenOutResponse) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/user-tm/visit/screen-out?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async acceptScreenOut<TPromise = TMVisitWithFollowupResponse>(
    model: TMVisitAcceptScreenOutRequest,
    handle: {
      200?: (result: TMVisitWithFollowupResponse) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/user-tm/visit/accept-screen-out?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getReasons<TPromise = TMGetReasonsResponse>(
    model: DependentVisitRequest,
    handle: {
      200?: (result: TMGetReasonsResponse) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/user-tm/visit/reason?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getBehavioralHealthReasons<TPromise = TMGetBehavioralHealthReasonsResponse>(
    model: DependentRequest,
    handle: {
      200?: (result: TMGetBehavioralHealthReasonsResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/user-tm/visit/bh-reasons?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async setReason<TPromise = TMVisitSetReasonResponse>(
    model: TMVisitSetReasonRequest,
    handle: {
      200?: (result: TMVisitSetReasonResponse) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/user-tm/visit/reason?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async explainRepeatVisit<TPromise = TMVisitSetReasonResponse>(
    model: TMVisitExplainRepeatVisitRequest,
    handle: {
      200?: (result: TMVisitSetReasonResponse) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/user-tm/visit/explain-repeat-visit?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getModalities<TPromise = TMGetModalitiesResponse>(
    model: DependentVisitRequest,
    handle: {
      200?: (result: TMGetModalitiesResponse) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/user-tm/visit/modalities?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async setModality<TPromise = TMVisitSetModalityResponse>(
    model: TMVisitSetModalityRequest,
    handle: {
      200?: (result: TMVisitSetModalityResponse) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/user-tm/visit/modality?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async doctorStepUp<TPromise = TMVisitLightResponse>(
    model: TMVisitDoctorStepUpRequest,
    handle: {
      200?: (result: TMVisitLightResponse) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/user-tm/visit/doctor-step-up?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async answerQuestion<TPromise = TMVisitGetQuestionResponse>(
    model: TMVisitAnswerQuestionRequest,
    handle: {
      200?: (result: TMVisitGetQuestionResponse) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/user-tm/visit/question?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async answerHeightWeight<TPromise = TMVisitGetQuestionResponse>(
    model: TMVisitAnswerHeightWeightRequest,
    handle: {
      200?: (result: TMVisitGetQuestionResponse) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/user-tm/visit/height-weight?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getChatMessages<TPromise = TMVisitChatMessagesResponse>(
    model: DependentVisitRequest,
    handle: {
      200?: (result: TMVisitChatMessagesResponse) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/user-tm/visit/chat-messages?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async enterVideoWaitingRoom<TPromise = TMVisitResponse>(
    model: EnterVideoWaitingRoomRequest,
    handle: {
      200?: (result: TMVisitResponse) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/user-tm/visit/enter-video-waiting-room?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getVideoToken<TPromise = TMVisitVideoResponse>(
    model: DependentVisitRequest,
    handle: {
      200?: (result: TMVisitVideoResponse) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/user-tm/visit/video-token?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async sendPrescription<TPromise = TMVisitResponse>(
    model: SendPrescriptionRequest,
    handle: {
      200?: (result: TMVisitResponse) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/user-tm/visit/send-prescription?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async startChat<TPromise = TMVisitStartChatResponse>(
    model: DependentVisitRequest,
    handle: {
      200?: (result: TMVisitStartChatResponse) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/user-tm/visit/start-chat?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async cancelVisit<TPromise = TMVisitResponse>(
    model: DependentVisitRequest,
    handle: {
      200?: (result: TMVisitResponse) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/user-tm/visit/cancel-visit?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async declineTriage<TPromise = TMVisitResponse>(
    model: DependentVisitRequest,
    handle: {
      200?: (result: TMVisitResponse) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/user-tm/visit/decline-triage?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async approveTriage<TPromise = TMVisitResponse>(
    model: ApproveTriageRequest,
    handle: {
      200?: (result: TMVisitResponse) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/user-tm/visit/approve-triage?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getClinic<TPromise = GetClinicResponse>(
    model: GetClinicRequest,
    handle: {
      200?: (result: GetClinicResponse) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/user-tm/visit/clinic?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getReport<TPromise = TMVisitReportResponse>(
    model: DependentVisitRequest,
    handle: {
      200?: (result: TMVisitReportResponse) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/user-tm/visit/report?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async submitVisitAddendum<TPromise = TMVisitResponse>(
    model: TMVisitAddendumRequest,
    handle: {
      200?: (result: TMVisitResponse) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/user-tm/visit/submit-addendum?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getQuestion<TPromise = TMVisitGetQuestionResponse>(
    model: DependentVisitRequest,
    handle: {
      200?: (result: TMVisitGetQuestionResponse) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/user-tm/visit/question?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getFollowUp<TPromise = TMVisitGetQuestionResponse>(
    model: DependentVisitRequest,
    handle: {
      200?: (result: TMVisitGetQuestionResponse) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/user-tm/visit/follow-up?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async previousQuestion<TPromise = TMVisitGetQuestionResponse>(
    model: DependentVisitRequest,
    handle: {
      200?: (result: TMVisitGetQuestionResponse) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/user-tm/visit/previous-question?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getZipToken<TPromise = TMZipTokenResponse>(
    model: DependentVisitRequest,
    handle: {
      200?: (result: TMZipTokenResponse) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/user-tm/visit/zip-token?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getServerZipToken<TPromise = TMZipServerTokenResponse>(
    model: DependentVisitRequest,
    handle: {
      200?: (result: TMZipServerTokenResponse) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/user-tm/visit/zip-server-token?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getVisitSurvey<TPromise = GetVisitSurveyResponse>(
    model: DependentVisitRequest,
    handle: {
      200?: (result: GetVisitSurveyResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/user-tm/visit/visit-survey?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async sendVisitSurvey<TPromise = VoidResponse>(
    model: SendVisitSurveyRequest,
    handle: {200?: (result: VoidResponse) => void; 500?: (result: string) => void; 401?: (error: string) => void}
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/user-tm/visit/send-visit-survey?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async submitVisitSurveyComments<TPromise = VoidResponse>(
    model: SubmitVisitSurveyCommentsRequest,
    handle: {200?: (result: VoidResponse) => void; 500?: (result: string) => void; 401?: (error: string) => void}
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/user-tm/visit/visit-survey/comments?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getPreVisitSurveyResponse<TPromise = TMVisitGetPreVisitSurveyResponse>(
    model: DependentVisitRequest,
    handle: {
      200?: (result: TMVisitGetPreVisitSurveyResponse) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/user-tm/visit/previsit-survey?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async setPreVisitSurveyResponse<TPromise = TMVisitGetPreVisitSurveyResponse>(
    model: TMVisitSetPreVisitSurveyResponseRequest,
    handle: {
      200?: (result: TMVisitGetPreVisitSurveyResponse) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/user-tm/visit/previsit-survey?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async trackVideoAnalytic<TPromise = VoidResponse>(
    model: TMTrackVideoAnalyticRequest,
    handle: {200?: (result: VoidResponse) => void; 500?: (result: string) => void; 401?: (error: string) => void}
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/user-tm/visit/track-video?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }
}

export class HealthUserTpaClient extends BaseClient {
  constructor(options: ControllerOptions) {
    super(options);
  }

  async setInitialCredentials<TPromise = VoidResponse>(
    model: HealthUserTpaSetInitialCredentialsRequest,
    handle: {200?: (result: VoidResponse) => void; 500?: (result: string) => void; 401?: (error: string) => void}
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/tpa-stream/initial-credentials?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getInitialCredentials<TPromise = HealthUserTpaGetInitialCredentialsResponse>(
    model: DependentRequest,
    handle: {
      200?: (result: HealthUserTpaGetInitialCredentialsResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/tpa-stream/initial-credentials?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }
}

export class HealthUserX2AIClient extends BaseClient {
  constructor(options: ControllerOptions) {
    super(options);
  }

  async createUser<TPromise = UserHealthResponse>(
    model: X2AICreateUser,
    handle: {200?: (result: UserHealthResponse) => void; 500?: (result: string) => void; 401?: (error: string) => void}
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/user-x2ai/create-user?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }
}

export class HealthVpcIvrClient extends BaseClient {
  constructor(options: ControllerOptions) {
    super(options);
  }

  async updateStatus<TPromise = VoidResponse>(
    model: HealthVpcIvrUpdateStatusRequest,
    handle: {200?: (result: VoidResponse) => void; 500?: (result: string) => void; 401?: (error: string) => void}
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/vpc-ivr/update-status?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async testEligibility<TPromise = HealthVpcIvrTestEligibilityResponse>(
    model: HealthVpcIvrTestEligibilityRequest,
    handle: {
      200?: (result: HealthVpcIvrTestEligibilityResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/vpc-ivr/test-eligibility?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async sendDownloadLink<TPromise = VoidResponse>(
    model: HealthVpcIvrUpdateStatusRequest,
    handle: {200?: (result: VoidResponse) => void; 500?: (result: string) => void; 401?: (error: string) => void}
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/vpc-ivr/send-download-link?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }
}

export class HealthWebhookClient extends BaseClient {
  constructor(options: ControllerOptions) {
    super(options);
  }

  async zipnosis<TPromise = VoidResponse>(
    model: WebhookZipnosisPostRequest,
    handle: {
      200?: (result: VoidResponse) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/webhook/zipnosis?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async zendesk<TPromise = WebhookZendeskPostResponse>(
    model: WebhookZendeskPostRequest,
    handle: {
      200?: (result: WebhookZendeskPostResponse) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/webhook/zendesk?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async zendeskWhitelabel<TPromise = WebhookZendeskPostResponse>(
    model: WebhookZendeskWhitelabelPostRequest,
    handle: {
      200?: (result: WebhookZendeskPostResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/webhook/zendesk/whitelabel/:appId?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      url = url.replace(':appId', model.appId).replace('{appId}', model.appId);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async zendeskCleverRx<TPromise = WebhookZendeskPostResponse>(
    model: WebhookZendeskPostRequest,
    handle: {
      200?: (result: WebhookZendeskPostResponse) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/webhook/zendesk/cleverrx?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async tpaStreamCrawl<TPromise = VoidResponse>(
    model: WebhookTpaStreamCrawlPostRequest,
    handle: {200?: (result: VoidResponse) => void; 500?: (result: string) => void; 401?: (error: string) => void}
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/webhook/tpa-stream/crawl?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async tpaStreamClaim<TPromise = VoidResponse>(
    model: WebhookTpaStreamClaimPostRequest,
    handle: {200?: (result: VoidResponse) => void; 500?: (result: string) => void; 401?: (error: string) => void}
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/webhook/tpa-stream/claim?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async zendeskActivityHook<TPromise = VoidResponse>(
    model: ZendeskActivityWebhookRequest,
    handle: {200?: (result: VoidResponse) => void; 500?: (result: string) => void; 401?: (error: string) => void}
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/webhook/zendesk-activity?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }
}

export interface UpdateAccountsRequest {
  accountId: string;
  email: string;
  phoneNumber: string;
  accountOwner: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  zip: string;
  allowPromotionalCommunication: boolean;
  contactType: AccountContactTypes;
}

export type AccountContactTypes =
  | 'nonProfit'
  | 'association'
  | 'healthcareProvider'
  | 'broker'
  | 'employer'
  | 'TPA'
  | 'other';

export interface GetAccountResponse {
  account: HttpAccountExtended;
}

export interface HttpAccountExtended extends HttpAccount {
  cards: HttpCard[];
  partners: HttpPartner[];
}

export interface HttpAccount {
  id: string;
  email: string;
  primaryPartnerId?: string;
  phoneNumber?: string;
  accountSetUp: boolean;
  accountInvited: boolean;
  accountOwner?: string;
  accountInvitedDate?: Date;

  address1?: string;
  address2?: string;
  city?: string;
  state?: string;
  zip?: string;

  contactType: AccountContactTypes;

  allowPromotionalCommunication: boolean;
  createdDate: Date;
  updatedDate: Date;
  notes: HttpNote[];
}

export interface HttpNote {
  note: string;
  user: string;
  createdDate: Date;
}

export interface HttpCard {
  id: string;
  partnerId: string;
  accountId?: string;

  companyName?: string;
  groupCode: string;
  memberId: string;
  urlSlug?: string;
  shareUrlSlug?: string;

  deactivatedDate?: Date;
  requiresEligibility: boolean;
  usersCanAddTheirOwnDependents: boolean;
  zipEnv: 'DEV' | 'PROD';

  createdDate: Date;
  updatedDate: Date;
  notes: HttpNote[];
  openOrClosedEligibility?: 'open' | 'closed';
}

export interface HttpPartner {
  id: string;
  partnerName?: string;
  partnerType?: PartnerType;

  parentPartnerId?: string;
  contract?: HttpPartnerContract;
  commission?: HttpPartnerCommission;
  commissionReferrals?: HttpPartnerCommissionReferral[];
  w9?: HttpPartnerW9;
  startingGroupCode: string;
  startingMemberId: string;
  payoutStyle: PartnerPayoutStyle;

  createdDate: Date;
  updatedDate: Date;
  isPharmacist: boolean;
  isParentPharmacist: boolean;
  notes: HttpNote[];
  health?: HttpPartnerHealthDetails;
  healthContract?: HttpPartnerHealthContract;

  cmsEnabled?: boolean;
  isMasterTpa?: boolean;
}

export type PartnerType = 'Master' | 'Tier 1' | 'Tier 2' | 'Tier 3';

export interface HttpPartnerContract {
  importStatus?: 'Docusign' | string;
  docusignEnvelopeId?: string;
  contractSigned?: Date;
  contractRequested?: Date;
}

export interface HttpPartnerCommission {
  minimumPayout: number;
  commissionAmount: number;
  stripeHasBankAccount: boolean;
  stripeLinkSent: boolean;
  stripeAccountSetup: boolean;
}

export interface HttpPartnerCommissionReferral {
  commissionAmount: number;
  partnerId: string;
  dateStarted: Date;
}

export interface HttpPartnerW9 {
  docusignEnvelopeId?: string;
  dateSent?: Date;
  dateSigned?: Date;
}

export type PartnerPayoutStyle = 'giftcard' | 'stripe';

export interface HttpPartnerHealthDetails {
  commission?: {
    commissionAmount: number;
  };
  commissionReferrals?: HttpPartnerCommissionReferral[];
  dateConverted: Date;
}

export interface HttpPartnerHealthContract {
  contractSigned?: Date;
  contractRequested?: Date;
  docusignEnvelopeId?: string;
}

export interface SearchAccountsRequest {
  query: string;
  page: string;
}

export interface SearchAccountsResponse {
  accounts: HttpAccountSearch[];
  page: number;
}

export interface HttpAccountSearch {
  id: string;
  email: string;
  accountOwner: string;
  cardsCount: number;
  partnersCount: number;
}

export interface GetAccountRequest {
  accountId: string;
}

export interface VoidResponse {}

export interface CreatePartnerAccountRequest {
  email: string;
  phoneNumber: string;
  accountOwner: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  zip: string;
  allowPromotionalCommunication: boolean;
  contactType: AccountContactTypes;
  partnerId: string;
  autoInviteAccount: boolean;
  enrollInRxWelcomeSequence: boolean;
}

export interface CreatePartnerAccountResponse {
  accountId: string;
}

export interface CardOrderValidationRequest {
  password: string;
}

export interface CardOrderValidationResponse {
  isValid: boolean;
}

export interface GetAllCardOrdersRequest extends PartialFullDateRangeRequest {
  filter: PartnerCardTransactionStatus | '';
  password?: string;
  page: string;
  take: string;
}

export interface PartialFullDateRangeRequest {
  startDate?: FullDate;
  endDate?: FullDate;
}

export interface FullDate {
  year: number;
  month: number;
  day: number;
  stamp?: number | undefined;
}

export type PartnerCardTransactionStatus = 'pending' | 'proof' | 'approved' | 'production' | 'shipped' | 'archive';

export interface GetAllCardOrdersResponse {
  cardOrders: HttpPartnerCardTransactionSearch[];
  count: number;
}

export type HttpPartnerCardTransactionSearch = {
  id: string;
  email: string;
  companyName: string;
  status: PartnerCardTransactionStatus;
  quantity: number;
  createdDate: string;
  updatedDate: string;
  shippingMethod: 'standard' | 'expedited' | 'rush';
  cardType?: 'generic' | 'co-branded' | 'logo';
};

export interface GetAllCardOrdersCSVRequest extends PartialFullDateRangeRequest {
  filter: PartnerCardTransactionStatus | '';
  password?: string;
}

export interface GetAllCardOrdersCSVResponse {
  cardOrders: HttpPartnerCardTransaction[];
}

export type HttpPartnerCardTransaction = {
  id: string;
  email: string;
  companyName: string;
  name: string;
  partnerId: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  zipCode: string;
  language: 'english' | 'spanish';
  cardType: 'generic' | 'co-branded' | 'logo';
  quantity: number;
  shippingMethod: 'standard' | 'expedited' | 'rush';
  qrCodeRequested: boolean;
  receiptNumber?: string;
  receiptUrl?: string;
  createdDate: string;
  updatedDate: string;
  requestedDeliveryDate?: string;
  stripeChargeId?: string;
  amountCharged: number;
  status: PartnerCardTransactionStatus;
  cobrandedName?: string;
  trackingNumber?: string;
  carrier?: 'fedex' | 'ups' | '';
  logoBase64?: string;
  qrCodeBase64?: string;
  cardFrontProofBase64?: string;
  cardBackProofBase64?: string;
  proofsApprovedDate?: string;
  dateShipped?: string;
  groupCode: string;
  memberId: string;
  url: string;
  timestamps?: {type: string; date: Date; meta?: string; meta2?: string}[];
  newLogoRequestDate?: string;
};

export interface GetPartnerCardOrderRequest {
  password?: string;
  cardOrderId: string;
}

export interface GetPartnerCardOrderResponse {
  cardOrder: HttpPartnerCardTransaction;
}

export interface UpdatePartnerCardOrderStatusRequest extends GetPartnerCardOrderRequest {
  status: PartnerCardTransactionStatus;
}

export interface UpdatePartnerCardOrderRequest extends GetPartnerCardOrderRequest {
  status: PartnerCardTransactionStatus;
  name: string;
  email: string;
  language: 'english' | 'spanish';
  shippingMethod: 'standard' | 'expedited' | 'rush';
  address1: string;
  address2: string;
  city: string;
  state: string;
  zipCode: string;
}

export interface UploadCardOrderProofRequest extends GetPartnerCardOrderRequest {
  type: 'front' | 'back';
  dataUri: string;
  fileType: 'image/jpeg' | 'image/png';
}

export interface UpdatePartnerCardOrderTrackingRequest extends GetPartnerCardOrderRequest {
  trackingNumber: string;
  carrier: 'fedex' | 'ups' | '';
}

export interface GetUnfoundClaimsRequest {
  page: number;
}

export interface GetUnfoundClaimsResponse {
  unfoundClaims: {
    groupCode: string;
    memberId: string;
    count: number;
  }[];
}

export interface ResolveClaimRequest {
  oldGroupCode: string;
  oldMemberId: string;
  newGroupCode: string;
  newMemberId: string;
}

export interface SuccessResponse {
  success: boolean;
}

export interface VoidRequest {}

export interface ResolveClaimResponse {
  stripePayoutRequests: HttpStripePayoutRequest[];
}

export interface HttpStripePayoutRequest {
  id: string;
  partner: HttpPartner;
  amount: number;
  dateRequested: Date;
}

export interface FulfillPayoutRequestRequest {
  requestId: string;
}

export interface AdminMonthReportMonthsResponse {
  months: string[];
}

export interface AdminMonthReportRequest {
  month: string;
}

export interface AdminMonthReportResponse {
  commission: AdminMonthReportCommission;
  lastMonthCommissions: AdminMonthReportCommission;
  commissionErrors: {
    type: 'below-zero';
    claimId: string;
    partnerId: string;
    cardId: string;
    groupCode: string;
    memberId: string;
  }[];
}

export interface AdminMonthReportCommission {
  dateRan: Date;
  monthRan: string; // '2019-08'
  numberOfClaims: number;
  totalAmountDue: number;
  totalAmountSaved: number;
  averagePercentSaved: number;
  final: boolean;
  claims: {
    groupCode: string;
    memberId: string;
    count: number;
    earned: number;
    saved: number;
  }[];
  claimsDays: {name: string; claims: number; revenue: string; savings: string}[];
}

export interface AdminLoginRequest {
  email: string;
  password: string;
}

export interface AdminLoginResponse {
  user: HttpAdminUser;
  jwt: string;
}

export interface HttpAdminUser {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
}

export interface AdminReportResponse {
  report: AdminReport;
}

export interface AdminReport {
  partnerCount: number;
  accountCount: number;
  cardCount: number;
  userCount: number;
}

export interface AdminGenerateBinPcnReportRequest {
  month: string;
  type: 'singlecare' | 'rxedo';
}

export interface AdminStringReportResponse {
  report: string;
}

export interface GetClaimsByDayRequest {
  key: string;
  provider: 'rxedo' | 'singlecare';
}

export interface GetClaimsByDayResponse {}

export interface SlugsByDayResponse {
  slugs: {slug: string; count: number; date: string; cardId?: string}[];
}

export interface AdminGetFaqsResponse {
  faqs: {
    platform: FaqContentPlatform;
    faqs: {
      key: string;
      title: FaqMarkdown[];
      body: FaqMarkdown[];
    }[];
  }[];
}

export type FaqContentPlatform = 'clx-app' | 'clh-app' | 'hc' | 'patriotrx' | 'pdh';

export type FaqMarkdown = {
  type: 'n' | 'p' | 'pp' | 'b' | 'bb' | 'i' | 'u';
  text: string;
};

export interface AdminUpdateFaqRequest {
  platform: FaqContentPlatform;
  faqs: {
    key: string;
    title: FaqMarkdown[];
    body: FaqMarkdown[];
  }[];
}

export interface AdminPublishFaqDraftRequest {
  platform: FaqContentPlatform;
}

export interface PartnerRequest {
  partnerId: string;
}

export interface AccountCommissionResultMonthsResponse {
  months: string[];
}

export interface AccountMonthReportRequest {
  month: string;
  partnerId: string;
}

export interface AccountClaimCommissionResultResponse {
  commissionResult: HttpClaimCommissionResult;
  partnerOwnedCards: {groupCode: string; memberId: string}[];
  allCards: {
    id: string;
    partnerName: string;
    partnerId: string;
    groupCode: string;
    memberId: string;
    companyName?: string;
  }[];
  partnerResults: {
    partnerName: string;
    groupCode: string;
    memberId: string;
    numberOfClaims: number;
    totalAmountDue: number;
  }[];
  lastCommissionResult: HttpClaimCommissionResult;
}

export interface HttpClaimCommissionResult {
  dateRan: Date;
  monthRan: string; // '2019-08'
  groupedClaims: {
    totalAmountDue: number;
    groupCode: string;
    memberId: string;
    numberOfClaims: number;
  }[];
  byDateClaims: {
    totalAmountDue: number;
    date: string;
    numberOfClaims: number;
  }[];
  totalAmountDue: number;
  numberOfClaims: number;
  final: boolean;
}

export interface CsvResponse {
  csv: string;
}

export interface GetHealthReportsRequest extends FullDateRangeRequest {
  memberFilter: 'primary' | 'all';
}

export interface FullDateRangeRequest {
  startDate: FullDate;
  endDate: FullDate;
}

export interface GetHealthReportsResponse {
  usersCreated: {
    primaries: number;
    spouses: number;
    dependents: number;
    pets: number;
  };
  completedCalls: number;
  redirectedCalls: number;
  visitsCompleted: number;
  cabinetDrugsSaved: number;
  visitsAsync: number;
  visitsPhone: number;
  visitsVideo: number;
  incomingCalls: number;
  bellaUtilization: number;
  percentCallsEligible: number;
  locUtilization: number;
  appDownloads: number;
  downloadsFromShare: number;
  rxSearches: number;
  prescriptionsSent: number;
}

export interface GetHealthReportsUserDemographicsResponse {
  usersByAge: {[key: string]: {primary: number; spouse: number; dependent: number}};
  byState: {state: string; count: number}[];
}

export interface GetHealthReportsDataRequest extends FullDateRangeRequest {
  month: string;
  memberFilter: 'primary' | 'all';
}

export interface GetHealthReportsDataResponse {
  data: number[];
}

export interface GetHealthReportsMedicineCabinetRequest extends FullDateRangeRequest {
  userFilter: 'rx' | 'health';
}

export interface GetHealthReportsMedicineCabinetResponse {
  topMeds: {drugName: string; count: number}[];
}

export interface GetHealthReportsUsersByCardRequest extends PagedReportRequest {
  partnerId?: string;
}

export interface PagedReportRequest {
  offset: string;
  count: string;
}

export interface GetHealthReportsUsersByCardResponse {
  usersByCard: {count: number; card: HttpCardLight}[];
}

export interface HttpCardLight {
  partnerId: string;
  id: string;
  companyName?: string;
  groupCode: string;
  memberId: string;
  urlSlug?: string;
}

export interface GetDownloadsByPartnerResponse {
  breakdown: {groupCode: string; companyName: string; partnerName: string; memberId: string; count: number}[];
}

export interface GetHealthReportsChargesByCardResponse {
  chargesByCard: {charges: number; card: HttpCardLight}[];
}

export interface GetHealthReportsTopPricedDrugsRequest extends PartialFullDateRangeRequest {}

export interface GetHealthReportsTopPricedDrugsResponse {
  topPricedDrugs: {drugName: string; ndc: string; count: number}[];
}

export interface GetHealthReportsSurveysRequest extends PartialFullDateRangeRequest, PagedReportRequest {
  cardId?: string;
  networkFilter: 'tm' | 'dialcare' | '';
  appFilter?: AppCompanyId;
}

export type AppCompanyId = 'clh' | 'healthcompass' | 'patriotrx' | 'pdh';

export interface GetHealthReportsSurveysResponse {
  surveys: HttpTMVisitSurvey[];
  count: number;
}

export type HttpTMVisitSurvey = {
  id: string;
  firstName: string;
  lastName: string;
  createdDate: string;
  rating: number;
  comment: string;
  wouldRecommend: boolean;
  testimonial?: string;
  lowRatingFeedback?: string;
  type: 'tm' | 'dialcare' | 'dialcare-mental' | 'dialcare-vet';
};

export interface GetHealthReportsSurveyStatsRequest extends PartialFullDateRangeRequest {
  cardId?: string;
  appFilter?: AppCompanyId;
}

export interface GetHealthReportsSurveyStatsResponse {
  telemedicine: {
    reviews: {
      star1: number;
      star2: number;
      star3: number;
      star4: number;
      star5: number;
    };
    recommendPercentage: number;
  };
  dialcare: {
    reviews: {
      star1: number;
      star2: number;
      star3: number;
      star4: number;
      star5: number;
    };
    recommendPercentage: number;
  };
  totals: {
    reviews: {
      star1: number;
      star2: number;
      star3: number;
      star4: number;
      star5: number;
    };
    recommendPercentage: number;
  };
  previsit: {
    er: number;
    urgent: number;
    primary: number;
    specialist: number;
    none: number;
  };
  previsitCompleted: {
    er: number;
    urgent: number;
    primary: number;
    specialist: number;
    none: number;
  };
}

export interface GetHealthPartnerKPIsPartnersRequest extends PartialFullDateRangeRequest {
  offset: string;
  take: string;
  groupsPrefix: 'eq' | 'lt' | 'gt';
  groupsFilter: string;
  tasksPrefix: 'eq' | 'lt' | 'gt';
  tasksFilter: string;
  rank: '' | '0' | '1' | '2';
  master: string;
  isAccountSetup: 'yes' | 'no' | 'all';
}

export interface GetHealthPartnerKPIsPartnersResponse {
  partners: {
    id: string;
    createdDate: string;
    partnerName: string;
    email: string;
    rank: 'private' | 'lieutenant' | 'general';
    completedTasks: number;
    groups: number;
    master: string;
    numberOfClaims: number;
    contractSigned: string;
  }[];
  count: number;
}

export interface GetHealthPartnerKPIsPartnersCsvRequest extends PartialFullDateRangeRequest {
  groupsPrefix: 'eq' | 'lt' | 'gt';
  groupsFilter: string;
  tasksPrefix: 'eq' | 'lt' | 'gt';
  tasksFilter: string;
  rank: '' | '0' | '1' | '2';
  master: string;
  isAccountSetup: 'yes' | 'no' | 'all';
}

export interface GetHealthPartnerKPIsPartnerResponse {
  partner: {
    email: string;
    createdDate: string;
    partnerName: string;
    numberOfGroups: number;
    loginCount: number;
    lastLogin: string;
    completedTasks: PartnerTask[];
    master: string;
    accountSetUp: boolean;
    accountInvitedDate?: string;
    accountId: string;
  };
}

export type PartnerTask = {type: PartnerTaskType; dateCompleted: Date};

export type PartnerTaskType =
  | 'visitedPortal'
  | 'viewedNumbers'
  | 'downloadedApp'
  | 'visitedLandingPage'
  | 'watchedVideo'
  | 'signedDocs'
  | 'pricedRx'
  | 'sharedLink'
  | 'visitedProspectingPage'
  | 'sentFlyer'
  | 'orderedCards'
  | 'downloadedFlyers'
  | 'setupGroup'
  | 'firstClaim'
  | 'firstConsult'
  | 'hitPayout'
  | 'gotPaid';

export interface GetHealthPartnerKPIsReportsRequest extends PartialFullDateRangeRequest {}

export interface GetHealthPartnerKPIsReportsResponse {
  partners: {label: string; value: number}[];
  groups: {label: string; value: number}[];
  totalPartners: number;
  totalGroups: number;
  groupsNoUsers: number;
  partnersNoGroups: number;
  tasks: {task: PartnerTaskType; count: number}[];
  groupsOnboarding: number;
  numberOfLives: number;
}

export interface GetVisitsByGroupRequest extends PartialFullDateRangeRequest {}

export interface GetVisitsByGroupResponse {
  byGroup: {
    card: HttpCardLight;
    isSubsidized: boolean;
    activePrimaries: number;
    visits: {
      async: number;
      phone: number;
      video: number;
      ucm: number;
    };
  }[];
}

export interface GetGroupVisitCsvRequest {
  month: string; // YYYY-MM
}

export interface GetAdminHelpCenterCategoriesResponse {
  categories: string[];
}

export interface GetAdminHelpCenterResourcesRequest extends PagedReportRequest {
  category: string;
}

export interface GetAdminHelpCenterResourcesResponse {
  resources: HttpAdminHelpCenterResourceLight[];
  count: number;
  allCategories: string[];
}

export type HttpAdminHelpCenterResourceLight = {
  id: string;
  title: string;
  category: string;
  createdDate: string; // MM/DD/YYYY
  updatedDate: string; // MM/DD/YYYY
  author: {firstName: string; lastName: string; id: string};
  lastModifiedByAuthor: {firstName: string; lastName: string; id: string};
  slug: string;
};

export interface GetAdminHelpCenterResourceBySlugRequest {
  slug: string;
}

export interface GetAdminHelpCenterResourceResponse {
  resource: HttpAdminHelpCenterResource;
}

export type HttpAdminHelpCenterResource = HttpAdminHelpCenterResourceLight & {
  content: string;
  attachments: HttpAdminHelpCenterResourceAttachment[];
};

export type HttpAdminHelpCenterResourceAttachment = {
  id: string;
  fileName: string;
  fileType: string;
  resourceId: string;
  createdDate: string;
};

export interface UpdateAdminHelpCenterResourceRequest extends GetAdminHelpCenterResourceRequest {
  title: string;
  content: string;
}

export interface GetAdminHelpCenterResourceRequest {
  resourceId: string;
}

export interface CreateAdminHelpCenterResourceRequest {
  title: string;
  content: string;
  category: string;
  attachments: {id: string; fileName: string; fileType: string}[];
}

export interface CreateAdminHelpCenterResourceResponse extends GetAdminHelpCenterResourceResponse {
  uploadUrls: {id: string; uploadUrl: string}[];
}

export interface CreateAdminHelpCenterResourceAttachmentRequest extends GetAdminHelpCenterResourceRequest {
  fileName: string;
  fileType: string;
  title: string;
}

export interface CreateAdminHelpCenterResourceAttachmentResponse {
  uploadUrl: string;
}

export interface GetAdminHelpCenterResourceAttachmentRequest extends GetAdminHelpCenterResourceRequest {
  attachmentId: string;
}

export interface GetAdminHelpCenterResourceAttachmentResponse {
  downloadUrl: string;
}

export interface GetAllMarketingResponse {
  materials: HttpMarketingMaterial[];
}

export type HttpMarketingMaterial = {
  id: string;
  title: string;
  image: string;
  url: string;
  category: string;
  downloads: number;
  order: number;
};

export interface AddMaterialRequest {
  type: 'partner' | 'client';
  title: string;
  imageName: string;
  fileName: string;
  imageType: string;
  fileType: string;
  category: string;
  order: number;
}

export interface AddMaterialResponse {
  imageUpload: string;
  fileUpload: string;
}

export interface MaterialRequest {
  materialId: string;
}

export interface GetCampaignTemplatesResponse {
  templates: {sendgridLink: string; description: string; key: string}[];
}

export interface SubmitMarketingCampaignRequest {
  campaignType: 'text' | 'email' | 'one-email' | 'push';
  csvContent: string;
  emailCampaignDates?: FullDate[];
  textMessages?: {message: string; dateToSend: FullDate}[];
  companyName: string;
  urlSlug: string;
  groupCode: string;
  memberId: string;
  selectedOneOffTemplateKey?: string;
  overrides?: {
    alreadyDownloaded: boolean;
    alreadyReceived: boolean;
  };
  pushNotificationConfig?: {
    title: string;
    body: string;
    dateToSend: FullDate;
    hourToSendPST: number;
    allUsers: boolean;
  };
}

export interface GetCHMarketingCampaignStatsRequest extends PartialFullDateRangeRequest {}

export interface GetCHMarketingCampaignStatsResponse {
  stats: {
    emailsDelivered: number;
    emailsScheduled: number;
    textsDelivered: number;
    textsScheduled: number;
    pushesDelivered: number;
    pushesScheduled: number;
  };
  byDate: {
    emailsDelivered: {date: string; value: number}[];
    emailsScheduled: {date: string; value: number}[];
    textsDelivered: {date: string; value: number}[];
    textsScheduled: {date: string; value: number}[];
    pushesDelivered: {date: string; value: number}[];
    pushesScheduled: {date: string; value: number}[];
  };
}

export interface GetCHMarketingCampaignsRequest extends PartialFullDateRangeRequest {}

export interface GetCHMarketingCampaignsResponse {
  emailCampaigns: {
    companyName: string;
    cardId: string;
    delivered: number;
    scheduled: number;
    total: number;
    campaignName: string;
    dateSent: FullDate;
  }[];
  textCampaigns: {
    companyName: string;
    cardId: string;
    delivered: number;
    scheduled: number;
    total: number;
    dateSent: FullDate;
    exampleText: string;
  }[];
  pushCampaigns: {
    campaignId: string;
    companyName?: string;
    cardId?: string;
    delivered: number;
    scheduled: number;
    total: number;
    dateSent: FullDate;
    pushTitle: string;
    pushMessage: string;
  }[];
}

export interface SendTestEmailRequest {
  testRecipients: {name: string; email: string}[];
  templateKey: string;
  groupCode: string;
  memberId: string;
}

export interface UploadVoluntaryCensusRequest {
  cardId: string;
  csv: string;
}

export interface UploadVoluntaryCensusResponse {
  membersRemoved: number;
  membersAdded: number;
}

export interface AdminCmsGetAllContentResponse {
  pendingContent: {
    id: string;
    clientId: string;
    partnerName: string;
    groupCode: string;
    memberId: string;
    companyName: string;
    dateRequested: string;
  }[];
}

export interface AdminCmsGetContentRequest {
  versionId: string;
}

export interface AdminCmsGetContentResponse {
  client: HttpClient;
  pages: HttpClientContentPage[];
  content: HttpClientContent[];
  lastPublished?: string;
  partnerName: string;
  groupCode: string;
  memberId: string;
  dateRequested: string;
  language: 'es' | 'en';
  versionId: string;
}

export interface HttpClient {
  id: string;
  companyName: string;
  cardId: string;
  partnerId: string;
  address1?: string;
  address2?: string;
  city?: string;
  state?: string;
  zip?: string;
  tpaId?: string;
  isSubClient?: boolean;
}

export type HttpClientContentPage = {
  id: string;
  title: string;
  description: string;
  iconId: ClientContentIcon;
  order: number;
};

export type ClientContentIcon = 'q' | 'ex' | 'rx' | 'money' | '';

export type HttpClientContent = {
  id: string;
  pageId: string;
  content: DbClientContentContent;
  order: number;
};

export type DbClientContentContent =
  | {
      type: 'title';
      label: string;
      textStyle?: DbClientContentTextStyle;
      viewStyle?: DbClientContentViewStyle;
    }
  | {type: 'body'; content: string; textStyle?: DbClientContentTextStyle; viewStyle?: DbClientContentViewStyle}
  | {
      type: 'link';
      url: string;
      label: string;
      textStyle?: DbClientContentTextStyle;
      viewStyle?: DbClientContentViewStyle;
    }
  | {
      type: 'pageButton';
      pageId: string;
      label: string;
      buttonStyle?: DbClientContentButtonStyle;
      viewStyle?: DbClientContentViewStyle;
    }
  | {type: 'image'; url: string; imageStyle?: DbClientContentImageStyle; viewStyle?: DbClientContentViewStyle}
  | {type: 'listItem'; content: string; textStyle?: DbClientContentTextStyle; viewStyle?: DbClientContentViewStyle}
  | {
      type: 'linkButton';
      url: string;
      label: string;
      buttonStyle?: DbClientContentButtonStyle;
      viewStyle?: DbClientContentViewStyle;
    }
  | {type: 'line'; hasBorder: boolean; viewStyle?: DbClientContentViewStyle};

export type DbClientContentTextStyle = {
  fontSize: 'sm' | 'md' | 'lg' | undefined;
  fontWeight: 'light' | 'base' | 'bold' | undefined;
  textAlign: 'left' | 'center' | undefined;
  decoration: 'none' | 'underline' | undefined;
};

export type DbClientContentViewStyle = {
  mt: 'none' | 'xs' | 'sm' | 'md' | 'lg' | undefined;
  ml: 'none' | 'xs' | 'sm' | 'md' | 'lg' | undefined;
  mr: 'none' | 'xs' | 'sm' | 'md' | 'lg' | undefined;
  mb: 'none' | 'xs' | 'sm' | 'md' | 'lg' | undefined;
};

export type DbClientContentButtonStyle = {
  fontSize: 'sm' | 'md' | 'lg' | undefined;
  fontWeight: 'light' | 'base' | 'bold' | undefined;
};

export type DbClientContentImageStyle = {
  height: number | undefined;
};

export interface CreatePartnersRequest {
  parentPartnerId: string;
  partnerName: string;
  partnerType: PartnerType;
  startingGroupCode: string;
  startingMemberId: string;
  accountDetails: CreatePartnerAccountDetails;
  serviceType: 'cleverhealth' | 'cleverrx';
  commission: number;
  minimumPayout: number;
  commissionReferral: HttpPartnerCommissionReferral | null;
  sendToDocusign: boolean;

  card: {
    companyName?: string;
    urlSlug?: string;
  };

  sendEmailToParent: boolean;
  autoSendAccountActivation: boolean;
  enrollInRxWelcomeSequence: boolean;
}

export interface CreatePartnerAccountDetails {
  accountId?: string;
  firstName?: string;
  lastName?: string;
  contactType?: AccountContactTypes;
  email?: string;
  phoneNumber?: string;
  address1?: string;
  address2?: string;
  city?: string;
  state?: string;
  zip?: string;
  allowPromotionalCommunication?: boolean;
}

export interface GetPartnerResponse {
  partner: HttpPartnerExtended;
}

export interface HttpPartnerExtended extends HttpPartner {
  account: HttpAccountLight;
  groupTree: HttpPartnerGroupTreeItem;
}

export interface HttpAccountLight {
  id: string;
  email: string;
  primaryPartnerId?: string;
  accountOwner?: string;
  accountSetUp: boolean;
  accountInvited?: boolean;
  accountInvitedDate?: Date;
  lastLogin?: string;
}

export interface HttpPartnerGroupTreeItem {
  startingGroupCode: string;
  startingMemberId: string;
  partnerId: string;
  partnerName: string;
  commissionAmount: number;
  partnerType: string;
  account: HttpAccountLight;
  children: HttpPartnerGroupTreeItem[];
  cards: HttpCardAndAccount[];
  tpas: HttpTpaLight[];
  clients: HttpClient[];
  parent?: HttpPartnerGroupTreeItem;
  isHealthEnabled?: boolean;
  isPharmacist?: boolean;
}

export interface HttpCardAndAccount extends HttpCardLight {
  account?: HttpAccountLight;
}

export type HttpTpaLight = {
  id: string;
  companyName: string;
  urlSlug?: string;
  partnerId: string;
  address: TpaAddress;
};

export type TpaAddress = {
  address1: string;
  address2: string;
  city: string;
  state: string;
  zip: string;
};

export interface BreakPartnerOffRequestRequest {
  partnerType: PartnerType;
  sendToDocusign: boolean;
  partnerId: string;
  commissionAmount: number;
  referralAmount?: number;
}

export interface UpdatePartnerRequest {
  partnerId: string;
  partnerName: string;
  parentPartnerId: string;
  startingGroupCode: string;
  startingMemberId: string;
  partnerType: PartnerType;
  commissionReferrals: HttpPartnerCommissionReferral[];
  commissionAmount: number;
  minimumPayout: number;
  referrals?: {
    partnerId: string;
    commissionReferrals: HttpPartnerCommissionReferral[];
  }[];
  cmsEnabled: boolean;
}

export interface GetAllAmountsDueResponse {
  partners: {amountDue: number; partnerId: string; partnerName: string}[];
}

export interface LinkStripeRequest {
  partnerId: string;
}

export interface LinkStripeResponse extends GetPartnerResponse {}

export interface UpdateCardRequest extends CardRequest {
  cardId: string;
  partnerId: string;
  companyName: string;
  groupCode: string;
  memberId: string;
  requiresEligibility: boolean;
  usersCanAddTheirOwnDependents?: boolean;
  zipEnv?: 'DEV' | 'PROD';
  urlSlug: string;
  shareUrlSlug?: string;
}

export interface CardRequest {
  cardId: string;
}

export interface GetCardResponse {
  card: HttpCardExpanded;
  partner?: HttpPartner;
  tpaDetails?: {
    companyName: string;
    tpaId: string;
  };
  partnerNotes?: string;
}

export interface HttpCardExpanded extends HttpCard {
  account?: HttpAccountLight;
  partner: HttpPartner;
  client?: HttpClient;
  tpaDetails?: {tpaId: string; companyName: string};
}

export interface AddManualDebitRequest {
  partnerId: string;
  amount: string;
  note: string;
}

export interface GetLedgerResponse {
  ledger: HttpPartnerLedger;
  canRequestGiftCard: boolean;
}

export interface HttpPartnerLedger {
  lineItems: HttpPartnerLedgerLineItem[];
}

export type HttpPartnerLedgerLineItem =
  | {
      id: string;
      type: 'credit';
      amount: number;
      origin:
        | {
            type: 'commissionResult';
            commissionResultId: string;
            commissionMonth: string;
            dateAdded: Date;
          }
        | {
            type: 'claimCommissionResult';
            claimCommissionResultId: string;
            commissionMonth: string;
            dateAdded: Date;
          }
        | {
            type: 'manual';
            dateAdded: Date;
            note: string;
          };
      date: Date;
    }
  | {
      id: string;
      type: 'debit';
      date: Date;
      amount: number;
      hasClaims?: boolean;
      hasConsults?: boolean;
      origin: {reportApproximate: boolean; dateAdded: Date; note: string} & (
        | {
            type: 'stripe';
          }
        | {
            type: 'gift-card';
          }
        | {
            type: 'gift-card-request';
          }
        | {
            type: 'stripe-request';
          }
        | {
            type: 'manual';
          }
      );
    };

export interface CreateCardRequest {
  parentPartnerId: string;
  groupCode: string;
  memberId: string;
  accountDetails: CreateCardAccountDetails;
  card: {
    companyName?: string;
    urlSlug?: string;
  };
}

export type CreateCardAccountDetails = {
  accountId?: string;
  firstName?: string;
  lastName?: string;
  contactType?: AccountContactTypes;
  allowPromotionalCommunication?: boolean;
  email?: string;
  phoneNumber?: string;
  address1?: string;
  address2?: string;
  city?: string;
  state?: string;
  zip?: string;
};

export interface StartCreatePartnersRequest {
  parentPartnerId: string;
}

export interface StartCreatePartnersResponse {
  parentPartner: HttpPartnerExtended;
  groupCode: string;
  memberId: string;
}

export interface ValidatePartnerNameUniqueRequest {
  partnerName: string;
}

export interface ValidateUniqueResponse {
  valid: boolean;
}

export interface ValidateUrlSlugUniqueRequest {
  urlSlug: string;
}

export interface SearchPartnersRequest {
  query: string;
  page: string;
  onlyMasters: string;
}

export interface SearchPartnersResponse {
  partners: HttpPartnerSearch[];
  page: number;
}

export interface HttpPartnerSearch {
  id: string;
  partnerName: string;
  parentPartnerName: string;
  startingGroupCode: string;
  startingMemberId: string;
}

export interface GetPartnerRequest {
  partnerId: string;
}

export interface GetCardRequest {
  cardId: string;
}

export interface GetReferralPartnersResponse {
  referrals: HttpPartner[];
}

export interface GetPayoutsResponse {
  payouts: PartnerPayout[];
}

export interface PartnerPayout {
  cardId: string;
  groupCode: string;
  memberId: string;
  rate: string;
  amountDue: string;
}

export interface PageRequest {
  approvedPage: number;
}

export interface AdminPartnerRequestsResponse {
  requests: HttpPartnerRequestExtended[];
}

export interface HttpPartnerRequestExtended {
  id: string;
  createdDate: Date;
  initiatingPartnerId: string;
  initiatingPartnerName: string;
  initiatingStartingGroupCode: string;
  initiatingStartingMemberId: string;
  parentPartnerId: string;
  parentPartnerName: string;
  parentStartingGroupCode: string;
  parentStartingMemberId: string;
  masterPartnerId: string;
  masterPartnerName: string;
  masterStartingGroupCode: string;
  masterStartingMemberId: string;
  request: HttpPartnerRequestBody;
  approved: boolean;
  adjudicatedDate: Date;
  adjudicatedBy: string;
}

export type HttpPartnerRequestBody =
  | {
      type: 'card-vanity-url';
      cardId: string;
      vanityUrl: string;
      overrideGroupCode?: string;
      overrideMemberId?: string;
    }
  | {type: 'invite-partner'; body: AccountInvitePartnerRequest};

export interface AccountInvitePartnerRequest extends PartnerRequest {
  partnerName?: string;
  emailAddress?: string;
  commissionAmount?: string;

  companyName?: string;
  vanityUrl?: string;

  firstName?: string;
  lastName?: string;
  phoneNumber?: string;
  address1?: string;
  address2?: string;
  city?: string;
  state?: string;
  zip?: string;
  contactType?: string;

  overrideGroupCode?: string;
  overrideMemberId?: string;

  sendEmailToParent: boolean;
  serviceType: 'cleverhealth' | 'cleverrx';
  enrollInRxWelcomeSequence: boolean;
}

export interface AdminAdjudicatePartnerRequest {
  partnerRequestId: string;
  approved: boolean;
  denyReason?: string;

  request?: HttpPartnerRequestBody;
  autoSendActivation: boolean;
}

export interface AdminAdjudicatePartnerResponse {
  resultId?: string;
  resultType?: 'partner' | 'card';
}

export interface GetClientUsersRequest {
  cardId: string;
}

export interface GetClientUsersResponse {
  clientUsers: HttpClientUser[];
}

export interface HttpClientUser {
  id: string;
  email: string;
  firstName?: string;
  lastName?: string;
  phoneNumber?: string;
  accountSetUp: boolean;
  cardId: string;
  partnerId: string;
  createdDate: Date;
  updatedDate: Date;
  clientId: string;
}

export interface GetHealthClientRequest {
  cardId: string;
}

export interface GetHealthClientResponse {
  client: HttpClientExtended;
}

export type HttpClientExtended = {
  id: string;
  companyName: string;
  cardId: string;
  partnerId: string;
  groupCode: string;
  memberId: string;
  urlSlug: string;
  contacts?: ClientContacts;
  contract?: DbClientContract;
  voluntaryContract?: DbClientVoluntaryContract;
  isPepmEnabled?: boolean;
  pepmConfiguration?: HttpPepmConfiguration;
  isStripeSetup: boolean;
  autoPayEnabled: boolean;
  invoiceOptions?: {splitByState: boolean};
  address1?: string;
  address2?: string;
  city?: string;
  state?: string;
  zip?: string;
  startingDate?: string;
  additionalPepmContacts?: {name: string; email: string}[];
  isSubClient?: boolean;
  tpaId?: string;
  ediTpaUploadId?: string;
  productCodeConfig:
    | {membersPayByProductCode: true; productCodes: HttpGroupProductCode[]}
    | {membersPayByProductCode: false};
  autoMarketingEnabled: boolean;
};

export type ClientContacts = {
  contract: {name: string; email: string; phone: string};
  billing: {name: string; email: string; phone: string};
  admin: {name: string; email: string; phone: string};
};

export interface DbClientContract {
  contractSigned?: Date;
  contractRequested?: Date;
  docusignEnvelopeId?: string;
  overrideDate?: Date;
}

export interface DbClientVoluntaryContract {
  contractSigned?: Date;
  contractRequested?: Date;
  docusignEnvelopeId?: string;
}

export interface HttpPepmConfiguration {
  pepmRateInCents: number;
  effectiveDate: FullDate;
  receiveConsultFeeInvoices: boolean;
}

export type HttpGroupProductCode = {
  id: string;
  createdDate: string;
  updatedDate: string;
  networks: HealthConfiguration[];
  clientId: string;
  productCode: string;
};

export type HealthConfiguration =
  | {
      type: 'telemedicine';
      asyncCost: number;
      phoneCost: number;
      videoCost: number;
      chatCost: number;
      subsidize: boolean;
      enabled: boolean;
      splitAsyncCost?: {groupPay: number; memberPay: number};
      splitPhoneCost?: {groupPay: number; memberPay: number};
      splitVideoCost?: {groupPay: number; memberPay: number};
      splitChatCost?: {groupPay: number; memberPay: number};
    }
  | {type: 'prescription'; enabled: boolean}
  | {type: 'x2ai'; enabled: boolean}
  | {type: 'benjamin'; enabled: boolean}
  | {type: 'listeners'; enabled: boolean; subsidize: boolean}
  | {type: 'dialcare-mental'; enabled: boolean; subsidize: boolean; ivrOnly?: boolean}
  | {type: 'dialcare-vet'; enabled: boolean; ivrOnly?: boolean}
  | {
      type: 'ucm-ivr'; // vpc
      enabled: boolean;
      subsidize: boolean;
      initialVisitCost: {
        groupPay: number;
        memberPay: number;
        totalPay: number;
      };
      followUpVisitCost: {
        groupPay: number;
        memberPay: number;
        totalPay: number;
      };
    }
  | {type: 'ucm-urgent-care-ivr'; enabled: boolean; subsidize: boolean; groupPay: number; memberPay: number};

export interface CreateClientRequest {
  cardId: string;
  companyName: string;
  address1?: string;
  address2?: string;
  city?: string;
  state?: string;
  zip?: string;
}

export interface GetClientResponse {
  client: HttpClient;
}

export interface UpdateClientRequest {
  cardId: string;
  companyName?: string;
  address1?: string;
  address2?: string;
  city?: string;
  state?: string;
  zip?: string;
  startingDate?: string;
  ediTpaUploadId?: string;
  autoMarketingEnabled?: boolean;
}

export interface CreateClientUserRequest {
  email: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  cardId: string;
}

export interface GetClientUserResponse {
  clientUser: HttpClientUser;
}

export interface EditClientUserRequest extends GetClientUserRequest {
  firstName: string;
  lastName: string;
  phoneNumber: string;
}

export interface GetClientUserRequest {
  clientUserId: string;
  cardId: string;
}

export interface ActivateClientUserRequest {
  clientUserId: string;
}

export interface SetClaimReconcileRuleRequest {
  oldGroupCode: string;
  oldMemberId: string;
  newGroupCode: string;
  newMemberId: string;
}

export interface AutoSignDocumentRequest extends GetPartnerRequest {
  documentType: 'rxContract' | 'w9' | 'healthContract';
}

export interface GetPartnerAccountsResponse {
  accounts: HttpAccountLight[];
}

export interface GetPartnerAndAccountsResponse extends GetPartnerResponse, GetPartnerAccountsResponse {}

export interface GetPharmacistDownloadsResponse {
  pharmacists: {name: string; phone: string; groupCode: string; memberId: string}[];
}

export interface GetRxUsersRequest extends PagedReportRequest {
  phoneNumber: string;
  groupCode: string;
  memberId: string;
}

export interface GetRxUsersResponse {
  count: number;
  users: HttpAdminRxUserSearch[];
}

export interface HttpAdminRxUserSearch {
  id: string;
  createdDate: string;
  groupCode: string;
  memberId: string;
  phone: string;
  isPharmacist: boolean;
}

export interface GetRxUserRequest {
  userId: string;
}

export interface GetRxUserResponse {
  user: HttpAdminRxUser;
}

export interface HttpAdminRxUser {
  id: string;
  createdDate: string;
  phone: string;
  card: HttpCard;
  pharmacistDetails?: DBUserV2PharmacistDetails;
}

export type DBUserV2PharmacistDetails = {
  pharmacy?: PharmacyDetails;
  emailAddress?: string;
  zipcode?: string;
  name?: string;
};

export type PharmacyDetails = {
  address1: string;
  address2: string;
  postalCode: string;
  nabp: string;
  pharmacyType: PharmacyType;
  pharmacy: string;
};

export type PharmacyType = 'Walgreens' | KrogerPharmacyType | 'Walmart' | 'CVS' | 'Costco' | 'Other';

export type KrogerPharmacyType =
  | 'Bakers'
  | 'City Market'
  | 'Dillons'
  | 'Food 4 Less'
  | 'Foods Co'
  | 'Fred Meyer'
  | "Fry's"
  | 'Gerbes'
  | 'Harris Teeter'
  | 'King Soopers'
  | 'Jay C Food Store'
  | 'Kroger'
  | 'Owens Market'
  | 'Pay-Less Super Markets'
  | 'QFC'
  | 'Ralphs'
  | 'Smiths';

export interface UpdateRxUserRequest {
  userId: string;
  newGroupCode: string;
  newMemberId: string;
}

export interface HealthConfigResponse {
  networks: HealthConfiguration[];
  createdByRequest?: boolean;
  subsidizedInRequest?: boolean;
}

export interface UpdateHealthConfigRequest {
  cardId: string;
  networks: HealthConfiguration[];
}

export interface UpdatePartnerHealthCommissionRequest {
  partnerId: string;
  commissionReferrals: HttpPartnerCommissionReferral[];
  commissionAmount: number;
  referrals?: {
    partnerId: string;
    commissionReferrals: HttpPartnerCommissionReferral[];
  }[];
}

export interface GetAdminHealthClientUsersRequest extends PagedReportRequest {
  filter: string;
  cardId: string;
}

export interface GetAdminHealthClientUsersResponse {
  clientUsers: HttpClientUserSearch[];
  count: number;
}

export interface HttpClientUserSearch {
  id: string;
  email: string;
  firstName?: string;
  lastName?: string;
  phoneNumber?: string;
  accountInvited: boolean;
  accountInvitedDate?: string;
  accountActive: boolean;
  accountSetUp: boolean;
}

export interface GetHealthVisitsRequest extends FullDateRangeRequest, PagedReportRequest {
  status?: ZipnosisVisitState;
}

export type ZipnosisVisitState =
  | 'patient_encounter' // the initial state of a Visit
  | 'modality_required' // the initial state of a Visit
  | 'repeat_explanation_required' // the initial state of a Visit
  | 'sms_opt_in' // the initial state of a Visit
  | 'kicked_out' // the state in which a refer out has occurred in the Clinical Protocol
  | 'deleted' // the state which occurs when a user cancels/stops a Zipnosis
  | 'expired' // If a visit has not been completed by the patient 24 hours after it was started, then it is automatically transitioned to this state.
  | 'payment_requested' // when a questionnaire is completed and requires payment
  | 'patient_complete' // payment has been accepted and enters the clinician's queue for the visit's clinic
  | 'doctor_encounter' // a clinician has opened a visit and has locked it from being accessed by other clinicians
  | 'doctor_response' // a clinician has completed the visit for review by the patient and enters the patient inbox
  | 'video_verification' // video upgrade
  | 'phone_verification' // phone upgrade
  | 'patient_reviewed' // the patient has clicked on and reviewed their visit
  | 'prescriptions_unsent' // patient has sent their prescriptions to a pharmacy via ERX
  | 'prescriptions_sent' // patient has sent their prescriptions to a pharmacy via ERX
  | 'confirm_browser_for_video' //
  | 'height_weight_needed' //
  | 'doctor_step_up_opt_in' //
  /**/
  | 'cancelled-via-clever'
  | 'prescriptions_expired';

export interface GetHealthVisitsResponse {
  count: number;
  visits: HttpHealthVisit[];
}

export interface HttpHealthVisit {
  id: string;
  dateCreated: string;
  zipVisitId: number;
  reasonCode: string;
  status: ZipnosisVisitState;
  firstName: string;
}

export interface GetHealthUsersRequest extends PartialFullDateRangeRequest, PagedReportRequest {
  groupCode: string;
  memberId: string;
  phoneNumber: string;
  includeDateFilter: boolean;
  firstName?: string;
  lastName?: string;
  email?: string;
  dateOfBirth?: FullDate;
  zipCode?: string;
}

export interface GetHealthUsersResponse {
  count: number;
  users: HttpAdminHealthUserSearch[];
}

export interface HttpAdminHealthUserSearch {
  id: string;
  numberOfDependents: number;
  createdDate: string;
  groupCode: string;
  memberId: string;
  name: string;
  phone: string;
  appId: AppCompanyId;
}

export interface DownloadUserCsvRequest extends PartialFullDateRangeRequest {
  groupCode: string;
  memberId: string;
  phoneNumber: string;
  includeDateFilter: boolean;
  firstName?: string;
  lastName?: string;
  email?: string;
  dateOfBirth?: FullDate;
  zipCode?: string;
}

export interface GetHealthUserRequest {
  userId: string;
}

export interface GetHealthUserResponse {
  user: HttpAdminHealthUser;
}

export interface HttpAdminHealthUser {
  id: string;
  createdDate: string;
  phone: string;
  userDetails: HealthUserDetails;
  card: HttpCard;
  appId: AppCompanyId;
  dialcare?: {
    policyNumber: string;
  };
}

export type HealthUserDetails = {
  address1?: string;
  address2?: string;
  city?: string;
  state?: string;
  avatarUri?: string;
  avatarKey?: string;
  postal?: string;
  birthDate?: FullDate;
  email?: string;
  firstName?: string;
  lastName?: string;
  gender?: string;
  phone?: string;
};

export interface UpdateHealthUserRequest {
  userId: string;
  newGroupCode: string;
  newMemberId: string;
}

export interface GetHealthIvrAnalyticsRequest extends FullDateRangeRequest, PagedReportRequest {
  type: 'forward-to-ucm' | 'send-text' | '';
}

export interface GetHealthIvrAnalyticsResponse {
  count: number;
  ivrAnalytics: HttpHealthIvrAnalytics[];
}

export interface HttpHealthIvrAnalytics {
  id: string;
  type:
    | 'incoming-call'
    | 'start-eligibility'
    | 'send-text'
    | 'paid'
    | 'hang-up-on-payment'
    | 'forward-to-ucm'
    | 'eligibility' // status
    | 'eligibility-result';
  phoneNumber: string;
  zipCode: string;
  date: string;
}

export interface GetHealthVisitDataRequest {
  zipVisitId: string;
}

export interface GetHealthVisitDataResponse {
  data: ZipnosisVisit | {};
}

export type ZipnosisVisit = {
  id: number;
  state: ZipnosisVisitState;
  sub_state: string;
  promo_code_id?: any;
  patient_id: number;
  patient_name: string;
  assessment_text?: any;
  icd_code: string;
  reason_for_visit?: string;
  serial: string;
  required_triage_option?: 'chat' | 'video';
  clinic: VisitClinic;
  interview_progress?: number;
  doctor?: ZipDoctor;
  prescriptions: ZipnosisVisitPrescription[];
  clinical_protocol?: any;
  tos_accepted?: any;
  global_screen_out_accepted?: any;
  created_at: string;
  updated_at: string;
  finished_at?: any;
  submitted_at?: any;
  readable_state?: string;
  patient_answers: VisitPatientAnswers;
  zip_triage?: ZipTriage;
  images?: ZipImage[];
  pharmacy?: ZipnosisVisitPharmacy; // deprecated
};

export type VisitClinic = {
  id: number;
  name: string;
};

export type ZipDoctor = {
  id: number;
  first_name: string;
  last_name: string;
  title: string;
  name_with_title: string;
  full_name: string;
};

export interface ZipnosisVisitPrescription {
  id: number;
  pharmacy?: ZipnosisVisitPharmacy;
  visit_id: number;
  erx_last_sent?: any;
  drug_name: string;
  sig: string;
  order_number: string;
  route?: any;
  quantity: string;
  quantity_qualifier: string;
  strength: string;
  strength_qualifier: string;
  refills_count: number;
  refill_as_needed: boolean;
  ndc_code: string;
  drug_form: string;
  days_supply: string;
  allow_substitution: boolean;
  state: string;
  drug_description: string;
  description: string;
}

export interface ZipnosisVisitPharmacy {
  id: number;
  name: string;
  fax: string;
  ncpdp_id: number;
  latitude: number;
  phone: string;
  longitude: number;
  preferred: boolean;
  display_url: string;
  display_url_title: string;
  service_level: string;
  created_at: Date;
  updated_at: Date;
  address: ZipnosisVisitPharmacyAddress;
  distance?: any;
}

export interface ZipnosisVisitPharmacyAddress {
  id: number;
  address1: string;
  address2?: any;
  city: string;
  country?: any;
  phone: string;
  state: string;
  zip: string;
  full_address: string;
  latitude?: any;
  longitude?: any;
}

export type VisitPatientAnswers = {
  patient_gender: string;
  patient_age: number;
  patient_name: string;
  patient_first_name: string;
  sick_member_id: number;
  patient_dob: string;
  preparers_name: string;
  patient_email: string;
  patient_phone: string;
};

export type ZipTriage =
  | {
      notes?: null;
      queue_order?: number;
      queued_at?: string;
      created_at?: string;
      updated_at?: string;
      platform?: null;
      patient_connection?: {
        connected_at: string;
        disconnected_at: string;
      };
      doctor_connection?: null;
      id: number;
      state: 'queued' | 'ready';
      type: 'ZipTriageChat';
    }
  | {
      id: number;
      type: 'ZipTriageVideo';
      state: 'ready';
      notes?: any;
      queue_order?: number;
      queued_at?: any;
      created_at: string;
      updated_at: string;
      platform: 'twilio';
      patient_connection?: null;
      doctor_connection?: null;
    };

export interface ZipImage {
  id: number;
  file_name: string;
  file_size: number;
  clinician_last_viewed_at?: string;
  picture_taken_on: string;
  uploaded_by: string;
  question_identifier: string;
  type: string;
}

export interface GetAdminEDIMembersRequest {
  page: string;
  count: string;
  groupCode: string;
  memberId: string;
  lastName?: string;
  phoneNumber?: string;
  dob?: string;
  zipCode?: string;
  firstName?: string;
  isActive?: string;
  externalMemberId?: string;
}

export interface GetAdminEDIMembersResponse {
  members: HttpAdminEDIMember[];
  count: number;
}

export interface HttpAdminEDIMember {
  id: string;
  firstName?: string;
  lastName: string;
  dob: FullDate;
  zipCode: string;
  phoneNumber?: string;
  employeeType: 'primary' | 'spouse' | 'dependent';
  activeDate: FullDate;
  deactivatedDate: FullDate | null;
  uniqueId: string;
  partnerId: string;
  cardId: string;
  groupCode: string;
  memberId: string;
  companyName: string;
}

export interface GetAdminEDIMemberRequest {
  id: string;
}

export interface GetAdminEDIMemberResponse {
  member: HttpEligibleMemberExpanded & {companyName: string};
}

export type HttpEligibleMemberExpanded = {
  id: string;
  uniqueId: string;
  externalMemberId: string;
  dateOfBirth: FullDate;
  lastName: string;
  lastNameLower: string;
  firstName?: string;
  zipcode: string;
  otherFields: {[key: string]: string};
  partnerConfigId?: string;
  tpaConfigId?: string;
  cleverPartnerId: string;
  cleverCardId: string;
  cleverGroupCode: string;
  cleverMemberId: string;
  employeeType: EmployeeType;
  batchId: string;
  updatedBatchIds?: string[];
  createdDate: Date;
  updatedDate: Date;
  email?: string;
  state?: string;
  phoneNumber?: string;
  activeDate: FullDate;
  deactivatedDate: FullDate | null;
  deactivatedBatchId?: string;
  rowHash: number;
  gender?: string;
  city?: string;
  address1?: string;
  address2?: string;
  manualUpdate?: {
    date: Date;
    firstName: string;
    lastName: string;
    dateOfBirth: FullDate;
    zipcode: string;
    lastNameLower: string;
  };
};

export type EmployeeType = 'primary' | 'spouse' | 'dependent';

export interface UpdateAdminEDIMemberRequest {
  id: string;
  firstName: string;
  lastName: string;
  dob: FullDate;
  zipCode: string;
  activeDate: FullDate;
  deactivatedDate: FullDate | null;
  email: string;
}

export interface UpdateAdminEDIMemberResponse {
  member: HttpEligibleMemberExpanded;
}

export interface GetAdminEDIMemberTreeRequest {
  externalMemberId: string;
  cardId: string;
}

export interface GetAdminEDIMemberTreeResponse {
  members: HttpEligibleMemberExpanded[];
}

export interface GetHealthClientInvoicesRequest extends PartialFullDateRangeRequest, PagedReportRequest {}

export interface GetHealthClientInvoicesResponse {
  invoices: {
    createdDate: FullDate;
    dateSent?: FullDate;
    periodStart: FullDate;
    periodEnd: FullDate;
    chInvoiceId: string;
    stripeInvoiceId?: string;
    total: number;
    dueDate?: FullDate;
    status?: string;
    client:
      | {
          isTpa: true;
          companyName: string;
          tpaId: string;
        }
      | {
          isTpa: false;
          companyName: string;
          groupCode: string;
          memberId: string;
          cardId: string;
        };
  }[];
  count: number;
}

export interface GetHealthClientInvoiceRequest {
  invoiceId: string;
}

export interface GetHealthClientInvoiceResponse {
  invoiceData:
    | {
        stripeSetup: true;
        invoice: HttpClientInvoiceExtended;
      }
    | {
        stripeSetup: false;
        invoice: HttpClientInvoiceNoStripe;
      };
}

export interface HttpClientInvoiceExtended {
  invoiceId: string;
  stripeInvoiceId: string;
  number: string;
  periodStart: FullDate;
  periodEnd: FullDate;
  accountName: string;
  amountDue: number;
  amountPaid: number;
  created: FullDate;
  description: string;
  dueDate: FullDate;
  email: string;
  phone: string;
  status: string;
  billingAddress: {
    city: string | null;
    country: string | null;
    line1: string | null;
    line2: string | null;
    postal_code: string | null;
    state: string | null;
  };
  items: {
    id: string;
    unitAmount: number;
    description: string;
    quantity: number;
  }[];
  customerName: string;
  invoiceUrl?: string;
  invoicePdf?: string;
  markedAsPaid?: string;
  cardId?: string;
  tpaId?: string;
}

export interface HttpClientInvoiceNoStripe {
  cardId?: string;
  tpaId?: string;
  invoiceId: string;
  periodStart: FullDate;
  periodEnd: FullDate;
  amountDue: number;
  items: {
    id: string;
    unitAmount: number;
    description: string;
    quantity: number;
  }[];
}

export interface GetHealthClientRequestsRequest {
  page: string;
  filter: 'pending' | 'approved' | 'denied';
}

export interface GetHealthClientRequestsResponse {
  count: number;
  requests: HttpClientRequestSearch[];
}

export type HttpClientRequestSearch = {
  id: string;
  dateCreated: string;
  urlSlug: string;
  startingDate: string;
  numberOfEmployees: number;
  companyName: string;
};

export interface GetHealthClientRequestRequest {
  requestId: string;
}

export interface GetHealthClientRequestResponse {
  request: HttpClientRequest;
}

export type HttpClientRequest = {
  id: string;
  partnerId: string;
  cardId?: string;
  clientId?: string;
  partnerName?: string;
  dateCreated: string;
  companyName: string;
  startingDate: string;
  numberOfEmployees: number;
  urlSlug: string;
  address1?: string;
  address2?: string;
  city?: string;
  state?: string;
  zip?: string;
  isSubsidized: boolean;
  requiresEligibility: boolean;
  notes: string;
  status: string;
  initialNetworks?: HealthConfiguration[];
  contacts?: ClientContacts;
  autoSignupContacts?: boolean;
};

export interface UpdateHealthClientRequestRequest {
  requestId: string;
  companyName: string;
  startingDate: string;
  numberOfEmployees: number;
  urlSlug: string;
  address1?: string;
  address2?: string;
  city?: string;
  state?: string;
  zip?: string;
  isSubsidized: boolean;
  requiresEligibility: boolean;
  initialNetworks: HealthConfiguration[];
  contacts?: ClientContacts;
  autoSignupContacts?: boolean;
}

export interface AdjudicateHealthClientRequestRequest extends UpdateHealthClientRequestRequest {
  approved: boolean;
  denyReason?: string;
}

export interface CreateAdminGroupRequest {
  partnerId: string;
  companyName: string;
  startingDate: string;
  numberOfEmployees: number;
  urlSlug: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  zip: string;
  requiresEligibility: boolean;
  contacts: ClientContacts;
  autoSignupContacts: boolean;
  networks: HealthConfiguration[];
}

export interface CreateAdminGroupResponse extends CardRequest {}

export interface GetHealthGroupsRequest extends PartialFullDateRangeRequest, PagedReportRequest {
  filters: {
    subsidized: 'voluntary' | 'employer' | 'all';
    activePrimaries: number;
  };
}

export interface GetHealthGroupsResponse {
  groups: {
    id: string;
    isSubsidized: boolean;
    estimatedNumOfLives: number;
    numOfLives: number;
    companyName: string;
    createdDate: string;
    groupCode: string;
    memberId: string;
    cardId: string;
    contractIsSigned: boolean;
    activePrimaries: number;
    downloads: number;
    stripeSetup: boolean;
  }[];
  count: number;
}

export interface GetHealthGroupsCsvRequest extends PartialFullDateRangeRequest {
  filters: {
    subsidized: 'voluntary' | 'employer' | 'all';
    activePrimaries: number;
  };
}

export interface AdminUpdateHealthClientContactsRequest {
  clientId: string;
  contacts: ClientContacts;
}

export interface AdminSendClientAgreementRequest {
  clientId: string;
}

export interface SearchGroupsRequest {
  query: string;
}

export interface SearchGroupsResponse {
  groups: {id: string; groupCode: string; memberId: string; companyName: string}[];
}

export interface SetGroupInvoiceOptionsRequest extends CardRequest {
  invoiceOptions: {
    splitByState: boolean;
  };
}

export interface GetCompanyNameRequest {
  cardOrTpaId: string;
  clientType: 'tpa' | 'group';
}

export interface GetCompanyNameResponse {
  companyName: string;
}

export interface CreateGroupProductCodeRequest {
  clientId: string;
  networks: HealthConfiguration[];
  productCode: string;
}

export interface CreateProductCodeResponse {
  productCodeId: string;
}

export interface GetProductCodeRequest {
  productCodeId: string;
}

export interface GetGroupProductCodeResponse {
  productCode: HttpGroupProductCode;
  client: HttpClient;
}

export interface UpdateGroupProductCodeRequest extends GetProductCodeRequest {
  networks: HealthConfiguration[];
}

export interface ValidateGroupProductCodeUniqueRequest extends ClientRequest {
  productCode: string;
}

export interface ClientRequest {
  clientId: string;
}

export interface GetProductCodeStartingNetworksResponse {
  startingNetworks: HealthConfiguration[];
}

export interface GetIvrEnabledClientsResponse {
  vpcCardIds: string[];
  ucCardIds: string[];
  openEligibilityCardIds: string[];
}

export interface ProcessVpcUserCsvRequest {
  fileName: string;
}

export interface ProcessVpcUserCsvResponse {
  success: boolean;
}

export interface AdminSearchRequest {
  searchType: 'partners' | 'accounts' | 'clients';
  page: number;
  query: string;
}

export interface AdminSearchResponse {
  partners: HttpAdminPartnerSearch[];
  clients: HttpAdminClientSearch[];
  accounts: HttpAccountSearch[];
}

export type HttpAdminPartnerSearch = {
  id: string;
  partnerName: string;
  parentPartnerName: string;
  parentPartnerId: string;
  startingGroupCode: string;
  startingMemberId: string;
};

export type HttpAdminClientSearch =
  | {
      clientType: 'group';
      cardId: string;
      companyName: string;
      partnerName: string;
      groupCode: string;
      memberId: string;
      partnerId: string;
    }
  | {
      clientType: 'tpa';
      tpaId: string;
      companyName: string;
      partnerName: string;
      partnerId: string;
    }
  | {
      clientType: 'subgroup';
      cardId: string;
      companyName: string;
      partnerName: string;
      groupCode: string;
      memberId: string;
      tpaId: string;
      tpaCompanyName: string;
      partnerId: string;
    };

export interface AdminUpdatePepmRequest {
  isTpa: boolean;
  tpaId?: string;
  cardId?: string;
  pepmRateInCents: number;
  receiveConsultFeeInvoices: boolean;
}

export interface GetHealthClientOrTpaResponse {
  client: {isTpa: false; client: HttpClientExtended} | {isTpa: true; tpa: HttpTpa};
}

export type HttpTpa = {
  id: string;
  companyName: string;
  urlSlug?: string;
  partner: {id: string; partnerName: string};
  address: TpaAddress;
  contacts: TpaContacts;
  stripe: {isSetup: boolean; autoPayEnabled: boolean};
  subGroups: HttpTpaSubGroup[];
  isPepmEnabled: boolean;
  pepmConfiguration?: HttpPepmConfiguration & {
    additionalPepmContacts?: {name: string; email: string}[];
  };
  productCodes: HttpTpaProductCode[];
  defaultProductCode?: HttpTpaProductCode;
  autoMarketingEnabled: boolean;
};

export type TpaContacts = {
  billing: {name: string; email: string; phone: string};
  admin: {name: string; email: string; phone: string};
};

export type HttpTpaSubGroup = {
  clientId: string;
  cardId: string;
  partnerId: string;
  companyName: string;
  groupCode: string;
  memberId: string;
  urlSlug: string;
  ediTpaUploadId: string;
  networks?: HealthConfiguration[];
  activePrimaries?: number;
};

export type HttpTpaProductCode = {
  id: string;
  createdDate: string;
  updatedDate: string;
  networks: HealthConfiguration[];
  tpaId: string;
  displayName: string;
  productCode: string;
  requiresEligibility: boolean;
  openOrClosedEligibility: 'open' | 'closed';
  isDefault: boolean;
};

export interface AdminUpdatePepmContactsRequest {
  isTpa: boolean;
  tpaId?: string;
  cardId?: string;
  additionalContact: {name: string; email: string};
}

export interface GetAdminPepmInvoicesRequest extends PartialFullDateRangeRequest, PagedReportRequest {}

export interface GetAdminPepmInvoicesResponse {
  invoices: {
    createdDate: FullDate;
    dateSent?: FullDate;
    periodStart: FullDate;
    periodEnd: FullDate;
    invoiceId: string;
    stripeInvoiceId?: string;
    total: number;
    pepmRateInCents: number;
    finalMemberCount: number;
    status?: string;
    dueDate?: FullDate;
    markedAsPaid?: string;
    client:
      | {
          isTpa: true;
          tpaId: string;
          companyName: string;
        }
      | {
          isTpa: false;
          groupCode: string;
          memberId: string;
          cardId: string;
          companyName: string;
        };
  }[];
  count: number;
}

export interface GetAdminPepmInvoiceRequest {
  invoiceId: string;
}

export interface GetAdminPepmInvoiceResponse {
  invoice: HttpPepmInvoiceExtended;
}

export interface HttpPepmInvoiceExtended {
  invoiceId: string;
  stripeInvoiceId: string;
  companyName: string;
  pepmRateInCents: number;
  number: string;
  periodStart: FullDate;
  periodEnd: FullDate;
  accountName: string;
  amountDue: number;
  amountPaid: number;
  created: FullDate;
  description: string;
  dueDate: FullDate;
  email: string;
  phone: string;
  status: string;
  billingAddress: {
    city: string | null;
    country: string | null;
    line1: string | null;
    line2: string | null;
    postal_code: string | null;
    state: string | null;
  };
  items: {
    id: string;
    unitAmount: number;
    description: string;
    quantity: number;
  }[];
  customerName: string;
  invoiceUrl?: string;
  invoicePdf?: string;
  finalMemberCount: number;
  markedAsPaid?: string;
}

export interface GetDownloadsByPartnerRequest {
  partnerId: string;
}

export interface GetClaimsReportRequest {
  startDate: FullDate;
  endDate: FullDate;
}

export interface ClaimsReportResponse {
  totalSavings: number;
  averageSavingPercent: number;
  numberOfSearches: number;
  claimOfThePeriod: ClaimOfTheDay;
  topPharmacies: {
    pharmacyName: string;
    count: number;
  }[];
  appClaims: number;
  nonAppClaims: number;
  singlecareClaims: number;
  rxedoClaims: number;
  topPartnersClaims: TopPartnerClaims[];
  // topPartnersUsers: TopPartnerUsers[];
  searchesByPlatform: {
    platform: 'clx-web' | 'clh-web' | 'clx-app-user' | 'clx-app-pharm' | 'clx-iframe' | 'portal' | 'clh-app' | null;
    searches: number;
  }[];
  webStats: {
    desktop: number;
    tablet: number;
    mobile: number;
    topUrls: {groupCode: string; memberId: string; urlSlug: string; searches: number}[];
  };
  appTMClaims: number;
}

export interface ClaimOfTheDay {
  date: string;
  drugName: string;
  totalSavings: number;
  percentSaved: number;
  pharmacy: string;
}

export interface TopPartnerClaims {
  partnerId: string;
  partnerName: string;
  groupCode: string;
  memberId: string;
  appClaims: number;
  nonAppClaims: number;
  totalSavings: number;
  averageSavingPercent: number;
}

export interface GetMasterClaimReportRequest {
  startDate: FullDate;
  endDate: FullDate;
}

export interface MasterClaimReportResponse {
  masters: MasterClaimReport[];
}

export interface MasterClaimReport {
  partnerName: string;
  partnerId: string;
  appClaims: number;
  nonAppClaims: number;
  totalSavings: number;
  averageSavingPercent: number;
  commissionEarned: number;
  clxDownloads: number;
  clhDownloads: number;
}

export interface GetAllTimeClaimsReportRequest {}

export interface AllTimeClaimsReportResponse {
  totalSavings: number;
  averageSavingPercent: number;
  appClaims: number;
  nonAppClaims: number;
  numberOfSearches: number;
}

export interface GetClaimsDayReportRequest {
  startDate: FullDate;
  endDate: FullDate;
  page: number;

  ndcOrPrescription?: string;
  pharmacy?: string;
  groupCode?: string;
  memberId?: string;
}

export interface GetClaimsByDayReportResponse {
  claims: ClaimItem[];
}

export interface ClaimItem {
  drugName: string;
  quantity: number;
  priceBase: number;
  amountPaid: number;
  amountSaved: number;
  pharmacy: string;

  groupCode: string;
  memberId: string;

  partnerId: string;

  date: FullDate;
  time: FullTime;
  ndc: string;

  fromApp: boolean;
  fromShare: boolean;

  type: 'singlecare' | 'rxedo';

  compensable: 1 | 0 | -1;
  rawRow: {[key: string]: any};
}

export interface FullTime {
  hour: number;
  minute: number;
  second: number;
  stamp: number;
}

export interface GetClaimsResultReportResponse {
  claimsByDate: ClaimsResultReportByDate[];
}

export interface ClaimsResultReportByDate {
  batchId: string;
  monthsEffected: string[];
  claimsInserted: number;
  unfoundCards: number;
  fileName: string;
  provider: 'singlecare' | 'rxedo';
  dateRan: Date;
  duration: number;
}

export interface GetVoucherSurveyStatsRequest extends PartialFullDateRangeRequest {
  platform?: 'clx-app' | 'clh-app' | 'clx-web';
}

export interface GetVoucherSurveyStatsResponse {
  willUse: {
    yes: number;
    no: number;
  };
  wasUsed: {
    yes: number;
    no: number;
  };
  goodPrice: {
    yes: number;
    no: number;
  };
  platforms: {
    'clx-app': number;
    'clh-app': number;
    'clx-web': number;
  };
  surveyCount: number;
}

export interface GetVoucherSurveysRequest extends PartialFullDateRangeRequest, PagedReportRequest {
  platform?: 'clx-app' | 'clh-app' | 'clx-web';
}

export interface GetVoucherSurveysResponse {
  surveys: HttpAdminVoucherSurvey[];
  count: number;
}

export type HttpAdminVoucherSurvey = {
  id: string;
  willUse: {
    dateSubmitted?: Date;
    code: -1 | 0 | 1;
  };
  goodPrice: {
    dateSubmitted?: Date;
    code: -1 | 0 | 1;
  };
  wasUsed: {
    dateSubmitted?: Date;
    code: -1 | 0 | 1;
  };
  notUsedReason?: {
    dateSubmitted: Date;
    reason: string;
  };
  platform: 'clx-app' | 'clh-app' | 'clx-web';
  createdDate: Date;
  updatedDate: Date;
  userPhoneNumber: string;
  ndc: string;
  price: number;
};

export interface GetPapSubmissionsResponse {
  submissions: {
    platform: AnalyticsPlatform;
    date: Date;
    ndc: string;
    email: string;
    url?: string;
  }[];
}

export type AnalyticsPlatform = 'clx-web' | 'clh-web' | 'clx-app' | 'clx-iframe' | 'portal' | 'clh-app';

export interface AdminScriptSaveSearchRequest {
  drugName: string;
}

export interface AdminScriptSaveSearchResponse {
  drugNames: string[];
}

export interface AdminScriptSaveFindDrugsRequest {
  drugName: string;
  zipCode: string;
  groupCode?: string;
  memberId?: string;
  gsn?: string;
  brandIndicator?: 'B' | 'G';
}

export interface AdminScriptSaveFindDrugsResponse {
  results: ScriptSaveFindDrugsResponse;
}

export interface ScriptSaveFindDrugsResponse {
  names: {
    drugName: string;
    isGeneric: boolean;
  }[];
  quantities: {
    gsn: string;
    quantity: number;
    quantityLabel: string;
  }[];
  forms: {
    gsn: string;
    form: string;
  }[];
  strengths: {
    gsn: string;
    strength: string;
  }[];
  selectedDrug: {
    gsn: string;
    ndc: string;
  };
}

export interface AdminScriptSaveGetDrugStructureRequest {
  gsn: string;
}

export interface AdminScriptSaveGetDrugStructureResponse {
  results: ScriptSaveDrugStructureResponse;
}

export interface ScriptSaveDrugStructureResponse {
  quantities: {
    gsn: string;
    quantity: number;
    quantityLabel: string;
  }[];
  forms: {
    gsn: string;
    form: string;
  }[];
  strengths: {
    gsn: string;
    strength: string;
  }[];
}

export interface AdminScriptSavePriceRequest {
  zipCode: string;
  quantity: string;
  ndc: string;
  groupCode?: string;
  memberId?: string;
}

export interface AdminScriptSavePriceResponse {
  prices: DrugPriceV2[];
}

export interface DrugPriceV2 {
  pharmacy: string;
  pharmacyType: PharmacyType;
  nabp?: string;
  street1: string;
  street2: string;
  city: string;
  state: string;
  zipCode: string;
  ndc: string;
  price: number;
  uncPrice: number;
  percentSavings: string;

  distance: number;

  latitude: number;
  longitude: number;

  closest: boolean;
  cheapest: boolean;
  preferredPharmacy: boolean;
  preferredPharmacyType: boolean;

  coupon: DrugCoupon;
}

export interface DrugCoupon {
  provider: 'rxedo' | 'singlecare' | 'scriptsave';
  retrieved: boolean;
  groupNumber: string;
  memberId: string;
  rxBin: string;
  pcn: string;

  pharmacistPhoneNumber: string;
  memberPhoneNumber: string;

  user?: string;
  password?: string;
}

export interface PriceV2Request {
  ndc: string;
  zipcode?: string;
  quantity: number;
  pharmacy?: PharmacyPriceRequest;
  platform?: 'clx-web' | 'clh-web' | 'clx-app' | 'clx-iframe' | 'portal';
}

export interface PharmacyPriceRequest {
  address1: string;
  pharmacyType: PharmacyType;
  pharmacy: string;
  address2: string;
  postalCode: string;
  nabp: string;
}

export interface PricingV2Response {
  prices: DrugPriceV2[];
  papEligibility?: {isEligible: boolean; papPrice: string};
}

export interface CreateTpaRequest {
  partnerId: string;
  urlSlug: string;
  companyName: string;
  address: TpaAddress;
  contacts: TpaContacts;
}

export interface CreateTpaResponse {
  tpaId: string;
}

export interface TpaRequest {
  tpaId: string;
}

export interface GetTpaResponse {
  tpa: HttpTpa;
}

export interface GetTpaSubGroupsResponse {
  subGroups: HttpTpaSubGroup[];
}

export interface GenerateTpaSubGroupsRequest extends TpaRequest {
  newGroups: {groups: {groupId: string; groupName: string | null}[]; productCode: string}[];
}

export interface UpdateTpaRequest extends TpaRequest {
  urlSlug?: string;
  companyName?: string;
  address?: TpaAddress;
  contacts?: TpaContacts;
  partnerId?: string;
  autoMarketingEnabled?: boolean;
}

export interface CreateSubGroupRequest {
  tpaId: string;
  companyName: string;
  startingDate?: string;
  numberOfEmployees?: number;
  urlSlug: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  zip: string;
  isSubsidized: boolean;
  requiresEligibility: boolean;
  networks: HealthConfiguration[];
  ediTpaUploadId: string;
  isOpenGroup: boolean;
}

export interface CreateSubGroupResponse {
  cardId: string;
}

export interface GetTpaUsersResponse {
  tpaUsers: HttpTpaUser[];
}

export type HttpTpaUser = {
  id: string;
  tpaId: string;
  createdDate: string;
  updatedDate: string;
  email: string;
  accountSetUp: boolean;
  phoneNumber: string;
  firstName: string;
  lastName: string;
  accountInvited: boolean;
  accountInvitedDate?: string;
  accountActive: boolean;
};

export interface CreateTpaUserRequest extends TpaRequest {
  email: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
}

export interface GetTpaUserResponse {
  tpaUser: HttpTpaUser;
}

export interface UpdateTpaUserRequest extends GetTpaUserRequest {
  firstName: string;
  lastName: string;
  phoneNumber: string;
}

export interface GetTpaUserRequest extends TpaRequest {
  tpaUserId: string;
}

export interface GetTpaReportRequest extends PartialFullDateRangeRequest, PagedReportRequest {
  filters: {
    activePrimaries: number;
  };
}

export interface GetTpaReportResponse {
  tpas: {
    tpaId: string;
    numOfLives: number;
    companyName: string;
    createdDate: string;
    activePrimaries: number;
    downloads: number;
    stripeSetup: boolean;
  }[];
  count: number;
}

export interface GetTpaReportCsvRequest {
  filters: {
    activePrimaries: number;
  };
}

export interface GetTpaProductCodeResponse {
  productCode: HttpTpaProductCode;
  tpa: HttpTpaLight;
}

export interface UpdateTpaProductCodeRequest extends GetProductCodeRequest {
  networks: HealthConfiguration[];
  requiresEligibility: boolean;
  openOrClosedEligibility: 'open' | 'closed';
}

export interface CreateTpaProductCodeRequest {
  tpaId: string;
  networks: HealthConfiguration[];
  displayName: string;
  productCode: string;
  requiresEligibility: boolean;
  openOrClosedEligibility: 'open' | 'closed';
}

export interface ValidateProductCodeUniqueRequest extends TpaRequest {
  productCode: string;
}

export interface AppOpenRequest {
  firstTime: boolean;
  fromShare: string;
  fromLink: boolean;
}

export interface LogEventRequest {
  eventName: string;
  sessionId: string;
  userId?: string;
  meta?: string;
  isPharmacist?: boolean;
}

export interface LogVoucherEventRequest {
  eventName: AnalyticsType;
  sessionId: string;
  userId?: string;
  meta?: string;
  price?: DrugPriceV2;
  isPharmacist?: boolean;
}

export type AnalyticsType =
  | 'js-error'
  | 'bad-404'
  | 'native-error'
  | 'share-from-website'
  | 'share-from-website-health'
  | 'app-open'
  | 'app-first-open'
  | 'app-first-open-link'
  | 'app-first-open-share'
  | 'partner-slug-request'
  | 'search'
  | 'price'
  | 'price-no-filter'
  | 'price-no-filter-replace'
  | 'web-calculator'
  | 'listeners'
  | 'voucher'
  | 'x2ai-open'
  | 'x2ai-close'
  | 'loc-open'
  | 'loc-close'
  | 'share-savings'
  | 'view-cabinet-voucher'
  | 'view-voucher'
  | 'view-cabinet-more-prices'
  | 'open-survey-push'
  | 'pap-initial'
  | 'pap-submit'
  | 'pap-step'
  | 'pap-submission'
  | 'dialcare'
  | 'msk'
  | 'promo-code';

export interface TelemetryStartRequest {
  sessionId: string;
  phoneDetails?: DBPhoneDetails;
}

export interface DBPhoneDetails {
  apiLevel?: number;
  brand?: string;
  model?: string;
  systemVersion?: string;
  systemName?: string;
  locale?: string;
  timezone?: string;
  lastAccess?: Date;
  error?: string;
  zipcode?: string;
  appVersion?: string;
}

export interface StartCardOrderPaymentRequest extends PartnerRequest {
  tokenId: string;
}

export interface StartCardOrderPaymentResponse {
  cardId: string;
  last4: string;
  exp_month: string;
  exp_year: string;
}

export interface ConfirmCardOrderPaymentRequest extends PartnerRequest {
  paymentId: string;
  cardId: string;
  email: string;
  companyName: string;
  name: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  zipCode: string;
  language: 'english' | 'spanish';
  cardType: 'generic' | 'co-branded' | 'logo';
  quantity: number;
  shippingMethod: 'standard' | 'expedited' | 'rush';
  qrCodeRequested: boolean;
  requestedDeliveryDate: string;
  logoDataUri?: string;
  logoFileType?: string;
  qrCodeDataUri?: string;
  cobrandedName?: string;
}

export interface ConfirmCardOrderPaymentResponse {
  receipt_url: string;
}

export interface GetAllPartnerCardOrdersResponse {
  cardOrders: HttpPartnerCardTransactionSearch[];
}

export interface GetPartnerPendingCardOrderRequest extends PartnerRequest {
  cardOrderId: string;
}

export interface GetPartnerPendingCardOrderResponse {
  cardOrder: HttpPartnerCardTransaction;
}

export interface UpdatePartnerPendingCardOrderLogoRequest extends GetPartnerPendingCardOrderRequest {
  logoDataUri: string;
  logoFileType: string;
}

export interface PriceRequest {
  ndc: string;
  zipcode?: string;
  quantity: string;
  forceBranded: boolean;
  pharmacy?: PharmacyPriceRequest;
  watchedDrugId?: string;
}

export interface PricingResponse {
  watchedDrugId: string;
  pickedDrug: HttpPickedDrug;
  user: HttpUser;
  prices: DrugPrice[];
}

export interface HttpPickedDrug {
  zipcode: string;
  isGeneric: boolean;
  drugName: string;
  ndc: string;
  form: string;
  strengthItem: string;
  quantity: string;
  displayQuantity: string;
  forceBranded: boolean;
}

export interface HttpUser {
  id: string;
  watchedDrugs: HttpWatchedDrug[];
  createdDate: Date;
  brokerDetails: BrokerDetails;
  preferredPharmacyType?: PharmacyType;
  preferredPharmacy?: Pharmacy;
  pushToken: {platform: string; token: string};
}

export interface HttpWatchedDrug {
  watchedDrugId: string;
  prices: DrugPrice[];
  pickedDrug: HttpPickedDrug;
  savedCoupon?: DrugPrice;
  dateSaved: Date;
  lastRefreshed: Date;
}

export interface DrugPrice {
  pickedDrug: HttpPickedDrug;
  pharmacy: string;
  pharmacyType: PharmacyType;
  nabp?: string;
  street1: string;
  street2: string;
  city: string;
  state: string;
  zipCode: string;
  ndc: string;
  price: number;

  distance: number;

  latitude: number;
  longitude: number;

  closest: boolean;
  cheapest: boolean;
  preferredPharmacy: boolean;
  preferredPharmacyType: boolean;

  coupon: DrugCoupon;
}

export interface BrokerDetails {
  id: string;
  member_id: string;
  company_name: string;
  url_slug: string;
  share_url_slug?: string;
  group_number: string;
  isDefault: boolean;
  uniqueMemberRegistration: boolean;
  externalMemberId?: string;
  externalMemberIdSuffix?: string;
  requireDateOfBirth?: boolean;
  externalProvider: 'direct-care' | 'test';
}

export interface Pharmacy {
  address1: string;
  address2: string;
  city: string;
  latitude: number;
  longitude: number;
  postalCode: string;
  state: string;
  distance: number;
  nabp: string;
  name: string;
  pharmacyType: PharmacyType;
}

export interface GetDrugDetailsRequest {
  ndc: string;
}

export interface GetDrugDetailsResponse {
  information: SinglecareMonoGraphData;
  faqs: SinglecareFAQ[];
  imageUrl: string;
}

export interface SinglecareMonoGraphData {
  BrandNames: string[];
  ConsumerForms: string[];
  ConsumerRoutes: string[];
  Contraindications: string[];
  DrugFoodAvoidings: string[];
  HowToUses: string[];
  Indications: string[];
  LessSeriousSideEffects: string[];
  MissedDoses: any[];
  NDCs: string[];
  Pronunciations: string[];
  SeriousSideEffects: string[];
  StorageDisposals: string[];
  TherapeuticClasses: string[];
  WarningCautions: string[];
}

export interface SinglecareFAQ {
  IsActive: boolean;
  IsRequired: boolean;
  Question: SinglecareQuestion;
  QuestionId: number;
  QuestionSetId: number;
  QuestionSetQuestionId: number;
  SortOrder: number;
}

export interface SinglecareQuestion {
  Answers: SinglecareAnswer[];
  Description: string;
  IsActive: boolean;
  QuestionId: number;
  Text: string;
}

export interface SinglecareAnswer {
  AnswerId: number;
  Description: string;
  IsActive: boolean;
  QuestionId: number;
  SortOrder: number;
  Text: string;
}

export interface GetDrugStructureRequest {
  name: string;
}

export interface GetDrugStructureResponse {
  drugResult: HttpDrugResult | null;
}

export interface HttpDrugResult {
  brand: HttpDrug;
  generic: HttpDrug;
}

export interface HttpDrug {
  name: string;
  doseForms: HttpDoseForm[];
}

export interface HttpDoseForm {
  form: string;
  dispensables: HttpDispensable[];
}

export interface HttpDispensable {
  strengthItem: string;
  dispensedQuantities: HttpDispensedQuantity[];
  ndcs: HttpNDC[];
}

export interface HttpDispensedQuantity {
  quantity: string;
  displayQuantity: string;
}

export interface HttpNDC {
  ndc: string;
  name: string;
  fullName: string;
  isGeneric: boolean;
}

export interface SearchRequest {
  query: string;
}

export interface SearchResponse {
  results: string[];
}

export interface LegacySearchResponse {
  results: {
    drug: {
      genericName: string;
      brandName: string;
      doseForms: {
        doseFormId: string;
        form: string;
        rank: number;
        dispensables: {
          dispensableId: string;
          strength: string;
          strengthUnitOfMeasure: string;
          strengthRank: number;
          quantities: {
            quantity: string;
          }[];
          ndcs: {
            ndc: string;
            drugName: string;
            rank: number;
          }[];
        }[];
      }[];
    };
    doseFormId: string;
    dispensableId: string;
    ndc: string;
  }[];
}

export interface GetDrugStructureV2Request {
  seoName: string;
}

export interface HttpDrugResultV2 {
  brands: HttpDrugV2[];
  generics: HttpDrugV2[];
}

export interface HttpDrugV2 {
  name: string;
  doseForms: HttpDoseFormV2[];
}

export interface HttpDoseFormV2 {
  form: string;
  dispensables: HttpDispensableV2[];
}

export interface HttpDispensableV2 {
  strengthItem: string;
  dispensedQuantities: HttpDispensedQuantityV2[];
}

export interface HttpDispensedQuantityV2 {
  quantity: number;
  ndc: HttpNDCV2;
}

export interface HttpNDCV2 {
  displayQuantity: number;
  dosage: string;
  form: string;
  fullName: string;
  gpi: string;
  inactive: boolean;
  isGeneric: boolean;
  marketingForm: string;
  marketingName: string;
  marketingSEOName: string;
  ndc: string;
  name: string;
  numScripts: number;
  packageQuantity: number;
  packageSize: number;
  packageSizeUOM: string;
  priceUsualAndCustomary: number;
  quantity: number;
  repack: boolean;
  rxNorm: string;
  seoName: string;
  strength: number;
  strengthString: string;
  strengthUnitOfMeasure: string;
}

export interface GetDrugInformationV2Request {
  ndc: string;
}

export interface DrugInformationV2 {
  Drug: DrugInformationV2Drug;
  FAQs: any[];
  ImageUrl: string;
  MonoGraphData: DrugInformationV2MonoGraphData;
  RelatedDrugs: DrugInformationV2RelatedDrug[];
}

export interface DrugInformationV2Drug {
  CanonicalURL: string;
  DeaClassCode: string;
  Description: string;
  DisplayQuantity: number;
  Dosage: string;
  Form: string;
  FullName: string;
  GPI: string;
  Inactive: boolean;
  IsGeneric: boolean;
  MarketingForm: string;
  MarketingName: string;
  MarketingSEOName: string;
  MetaDescription: string;
  MetaTitle: string;
  NDC: string;
  Name: string;
  NumScripts: number;
  PackageQuantity: number;
  PackageSize: number;
  PackageSizeUOM: string;
  PriceUsualAndCustomary: number;
  Quantity: number;
  Repack: boolean;
  RxNorm: string;
  SEOName: string;
  SEONoIndex: boolean;
  Strength: number;
  StrengthString: string;
  StrengthUnitOfMeasure: string;
  TopPrescriptionSortOrder: number;
  Treatment: string;
  UnitDoseUnitUsePkgCode: string;
}

export interface DrugInformationV2MonoGraphData {
  BrandNames: string[];
  ConsumerForms: string[];
  ConsumerRoutes: string[];
  Contraindications: string[];
  DrugFoodAvoidings: string[];
  HowToUses: string[];
  Indications: string[];
  LessSeriousSideEffects: string[];
  MissedDoses: any[];
  NDCs: string[];
  Pronunciations: string[];
  SeriousSideEffects: string[];
  StorageDisposals: string[];
  TherapeuticClasses: string[];
  WarningCautions: string[];
}

export interface DrugInformationV2RelatedDrug {
  DrugName: string;
  SEOName: string;
}

export interface SearchResponseV2 {
  results: SinglecareDrugSearchResult[];
}

export interface SinglecareDrugSearchResult {
  score: number;
  display_name: string;
  id: string;
  seo_name: string;
  ndc: string;
}

export interface GetVoucherRequest {
  price: DrugPriceV2;
}

export interface GetVoucherResponse {
  price: DrugPriceV2;
}

export interface GetVoucherSurveyResponse {
  willUse: -1 | 0 | 1;
  goodPrice: -1 | 0 | 1;
  wasUsed: -1 | 0 | 1;
  notUsedReason: string;
  fullySubmitted: boolean;
}

export interface SubmitVoucherSurveyRequest {
  willUse: -1 | 0 | 1;
  goodPrice: -1 | 0 | 1;
  wasUsed: -1 | 0 | 1;
  notUsedReason?: string;
  platform: 'clx-app' | 'clh-app';
  price: DrugPriceV2;
}

export interface SubmitPapRequest {
  platform: AnalyticsPlatform;
  ndc: string;
  formData: RxValetPapFormData;
  url?: string;
}

export type RxValetPapFormData = {
  annualIndividualIncome: string;
  firstName: string;
  lastName: string;
  agreeToTerms: boolean;
  bestTimeToCall: string;
  contactNumber: string;
  email: string;
  householdMembers: string;
  physicianCity: string;
  physicianName: string;
  totalHouseholdIncome: string;
  physicianPhone: string;
  physicianState: string;
};

export interface SubmitPapRequestV2 {
  platform: AnalyticsPlatform;
  ndc: string;
  formData: RxValetPapFormDataV2;
  url?: string;
}

export interface RxValetPapFormDataV2 extends RxValetPapFormData {
  memberAddress1: string;
  memberAddress2: string;
  memberCity: string;
  memberState: string;
  memberZip: string;
  dateOfBirth: string; // MM-DD-YYYY
}

export interface AuthenticateRequest {
  apiKey: string;
}

export interface AuthenticateResponse {
  accessToken: string;
  expirationDate: string;
}

export interface SearchDrugsRequest {
  drugName: string;
}

export interface SearchDrugsResponse {
  results: string[];
}

export interface DrugStructureRequest {
  drugName: string;
}

export interface DrugStructureResponse {
  generic: DrugDetail;
  brand: DrugDetail;
}

export interface DrugDetail {
  name: string;
  doseForms: DrugDoseForm[];
}

export interface DrugDoseForm {
  form: string;
  dispensables: DrugDispensable[];
}

export interface DrugDispensable {
  strengthItem: string;
  dispensedQuantities: DrugDispensedQuantity[];
  ndcs: DrugNDC[];
}

export interface DrugDispensedQuantity {
  quantity: string;
}

export interface DrugNDC {
  ndc: string;
  name: string;
  fullName: string;
  isGeneric: boolean;
}

export interface DrugInfoRequest {
  ndc: string;
}

export interface DrugInfoResponse {
  information: DrugInformationV2;
}

export interface PriceDrugRequest {
  ndc: string;
  zipcode?: string;
  quantity: number;
  forceBranded: boolean;
}

export interface PriceDrugResponse {
  pickedDrug: HttpPickedDrug;
  prices: ExternalDrugPrice[];
}

export interface ExternalDrugPrice {
  pickedDrug: HttpPickedDrug;
  pharmacy: string;
  pharmacyType: PharmacyType;
  nabp?: string;
  street1: string;
  street2: string;
  city: string;
  state: string;
  zipCode: string;
  ndc: string;
  price: number;

  distance: number;

  latitude: number;
  longitude: number;

  closest: boolean;
  cheapest: boolean;
  coupon: ExternalDrugCoupon;
}

export interface ExternalDrugCoupon {
  groupNumber: string;
  memberId: string;
  rxBin: string;
  pcn: string;

  pharmacistPhoneNumber: string;
  memberPhoneNumber: string;
}

export interface SearchDrugsV2Request {
  drugName: string;
}

export interface SearchDrugsV2Response {
  results: SinglecareDrugSearchResult[];
}

export interface DrugStructureV2Response {
  brands: HttpDrugV2[];
  generics: HttpDrugV2[];
}

export interface PriceDrugV2Request {
  ndc: string;
  zipcode?: string;
  quantity: number;
  pharmacy?: PharmacyPriceRequest;
  forceBranded: boolean;
}

export interface PriceDrugV2Response {
  pickedDrug: HttpPickedDrug;
  prices: DrugPriceV2[];
}

export interface GeofenceGetPharmaciesRequest {
  lat: string;
  lng: string;
  maxDistance?: string;
}

export interface GeofenceGetPharmaciesResponse {
  pharmacies: {
    lat: number;
    lng: number;
    nabp: string;
    pharmacyName: string;
  }[];
}

export interface CmsGetContentRequest extends PartnerRequest {
  clientId: string;
  languageVersion: 'en' | 'es';
}

export interface CmsGetContentResponse {
  client: HttpClient;
  pages: HttpClientContentPage[];
  otherPages: HttpClientContentOtherPage[];
  content: HttpClientContent[];
  lastPublished?: string;
  languageVersion: 'en' | 'es';
}

export type HttpClientContentOtherPage = {
  id: string;
  title: string;
  description: string;
  iconId: ClientContentIcon;
  client: HttpClient;
  content: HttpClientContent[];
};

export interface CmsUpdateContentRequest extends CmsGetContentRequest {
  pages: {
    id: string;
    title: string;
    description: string;
    iconId: ClientContentIcon;
    order: number;
    content: HttpClientContent[];
  }[];
}

export interface AccountLoginRequest {
  email: string;
  password: string;
}

export interface AccountLoginResponse extends AccountResponse {
  jwt: string;
  user: HttpAccountUser;
}

export interface AccountResponse {
  user: HttpAccountUser;
}

export interface HttpAccountUser {
  id: string;
  email: string;
  primaryPartnerId?: string;
  phoneNumber?: string;
  accountSetUp: boolean;
  accountOwner?: string;

  address1?: string;
  address2?: string;
  city?: string;
  state?: string;
  zip?: string;

  contactType: AccountContactTypes;
  hubspotId?: number;

  allowPromotionalCommunication: boolean;
  createdDate: Date;
  updatedDate: Date;
  notes: HttpNote[];

  cards: HttpCard[];
  partners: HttpPartner[];
}

export interface GetPartnerSimpleResponse {
  partner: HttpPartnerExtendedSimple;
}

export interface HttpPartnerExtendedSimple extends HttpPartner {
  account: HttpAccount;
  groupTree: HttpPartnerGroupTreeItemSimple;
}

export interface HttpPartnerGroupTreeItemSimple {
  startingGroupCode: string;
  startingMemberId: string;
  partnerId: string;
  partnerName: string;
  commissionAmount: number;
  partnerType: string;
  children: HttpPartnerGroupTreeItemSimple[];
  cards: (
    | HttpCard
    | {
        owned: false;
        companyName?: string;
        groupCode: string;
        memberId: string;
        urlSlug?: string;
      }
  )[];
  parent?: HttpPartnerGroupTreeItemSimple;
}

export interface SetStripeBankAccountRequest {
  partnerId: string;
  stripeBankAccountToken: string;
}

export interface RequestW9AccountRequest {
  partnerId: string;
}

export interface RequestW9AccountResponse {}

export interface AccountCommissionResultResponse {
  commissionResult: HttpCommissionResult;
  partnerOwnedCards: {groupCode: string; memberId: string}[];
  allCards: {
    id: string;
    partnerName: string;
    partnerId: string;
    groupCode: string;
    memberId: string;
    companyName?: string;
  }[];
  partnerResults: {
    partnerName: string;
    groupCode: string;
    memberId: string;
    numberOfClaims: number;
    totalAmountDue: number;
    totalAmountSaved: number;
    averagePercentSaved: number;
  }[];
  lastCommissionResult: HttpCommissionResult;
}

export interface HttpCommissionResult {
  dateRan: Date;
  monthRan: string; // '2019-08'
  groupedClaims: {
    groupCode: string;
    memberId: string;
    totalAmountDue: number;
    totalAmountSaved: number;
    averagePercentSaved: number;
    compensable: number;
  }[];
  byDateClaims: {
    date: string;
    totalAmountDue: number;
    totalAmountSaved: number;
    averagePercentSaved: number;
    compensable: number;
  }[];
  numberOfClaims: number;
  totalAmountDue: number;
  totalAmountSaved: number;
  averagePercentSaved: number;
  final: boolean;
}

export interface SlugsByDayRequest {
  partnerId: string;
}

export interface AccountForgotPasswordRequest {
  email: string;
}

export interface PartnerContactBrokerRequest extends PartnerRequest {
  reason: 'new-contract' | 'stripe-account';
}

export interface PartnerLedgerDebitReportRequest extends PartnerRequest {
  debitLedgerId: string;
}

export interface PayoutViableResponse {
  viable: {
    contract: {[partnerId: string]: boolean};
    contractSigned: {[partnerId: string]: boolean};
    stripeCreated: boolean;
    stripeSetup: boolean;
    stripeBankAccount: boolean;
    w9Created: boolean;
    w9Signed: boolean;
    payoutThreshold: boolean;
    payoutThresholdAmount: number;
    payout: number;
    canPayout: boolean;
  };
}

export interface AccountResetPasswordRequest {
  email: string;
  code: string;
  newPassword: string;
}

export interface PartnerUpdateAccountsRequest {
  accountId: string;
  email: string;
  phoneNumber: string;
  accountOwner: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  zip: string;
}

export interface AccountCreateCardRequest extends PartnerRequest {
  companyName?: string;
  vanityUrl?: string;
}

export interface PartnerRequestsResponse {
  requests: HttpPartnerRequest[];
}

export interface HttpPartnerRequest {
  id: string;
  createdDate: Date;
  initiatingPartnerId: string;
  parentPartnerId: string;
  masterPartnerId: string;
  request: HttpPartnerRequestBody;
  approved: boolean;
  adjudicatedDate: Date;
  adjudicatedBy: string;
}

export interface DownloadMaterialRequest extends PartnerRequest {
  title: string;
}

export interface WebBrokerDetailsRequest {
  groupCode: string;
  memberId: string;
}

export interface WebBrokerDetailsResponse {
  brokerDetails: {
    name: string;
    groupCode: string;
    memberId: string;
    urlSlug: string;
  };
}

export interface WebBrokerContactFormRequest {
  groupCode: string;
  memberId: string;
  companyName: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
}

export interface GetPartnerCardsResponse {
  cards: HttpCardLight[];
}

export interface GetPartnerAndCardRequest {
  partnerId: string;
  cardId: string;
}

export interface GetHealthConfigResponse {
  networks: HealthConfiguration[];
}

export interface GetPartnerReferralsResponse {
  referrals: {
    commissionReferrals: HttpPartnerCommissionReferral[];
    id: string;
    partnerName: string;
  }[];
}

export interface GetPartnerHealthClientsResponse {
  clients: (HttpClient & {
    urlSlug?: string;
    numberOfEmployees?: number;
    startingDate?: string;
    isSubsidized?: boolean;
  })[];
}

export interface GetPartnerHealthClientRequest {
  clientId: string;
}

export interface GetPartnerHealthClientResponse {
  client: HttpClient & {
    groupCode: string;
    memberId: string;
    urlSlug: string;
    numberOfEmployees?: number;
    startingDate?: string;
    isSubsidized?: boolean;
    contacts?: ClientContacts;
    contract?: DbClientContract;
    voluntaryContract?: DbClientVoluntaryContract;
  };
}

export interface UpdatePartnerHealthClientRequest {
  clientId: string;
  companyName: string;
  urlSlug: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  zipCode: string;
  numberOfEmployees: number;
  startingDate: string;
  isSubsidized: boolean;
}

export interface GetPartnerHealthClientUsersRequest extends PagedReportRequest {
  filter: string;
  clientId: string;
  partnerId: string;
}

export interface GetPartnerHealthClientUsersResponse {
  clientUsers: HttpClientUserSearch[];
  count: number;
}

export interface CreatePartnerHealthClientUserRequest {
  email: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  cardId: string;
}

export interface GetPartnerHealthClientUserResponse {
  clientUser: HttpClientUser;
}

export interface ActivatePartnerHealthClientUserRequest {
  cardId: string;
  clientUserId: string;
}

export interface SubmitHealthGroupRequestRequest {
  partnerId: string;
  companyName: string;
  startingDate: string;
  numberOfEmployees: number;
  urlSlug: string;
  address1?: string;
  address2?: string;
  city?: string;
  state?: string;
  zip?: string;
  isSubsidized: boolean;
  requiresEligibility: boolean;
  notes: string;
  contacts?: ClientContacts;
  autoSignupContacts?: boolean;
}

export interface SubmitHealthGroupRequestResponse {
  requestId: string;
}

export interface GetPartnerHealthClientRequestsResponse {
  requests: HttpClientRequestSearch[];
}

export interface GetPartnerHealthClientRequestRequest extends PartnerRequest {
  requestId: string;
}

export interface GetPartnerHealthClientRequestResponse {
  request: HttpClientRequest;
}

export interface RequestPartnerContractResponse {
  status: 'signed' | 'not-signed';
  embedUrl?: string;
}

export interface UpdatePartnerHealthClientContactsRequest extends PartnerRequest {
  clientId: string;
  contacts: ClientContacts;
}

export interface GetTpaProductCodesResponse {
  productCodes: HttpTpaProductCode[];
}

export interface GetPartnerTpasResponse {
  tpas: {
    id: string;
    companyName: string;
    address: TpaAddress;
    numberOfSubGroups: number;
    activePrimaries: number;
  }[];
}

export interface GetPartnerTpaRequest {
  tpaId: string;
}

export interface GetPartnerTpaResponse {
  tpa: HttpTpa;
}

export interface GetPartnerHealthReportsRequest extends PartnerRequest, PartialFullDateRangeRequest {
  allTimeData?: boolean;
  cardId: string;
  productCode: string;
}

export interface GetPartnerHealthReportsResponse {
  usersCreated: {
    primaries: number;
    spouses: number;
    dependents: number;
    pets: number;
  };
  usersByAge: {[key: string]: {primary: number; spouse: number; dependent: number}};
  visitsCompleted: number;
  visitsAsync: number;
  visitsPhone: number;
  visitsVideo: number;
  bellaUtilization: number;
  locUtilization: number;
  prescriptionSavings: number;
  prescriptionCount: number;
}

export interface GetPartnerHealthReportsTopReasonsResponse {
  reasons: {reasonCode: string; value: number}[];
}

export interface GetPartnerHealthReportsTopDrugsResponse {
  topDrugs: {drugName: string; amountSaved: number}[];
}

export interface GetPartnerHealthReportsChartDataResponse {
  data: {label: string; value: number}[];
}

export interface GetPartnerHealthReportsTopStatesResponse {
  states: {name: string; value: number}[];
}

export interface GetHealthPartnerDownloadsByCardRequest extends PagedReportRequest {
  partnerId: string;
}

export interface GetHealthPartnerDownloadsByCardResponse {
  usersByCard: {count: number; card: HttpCardLight}[];
}

export interface GetPartnerTasksResponse {
  tasks: PartnerTask[];
  unclaimedRewards: number[];
}

export interface CompletePartnerTaskRequest extends PartnerRequest {
  type: PartnerTaskType;
}

export interface CompletePartnerTaskRankRequest extends PartnerRequest {
  rank: number;
  address1: string;
  address2: string;
  city: string;
  state: string;
  zipCode: string;
  name: string;
  accepted: boolean;
}

export interface CheckPartnerContractsSignedResponse {
  chContractIsSigned: boolean;
  w9ContractIsSigned: boolean;
  tasks?: PartnerTask[];
}

export interface GetPharmacyRequest {
  zipcode?: string;
}

export interface GetPharmacyResponse {
  pharmacies: Pharmacy[];
}

export interface PortalAnalyticLogEventRequest {
  userType: PortalAccountType;
  eventType: PortalAnalyticType;
  page?: string;
}

export type PortalAccountType = 'multi-portal' | 'partner' | 'admin' | 'client' | 'tpa';

export type PortalAnalyticType = 'login' | 'page-visit';

export interface PortalLoginRequest {
  email: string;
  password: string;
}

export interface PortalLoginResponse {
  accountType: PortalAccountType;
  jwt: string;
  user: HttpAccountUser | HttpAdminUser | HttpClientUser | HttpTpaUser | HttpMultiPortalAccount;
  clientDetails?: ClientUserCompanyDetails;
  tpaDetails?: HttpTpaLight;
}

export type HttpMultiPortalAccount = {
  id: string;
  email: string;
  phoneNumber?: string;
  firstName: string;
  lastName: string;
  accountSetUp: boolean;
  accounts: {
    accountId: string;
    accountType: 'partner' | 'admin' | 'client' | 'tpa';
    companyOrPartnerName: string;
  }[];
};

export interface ClientUserCompanyDetails {
  consultationCosts: {
    asyncCost: number;
    phoneCost: number;
    videoCost: number;
    chatCost: number;
  };
  billingAddress: {
    address1?: string;
    address2?: string;
    city?: string;
    state?: string;
    zip?: string;
  };
  groupCode: string;
  memberId: string;
  urlSlug: string;
  companyName: string;
  networks: HealthConfiguration[];
  pepm?: {
    isEnabled: boolean;
    rateInCents: number;
  };
  isSubGroup: boolean;
  tpaId?: string;
  membersPayByProductCode?: boolean;
}

export interface ImpersonateClientRequest {
  clientType: 'client' | 'tpa';
}

export interface ImpersonateClientResponse {
  details:
    | {
        clientType: 'client';
        user: HttpClientUser;
        clientDetails: ClientUserCompanyDetails;
      }
    | {
        clientType: 'tpa';
        user: HttpTpaUser;
        tpaDetails: HttpTpaLight;
      };
}

export interface PortalForgotPasswordRequest {
  email: string;
}

export interface PricingIframeLogEventRequest {
  meta?: string;
  meta2?: string;
  groupCode?: string;
  memberId?: string;
  type: PricingIframeAnalyticsType;
}

export type PricingIframeAnalyticsType = 'search' | 'price' | 'load-voucher' | 'text-voucher' | 'print-voucher';

export interface MessagePriceRequest {
  pharmacy: string;
  price: string;
  bin: string;
  group: string;
  pcn: string;
  memberId: string;
  phoneNumber?: string;
  questionsNumber: string;
  pharmacistPhoneNumber: string;
  drugName: string;
  provider?: 'rxedo' | 'singlecare' | 'scriptsave';
}

export interface EmailPriceRequest {
  pharmacy: string;
  price: string;
  bin: string;
  group: string;
  pcn: string;
  memberId: string;
  email: string;
  questionsNumber: string;
  pharmacistPhoneNumber: string;
  drugName: string;
  provider?: 'rxedo' | 'singlecare' | 'scriptsave';
}

export interface MessageAppRequest {
  urlSlug: string;
  phoneNumber: string;
  health?: boolean;
}

export interface SubmitContactDetailsRequest {
  name: string;
  phone: string;
  companyName: string;
  email: string;
}

export interface MessageAppCustomizedRequest {
  senderName: string;
  recipientName: string;
  recipientPhoneNumber: string;
  urlSlug: string;
  messageOption: '0' | '1' | '2' | '3';
}

export interface ShareCHFlyerRequest {
  flyers: {
    employer: boolean;
    voluntary: boolean;
    overview: boolean;
  };
  sender: {name: string; email: string};
  recipients: {name: string; email: string}[];
  qrCodeBase64?: string;
  companyNameBase64?: string;
}

export interface RedirectToClhJoinRequest {
  urlSlug: string;
}

export interface GetTpaSubGroupsRequest {
  includeNetworks?: 'true' | 'false';
  includeActivePrimaries?: 'true' | 'false';
}

export interface GetHealthClientImportReportsRequest {
  page: string;
  count: string;
}

export interface GetHealthClientImportReportsResponse {
  count: number;
  reports: HttpClientImportReportSimple[];
}

export interface HttpClientImportReportSimple {
  id: string;
  createdDate: string;
  batchId: string;
  fileName: string;
  details: {membersAdded: number; membersUpdated: number; membersRemoved: number};
  errorsLength: number;
}

export interface GetHealthClientImportReportRequest {
  reportId: string;
}

export interface GetHealthClientImportReportResponse {
  report: HttpClientImportReportExpanded;
}

export interface HttpClientImportReportExpanded {
  id: string;
  createdDate: string;
  batchId: string;
  fileName: string;
  details: {membersAdded: number; membersUpdated: number; membersRemoved: number};
  errors: {row: {[key: string]: string}; error: string}[];
}

export interface GetTpaMembersRequest {
  count: string;
  page: string;
  searchQuery: string;
  firstName: string;
  lastName: string;
  zipCode: string;
  phoneNumber: string;
  dateOfBirth: string;
  active: 'active' | 'inactive' | '';
  externalMemberId: string;
  cardId: string;
}

export interface GetTpaMembersResponse {
  members: HttpTpaMemberSearch[];
  count: number;
}

export interface HttpTpaMemberSearch extends HttpTpaMemberLight {
  registeredDate?: string;
}

export interface HttpTpaMemberLight extends HttpHealthMemberSearch {
  cardId: string;
}

export interface HttpHealthMemberSearch {
  id: string;
  firstName?: string;
  lastName: string;
  dob: FullDate;
  zipCode: string;
  phoneNumber?: string;
  employeeType: 'primary' | 'spouse' | 'dependent';
  activeDate: FullDate;
  deactivatedDate: FullDate | null;
  uniqueId: string;
}

export interface GetHealthClientMemberRequest {
  id: string;
}

export interface GetHealthClientMemberAndDependentsResponse {
  member: HttpHealthMember;
  dependents: HttpHealthMember[];
}

export interface HttpHealthMember {
  id: string;
  firstName: string;
  lastName: string;
  dateOfBirth: FullDate;
  zipcode: string;
  employeeType: EmployeeType;
  createdDate: Date;
  email?: string;
  state?: string;
  phoneNumber?: string;
  activeDate: FullDate;
  deactivatedDate: FullDate | null;
  externalMemberId: string;
  manualUpdate?: {
    date: Date;
    firstName: string;
    lastName: string;
    dateOfBirth: FullDate;
    zipcode: string;
  };
  uniqueId: string;
  cardId: string;
  city?: string;
  address1?: string;
  address2?: string;
  gender?: string;
}

export interface GetSFTPCredentialsResponse {
  username?: string;
}

export interface CreateSFTPCredentialsRequest {
  regeneratePassword: boolean;
}

export interface CreateSFTPCredentialsResponse {
  username: string;
  password?: string;
}

export interface GetTpaConfigColumnsResponse {
  emailsOnFile: string[];
  columns?: {
    uniqueId: {fields: string[]};
    externalMemberId: {name: string};
    dateOfBirth: {name: string; format: string};
    lastName: {name: string};
    firstName?: {name: string; optional: boolean};
    emailAddress?: {name: string; optional: boolean};
    state?: {name: string; format: 'code' | 'name'; optional: boolean};
    phoneNumber?: {name: string; optional: boolean};
    zipcode: {name: string};
    activateDate: {name: string; format: string};
    deactivateDate: {name: string; format: string; optional: boolean};
    employeeType?: {name: string; format: string; optional: boolean};
    cleverGroupId: {name: string};
    productCode?: {name: string; optional: boolean};
    groupName?: {name: string; optional: boolean};
    address1?: {name: string; optional: boolean};
    address2?: {name: string; optional: boolean};
    city?: {name: string; optional: boolean};
    gender?: {name: string; format: 'M/F/O' | 'male/female/other'; optional: boolean};
  };
  autoMarketingEnabled: boolean;
}

export interface UpdateTpaConfigColumnsRequest {
  emailsOnFile: string[];
  newColumns: {
    uniqueId: {fields: string[]};
    externalMemberId: {name: string};
    dateOfBirth: {name: string; format: string};
    lastName: {name: string};
    firstName?: {name: string; optional: boolean};
    emailAddress?: {name: string; optional: boolean};
    state?: {name: string; format: 'code' | 'name'; optional: boolean};
    phoneNumber?: {name: string; optional: boolean};
    zipcode: {name: string};
    activateDate: {name: string; format: string};
    deactivateDate: {name: string; format: string; optional: boolean};
    employeeType?: {name: string; format: string; optional: boolean};
    cleverGroupId: {name: string};
    productCode?: {name: string; optional: boolean};
    groupName?: {name: string; optional: boolean};
    address1?: {name: string; optional: boolean};
    address2?: {name: string; optional: boolean};
    city?: {name: string; optional: boolean};
    gender?: {name: string; format: 'M/F/O' | 'male/female/other'; optional: boolean};
  };
}

export interface ManualUploadEligibilityRequest {
  csv: string;
  emailsOnFile?: string[];
}

export interface GetClientInvoicesResponse {
  invoices: HttpClientInvoiceSimple[];
  stripeSetup: boolean;
}

export interface HttpClientInvoiceSimple {
  invoiceId: string;
  stripeInvoiceId?: string;
  amountDue: number;
  dueDate?: FullDate;
  number: string;
  periodStart: FullDate;
  periodEnd: FullDate;
  type: 'standard' | 'pepm';
  status: 'open' | 'due' | 'paid' | 'closed';
}

export interface GetClientInvoiceRequest {
  stripeInvoiceId: string;
}

export interface GetClientInvoiceResponse {
  invoice:
    | {
        type: 'standard';
        invoice: HttpClientInvoiceExtended;
      }
    | {
        type: 'pepm';
        invoice: HttpPepmInvoiceExtended;
      };
}

export interface HealthClientSendCorrespondenceRequest {
  replyEmail: string;
  subject: string;
  message: string;
}

export interface UpdateTpaUserDetailsRequest {
  firstName: string;
  lastName: string;
  phoneNumber: string;
}

export interface UpdateTpaUserDetailsResponse {
  tpaUser: HttpTpaUser;
}

export interface UpdateTpaBillingDetailsRequest {
  address: TpaAddress;
}

export interface UpdateTpaBillingDetailsResponse {
  tpaDetails: HttpTpaLight;
}

export interface GetClientPaymentMethodsResponse {
  cards: ClientCardPaymentMethod[];
  bankAccounts: ClientBankAccountPaymentMethod[];
  isSetup: boolean;
  defaultPaymentMethodId?: string;
  autoPayEnabled: boolean;
}

export interface ClientCardPaymentMethod {
  id: string;
  brand: string;
  country: string | null;
  description?: string | null;
  exp_month: number;
  exp_year: number;
  last4: string;
}

export interface ClientBankAccountPaymentMethod {
  id: string;
  bankName: string;
  last4: string;
  status: 'new' | 'validated' | 'verified' | 'verification_failed' | 'errored';
}

export interface VerifyACHPaymentMethodRequest {
  bankAccountId: string;
  amounts: [number, number];
}

export interface VerifyACHPaymentMethodResponse {
  status: string;
}

export interface DeleteCardPaymentMethodRequest {
  cardId: string;
}

export interface DeleteBankAccountPaymentMethodRequest {
  bankAccountId: string;
}

export interface CreateHealthClientStripeCustomerResponse {
  customerId: string;
}

export interface SetupClientPaymentMethodRequest {
  tokenId: string;
}

export interface PayClientInvoiceRequest {
  stripeInvoiceId: string;
  paymentMethodId: string;
  paymentMethodType: 'card' | 'ach';
}

export interface PayClientInvoiceResponse {
  invoiceId: string;
}

export interface UpdateClientDefaultPaymentMethodRequest {
  paymentMethodId: string;
}

export interface UpdateClientDefaultPaymentMethodResponse {
  defaultPaymentMethodId: string;
}

export interface UpdateClientBillingAutoPayRequest {
  enabled: boolean;
}

export interface CreateTpaMemberRequest extends CreateHealthClientMemberRequest {
  cardId: string;
}

export interface CreateHealthClientMemberRequest {
  firstName: string;
  lastName: string;
  dateOfBirth: FullDate;
  zipcode: string;
  employeeType: EmployeeType;
  email?: string;
  state?: string;
  phoneNumber?: string;
  activeDate: FullDate;
  deactivatedDate: FullDate | null;
  externalMemberId: string;
  isPrimary: boolean;
  primaryEdiId?: string;
  city: string;
  address1: string;
  address2: string;
  gender: 'M' | 'F';
}

export interface CreateTpaFamilyRequest extends CreateHealthClientFamilyRequest {
  cardId: string;
}

export interface CreateHealthClientFamilyRequest {
  primary: {
    firstName: string;
    lastName: string;
    dateOfBirth: FullDate;
    zipcode: string;
    employeeType: EmployeeType;
    email?: string;
    state?: string;
    phoneNumber?: string;
    activeDate: FullDate;
    externalMemberId: string;
    deactivatedDate?: FullDate;
    city: string;
    address1: string;
    address2: string;
    gender: 'M' | 'F';
  };
  spouse?: {
    firstName: string;
    lastName: string;
    dateOfBirth: FullDate;
    zipcode: string;
    employeeType: EmployeeType;
    email?: string;
    state?: string;
    phoneNumber?: string;
    activeDate: FullDate;
    deactivatedDate?: FullDate;
    city: string;
    address1: string;
    address2: string;
    gender: 'M' | 'F';
  };
  dependents: {
    firstName: string;
    lastName: string;
    dateOfBirth: FullDate;
    zipcode: string;
    employeeType: EmployeeType;
    email?: string;
    state?: string;
    phoneNumber?: string;
    activeDate: FullDate;
    deactivatedDate?: FullDate;
    city: string;
    address1: string;
    address2: string;
    gender: 'M' | 'F';
  }[];
}

export interface UpdateTpaMemberRequest {
  ediId: string;
  firstName?: string;
  lastName?: string;
  dateOfBirth?: FullDate;
  zipcode?: string;
  activeDate?: FullDate;
  deactivatedDate?: FullDate | null;
  email?: string;
}

export interface GetTpaGroupDetailsResponse {
  cardId: string;
  urlSlug: string;
  clientId: string;
  groupCode: string;
  memberId: string;
  activePrimaries: number;
  totalMembers: number;
  networks: HealthConfiguration[];
  users: number;
  address: TpaAddress;
  ediTpaUploadId: string;
  createdDate: string;
  companyName: string;
}

export interface GetTpaMembersCSVRequest {
  columns: {
    uniqueId: boolean;
    externalMemberId: boolean;
    dateOfBirth: boolean;
    lastName: boolean;
    firstName: boolean;
    zipcode: boolean;
    employeeType: boolean;
    emailAddress: boolean;
    state: boolean;
    phoneNumber: boolean;
    activateDate: boolean;
    deactivateDate: boolean;
    cleverGroupId: boolean;
    productCode: boolean;
    city: boolean;
    address1: boolean;
    address2: boolean;
    gender: boolean;
  };
  filter: string;
  firstName: string;
  lastName: string;
  zipCode: string;
  phoneNumber: string;
  dateOfBirth: string;
  active: 'active' | 'inactive' | '';
  externalMemberId: string;
  cardIdFilter: string;
}

export interface GetTpaReportsRequest extends PartialFullDateRangeRequest {
  allTimeData?: boolean;
  cardId: string;
  productCode: string;
}

export interface GetTpaReportsResponse {
  usersCreated: {
    primaries: number;
    spouses: number;
    dependents: number;
    pets: number;
  };
  usersByAge: {[key: string]: {primary: number; spouse: number; dependent: number}};
  visitsCompleted: number;
  visitsAsync: number;
  visitsPhone: number;
  visitsVideo: number;
  bellaUtilization: number;
  locUtilization: number;
  prescriptionSavings: number;
  prescriptionCount: number;
}

export interface GetTpaReportsTopReasonsResponse {
  reasons: {reasonCode: string; value: number}[];
}

export interface GetTpaReportsTopDrugsResponse {
  topDrugs: {drugName: string; amountSaved: number}[];
}

export interface GetTpaReportsChartDataResponse {
  data: {label: string; value: number}[];
}

export interface GetTpaReportsTopStatesResponse {
  states: {name: string; value: number}[];
}

export interface ValidateTranslationKeyRequest {
  key: string;
}

export interface ValidateTranslationKeyResponse {
  isValid: boolean;
}

export interface GetTranslationVersionsResponse {
  versions: {
    id: string;
    createdDate: string;
    updatedDate: string;
    language: 'en' | 'es';
    translations: {key: string; content: string}[];
  }[];
}

export interface UpdateTranslationVersionRequest extends ValidateTranslationKeyRequest {
  translations: {key: string; content: string}[];
  language: 'es';
}

export interface GetBrokerDetailsRequest {
  groupCode: string;
  memberId: string;
  dateOfBirth?: string;
}

export interface GetBrokerDetailsResponse {
  brokerDetails: BrokerDetails;
}

export interface GetBrokerDetailsSlugRequest {
  slug: string;
}

export interface RegisterRequest {
  phoneNumber: string;
  isLogin: boolean;
  fromShare?: boolean;
  brokerDetails: BrokerDetails;
  phoneDetails: DBPhoneDetails;
}

export interface LightJwtResponse {
  jwt: string;
}

export interface ValidateRequest {
  activationCode: string;
}

export interface JwtGetUserResponse extends UserResponse {
  jwt: string;
}

export interface UserResponse {
  user: HttpUser;
}

export interface SetPushTokenRequest {
  token: string;
  platform: string;
}

export interface SetPharmacyPreferencesRequest {
  preferredPharmacyType?: PharmacyType;
  preferredPharmacy?: Pharmacy;
}

export interface GetUserRequest {}

export interface WatchDrugRequest {
  watchedDrugId: string | null;
  prices: DrugPrice[];
  pickedDrug: HttpPickedDrug;
  saveCoupon: DrugPrice;
}

export interface WatchDrugResponse extends UserResponse {
  watchedDrugId: string;
}

export interface RadarGeoRequest {
  events: GeoEvent[];
  user: GeoUser;
}

export interface GeoEvent {
  _id: string;
  createdAt: string;
  live: boolean;
  type: string;
  user: GeoUserSmall;
  geofence?: Geofence;
  location: GeoLocation;
  locationAccuracy: number;
  confidence: number;
  place?: GeoPlace;
}

export interface GeoUserSmall {
  _id: string;
  userId: string;
  description: string;
  metadata: GeoMetadata;
  deviceId?: string;
}

export interface GeoMetadata {
  session: string;
}

export interface Geofence {
  _id: string;
  tag: string;
  externalId: string;
  description: string;
}

export interface GeoLocation {
  type: string;
  coordinates: number[];
}

export interface GeoPlace {
  _id: string;
  name: string;
  chain: Chain;
  location: GeoLocation;
  categories: string[];
}

export interface Chain {
  name: string;
  slug: string;
  domain: string;
}

export interface GeoUser {
  _id: string;
  live: boolean;
  userId: string;
  deviceId: string;
  description: string;
  metadata: GeoMetadata;
  updatedAt: string;
  createdAt: string;
  location: GeoLocation;
  locationAccuracy: number;
  stopped: boolean;
  foreground: boolean;
  deviceType: string;
  ip: string;
  geofences: Geofence[];
  place: GeoPlace;
  insights: GeoInsights;
}

export interface GeoInsights {
  state: GeoState;
}

export interface GeoState {
  home: boolean;
  office: boolean;
  traveling: boolean;
}

export interface UnwatchDrugRequest {
  watchedDrugId: string;
}

export interface UnwatchDrugResponse extends UserResponse {}

export interface StartMailOrderServiceRequest {
  personDetails: TpsPersonDetails;
  billingAddress: TpsBillingAddress;
  shippingAddress: TpsShippingAddress;
  pharmacy: TpsPharmacy;
  medicalDetails: TpsMedicalDetails;
  ccDetails: TpsCCDetails;
  drug: HttpPickedDrug;
}

export type TpsPersonDetails = {
  firstName: string;
  middleName: string;
  lastName: string;
  emailAddress: string;
  dateOfBirth: string;
  gender: string;
};

export type TpsBillingAddress = {
  streetAddress: string;
  otherAddress: string;
  city: string;
  stateCode: string;
  zipCode: string;
  homePhone: string;
};

export type TpsShippingAddress = {
  streetAddress: string;
  otherAddress: string;
  city: string;
  stateCode: string;
  zipCode: string;
};

export type TpsPharmacy = {
  pharmacyName: string;
  pharmacyPhone: string;
};

export type TpsMedicalDetails = {
  allergies: string[];
  medications: string[];
  conditions: string[];
};

export type TpsCCDetails = {
  card_number: string;
  card_cvc: string;
  exp_month: string;
  exp_year: string;
};

export interface StartMailOrderServiceResponse {
  result: StartMailOrderServiceResult;
}

export type StartMailOrderServiceResult =
  | {
      success: boolean;
    }
  | {errors: string[]};

export interface GetBrokerDetailsV2Request {
  groupCode: string;
  memberId: string;
  dateOfBirth?: string;
  noCode: boolean;
}

export interface GetBrokerDetailsV2Response {
  brokerDetails: BrokerDetailsV2;
}

export interface BrokerDetailsV2 {
  id: string;
  memberId: string;
  groupCode: string;
  companyName: string;
  urlSlug: string;
  shareUrlSlug: string;
  partnerId: string;
  cardId: string;
  isDefault: boolean;
  externalMemberIdSuffix?: string;
  externalMemberId?: string;
  uniqueMemberRegistration: boolean;
  externalProvider: string;
  requireDateOfBirth: boolean;
  pharmacist: boolean;
  isParentPharmacist: boolean;
  parentPharmacist?: {
    groupCode: string;
    memberId: string;
    urlSlug?: string;
  };
}

export interface GetBrokerDetailsSlugV2Request {
  slug: string;
}

export interface JwtGetUserV2Response extends UserV2Response {
  jwt: string;
}

export interface UserV2Response {
  user: HttpUserV2;
}

export interface HttpUserV2 {
  id: string;
  createdDate: string;
  phoneNumber: string;
  brokerDetails: BrokerDetailsV2;
  pushToken: {platform: string; token: string};
  pharmacistDetails?: HttpUserV2PharmacyDetails;
  prescryptiveDetails?: {carrierPCN: string; memberId: string; rxBin: string; rxGroup: string};
  shareCode?: string;
  zendeskUserDetails?: {name: string; email: string};
}

export type HttpUserV2PharmacyDetails = {
  pharmacy?: PharmacyDetails;
  emailAddress?: string;
  zipcode?: string;
  name?: string;
};

export interface RegisterV2Request {
  phoneNumber: string;
  isLogin: boolean;
  fromShare?: boolean;
  fromShareCode?: string;
  brokerDetails: BrokerDetailsV2;
  phoneDetails: DBPhoneDetails;
  pharmacistName?: string;
}

export interface MigrateUserRequest {
  jwt: string;
}

export interface GetCabinetRequest {
  page: number;
}

export interface GetCabinetResponse {
  drugs: HttpUserCabinetDrug[];
  total: number;
}

export interface HttpUserCabinetDrug {
  userCabinetDrugId: string;
  price: DrugPriceV2;
  pickedDrug: HttpPickedDrug;
  dateSaved: string;
  lastRefreshed: string;
}

export interface SaveToCabinetRequest {
  pickedDrug: HttpPickedDrug;
  price: DrugPriceV2;
  isRecurring?: boolean;
}

export interface DeleteFromCabinetRequest {
  userCabinetDrugId: string;
}

export interface GetShareCountResponse {
  count: number;
}

export interface SetPrescyptiveDetailsRequest {
  carrierPCN: string;
  memberId: string;
  rxBin: string;
  rxGroup: string;
}

export interface GetFaqsResponse {
  faqs: {
    key: string;
    title: ClxFaqMarkdown[];
    body: ClxFaqMarkdown[];
  }[];
}

export type ClxFaqMarkdown = {
  type: 'n' | 'p' | 'pp' | 'b' | 'bb' | 'i' | 'u';
  text: string;
};

export interface SetZendeskUserDetailsRequest {
  name: string;
  email: string;
}

export interface SubmitPapFormRequest {
  ndc: string;
  formData: RxValetPapFormData;
}

export interface SetPharmacistDetailsRequest {
  pharmacy?: PharmacyDetails;
  emailAddress?: string;
  zipcode?: string;
}

export interface PharmacistGetRewardsResponse {
  amountPerStar: number;
  minPayout: number;
  pending: {numberOfClaims: number; totalAmountDue: number; totalAmountSaved: number; averagePercentSaved: number};
  active: {numberOfClaims: number; totalAmountDue: number; totalAmountSaved: number; averagePercentSaved: number};
}

export interface GetStaticCardsResponse {
  cards: StaticCard[];
}

export interface StaticCard {
  rxBin: string;
  pcn: string;
  pharmacyType: PharmacyType;
  provider: 'rxedo' | 'singlecare' | 'scriptsave';
  pharmacyName: string;
}

export interface WebVoucherLogEventRequest {
  sessionId: string;
  price?: string;
  pharmacyName?: string;
  zipcode?: string;
  ndc?: string;
  drugName?: string;
  groupCode?: string;
  memberId?: string;
  type: WebLogAnalyticsType;
  email?: string;
  phone?: string;
  quantity?: string;
}

export type WebLogAnalyticsType = 'initial' | 'price' | 'load-voucher' | 'text-voucher' | 'email-voucher';

export interface GetWebVoucherSurveyRequest {
  webUserId?: string;
  price: DrugPriceV2;
}

export interface GetWebVoucherSurveyResponse {
  willUse: -1 | 0 | 1;
  goodPrice: -1 | 0 | 1;
  wasUsed: -1 | 0 | 1;
  notUsedReason: string;
  fullySubmitted: boolean;
}

export interface SubmitWebVoucherSurveyRequest {
  willUse: -1 | 0 | 1;
  goodPrice: -1 | 0 | 1;
  wasUsed: -1 | 0 | 1;
  notUsedReason?: string;
  platform: 'clx-web';
  price: DrugPriceV2;
  webUserId: string;
}

export interface GetZipcodeRequest {
  lat: string;
  lng: string;
}

export interface GetZipcodeResponse {
  zipcode: string;
}

export interface CreatePartnerContactRequest {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  notes: string;
  referredBy: string;
  partnerType: string;
}

export interface GetLatLngRequest {
  zipcode: string;
}

export interface GetLatLngResponse {
  lat: string;
  lng: string;
}

export interface GetKrogerBrandRequest {
  zipcode: string;
}

export interface GetKrogerBrandResponse {
  krogerPharmacyType: KrogerPharmacyType | null;
}

export interface MetaResponse {
  pushModels: PushModels;
}

export type PushModels =
  | {
      type: 'pharmacy-push';
      pharmacy: Pharmacy;
    }
  | {
      type: 'text';
    }
  | {
      type: 'visit';
      visitId: string;
      dependentId: string;
    }
  | {
      type: 'openLink';
      url: string;
    };

export interface PartnerSlugRequest {
  partnerId: string;
  isShare: string;
}

export interface WebContactFormRequest {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  referral: string;
  contactTypes: string[];
  message: string;
}

export interface WebCalculatorRequest {
  userId: string;
  step: string;
  value: string;
}

export interface DocusignWebhookPostRequest {
  partnerId: string;
  type: 'W9' | 'Contract' | 'CHContract' | 'ClientAgreement' | 'VoluntaryClientAgreement';
}

export interface HealthAppOpenRequest {
  firstTime: boolean;
  fromShare: boolean;
  fromLink: boolean;
}

export interface HealthGetZipUrlResponse {
  url: string;
}

export interface GetHealthClientMembersRequest extends PagedReportRequest {
  filter: string;
  active: 'active' | 'inactive' | '';
}

export interface GetHealthClientMembersResponse {
  members: HttpHealthMemberSearch[];
  count: number;
}

export interface GetHealthClientMembersAdvancedSearchRequest {
  count: string;
  page: string;
  firstName: string;
  lastName: string;
  zipCode: string;
  phoneNumber: string;
  dateOfBirth: string;
  active: 'active' | 'inactive' | '';
  externalMemberId: string;
}

export interface GetHealthClientMembersCSVRequest {
  columns: {
    uniqueId: boolean;
    externalMemberId: boolean;
    dateOfBirth: boolean;
    lastName: boolean;
    firstName: boolean;
    zipcode: boolean;
    employeeType: boolean;
    emailAddress: boolean;
    state: boolean;
    phoneNumber: boolean;
    activateDate: boolean;
    deactivateDate: boolean;
    productCode: boolean;
    city: boolean;
    address1: boolean;
    address2: boolean;
    gender: boolean;
  };
  filter: string;
  firstName: string;
  lastName: string;
  zipCode: string;
  phoneNumber: string;
  dateOfBirth: string;
  active: 'active' | 'inactive' | '';
  externalMemberId: string;
}

export interface GetHealthClientMemberResponse {
  member: HttpHealthMember;
}

export interface UpdateHealthClientMemberRequest {
  ediId: string;
  firstName?: string;
  lastName?: string;
  dateOfBirth?: FullDate;
  zipcode?: string;
  employeeType?: EmployeeType;
  email?: string;
  state?: string;
  phoneNumber?: string;
  activeDate?: FullDate;
  deactivatedDate?: FullDate | null;
}

export interface DeleteHealthClientMemberRequest {
  id: string;
}

export interface GetHealthClientReportsRequest extends PartialFullDateRangeRequest {
  allTimeData?: boolean;
  memberFilter: 'primary' | 'all';
}

export interface GetHealthClientReportsResponse {
  usersCreated: {
    primaries: number;
    spouses: number;
    dependents: number;
    pets: number;
  };
  visitsCompleted: number;
  visitsAsync: number;
  visitsPhone: number;
  visitsVideo: number;
  bellaUtilization: number;
  locUtilization: number;
  prescriptionSavings: number;
  prescriptionCount: number;
}

export interface GetHealthClientReportsTopReasonsResponse {
  reasons: {reasonCode: string; value: number}[];
}

export interface GetHealthClientReportsTopDrugsResponse {
  topDrugs: {drugName: string; amountSaved: number}[];
}

export interface GetHealthClientReportsAppDownloadsResponse extends GetHealthClientReportsChartDataResponse {
  byState: {name: string; value: number}[];
}

export interface GetHealthClientReportsChartDataResponse {
  data: {label: string; value: number}[];
}

export interface GetHealthClientReportsTopStatesResponse {
  topStates: {name: string; value: number}[];
  allStates: {name: string; value: number}[];
}

export interface UpdateClientUserDetailsRequest {
  firstName: string;
  clientUserId: string;
  lastName: string;
  phoneNumber: string;
}

export interface UpdateClientUserDetailsResponse {
  clientUser: HttpClientUser;
}

export interface UpdateClientBillingDetailsRequest {
  address1: string;
  address2?: string;
  city: string;
  state: string;
  zipCode: string;
}

export interface UpdateClientBillingDetailsResponse {
  client: HttpClient;
}

export interface CreateHealthClientStripeCustomerRequest {
  clientUserId: string;
}

export interface GetClientPartnerConfigColumnsResponse {
  emailsOnFile: string[];
  columns?: {
    uniqueId: {fields: string[]};
    externalMemberId: {name: string};
    dateOfBirth: {name: string; format: string};
    lastName: {name: string};
    firstName?: {name: string; optional: boolean};
    emailAddress?: {name: string; optional: boolean};
    state?: {name: string; format: 'code' | 'name'; optional: boolean};
    phoneNumber?: {name: string; optional: boolean};
    zipcode: {name: string};
    activateDate: {name: string; format: string};
    deactivateDate: {name: string; format: string; optional: boolean};
    employeeType?: {name: string; format: string; optional: boolean};
    productCode?: {name: string; optional: boolean};
    address1?: {name: string; optional: boolean};
    address2?: {name: string; optional: boolean};
    city?: {name: string; optional: boolean};
    gender?: {name: string; format: 'M/F/O' | 'male/female/other'; optional: boolean};
  };
  autoMarketingEnabled: boolean;
}

export interface UpdateClientPartnerConfigColumnsRequest {
  emailsOnFile: string[];
  clientPartnerConfigColumns: {
    uniqueId: {fields: string[]};
    externalMemberId: {name: string};
    dateOfBirth: {name: string; format: string};
    lastName: {name: string};
    firstName?: {name: string; optional: boolean};
    emailAddress?: {name: string; optional: boolean};
    state?: {name: string; format: 'code' | 'name'; optional: boolean};
    phoneNumber?: {name: string; optional: boolean};
    zipcode: {name: string};
    activateDate: {name: string; format: string};
    deactivateDate: {name: string; format: string; optional: boolean};
    employeeType?: {name: string; format: string; optional: boolean};
    productCode?: {name: string; optional: boolean};
    address1?: {name: string; optional: boolean};
    address2?: {name: string; optional: boolean};
    city?: {name: string; optional: boolean};
    gender?: {name: string; format: 'M/F/O' | 'male/female/other'; optional: boolean};
  };
}

export interface ToggleEligibilityMarketingRequest {
  autoMarketingEnabled: boolean;
}

export interface PriceNoFilterRequest {
  ndc: string;
  lat: string;
  lng: string;
  quantity: number;
}

export interface HealthIvrUpdateStatusRequest {
  a: string; // authorization
  s: 'incoming-call' | 'start-eligibility' | 'send-text' | 'paid' | 'forward-to-ucm' | 'hang-up-on-payment'; // status
  p: string; // phone number
  z: string; // zip code
  ediId?: string; // ediId
}

export interface HealthIvrTestEligibilityRequest {
  a: string; // authorization
  p: string; // phone number
  lastName?: string;
  lastNameDigits?: string;
  dob: string;
  zipcode: string;
}

export interface HealthIvrTestEligibilityResponse {
  eligible: boolean;
  ediId?: string;
}

export interface LOCSendPushNotificationRequest {
  title: string;
  memberId: string;
  description: string;
}

export interface LOCAnalyticsRequest {
  memberId: string;
  action: 'callstarted' | 'callfinished';
}

export interface DependentRequest {
  dependentId: string;
}

export interface HealthMskLoginResponse {
  html: string;
  injectedJavascript?: string;
}

export interface GetBrokerDetailsHealthRequest {
  groupCode: string;
  memberId: string;
  noCode: boolean;
  appId?: AppCompanyId;
}

export interface GetBrokerDetailsHealthResponse {
  brokerDetails: BrokerDetailsHealth;
}

export interface BrokerDetailsHealth {
  id: string;
  memberId: string;
  groupCode: string;
  companyName: string;
  urlSlug?: string;
  shareUrlSlug?: string;
  partnerId: string;
  cardId: string;
  isDefault: boolean;
  requiresEligibility: boolean;
  openOrClosedEligibility?: 'open' | 'closed';
  networks?: HealthConfiguration[];
  usesPromoCodes?: boolean;
}

export interface DetermineEligibilityRequest {
  groupCode?: string;
  memberId?: string;
  dateOfBirthStamp?: number;
  dateOfBirth?: string;
  lastName?: string;
  lastNameDigits?: string;
  zipCode: string;
  appId?: AppCompanyId;
}

export interface GetDetermineEligibilityResponse {
  brokerDetails: BrokerDetailsHealth;
  userDetails: HealthOnboardingUserDetails;
  networks: HealthConfiguration[];
}

export type HealthOnboardingUserDetails = {
  ediId: string;
  firstName?: string;
  lastName: string;
  email?: string;
  uniqueId: string;
  dateOfBirth: FullDate;
  zipcode: string;
  employeeType: EmployeeType;
  state?: string;
  phoneNumber?: string;
  openEligibilityRelationship?: EmployeeType;
  productCode?: string;
};

export interface CheckOpenEligibilityRelationshipRequest {
  relationship: 'primary' | 'spouse';
  ediId: string;
}

export interface CheckOpenEligibilityRelationshipResponse {
  relationshipExists: boolean;
}

export interface GetBrokerDetailsSlugHealthRequest {
  slug: string;
  appId?: AppCompanyId;
  origin?: AnalyticsPlatform;
}

export interface JwtGetUserHealthResponse extends UserHealthResponse {
  jwt: string;
}

export interface UserHealthResponse {
  user: HttpHealthUser;
  dependents: HttpUserDependent[];
}

export interface HttpHealthUser {
  id: string;
  createdDate: string;
  userDependentId: string;
  ediId: string;
  externalUniqueId: string;
  brokerDetails: BrokerDetailsHealth;
  pushToken: {platform: string; token: string};
  pushEnabled: boolean;
  preferredCommunication: 'text' | 'email';
  shareCode: string;
  enabledPushTypes: PushNotificationType[];
  hasSetupPinCode?: boolean;
  openEligibilityRelationship?: 'primary' | 'spouse' | 'dependent';
}

export type PushNotificationType = 'marketing' | 'survey' | 'visit' | 'rx';

export interface HttpUserDependent {
  id: string;
  relationship: 'primary' | 'spouse' | 'dependent' | 'pet';
  ediId?: string;
  uniqueId?: string;
  tm?: {
    termsSigned?: Date;
    medicareDisclaimerSigned?: Date;
  };
  x2ai?: {
    personId: string;
  };
  userDetails: HealthUserDetails;
}

export interface RegisterHealthRequest {
  phoneNumber: string;
  isLogin: boolean;
  fromShare: boolean;
  brokerDetails: BrokerDetailsHealth;
  phoneDetails: DBPhoneDetails;
  userDetails?: HealthOnboardingUserDetails;
  fromShareCode?: string;
  appId?: AppCompanyId;
  fromDeepLink?: boolean;
  promoCode?: string;
}

export interface HealthLightJwtResponse {
  jwt: string;
}

export interface HealthSetPushTokenRequest {
  token: string;
  platform: string;
}

export interface StartStripeCustomerResponse {
  customerId: string;
  ephemeralKey: string;
}

export interface StartSetupIntentResponse {
  clientSecret: string;
}

export interface StripePlaceHoldRequest {
  paymentMethodId: string;
  visitId: string;
  dependentId: string;
  modality: ZipnosisVisitModality['slug'];
}

export type ZipModality = 'async' | 'direct-to-phone' | 'direct-to-video' | 'direct-to-chat';

export type ZipnosisVisitModality = {
  slug: ZipModality;
  price: number;
};

export interface StripePlaceHoldResponse {
  paymentIntentId: string;
  clientSecret: string;
}

export interface SubsidizeVisitRequest {
  visitId: string;
  dependentId: string;
  modality: ZipnosisVisitModality['slug'];
}

export interface StripeListCardsResponse {
  cards: StripeCard[];
}

export type StripeCard = {
  card: PaymentMethodCard;
  id: string;
};

export interface PaymentMethodCard {
  /**
   * Card brand. Can be `amex`, `diners`, `discover`, `jcb`, `mastercard`, `unionpay`, `visa`, or `unknown`.
   */
  brand: string;

  /**
   * Two-letter ISO code representing the country of the card. You could use this attribute to get a sense of the international breakdown of cards you've collected.
   */
  country: string | null;

  /**
   * A high-level description of the type of cards issued in this range. (For internal use only and not typically available in standard API requests.)
   */
  description?: string | null;

  /**
   * Two-digit number representing the card's expiration month.
   */
  exp_month: number;

  /**
   * Four-digit number representing the card's expiration year.
   */
  exp_year: number;

  /**
   * Uniquely identifies this particular card number. You can use this attribute to check whether two customers who've signed up with you are using the same card number, for example. For payment methods that tokenize card information (Apple Pay, Google Pay), the tokenized number might be provided instead of the underlying card number.
   *
   * *Starting May 1, 2021, card fingerprint in India for Connect will change to allow two fingerprints for the same card --- one for India and one for the rest of the world.*
   */
  fingerprint?: string | null;

  /**
   * Card funding type. Can be `credit`, `debit`, `prepaid`, or `unknown`.
   */
  funding: string;

  /**
   * Issuer identification number of the card. (For internal use only and not typically available in standard API requests.)
   */
  iin?: string | null;

  /**
   * The name of the card's issuing bank. (For internal use only and not typically available in standard API requests.)
   */
  issuer?: string | null;

  /**
   * The last four digits of the card.
   */
  last4: string;
}

export interface SetVpcPaymentMethodRequest {
  paymentMethodId: string;
}

export interface StripeListChargesResponse {
  charges: StripeCharge[];
}

export interface StripeCharge {
  /**
   * Unique identifier for the object.
   */
  id: string;

  /**
   * String representing the object's type. Objects of the same type share the same value.
   */
  object: 'charge';

  /**
   * Amount intended to be collected by this payment. A positive integer representing how much to charge in the [smallest currency unit](https://stripe.com/docs/currencies#zero-decimal) (e.g., 100 cents to charge $1.00 or 100 to charge ¥100, a zero-decimal currency). The minimum amount is $0.50 US or [equivalent in charge currency](https://stripe.com/docs/currencies#minimum-and-maximum-charge-amounts). The amount value supports up to eight digits (e.g., a value of 99999999 for a USD charge of $999,999.99).
   */
  amount: number;

  /**
   * Amount in %s captured (can be less than the amount attribute on the charge if a partial capture was made).
   */
  amount_captured: number;

  /**
   * Amount in %s refunded (can be less than the amount attribute on the charge if a partial refund was issued).
   */
  amount_refunded: number;

  /**
   * The amount of the application fee (if any) requested for the charge. [See the Connect documentation](https://stripe.com/docs/connect/direct-charges#collecting-fees) for details.
   */
  application_fee_amount: number | null;

  /**
   * Authorization code on the charge.
   */
  authorization_code?: string;

  /**
   * The full statement descriptor that is passed to card networks, and that is displayed on your customers' credit card and bank statements. Allows you to see what the statement descriptor looks like after the static and dynamic portions are combined.
   */
  calculated_statement_descriptor: string | null;

  /**
   * If the charge was created without capturing, this Boolean represents whether it is still uncaptured or has since been captured.
   */
  captured: boolean;

  /**
   * Time at which the object was created. Measured in seconds since the Unix epoch.
   */
  created: number;

  /**
   * Three-letter [ISO currency code](https://www.iso.org/iso-4217-currency-codes.html), in lowercase. Must be a [supported currency](https://stripe.com/docs/currencies).
   */
  currency: string;

  /**
   * An arbitrary string attached to the object. Often useful for displaying to users.
   */
  description: string | null;

  /**
   * Whether the charge has been disputed.
   */
  disputed: boolean;

  /**
   * Error code explaining reason for charge failure if available (see [the errors section](https://stripe.com/docs/api#errors) for a list of codes).
   */
  failure_code: string | null;

  /**
   * Message to user further explaining reason for charge failure if available.
   */
  failure_message: string | null;

  /**
   * Has the value `true` if the object exists in live mode or the value `false` if the object exists in test mode.
   */
  livemode: boolean;

  /**
   * `true` if the charge succeeded, or was successfully authorized for later capture.
   */
  paid: boolean;

  /**
   * ID of the payment method used in this charge.
   */
  payment_method: string | null;

  /**
   * Details about the payment method at the time of the transaction.
   */
  payment_method_details: ChargePaymentMethodDetails | null;

  /**
   * This is the email address that the receipt for this charge was sent to.
   */
  receipt_email: string | null;

  /**
   * This is the transaction number that appears on email receipts sent for this charge. This attribute will be `null` until a receipt has been sent.
   */
  receipt_number: string | null;

  /**
   * This is the URL to view the receipt for this charge. The receipt is kept up-to-date to the latest state of the charge, including any refunds. If the charge is for an Invoice, the receipt will be stylized as an Invoice receipt.
   */
  receipt_url: string | null;

  /**
   * Whether the charge has been fully refunded. If the charge is only partially refunded, this attribute will still be false.
   */
  refunded: boolean;

  /**
   * For card charges, use `statement_descriptor_suffix` instead. Otherwise, you can use this value as the complete description of a charge on your customers' statements. Must contain at least one letter, maximum 22 characters.
   */
  statement_descriptor: string | null;

  /**
   * Provides information about the charge that customers see on their statements. Concatenated with the prefix (shortened descriptor) or statement descriptor that's set on the account to form the complete statement descriptor. Maximum 22 characters for the concatenated descriptor.
   */
  statement_descriptor_suffix: string | null;

  /**
   * The status of the payment is either `succeeded`, `pending`, or `failed`.
   */
  status: string;

  /**
   * A string that identifies this transaction as part of a group. See the [Connect documentation](https://stripe.com/docs/connect/charges-transfers#transfer-options) for details.
   */
  transfer_group: string | null;
}

export interface ChargePaymentMethodDetails {
  card?: PaymentMethodDetailsCard;
  /**
   * The type of transaction-specific details of the payment method used in the payment, one of `ach_credit_transfer`, `ach_debit`, `acss_debit`, `alipay`, `au_becs_debit`, `bancontact`, `card`, `card_present`, `eps`, `giropay`, `ideal`, `klarna`, `multibanco`, `p24`, `sepa_debit`, `sofort`, `stripe_account`, or `wechat`.
   * An additional hash is included on `payment_method_details` with a name matching this value.
   * It contains information specific to the payment method.
   */
  type: string;
}

export interface PaymentMethodDetailsCard {
  /**
   * Card brand. Can be `amex`, `diners`, `discover`, `jcb`, `mastercard`, `unionpay`, `visa`, or `unknown`.
   */
  brand: string | null;

  /**
   * Two-letter ISO code representing the country of the card. You could use this attribute to get a sense of the international breakdown of cards you've collected.
   */
  country: string | null;

  /**
   * A high-level description of the type of cards issued in this range. (For internal use only and not typically available in standard API requests.)
   */
  description?: string | null;

  /**
   * Two-digit number representing the card's expiration month.
   */
  exp_month: number;

  /**
   * Four-digit number representing the card's expiration year.
   */
  exp_year: number;

  /**
   * Uniquely identifies this particular card number. You can use this attribute to check whether two customers who've signed up with you are using the same card number, for example. For payment methods that tokenize card information (Apple Pay, Google Pay), the tokenized number might be provided instead of the underlying card number.
   *
   * *Starting May 1, 2021, card fingerprint in India for Connect will change to allow two fingerprints for the same card --- one for India and one for the rest of the world.*
   */
  fingerprint?: string | null;

  /**
   * Card funding type. Can be `credit`, `debit`, `prepaid`, or `unknown`.
   */
  funding: string | null;

  /**
   * Issuer identification number of the card. (For internal use only and not typically available in standard API requests.)
   */
  iin?: string | null;

  /**
   * The name of the card's issuing bank. (For internal use only and not typically available in standard API requests.)
   */
  issuer?: string | null;

  /**
   * The last four digits of the card.
   */
  last4: string | null;

  /**
   * True if this payment was marked as MOTO and out of scope for SCA.
   */
  moto?: boolean | null;

  /**
   * Identifies which network this charge was processed on. Can be `amex`, `cartes_bancaires`, `diners`, `discover`, `interac`, `jcb`, `mastercard`, `unionpay`, `visa`, or `unknown`.
   */
  network: string | null;
}

export interface HealthValidateRequest {
  activationCode: string;
}

export interface HealthValidatePinCodeRequest {
  pinCode: string;
}

export interface HealthValidatePinCodeResponse {
  isAuthenticated: boolean;
  currentAttempts: number;
  user: HttpHealthUser;
}

export interface HealthResetPinCodeRequest {
  oldPinCode?: string;
  newPinCode: string;
}

export interface HealthSetPharmacyPreferencesRequest {
  preferredPharmacyType?: PharmacyType;
  preferredPharmacy?: Pharmacy;
}

export interface HealthGetCabinetRequest {
  dependentId: string;
  page: number;
}

export interface HealthGetCabinetResponse {
  drugs: HttpUserCabinetDrug[];
  total: number;
}

export interface HealthSaveToCabinetRequest {
  dependentId: string;
  pickedDrug: HttpPickedDrug;
  price: DrugPriceV2;
  isRecurring?: boolean;
}

export interface HealthOnboardingSaveToCabinetRequest {
  drugs: {pickedDrug: HttpPickedDrug; price: DrugPriceV2}[];
}

export interface HealthGetCabinetAllDependentsResponse {
  drugs: (HttpUserCabinetDrug & {dependentId: string})[];
}

export interface HealthDeleteFromCabinetRequest {
  dependentId: string;
  userCabinetDrugId: string;
}

export interface GetDependentsRequest {}

export interface GetDependentsResponse {
  dependents: HttpUserDependent[];
}

export interface HealthAddDependentsRequest {
  relationship: 'primary' | 'spouse' | 'dependent' | 'pet';
  addedDuringVpc?: boolean;
}

export interface CreateDependentsResponse {
  dependent: HttpUserDependent;
}

export interface HealthUpdateUserDetails {
  dependents: HealthUserDependents[];
}

export interface HealthUserDependents {
  dependentId: string;
  details: HealthUserDetails;
}

export interface HealthRemoveDependentsRequest {
  dependentId: string;
}

export interface GetDefaultVoucherResponse {
  voucher: DrugCoupon;
}

export interface GetNetworksResponse {
  networks: HealthConfiguration[];
}

export interface GetVisitsResponse {
  visits: HttpVisit[];
}

export type HttpVisit = {
  type: 'zipnosis';
  visitId: string;
  dependentId: string;
  date: string;
  zipVisit: ZipnosisVisit;
  status: 'active' | 'waiting' | 'finished';
};

export interface GetActionPendingVisitsResponse {
  visits: HttpActionPendingVisit[];
}

export type HttpActionPendingVisit = {
  visitId: string;
  dependentId: string;
  latestPush?: {title: string; message: string};
};

export interface HealthSetUserDetailsRequest {
  dependentId: string;
  details: HealthUserDetails;
}

export interface HealthGetAvatarRequest {
  dependentId: string;
}

export interface HealthSetCommunicationRequest {
  preferredCommunication: 'text' | 'email';
  pushEnabled: boolean;
  pushNotificationTypes?: PushNotificationType[];
}

export interface HealthDeleteDependentRequest {
  dependentId: string;
}

export interface CheckIsStillEligibleResponse {
  isEligible: boolean;
}

export interface GetVpcEligibilityResponse {
  result:
    | {
        isEligible: true;
        lastName: string;
        zipCode: string;
        dateOfBirth: string; // MM/DD/YYYY
        ivrPhoneNumber: string;
        initialVisitMemberPayInCents: number;
        followUpVisitMemberPayInCents: number;
      }
    | {isEligible: false};
}

export interface GetHealthFaqsResponse {
  faqs: {
    key: string;
    title: ClhFaqMarkdown[];
    body: ClhFaqMarkdown[];
  }[];
}

export type ClhFaqMarkdown = {
  type: 'n' | 'p' | 'pp' | 'b' | 'bb' | 'i' | 'u';
  text: string;
};

export interface GetGroupContentResponse {
  pages: HttpClientContentPageNested[];
}

export type HttpClientContentPageNested = {
  id: string;
  title: string;
  description: string;
  iconId: ClientContentIcon;
  order: number;
  content: HttpClientContent[];
  dateCreated: Date;
};

export interface GetJWTEligibilityRequest {
  jwt: string;
  appId?: AppCompanyId;
}

export interface GetJWTEligibilityResponse {
  result:
    | {
        isEligible: true;
        result: GetDetermineEligibilityResponse;
      }
    | {isEligible: false};
}

export interface GetHealthUserStaticValuesResponse {
  dialcareIvrPhoneNumber: string;
  vpcIvrPhoneNumber: string;
  phaReasonCode: string;
  termsOfServiceUrl: string;
  privacyPolicyUrl: string;
  zipUrl: string;
}

export interface DialCareLoginRequest extends DependentRequest {
  network: 'dialcare-mental' | 'dialcare-vet';
}

export interface DialCareLoginResponse {
  url: string;
  isNoLongerEligible: boolean;
  isIvrOnly: boolean;
  injectedJavascript: string;
}

export interface SubmitDialcareSurveyRequest {
  rating: number;
  comment: string;
  wouldRecommend: boolean;
  dependentId: string;
  network?: 'dialcare-mental' | 'dialcare-vet';
}

export interface SubmitDialcareSurveyResponse {
  surveyId: string;
}

export interface SubmitDialcareSurveyCommentsRequest {
  surveyId: string;
  additionalComments: string;
  isTestimonial?: boolean;
  dependentId: string;
}

export interface LOCLoginRequest {
  dependentId: string;
}

export interface LOCLoginResponse {
  url: string;
  baseUrl: string;
}

export interface SavePromoCodeRequest {
  promoCodeValue: string;
}

export interface ListPromoCodesResponse {
  promoCodes: HttpCHPromoCode[];
}

export type HttpCHPromoCode = {
  id: string;
  promoCodeValue: string;
  type: CHPromoCodeType;
  displayName: string;
  status: CHPromoCodeStatus;
  chVisitId?: string;
};

export type CHPromoCodeType =
  | {
      type: 'free-visit';
    }
  | {
      type: 'discount';
      discountInCents: number;
    };

export type CHPromoCodeStatus = 'issued' | 'saved' | 'applied' | 'redeemed';

export interface ApplyPromoCodeRequest {
  promoCodeId: string;
  visitId: string;
  action: 'apply' | 'unapply';
}

export interface TMSignRequest {
  dependentId: string;
  medicareDisclaimerSigned: Date;
  termsSigned: Date;
}

export interface TMDependentResponse {
  dependent?: HttpUserDependent;
}

export interface GetTermsResponse {
  terms: string;
  privacy: string;
  medicalConsent: string;
}

export interface TMRequestPatientAddDependentRequest {
  patientId: number;
  request: AddDependentRequest;
}

export interface AddDependentRequest {
  family: {
    relationship: string;
    member_attributes: {
      first_name: string;
      last_name: string;
      birth_date: string;
      gender: string;
      sso_token: string;
    };
  };
}

export interface SearchItemsRequest {
  query: string;
}

export interface SearchItemsResponse {
  items: {id: number; name: string}[];
}

export interface GetMedicationsResponse {
  medications: ZipMedication[];
}

export type ZipMedication = {
  id: number;
  medication_id: number;
  medication_name: string;
  state: 'active' | 'inactive';
  external_medication_id?: string;
  external_code?: string;
};

export interface TMRequestPatientCreateMedicationRequest extends DependentRequest {
  medicationName: string;
  medicationId: number;
}

export interface GetAllergiesResponse {
  allergies: ZipAllergy[];
}

export type ZipAllergy = {
  id: number;
  allergy_id: number;
  allergy_name: string;
  state: 'active' | 'inactive';
  external_allergy_id?: string;
  external_code?: string;
};

export interface TMRequestPatientCreateAllergyRequest extends DependentRequest {
  allergyName: string;
  allergyId: number;
}

export interface TMGetPharmaciesRequest extends DependentRequest {
  pharmacyName: string;
  pharmacyType: PharmacyType;
  lat: string;
  lng: string;
}

export interface TMGetPharmaciesResponse {
  pharmacies: Pharmacy[];
}

export interface TMGetZipPharmaciesRequest extends DependentRequest {
  lat: string;
  lng: string;
  name?: string;
  page: number;
}

export interface TMGetZipPharmaciesResponse {
  pharmacies: ZipPharmacy[];
}

export interface ZipPharmacy {
  id: number;
  name: string;
  phone: string;
  fax: string;
  priority?: any;
  ncpdp_id: number;
  latitude: number;
  longitude: number;
  display_url: string;
  display_url_title: string;
  address: PharmacyAddress;
  created_at: string;
  updated_at: string;
  distance: number;
}

export interface PharmacyAddress {
  id: number;
  address1: string;
  address2?: any;
  city: string;
  country: string;
  phone?: any;
  state: string;
  zip: string;
  latitude?: any;
  longitude?: any;
}

export interface TMVisitStartVisitRequest {
  dependentId: string;
}

export interface TMVisitStartVisitResponse extends TMGetDisclaimerResponse {
  visitId: string;
  visit: ZipnosisVisit;
  disclaimer: {states: GetDisclaimerResponseState[]; medication_history_enabled: boolean};
}

export interface TMGetDisclaimerResponse {
  disclaimer: {states: GetDisclaimerResponseState[]; medication_history_enabled: boolean};
}

export type GetDisclaimerResponseState = {
  abbreviation: string;
  state: string;
  prompt_medicare: boolean;
  disclaimer_acknowledge_text: string;
  disclaimer: string;
  advance_beneficiary_notice?: string;
  medicare_prompt_questions: GetDisclaimerResponseMedicarePromptQuestion[];
  research_consent: GetDisclaimerResponseResearchConsent;
  patient_type: GetDisclaimerResponsePatientType;
};

export type GetDisclaimerResponseMedicarePromptQuestion = {
  text: string;
  value: boolean;
};

export type GetDisclaimerResponseResearchConsent = {
  text: string;
  required: boolean;
};

export type GetDisclaimerResponsePatientType = {
  required: boolean;
  text: string;
  options: {
    name: string;
    id: string;
    refer_out_if_chosen: boolean;
  }[];
};

export interface TMVisitGetVisitResponse {
  visit?: {
    visitId: string;
    zipVisit: ZipnosisVisit;
    zipTriageVideo?: ZipTriageVideo;
    chVisitData?: {preVisitSurveyResponse?: PreVisitSurveyResponse; modalitySlug?: ZipModality};
  };
}

export interface ZipTriageVideo {
  id: number;
  state: string;
  reason: string;
  visit_id: number;
  created_at: Date;
  updated_at: Date;
  notes?: any;
  platform: string;
  queue_order?: number;
  queued_at?: any;
  current_queue_size: number;
  patient_phone_number?: any;
  next_valid_notification_time?: any;
  connection_successful: boolean;
  notification_enabled: boolean;
  users: ZipTriageVideoUser[];
  patient_connection?: any;
  doctor_connection?: any;
  clinic_id: number;
  allow_video_guest_invite?: any;
  actions: ZipTriageVideoActions;
}

export interface ZipTriageVideoUser {
  actions: {};
  full_name: string;
  preparer_full_name: string;
  id: number;
  role: string;
  role_description: string;
}

export interface ZipTriageVideoActions {
  invite: boolean;
}

export type PreVisitSurveyResponse = 'er' | 'urgent' | 'primary' | 'specialist' | 'nothing' | '';

export interface TMVisitGetVisitRequest {
  dependentId: string;
  visitId: string;
}

export interface DependentVisitRequest {
  dependentId: string;
  visitId: string;
}

export interface TMVisitAcceptDisclaimerRequest extends DependentVisitRequest {
  disclaimer: {
    visit: {
      patient_type?: string;
      medicare_status?: boolean;
    };
    disclaimer_acknowledged: boolean;
    research_consent: boolean;
    state: string;
  };
}

export interface TMVisitGetScreenOutResponse {
  visitId: string;
  visit: ZipnosisVisit;
  screenOut: string;
  screenOutQuestion: string;
}

export interface TMVisitAcceptScreenOutRequest extends DependentVisitRequest {
  accept: 'yes' | 'no';
}

export interface TMVisitWithFollowupResponse extends TMVisitResponse {
  followUp?: GetFollowUpCareResponse;
}

export interface TMVisitResponse {
  visitId: string;
  visit: ZipnosisVisit;
  charge?: HttpCHCharge;
  zipTriageVideo?: ZipTriageVideo;
}

export type HttpCHCharge = {
  id: string;
  payment: CHChargePayment;
};

export type CHChargePayment = {price: number} & (
  | {
      type: 'stripe';
      paymentIntentId: string;
      receipt?: any /*stripe.PaymentIntent*/;
      refundReceipt?: any /*stripe.PaymentIntent*/;
    }
  | {
      type: 'promo-code';
      promoCodeId: ObjectId;
      paymentIntentId?: string;
      receipt?: any /*stripe.PaymentIntent*/;
      refundReceipt?: any /*stripe.PaymentIntent*/;
    }
  | {type: 'free'}
  | {type: 'subsidized'}
);

export type GetFollowUpCareResponse = {
  message: string;
  refer_outs: string;
  has_report: boolean;
  in_progress: boolean;
};

export interface TMGetReasonsResponse {
  reasons: GetReasonsResponse;
  otherReasonLabels: {
    directToPhoneReasonLabel?: string;
    directToVideoReasonLabel?: string;
    generalAsyncReasonLabel?: string;
  };
}

export type GetReasonsResponse = {
  reasons: Reason[];
  dont_see_option_settings: DontSeeOptionSettings;
};

export interface Reason {
  id: number;
  name: string;
  description?: string;
  icon?: string;
  conditions: Condition[];
}

export interface Condition {
  name: string;
  slug: string;
  cpid: number;
  label: string;
  description?: string;
  eligibility_settings: EligibilitySettings;
  eligible: boolean;
  ineligible_message: string;
}

export interface EligibilitySettings {
  patient_gender?: string;
  patient_max_age?: number;
  patient_min_age?: number;
  established_patients_only?: boolean;
}

export interface DontSeeOptionSettings {
  dont_see_option_header: string;
  dont_see_option_button_text: string;
  dont_see_option_to_top: boolean;
  dont_see_option_body: string;
}

export interface TMGetBehavioralHealthReasonsResponse {
  reasons: {
    name: string;
    slug: string;
    cpid: number;
    label: string;
    description?: string;
  }[];
}

export interface TMVisitSetReasonRequest extends DependentVisitRequest {
  reason: string;
}

export interface TMVisitSetReasonResponse extends TMVisitResponse {
  modalities: ZipnosisVisitModality[];
}

export interface TMVisitExplainRepeatVisitRequest extends DependentVisitRequest {
  reason: string;
}

export interface TMGetModalitiesResponse {
  modalities: ZipnosisVisitModality[];
}

export interface TMVisitSetModalityRequest extends DependentVisitRequest {
  modalitySlug: ZipModality;
}

export interface TMVisitSetModalityResponse extends TMVisitResponse {
  questions: QuestionOption[];
}

export interface QuestionOption {
  zid: number;
  value?: string;
  options: QuestionOption[];
  childs: any[];
  choice?: string;
  data_type?: string;
  layout?:
    | 'label'
    | 'checkbox'
    | 'date'
    | 'yes-no'
    | 'radio'
    | 'integer'
    | 'decimal'
    | 'single-line'
    | 'double-line'
    | 'radio-single'
    | 'image'
    | 'current-allergies'
    | 'current-medications';
  image?: string;
  label?: string;
}

export interface TMVisitDoctorStepUpRequest extends DependentVisitRequest {
  modalities: string[];
  phoneNumber: string;
}

export interface TMVisitLightResponse {
  visitId: string;
  visit: ZipVisitLight;
}

export type ZipVisitLight = {
  id: number;
  interview_progress?: number;
  state: ZipnosisVisitState;
  zip_triage?: ZipTriage;
};

export interface TMVisitAnswerQuestionRequest extends DependentVisitRequest {
  answer: ZipAnswer[];
}

export type ZipAnswer = {questionId: number | 'Medications' | 'Allergies'; answerId: string | string[] | number[]};

export interface TMVisitGetQuestionResponse extends TMVisitLightResponse {
  questions?: QuestionOption[];
  followUp?: GetFollowUpCareResponse;
  zipTriageVideo?: ZipTriageVideo;
}

export interface TMVisitAnswerHeightWeightRequest extends DependentVisitRequest {
  height?: number;
  weight?: number;
}

export interface TMVisitChatMessagesResponse extends TMVisitResponse {
  chatMessages: VisitChatResponse;
}

export type VisitChatResponse = {
  id: number;
  visit_id: number;
  state: string;
  messages: ZipMessage[];
  connections: any[];
  unread_message_count: number;
};

export interface ZipMessage {
  id: number;
  role: 'notification' | 'system' | 'patient' | 'doctor';
  image_id?: any;
  serial?: string;
  message: string;
  timestamp: string;
  sender_name: string;
  sender_id?: number;
}

export interface EnterVideoWaitingRoomRequest extends DependentVisitRequest {
  phoneNumber: string;
}

export interface TMVisitVideoResponse extends TMVisitResponse {
  videoToken: {session: string; token: string};
}

export interface SendPrescriptionRequest extends DependentVisitRequest {
  voucher: DrugCoupon;
  ncpdpId: number;
  prices?: any;
  pharmacy?: string;
}

export interface TMVisitStartChatResponse extends TMVisitResponse {
  connection: VisitChatConnection;
  chatMessages: VisitChatResponse;
}

export type VisitChatConnection = {
  id: number;
  user_id: number;
  role: 'notification' | 'system' | 'patient' | 'doctor';
  connected_at: string;
  heartbeat_at: null;
  chat_id: number;
  user_name_with_title: string;
};

export interface ApproveTriageRequest extends DependentVisitRequest {
  phoneNumber?: string;
}

export interface GetClinicRequest extends DependentVisitRequest {
  clinicId: string;
}

export interface GetClinicResponse {
  clinic: ZipClinic;
}

export interface ZipClinic {
  id: number;
  before_payment: string;
  before_payment_sync: string;
  before_verification: string;
  business_line_description: string;
  business_line_name: string;
  chat_enabled: boolean;
  clinic_access: string;
  clinic_group_id: number;
  end_of_zip: string;
  exit_interview_header?: any;
  exit_interview_button_text?: any;
  exit_interview_body?: any;
  hours_this_week: ZipClinicHoursThisWeek[];
  name: string;
  next_open_time: string;
  phone_enabled: boolean;
  start_visit_button_text: string;
  video_enabled: boolean;
  medication_history_enabled: boolean;
  scheduled_visits_only: boolean;
  closed: boolean;
}

export interface ZipClinicHoursThisWeek {
  start: string;
  end: string;
  custom_text?: string;
  closed: boolean;
}

export interface TMVisitReportResponse extends TMVisitResponse {
  report: VisitReportResponse;
}

export type VisitReportResponse = {
  id: number;
  zip_triage_friendly_type: string;
  zip_triage_notes?: any;
  assessment_text: string;
  clinician_footer: string;
  created_at: string;
  icd_code: string;
  updated_at: string;
  patient_info_basic_text: string;
  doctor_summary_basic_text: string;
  medications: Medication[];
  allergies: Medication[];
  unable_to_diagnose: boolean;
  visit_zip_tickets: any[];
  order_transactions: OrderTransaction[];
  school_work_note?: SchoolWorkNote;
  allow_patient_comments?: any;
  async_summary: string;
  doctor_summary: string;
  patient_info?: any;
  able_to_diagnose_text: string;
  unable_to_diagnose_text?: any;
  additional_notes: string;
  addenda: any[];
  prescriptions: string[];
  addenda_and_comments?: ReportAddendum[];
};

export interface Medication {
  id: number;
  description: string;
}

export interface OrderTransaction {
  base_price: number;
  created_at: string;
  promo_code?: any;
  promo_code_amount?: any;
  amount: number;
  refund_amount?: any;
  insurance_billed_amount?: any;
  insurance_billed_at?: any;
  insurance_paid_amount?: any;
  insurance_paid_at?: any;
  description?: any;
  transaction_type: string;
  free_visit_reason?: any;
  zip_group_discount_amount?: any;
}

export interface SchoolWorkNote {
  ok_to_return: boolean;
  return_date: string;
  has_restrictions: boolean;
  restrictions: string;
  evaluation_date_copy: string;
  return_message_copy: string;
  evaluate_by_date?: any;
  notes: string;
}

export interface ReportAddendum {
  body: string;
  created_at: string;
  id: number;
  notify?: any;
  reviewed_by_clinician_at?: any;
  target_audience: string[];
  target_audience_display: string;
  type: string;
  user: string;
  user_id: number;
  user_role: string;
}

export interface TMVisitAddendumRequest {
  dependentId: string;
  visitId: string;
  addendum: string;
  phoneNumber: string;
}

export interface TMZipTokenResponse {
  token: string;
}

export interface TMZipServerTokenResponse {
  token: string;
  serverToken: string;
}

export interface GetVisitSurveyResponse {
  response?: {
    rating: number;
    comment: string;
    wouldRecommend: boolean;
    testimonial?: string;
    lowRatingFeedback?: string;
  };
}

export interface SendVisitSurveyRequest {
  rating: number;
  comment: string;
  wouldRecommend: boolean;
  visitId: string;
  dependentId: string;
}

export interface SubmitVisitSurveyCommentsRequest extends DependentVisitRequest {
  additionalComments: string;
  isTestimonial?: boolean;
}

export interface TMVisitGetPreVisitSurveyResponse {
  visitId: string;
  preVisitSurveyResponse?: PreVisitSurveyResponse;
}

export interface TMVisitSetPreVisitSurveyResponseRequest extends DependentVisitRequest {
  preVisitSurveyResponse: PreVisitSurveyResponse;
}

export interface TMTrackVideoAnalyticRequest {
  dependentId?: string;
  visitId?: string;
  sessionId: string;
  phoneDetails?: DBPhoneDetails;
  eventName: string;
  data: any;
  userTimestamp: string;
}

export interface HealthUserTpaSetInitialCredentialsRequest {
  dependentId: string;
  policyHolderId: number;
}

export interface HealthUserTpaGetInitialCredentialsResponse {
  tpaCredentials?: {
    policyHolderId: number;
    validationStatus: 'pending' | 'success' | 'failed';
  };
}

export interface X2AICreateUser {
  dependentId: string;
}

export interface HealthVpcIvrUpdateStatusRequest {
  a: string; // authorization
  s: 'incoming-call' | 'start-eligibility' | 'send-text' | 'forward-to-ucm' | 'chose-vpc' | 'chose-uc'; // status
  p: string; // phone number
  z: string; // zip code
  ediId?: string; // ediId
  c: 'clh' | 'pdh';
}

export interface HealthVpcIvrTestEligibilityRequest {
  a: string; // authorization
  p: string; // phone number
  lastName?: string;
  lastNameDigits?: string;
  dob: string;
  zipcode: string;
  c: 'clh' | 'pdh';
}

export interface HealthVpcIvrTestEligibilityResponse {
  eligible: 0 | 1 | 2; // 0 = not eligible at all, 1 = eligible, 2 = eligible but no payment method in app
  services: 0 | 1 | 2 | 3; // 0 = none, 1 = vpc eligible only, 2 = uc eligible only, 3 = both eligible
  ediId?: string;
}

export interface WebhookZipnosisPostRequest {
  id: number;
  subscription_id: number;
  event_name:
    | 'visit.diagnosed'
    | 'visit.finished'
    | 'visit.created'
    | 'visit.referred'
    | 'video.provider_notified_patient'
    | 'visit.expired'
    | 'addendum.created';
  subject_type: string;
  subject_id: number;
  created_at: string;
  patient_id: number;
  external_patient_id: string;
  location: string;
}

export interface WebhookZendeskPostRequest {
  user_token: string;
}

export interface WebhookZendeskPostResponse {
  jwt: string;
}

export interface WebhookZendeskWhitelabelPostRequest extends WebhookZendeskPostRequest {
  appId: string;
}

export interface WebhookTpaStreamCrawlPostRequest {
  crawl_claim_ids: any[];
  members: {
    id: number;
    employer: {id: number};
  }[];
  policy_holder: {
    id: number;
    login_correction_message: string;
    login_problem: string;
  };
  success: boolean;
}

export interface WebhookTpaStreamClaimPostRequest {
  data: any;
}

export interface ZendeskActivityWebhookRequest {
  'ticket.id': string;
  'ticket.requester.details': string;
  'ticket.requester.email': string;
  'ticket.requester.external_id': string;
  'ticket.requester.first_name': string;
  'ticket.requester.last_name': string;
  'ticket.requester.name': string;
  'ticket.requester.phone': string;
  'ticket.requester_field': string;
  'ticket.status': string;
  'ticket.tags': string;
  'ticket.url': string;
  'ticket.via': string;
}
