import React from 'react';

const OrderCardsIconSVG = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="28" height="21" viewBox="0 0 28 21">
    <g fill="none" fillRule="evenodd" stroke="currentColor" strokeWidth="2">
      <path strokeLinecap="square" d="M21.5 1.5l-4 18" />
      <rect width="26" height="19" x="1" y="1" rx="3" />
    </g>
  </svg>
);

export default OrderCardsIconSVG;
