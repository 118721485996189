import React from 'react';
export const safeway = (
  <svg xmlns="http://www.w3.org/2000/svg" width="180" height="33" viewBox="0 0 180 33">
    <defs>
      <path id="a" d="M0 .23h14.683v24.629H0z" />
      <path id="c" d="M.433.063h19.769v32.786H.432z" />
    </defs>
    <g fill="none" fillRule="evenodd" opacity=".5">
      <g transform="translate(0 4.368)">
        <mask id="b" fill="#fff">
          <use xlinkHref="#a" />
        </mask>
        <path
          fill="currentColor"
          d="M9.272 10.595L7.61 9.63C5.6 8.467 4.514 7.572 4.514 6.19c0-1.53 1.255-2.484 3.298-2.484 1.358 0 3.048.568 5.312 1.787l.287.163.011-3.996a.177.177 0 0 0-.045-.03C11.41.672 9.55.23 7.515.23 3.235.23.369 2.7.369 6.382c0 3.053 1.972 4.833 4.933 6.557l1.725 1.03c2.455 1.439 3.325 2.503 3.325 4.058 0 1.797-1.624 3.061-3.946 3.061-1.847 0-3.857-.73-6.32-2.301L0 18.719v4.6c.013.01.317.17.317.17 1.937.907 4.046 1.37 6.283 1.37 4.834 0 8.083-2.783 8.083-6.932 0-2.99-1.51-5.047-5.41-7.332"
          mask="url(#b)"
        />
      </g>
      <path
        fill="currentColor"
        d="M38.842 5.15v23.53h4.184V18.365h9.234v-3.689h-9.234V8.87h9.724v-3.72zM59.555 18.197h8.872v-3.685h-8.872V8.87h9.623v-3.72H55.44v23.532h13.9v-3.753h-9.784zM134.679 5.15h-5.017l-5.317 8.347c-.218.353-.41.655-.486.898h-.061c-.083-.243-.283-.55-.502-.894a2090.807 2090.807 0 0 1-5.323-8.352h-5.02s8.73 12.818 8.749 12.84V28.68h4.247V17.959c.461-.667 8.73-12.81 8.73-12.81M103.692 21.178s1.965-4.72 3.207-7.632c.211-.496.335-1.151.335-1.151h.047s.133.651.324 1.15c1.298 3.065 3.216 7.633 3.216 7.633h-7.129zm3.77-16.787h-.408l-11.07 24.29h4.51l1.753-4.09h10.018l1.752 4.09h4.503l-11.059-24.29zM85.377 15.474a7.185 7.185 0 0 0 .333-1.174h.056c.017.144.203.834.332 1.174.776 1.943 5.626 14.034 5.626 14.034h.382L100.55 5.15h-4.426l-4.177 13.108c-.112.356-.279 1.004-.295 1.158h-.056c-.013-.148-.189-.802-.318-1.158A5135.124 5135.124 0 0 0 85.92 4.282h-.364s-4.375 11.42-5.349 13.95c-.143.394-.302 1.036-.316 1.184h-.053c-.019-.154-.195-.802-.308-1.158-1.04-3.296-4.177-13.108-4.177-13.108h-4.424l8.444 24.358h.38s4.707-11.742 5.624-14.034M22.497 21.178s1.967-4.72 3.21-7.635c.209-.497.33-1.156.33-1.156h.052s.128.655.318 1.156c1.302 3.067 3.216 7.635 3.216 7.635h-7.126zm3.766-16.79h-.404L14.791 28.68h4.512l1.748-4.09h10.023l1.75 4.09h4.505L26.263 4.388z"
      />
      <g transform="translate(138.76)">
        <mask id="d" fill="#fff">
          <use xlinkHref="#c" />
        </mask>
        <path
          fill="currentColor"
          d="M19.532 27.019c0-6.515-9.673-7.128-9.673-16.382 0-6.68 5.186-9.99 10.343-10.54a39.494 39.494 0 0 0-1.668-.035c-1.246 0-1.856.05-2.911.099C5.98.635.833 5.794.51 13.733c-.025.51-.077 1.4-.077 2.798 0 1.401.052 2.284.077 2.802.313 7.723 5.197 12.813 14.342 13.516 1.485-.274 4.68-1.38 4.68-5.83"
          mask="url(#d)"
        />
      </g>
      <path
        fill="currentColor"
        d="M175.32 13.733C175.016 6.21 170.377 1.19 161.687.275c-1.53.314-4.566 1.48-4.566 5.805 0 6.535 9.677 7.14 9.677 16.39 0 5.788-4.383 10.022-10.948 10.506.415.013.857.024 1.445.024 1.242 0 1.861-.047 2.91-.096 9.64-.47 14.793-5.632 15.115-13.57.016-.519.067-1.402.067-2.803 0-1.397-.051-2.288-.067-2.798M177.302 30.921h.512l.603 1.598.61-1.598h.511v2.075h-.32v-1.8h-.014l-.68 1.8h-.213l-.68-1.8h-.005v1.8h-.324V30.92zm-1.26.231h-.647v-.23h1.612v.23h-.65v1.844h-.315v-1.844z"
      />
    </g>
  </svg>
);
