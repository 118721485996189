import React from 'react';

const ScrollArrowSvg = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="17" height="7" viewBox="0 0 17 7">
    <path
      fill="none"
      fillRule="evenodd"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M16 1L8.5 6 1 1"
    />
  </svg>
);

export default ScrollArrowSvg;
