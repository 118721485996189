import React from 'react';
import {Form} from 'react-final-form';
import Subheader from '../components/Subheader';
import RegisterForm from '../components/RegisterForm';
import {mapFinalFormErrors} from '../lib/utils';
import {AppService} from '../dataServices/appService';

const mapErrors = mapFinalFormErrors('Failed to contact admins.');

class RegisterContainer extends React.Component {
  state = {
    message: null,
  };

  onSubmit = (values) => {
    values.partnerType = values.partnerType || 'Healthcare Provider';
    return AppService.utilsClient
      .createPartnerContact(values, {})
      .then(() => {
        this.setState({
          message:
            'Thank you for your interest in partnering with Clever Rx.  One of our Clever team members will be contacting you soon.',
        });
        window.scrollTo(0, 0);
      })
      .catch(mapErrors);
  };

  validate = (values) => {
    const errors = {};

    if (values.password && values.password.length < 6) {
      errors.password = 'Password is too short (minimum is 6 characters)';
    }

    return errors;
  };

  render() {
    const {message} = this.state;

    return (
      <React.Fragment>
        <Subheader title="Tell Us More About Yourself" />
        <Form component={RegisterForm} onSubmit={this.onSubmit} validate={this.validate} message={message} />
      </React.Fragment>
    );
  }
}

export default RegisterContainer;
