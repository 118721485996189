import React from 'react';

const FooterLogoSvg = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="65" height="61" viewBox="0 0 65 61" version="1.1" x="0px" y="0px">
    <defs>
      <path id="a" d="M.43.04h60.58v60.58H.43z" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <path fill="#ffa500" d="M23.13 23.74l-.73 5.85h.44c1.8 0 3.05-1.8 3.05-3.5 0-1.83-1.1-2.35-2.76-2.35" />
      <g transform="translate(1.69)">
        <path
          fill="#ffa500"
          d="M49.17 19.44L43 29.87l3.96 11.52a.92.92 0 0 1-.87 1.21h-5.23a.92.92 0 0 1-.9-.71c-.3-1.28-.54-2.58-.79-3.85-.15-.67-.3-1.36-.37-2.03A99.07 99.07 0 0 1 36 42.1a.92.92 0 0 1-.82.51H23.7a.92.92 0 0 1-.89-.7c-.54-2.23-1.13-4.43-1.63-6.66-.22-.96-.4-1.88-.63-2.83-.27 3.15-.67 6.27-1.07 9.4a.92.92 0 0 1-.91.79h-4.67a.92.92 0 0 1-.91-1.03l2.79-22.71a.92.92 0 0 1 .91-.8h5.85c4.82 0 8.46 1.65 8.46 7.02 0 3.35-1.4 6-4.56 7.33l3.07 8.69 6.56-11.23-3.66-10.6a.92.92 0 0 1 .86-1.21h5.21c.44 0 .81.3.9.74.22 1.1.45 2.19.66 3.3.11.6.19 1.18.33 1.77.57-1.8 1.31-3.53 2.05-5.26a.92.92 0 0 1 .84-.55h5.11c.71 0 1.15.77.8 1.38M30.71.04a30.29 30.29 0 1 0 0 60.58 30.29 30.29 0 0 0 0-60.58"
        />
      </g>
    </g>
  </svg>
);

export default FooterLogoSvg;
