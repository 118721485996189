import React from 'react';
import PropTypes from 'prop-types';
import SiteFooter from './Footer';
import SiteHeaderContainer from '../containers/SiteHeaderContainer';

const Layout = ({children, bodyBg, showHomeLinks, headerStyle}) => (
  <div>
    <SiteHeaderContainer showHomeLinks={showHomeLinks} headerStyle={headerStyle} />
    <main role="main" className={`main-${bodyBg}`}>
      {children}
    </main>
    <SiteFooter footerStyle={headerStyle} />
  </div>
);

Layout.propTypes = {
  children: PropTypes.node,
  bodyBg: PropTypes.oneOf(['login', 'white', 'form']),
  headerStyle: PropTypes.oneOf(['normal', 'logo', 'none', 'dashboard', 'filled', 'card']),
};

Layout.defaultProps = {
  bodyBg: 'white',
  showHomeLinks: false,
  showForPartnerLinks: false,
  headerStyle: 'normal',
};

export default Layout;
