import React from 'react';
export const fred = (
  <svg version="1.1" viewBox="0 0 383 75" xmlns="http://www.w3.org/2000/svg">
    <g transform="translate(0 -977.36)" fill="currentColor">
      <path
        d="m113.88 1019.4c2.6205-15.156-4.0848-25.844-18.012-25.844-12.061 0-22.713 8.7711-24.89 22.49-2.2273 14.044 5.8894 21.7 17.953 21.7 8.7564 0 18.778-3.4303 23.009-13.798h-14.288c-1.4702 2.4738-3.8119 3.6652-6.1829 3.6652-3.9468 0-6.8754-3.269-6.089-8.2136h28.499zm-27.158-8.4541c0.7072-4.4692 3.6651-7.2599 8.1167-7.2599 3.5155 0 6.0596 3.4275 5.4493 7.2599h-13.566z"
        fillRule="evenodd"
      />
      <path
        d="m134.69 1025.8c-4.8829 0-5.0766-5.1059-4.3782-9.493 0.73362-4.6276 2.9315-9.892 7.8849-9.892s5.5931 5.0238 4.8595 9.6514c-0.77177 4.8683-3.1956 9.7336-8.3661 9.7336m19.543 10.769 9.1115-57.436h-14.93l-3.1516 19.863c-2.5031-4.1493-5.6019-5.4229-10.338-5.4229-8.9765 0-17.369 9.0117-19.57 22.892-1.878 11.808 2.3622 21.301 13.704 21.301 4.8096 0 8.3074-1.6785 12.636-6.7845l-0.88914 5.5872h13.428z"
        fillRule="evenodd"
      />
      <path
        d="m270.43 1019.4c2.6205-15.156-4.0818-25.844-18.012-25.844-12.061 0-22.713 8.7711-24.89 22.49-2.2302 14.044 5.8894 21.7 17.95 21.7 8.7594 0 18.781-3.4303 23.012-13.798h-14.291c-1.4672 2.4738-3.8118 3.6652-6.177 3.6652-3.9498 0-6.8784-3.269-6.0949-8.2136h28.502zm-27.161-8.4541c0.71014-4.4692 3.6681-7.2599 8.1196-7.2599 3.5155 0 6.0567 3.4275 5.4493 7.2599h-13.569z"
        fillRule="evenodd"
      />
      <path
        d="m348.53 1019.4c2.6205-15.156-4.0818-25.844-18.012-25.844-12.058 0-22.713 8.7711-24.89 22.49-2.2272 14.044 5.8894 21.7 17.95 21.7 8.7594 0 18.778-3.4303 23.009-13.798h-14.285c-1.4672 2.4738-3.8119 3.6652-6.1858 3.6652-3.9439 0-6.8696-3.269-6.0861-8.2136h28.499zm-27.161-8.4541c0.71014-4.4692 3.6681-7.2599 8.1196-7.2599 3.5155 0 6.0596 3.4275 5.4493 7.2599h-13.569z"
        fillRule="evenodd"
      />
      <path d="m18.628 1036.6 3.2015-20.183h18.525l2.1245-13.402h-18.522l1.658-10.447h23.261l2.1245-13.402h-40.199l-9.1173 57.433h16.944z" />
      <path d="m40.095 1036.6h14.933l2.2918-14.44c1.4291-9.0117 5.0238-12.202 11.125-12.202 1.0065 0 2.0306 0.317 3.0108 0.4784l2.6322-16.594c-0.63384-0.0763-1.253-0.23769-1.9015-0.23769-5.8132 0-9.9683 3.1105-13.088 9.6515h-0.14379l1.344-8.4571h-13.572l-6.6319 41.801z" />
      <path d="m178.84 1036.6 3.087-19.464 1.0564-8.9325 0.62798-8.457h0.14378l3.5536 36.854h11.776l15.253-36.854h0.14379l-2.506 10.37-1.6374 8.9325-2.7819 17.551h15.221l9.1144-57.433h-20.462l-14.801 35.815-3.4333-35.815h-20.462l-9.1144 57.433h15.221z" />
      <path d="m284.83 994.75h-15.001l6.3091 38.048c0.77176 4.6305-0.54581 6.1447-5.2145 6.1447-1.0006 0-1.9954-0.082-3.0724-0.082l-1.834 11.568c1.1503 0 3.8265 0.3198 6.5526 0.3198 13.284 0 15.509-4.0671 21.507-16.99l18.249-39.008h-14.361l-10.62 25.764-2.5148-25.764z" />
      <path d="m347.46 1036.6h14.933l2.2918-14.44c1.4291-9.0117 5.0209-12.202 11.125-12.202 1.0065 0 2.0336 0.317 3.0108 0.4784l2.6351-16.594c-0.63384-0.0763-1.256-0.23769-1.9015-0.23769-5.8132 0-9.9683 3.1105-13.088 9.6515h-0.14672l1.344-8.4571h-13.572l-6.6319 41.801z" />
      <path d="m373.12 1031.7h0.70134c0.7923 0 1.4672-0.2905 1.4672-1.0359 0-0.534-0.38147-1.0622-1.4672-1.0622-0.31399 0-0.53407 0.023-0.70134 0.047v2.0512zm0 3.3541h-0.96543v-6.0597c0.50179-0.07 0.98891-0.1437 1.7137-0.1437 0.91555 0 1.5171 0.1936 1.878 0.4607 0.36387 0.2641 0.55755 0.6749 0.55755 1.253 0 0.7981-0.53114 1.2823-1.1826 1.4731v0.047c0.53114 0.1 0.89207 0.5781 1.0124 1.4731 0.14672 0.942 0.29051 1.3058 0.38735 1.4966h-1.0124c-0.14379-0.1908-0.29345-0.7483-0.41082-1.5435-0.14673-0.7747-0.53114-1.0623-1.3029-1.0623h-0.67493v2.6058zm1.0124-7.744c-2.3916 0-4.343 2.0482-4.343 4.5836 0 2.5823 1.9514 4.6071 4.3665 4.6071 2.4121 0.026 4.343-2.0248 4.343-4.5836 0-2.5589-1.9309-4.6071-4.343-4.6071h-0.0293zm0.0293-0.8452c2.9697 0 5.3319 2.4151 5.3319 5.4288 0 3.0665-2.3622 5.4551-5.3554 5.4551-2.9697 0-5.3818-2.3886-5.3818-5.4551 0-3.0137 2.4121-5.4288 5.3818-5.4288h0.0293z" />
    </g>
  </svg>
);
