import React from 'react';
export const smiths = (
  <svg
    width="136.8mm"
    height="43.027mm"
    version="1.1"
    viewBox="0 0 136.80428 43.027309"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g transform="translate(200.19 -242.48)">
      <g transform="matrix(.26458 0 0 .26458 -202.46 240.79)">
        <g fill="currentColor">
          <path d="m191.26 72.849c22.574-21.964 54.668-3.807 54.668 18.274v61.223c0 5.705 1.46 9.305 4.755 12.608h-37.808c3.411-2.553 4.324-6.903 4.324-12.608v-61.236c0-16.83-23.135-16.884-23.135 0v61.237c0 5.708 2.52 9.833 5.834 12.608h-38.865c3.239-2.498 5.398-6.9 5.398-12.608v-61.237c0-16.884-22.682-16.877-22.682 0v61.237c0 5.705 0.782 10.056 3.24 12.608h-37.433c4.449-4.352 5.397-6.903 5.397-12.608v-54.929c0-11.198-2.454-17.191-8.853-21.312l37.588-11.516 0.061 8.809c11.123-12.608 34.27-15.16 47.511-0.55z" />
          <path d="m257.64 42.979c0-9.452 6.612-17.11 14.772-17.11 8.145 0 14.761 7.659 14.761 17.11 0 9.448-6.616 17.108-14.761 17.108-8.159-1e-3 -14.772-7.66-14.772-17.108z" />
          <path d="m348.98 151.92c-4.747 3.754-16.409 1.749-16.409-8.757v-68.457h14.257l4.75-10.006h-19.654v-24.529l-35.004 34.787h4.666c2.45 0.023 3.543 1.466 3.543 5.647v63.059c0 12.262 11.243 23.272 25.276 23.272 14.046 0 17.721-5.754 21.173-10.008 1.292-2.005 2.377-4.509 2.377-4.509l-2.809-2.247c0 1e-3 -0.645 0.491-2.166 1.748z" />
          <path d="m387.2 71.97v-49.68l-36.729 15.015c6.482 2.506 7.992 6.261 7.992 15.022v100.02c0 5.705-1.328 8.857-5.393 12.608h37.37c-2.795-2.851-3.24-6.9-3.24-12.608v-61.236c0-16.843 22.677-16.833 22.677 0v61.237c0 5.705-1.868 9.613-5.396 12.608h37.342c-2.983-2.851-5.588-6.9-5.588-12.608v-61.237c0-23.202-29.427-39.326-49.035-19.14z" />
          <path d="m438.7 63.724c-6.982-1.651-11.42-6.063-11.42-14.333 0-8.268 5.706-14.885 12.843-14.885 7.14 0 12.853 6.617 12.853 14.885-0.788 11.028-4.285 19.298-12.052 26.096-1.115 1.104-2.38-0.366-0.319-3.122 1.9-2.758 1.9-5.145 1.579-6.8-0.311-1.102-1.424-1.47-3.484-1.841z" />
          <path d="m510.77 159.75c0-4.153 2.98-7.225 6.507-7.225 3.484 0 6.462 3.071 6.462 7.225 0 4.194-2.978 7.263-6.462 7.263-3.527 0-6.507-3.069-6.507-7.263zm6.506 6.05c2.897 0 5.178-2.563 5.178-6.05 0-3.426-2.28-6.014-5.178-6.014-2.927 0-5.223 2.588-5.223 6.014 1e-3 3.486 2.297 6.05 5.223 6.05zm-1.36-1.856h-1.112v-8.348h2.803c1.744 0 2.615 0.728 2.615 2.379 0 1.483-0.838 2.129-1.918 2.297l2.104 3.672h-1.254l-1.949-3.618h-1.288v3.618zm1.337-4.689c0.954 0 1.795-0.078 1.795-1.365 0-1.028-0.822-1.227-1.607-1.227h-1.524v2.592z" />
          <path d="m74.042 131.5c0-35.603-54.836-44.083-61.559-79.325-7.035-36.887 32.975-51.003 52.21-40.576 12.671 6.872 17.463 3.114 23.985-0.499l-4e-3 47.594c0 2.052-2.445 2.457-3.135 0.438-4.735-13.821-10.029-27.601-21.213-35.679-22.607-16.328-38.496 9.73-9.981 32.604 15.286 12.264 47.677 31.046 50.351 59.408 0.126 1.324 4.473 29.757-19.08 45.189-7.665 5.027-21.076 7.771-34.499 5.814-17.396-2.532-17.889-8.256-26.375-9.354-7.171-0.922-12.817 6.884-14.281 7.809 0.141-0.327 0-33.72 0-50.292 0.107-0.256 2.231-0.823 3.338 0.867 4.054 17.432 14.58 28.391 21.796 33.648 8.299 6.06 38.447 9.987 38.447-17.646z" />
          <path d="m489.45 143.62c-1.66-23.463-37.911-28.729-41.472-52.081-3.978-26.22 26.008-33.495 39.646-26.646 8.989 4.513 12.38 2.044 17.011-0.331l-2e-3 31.252c0 1.347-1.731 1.611-2.222 0.289-3.359-9.076-6.862-18.517-15.038-23.428-14.438-8.665-26.254 7.81-6.027 20.388 17.042 10.598 31.351 23.596 32.908 40.028 0.075 0.873 3.16 19.54-13.537 29.669-5.433 3.294-14.947 5.103-24.459 3.823-12.345-1.664-12.687-5.419-18.707-6.14-5.086-0.613-9.086 3.95-10.125 4.545 0.097-0.216 0-21.57 0-32.444 0.08-0.169 1.579-0.538 2.364 0.573 2.872 11.442 10.34 18.636 15.456 22.092 5.89 3.979 25.489 6.516 24.204-11.589z" />
          <path d="m290.9 64.591-37.585 11.516c6.464 4.197 8.858 10.2 8.858 21.312v54.929c0 5.705-1.337 8.93-5.399 12.608h40.61c-4.642-2.395-6.483-6.903-6.483-12.765v-87.6z" />
        </g>
      </g>
    </g>
  </svg>
);
