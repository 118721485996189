import React from 'react';
import {Element} from 'react-scroll';
import FAQ from '../components/home/FAQ';
import ForPartnersMobile from '../components/home/ForPartners';
import HowItWorks from '../components/home/HowItWorks';
import Pharmacies from '../components/home/Pharmacies';
import PhoneCTA from '../components/home/PhoneCTA';
import ThreeCol from '../components/home/ThreeCol';
import TopSection from '../components/home/TopSection';
import Layout from '../components/Layout';
import OpenGraphMeta from '../components/OpenGraphMeta';

class Home extends React.PureComponent {
  state = {};

  componentDidMount() {}

  render() {
    return (
      <div className="home">
        <OpenGraphMeta />
        <Layout showHomeLinks>
          <TopSection
            title="Clever RX is the future of prescription savings!"
            subtitle="Save your clients thousands, help your community and build a great business."
            joinUsButton={true}
          />
          <ForPartnersMobile />
          <ThreeCol />
          <Element name="How">
            <HowItWorks />
          </Element>
          <Element name="Pharmacies">
            <Pharmacies />
          </Element>
          <Element name="FAQ">
            <FAQ />
          </Element>
          <PhoneCTA />
        </Layout>
      </div>
    );
  }
}

export default Home;
