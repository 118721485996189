import React, {useCallback, useState} from 'react';
import {DrugPriceV2} from '../dataServices/app.generated';
import {AppService} from '../dataServices/appService';
import {useEffectAsync} from '../hooks/useEffectAsync';
import {Utils} from '../utils/utils';
import {StateSwitch} from './StateSwitch';
import {FormCheckbox} from './FormCheckbox';
import {Config} from '../config';

export const VoucherSurvey = ({price}: {price?: DrugPriceV2}) => {
  const [survey, setSurvey] = useState<{
    goodPrice: -1 | 1 | 0;
    willUse: -1 | 1 | 0;
    wasUsed: -1 | 1 | 0;
    notUsedReason: string;
    fullySubmitted: boolean;
  }>({
    goodPrice: -1,
    willUse: -1,
    wasUsed: -1,
    notUsedReason: '',
    fullySubmitted: false,
  });
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState<number>(0);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffectAsync(async () => {
    if (!price || !price.ndc || !price.price) return;
    setLoading(true);
    const res = await AppService.utilsClient.getWebVoucherSurvey({price, webUserId: Config.webUserId}, {});
    if (res) {
      setSurvey(res);
      setStep(() => {
        if (res.fullySubmitted) return 4;
        if (res.goodPrice === -1) return 0;
        if (res.willUse === -1) return 1;
        if (res.wasUsed === -1) return 2;
        if (res.wasUsed === 0) return 3;
        return 4;
      });
    }
    setLoading(false);
  }, [price]);

  const onSubmit = useCallback(
    async (response: typeof survey, isSubmit?: boolean) => {
      if (!price || !price.ndc || !price.price) return;
      setIsSubmitting(true);
      AppService.utilsClient.submitWebVoucherSurvey(
        {...response, webUserId: Config.webUserId, price, platform: 'clx-web'},
        {}
      );
      if (isSubmit) {
        setStep(4);
        setIsSubmitting(false);
        return;
      }
      await Utils.timeout(1500);
      setStep((s) => {
        if (s === 0) return 1;
        if (s === 1 && response.willUse === 1) return 2;
        if (s === 2 && response.wasUsed === 0) return 3;
        return 4;
      });
      setIsSubmitting(false);
    },
    [price]
  );

  if (!price || loading || survey.fullySubmitted) return null;

  return (
    <div className={'my-4 px-2 py-4 text-center border border-white rounded-lg'}>
      <StateSwitch state={() => step}>
        {{
          0: () => (
            <VoucherSurveyQuestion
              title={'Do you think this is a good price?'}
              onYes={() => {
                if (isSubmitting) return;
                setSurvey((s) => ({...s, goodPrice: 1}));
                onSubmit({...survey, goodPrice: 1});
              }}
              onNo={() => {
                if (isSubmitting) return;
                setSurvey((s) => ({...s, goodPrice: 0}));
                onSubmit({...survey, goodPrice: 0});
              }}
              value={survey.goodPrice}
            />
          ),
          1: () => (
            <VoucherSurveyQuestion
              title={'Do you intend to use this voucher?'}
              onYes={() => {
                if (isSubmitting) return;
                setSurvey((s) => ({...s, willUse: 1}));
                onSubmit({...survey, willUse: 1});
              }}
              onNo={() => {
                if (isSubmitting) return;
                setSurvey((s) => ({...s, willUse: 0}));
                onSubmit({...survey, willUse: 0});
              }}
              value={survey.willUse}
            />
          ),
          2: () => (
            <VoucherSurveyQuestion
              title={'I was able to use this voucher successfully'}
              onYes={() => {
                if (isSubmitting) return;
                setSurvey((s) => ({...s, wasUsed: 1}));
                onSubmit({...survey, wasUsed: 1});
              }}
              onNo={() => {
                if (isSubmitting) return;
                setSurvey((s) => ({...s, wasUsed: 0}));
                onSubmit({...survey, wasUsed: 0});
              }}
              value={survey.wasUsed}
            />
          ),
          3: () => (
            <div className={'fade-in-400'}>
              <div>Why were you not able to use this voucher?</div>
              <textarea
                className={'clh-input resize-none bg-cleverGray-350'}
                value={survey.notUsedReason}
                onChange={(e) => setSurvey((s) => ({...s, notUsedReason: e.target.value}))}
              />
              <button
                className={'clever-btn rounded-full w-full block mt-3'}
                onClick={(e) => {
                  e.stopPropagation();
                  if (!survey.notUsedReason) return;
                  onSubmit(survey, true);
                }}
              >
                submit
              </button>
            </div>
          ),
          4: () => <div className={'fade-in-400'}>Thanks for your feedback!</div>,
        }}
      </StateSwitch>
    </div>
  );
};

const VoucherSurveyQuestion = ({
  title,
  onYes,
  onNo,
  value,
}: {
  title: string;
  onYes: () => void;
  onNo: () => void;
  value: -1 | 0 | 1;
}) => {
  return (
    <div className={'fade-in-400'}>
      <div>{title}</div>
      <div className={'flex items-center justify-center mt-3'}>
        <FormCheckbox isChecked={value === 1} onClick={onYes} />
        <div className={'mr-6 ml-3'}>Yes</div>
        <FormCheckbox isChecked={value === 0} onClick={onNo} />
        <div className={'ml-3'}>No</div>
      </div>
    </div>
  );
};
