const partnerTypes = [
  'Healthcare Provider',
  'Healthcare Broker/Agent',
  'Healthcare Consultant',
  'Healthcare Administrator',
];

export default partnerTypes.map((value) => ({
  value,
  label: value,
}));
