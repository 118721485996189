import React from 'react';
import Layout from '../components/Layout';
import RegisterContainer from '../containers/RegisterContainer';
import OpenGraphMeta from '../components/OpenGraphMeta';

const Register = () => (
  <Layout bodyBg="form" headerStyle="logo">
    <OpenGraphMeta title={'Register | Clever RX'} />
    <RegisterContainer />
  </Layout>
);

export default Register;
