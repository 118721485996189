import {withRouter} from 'react-router';
import React, {Component} from 'react';
import DocumentMeta from 'react-document-meta';

const metaImageUrl = '/static/images/meta.jpg';

const defaultTitle = 'Clever RX - Finally a Pharmacy Savings Card That Works for You!';

const defaultDesc =
  "Get huge discounts on tons of RX drugs. Accepted at pharmacies nationwide. Save up to 80% off prescription drugs. Now that's clever.";

class openGraphMeta extends Component {
  render() {
    let {title, location, description, image} = this.props;
    let baseUrl = 'https://cleverrx.com';
    let path = location.pathname;
    return (
      <DocumentMeta
        title={title || defaultTitle}
        meta={{
          'og:title': title || defaultTitle,
          'og:description': description || defaultDesc,
          'og:image': baseUrl + '' + (image || metaImageUrl),
          'og:url': baseUrl + '' + path,
          description: description || defaultDesc,
        }}
      />
    );
  }
}

export default withRouter(openGraphMeta);
