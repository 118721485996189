import React from 'react';
import {Button, Row, Col, Container} from 'reactstrap';
import {Link} from 'react-router-dom';

const HealthAgentsSVG = () => (
  <img
    alt="Health Agents / Brokers Icon"
    title="Health Agents / Brokers Icon"
    src="/static/images/heath-agents-icon.svg"
    className="img-fluid"
  />
);

const HealthSVG = () => (
  <img
    alt="Health Care Providers"
    title="Health Care Providers"
    src="/static/images/hcp-icon.svg"
    className="img-fluid"
  />
);

const EmployeesSVG = () => (
  <img
    alt="Employees / Patients"
    title="Employees / Patients"
    src="/static/images/employees-icon.svg"
    className="img-fluid"
  />
);

const ThreeCol = () => (
  <section className="three-col text-center">
    <Container fluid>
      <h2>
        Clever RX is on a mission to help healthcare providers, agents and consumers drive down prescription drug costs.
      </h2>
      <br />
      <Row>
        <Col xs="12" md="4">
          <div className="img-wrap">
            <HealthAgentsSVG />
          </div>
          <h4>Healthcare Agents</h4>
          <ul className="small">
            <li>Over 70% of employees and patients can benefit</li>
            <li>Save thousands on company plans</li>
            <li>Build a great business</li>
            <li>Co-branded website, cards and app</li>
          </ul>
          <Button tag={Link} to="/for-partners" color="primary" size="lg" block>
            I Want to be Clever Too
          </Button>
        </Col>
        <Col xs="12" md="4">
          <div className="img-wrap">
            <HealthSVG />
          </div>
          <h4>Healthcare Providers</h4>
          <ul className="small">
            <li>Save your patients money</li>
            <li>Increase patient compliance</li>
            <li>Help your community</li>
            <li>Co-branded cards, RX website and app</li>
            <li>Drive down Rx costs</li>
          </ul>
          <Button tag={Link} to="/for-partners" color="primary" size="lg" block>
            I Want to be Clever Too
          </Button>
        </Col>
        <Col xs="12" md="4">
          <div className="img-wrap">
            <EmployeesSVG />
          </div>
          <h4>Patients</h4>
          <ul className="small">
            <li>FREE to use</li>
            <li>Save up to 80% off prescription drugs</li>
            <li>Over 70% of patients can benefit</li>
            <li>Share with anyone!</li>
            <li className="ulpadding">&nbsp;</li>
          </ul>
          <Button tag={Link} to="/for-partners" color="primary" size="lg" block>
            I Want to be Clever Too
          </Button>
        </Col>
      </Row>
    </Container>
    <style jsx>{`
      .three-col {
        padding: 60px 0 30px 0;
        background: #24292d;
        position: relative;
        z-index: 1;
      }
      .three-col:after {
        background: inherit;
        bottom: 0;
        content: '';
        display: block;
        height: 50%;
        left: 0;
        position: absolute;
        right: 0;
        transform: skewY(-4deg);
        transform-origin: 100%;
        z-index: -1;
        -webkit-backface-visibility: hidden;
        box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
      }
      .three-col :global(img) {
        max-width: 100%;
        margin: 0 auto;
        vertical-align: middle;
      }
      .img-wrap {
        min-height: 86px;
        display: flex;
        align-items: center;
        margin: 0 auto;
        width: 100%;
      }
      h4 {
        margin: 30px auto 18px auto;
        line-height: 1.5;
      }
      .three-col :global(.small) {
        font-size: 16px;
        color: #8c99a7;
        max-width: 240px;
        margin: 0 auto 30px auto;
        line-height: 1.45;
        text-align: left;
      }
      .three-col :global(.btn) {
        max-width: 300px;
        margin: 0 auto 60px auto;
      }
      @media (min-width: 768px) {
        .three-col {
          padding: 80px 0 30px 0;
          margin-bottom: 100px;
        }
      }
      .ulpadding {
        visibility: hidden;
      }
    `}</style>
  </section>
);

export default ThreeCol;
