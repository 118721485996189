import React from 'react';

const TwitterSvg = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="34" height="27" viewBox="0 0 34 27" version="1.1" x="0px" y="0px">
    <path
      fill="currentColor"
      d="M34 3.201c-1.275.557-2.55.974-3.967 1.113 1.417-.835 2.55-2.226 3.117-3.757-1.417.835-2.833 1.391-4.392 1.67A6.913 6.913 0 0 0 23.658 0c-3.825 0-6.941 3.062-6.941 6.82 0 .556 0 1.113.141 1.53-5.95-.278-11.05-3.061-14.45-7.237-.708 1.114-.991 2.227-.991 3.48 0 2.366 1.275 4.453 3.116 5.706-1.133 0-2.266-.278-3.116-.835v.14c0 3.34 2.408 6.123 5.525 6.68-.567.139-1.134.278-1.842.278-.425 0-.85 0-1.275-.14.85 2.784 3.4 4.733 6.517 4.733-2.409 1.809-5.384 2.922-8.642 2.922-.567 0-1.133 0-1.7-.139A20.09 20.09 0 0 0 10.625 27c12.892 0 19.833-10.438 19.833-19.485V6.68c1.417-.974 2.55-2.226 3.542-3.479z"
    />
  </svg>
);

export default TwitterSvg;
