import {FORM_ERROR} from 'final-form';
import {mapValues, startCase} from 'lodash';
import moment from 'moment';

/**
 * Load the value of an environment variable and throw an error if not defined
 *
 * @param {string} varName
 * @return {*}
 */
export const requireEnvVar = (varName) => {
  const envVar = process.env[`REACT_APP_${varName}`];
  if (!envVar) {
    throw new Error(`${varName} environment variable must be defined!`);
  }
  return envVar;
};

/**
 * Return a promise that resolves after `delay` milliseconds
 *
 * @param {int} delay The time, in milliseconds, to sleep
 * @return {Promise}
 */
export const sleep = (delay) => new Promise((resolve) => setTimeout(resolve, delay));

/**
 * Return a Moment object if `dateString` is a valid date string.
 * Otherwise return the falsey value.
 *
 * @param {string|*} dateString
 * @return {*|Moment}
 */
export const stringToMoment = (dateString) => dateString && moment(dateString);

/**
 * Return a function that can accept an error returned by our API and returns
 * an error object to be used by Final Form.
 *
 * @see https://github.com/final-form/final-form#onsubmit-values-object-callback-errors-object--void--object--promiseobject--void
 * @param {string} [defaultErrorMessage] The error message to use if one is
 *   not provided by the API
 * @return {function}
 */
export const mapFinalFormErrors = (defaultErrorMessage = 'An error occurred.') => (serverError) => {
  const {errors, message} = serverError;

  const formErrors = mapValues(errors, (error, name) => `${startCase(name)} ${error}`);

  formErrors[FORM_ERROR] = message || defaultErrorMessage;

  return formErrors;
};

/**
 * Calculate percentage and return 0 if divisor is 0.
 *
 * @param {number} dividend
 * @param {number} divisor
 * @return {number}
 */
export const calculatePercentage = (dividend, divisor) => {
  if (divisor === 0) {
    return 0;
  }

  return Math.round((dividend / divisor) * 100);
};

export const formatCurrency = (price) => (isNaN(price) ? null : `$${Number.parseFloat(price).toFixed(2)}`);

export const INVITE_TOKEN_KEY = 'invite_token';

/**
 * Stores invite token params into local storage
 *
 * @param {object} inviteToken
 */
export const setInviteToken = (inviteToken) => {
  console.log('inviteToken:', inviteToken);
  if (inviteToken !== undefined) {
    localStorage.setItem(INVITE_TOKEN_KEY, JSON.stringify(inviteToken));
  }
};

export const getInviteToken = () => {
  if (typeof localStorage === 'undefined') {
    return {};
  }
  return JSON.parse(localStorage.getItem(INVITE_TOKEN_KEY));
};
