import React from 'react';
import {Container, Button} from 'reactstrap';
import {Link} from 'react-router-dom';

const OrangeCTA = () => (
  <section className="orange-cta text-center">
    <Container fluid>
      <h1>Save People Money on RX Drugs. Build Your Business. Get Rewarded. That’s Clever.</h1>
      <Button tag={Link} to="/contact" color="secondary" size="lg" block>
        Get Started
      </Button>
    </Container>
    <style jsx>{`
      .orange-cta {
        background: #ffa500;
        padding: 60px 0;
        position: relative;
        margin-top: 60px;
        z-index: 1;
      }
      .orange-cta:before {
        background: #ffa500;
        top: 0;
        content: '';
        display: block;
        height: 50%;
        left: 0;
        position: absolute;
        right: 0;
        transform: skewY(-5deg);
        transform-origin: 0 100%;
        z-index: -1;
        -webkit-backface-visibility: hidden;
      }
      h1 {
        margin: 0 auto 30px auto;
        font-weight: 300;
        font-size: 26px;
        line-height: 1.42;
        letter-spacing: 3.6px;
        color: #24292d;
      }
      .orange-cta :global(.btn) {
        max-width: 300px;
        margin: 0 auto;
        color: #ffa500;
      }
      @media (min-width: 768px) {
        .orange-cta {
          display: flex;
          align-items: center;
          height: 600px;
          margin-top: 140px;
        }
        h1 {
          font-size: 35px;
          letter-spacing: 5.6px;
        }
      }
    `}</style>
  </section>
);

export default OrangeCTA;
