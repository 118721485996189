import React from 'react';
import {Container, Button} from 'reactstrap';
import {Link} from 'react-router-dom';
import {Link as ScrollLink} from 'react-scroll';
import ScrollArrowSvg from '../images/scroll-arrow';

const TopCTA = () => (
  <section className="top text-center">
    <Container fluid>
      <h1>Partner with Clever RX today to save your clients money and seamlessly build a great business.</h1>
      <p>
        If you're not building an RX savings program for your clients, someone else will. Clever RX offers you
        innovative solutions such as partner-branded savings cards, website and a co-branded mobile app that you can
        share virally. The best part is that you earn every time a patient saves money using your card or app. Don't let
        someone else help your clients and get rewarded. Build a great business and feel clever about it.
      </p>
      <Button tag={Link} to="/contact" color="primary" size="lg" block>
        Get Started
      </Button>
    </Container>
    <ScrollLink to="How" className="scroll" smooth>
      <ScrollArrowSvg />
    </ScrollLink>
    <style jsx>{`
      .top {
        background: #24292d;
        padding: 20px 0 120px 0;
        color: #fff;
        position: relative;
        min-height: 500px;
      }
      h1 {
        margin: 0 auto 20px auto;
        font-weight: 300;
        font-size: 30px;
        line-height: 1.4;
        max-width: 990px;
      }
      p {
        line-height: 1.89;
        max-width: 780px;
        margin: 0 auto 30px auto;
        color: #808d99;
      }
      .top :global(.scroll) {
        cursor: pointer;
        padding: 30px;
        position: absolute;
        bottom: 30px;
        left: 50%;
        margin-left: -40px;
        line-height: 1;
        border-radius: 50%;
        transition: transform 0.6s ease;
      }
      .top :global(.scroll):hover {
        background: #1e2126;
      }
      .top :global(.btn) {
        max-width: 300px;
        margin: 0 auto;
      }
      @media (min-width: 768px) {
        .top {
          padding: 60px 0 120px 0;
        }
      }
    `}</style>
  </section>
);

export default TopCTA;
