import React from 'react';

const InstagramSvg = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="42" height="42" viewBox="0 0 42 42" version="1.1" x="0px" y="0px">
    <path
      fill="currentColor"
      fillRule="nonzero"
      d="M37.013 0H4.986A4.985 4.985 0 0 0 0 4.987v32.026A4.985 4.985 0 0 0 4.987 42h32.026A4.985 4.985 0 0 0 42 37.013V4.986A4.985 4.985 0 0 0 37.013 0zM31.5 5.25h3.938c.787 0 1.312.525 1.312 1.313V10.5c0 .787-.525 1.313-1.313 1.313H31.5c-.788 0-1.313-.526-1.313-1.313V6.562c0-.787.525-1.312 1.313-1.312zM21 12.994c4.462 0 8.137 3.544 8.137 8.006S25.462 29.006 21 29.006c-4.462 0-8.137-3.544-8.137-8.006.13-4.462 3.675-8.006 8.137-8.006zm15.75 25.069H5.25c-.787 0-1.313-.526-1.313-1.313V17.062h5.25C8.531 18.113 8.27 19.82 8.27 21c0 7.087 5.775 12.731 12.731 12.731 7.087 0 12.731-5.775 12.731-12.731 0-1.181-.131-3.019-1.05-3.938h5.25V36.75c.132.788-.393 1.313-1.181 1.313z"
    />
  </svg>
);

export default InstagramSvg;
