import {Config} from '../config';
import {AnalyticsClient, DrugClient, DrugV2Client, ShareClient, UserClient, UtilsClient} from './app.generated';
import {ControllerOptions} from './baseClient';

const options: ControllerOptions = {
  baseUrl: Config.host,
  getJwt: (): string | undefined => {
    return undefined;
  },
  handleError: (error: string) => {
    console.error(error);
    return null;
  },
  handleUnauthorized: (error: string) => {
    return null;
  },
};

export class AppService {
  static drugClienta = new DrugClient(options);
  static analyticsClient = new AnalyticsClient(options);
  static drugV2Client = new DrugV2Client(options);
  static userClient = new UserClient(options);
  static shareClient = new ShareClient(options);
  static utilsClient = new UtilsClient(options);
}

// https://www.youtube.com/watch?v=jnKtICvvUko
