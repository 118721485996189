import React from 'react';
import PropTypes from 'prop-types';
import {FormGroup, Input, FormFeedback, Label} from 'reactstrap';

const FieldGroup = ({input, meta, id, label, children, hideLabel, ...rest}) => {
  const hasError = meta.touched && (!!meta.error || meta.submitError);

  return (
    <FormGroup>
      <Label hidden={hideLabel} for={id}>
        {label}
      </Label>
      <Input {...input} id={id} invalid={hasError} {...rest} />
      {children}
      {hasError && <FormFeedback>{meta.error || meta.submitError}</FormFeedback>}
    </FormGroup>
  );
};

FieldGroup.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  hideLabel: PropTypes.bool,
  children: PropTypes.node,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  className: PropTypes.string,
  invalid: PropTypes.func,
};

FieldGroup.defaultProps = {
  hideLabel: false,
};

export default FieldGroup;
