import React, {useCallback, useEffect, useRef, useState} from 'react';
import {DrugPriceV2, HttpPickedDrug, MessagePriceRequest} from '../dataServices/app.generated';
import {TextFormats, TextFormatter} from '../utils/textFormatter';
import {AppService} from '../dataServices/appService';
import {toast} from 'react-toastify';
import {ModalContainer} from '../components/ModalContainer';
import {PromiseButton} from '../components/PromiseButton';
import ReactToPrint from 'react-to-print';
import FrontBackground from '../assets/card-front.svg';
import BackBackground from '../assets/card-back.svg';
import {useClickOutside} from '../hooks/useClickOutside';
import {observer} from 'mobx-react';
import {Customization} from './RxPricing';
import {Config} from '../config';
import {Utils} from '../utils/utils';
import {VoucherSurvey} from '../components/VoucherSurvey';
import {Fireworks} from '../components/Fireworks/Fireworks';
import PrintVoucher from '../components/PrintVoucher';
declare const gtag: any;

export const VoucherModal = observer(
  ({
    pickedDrug,
    modalVoucher,
    close,
    show,
    customization,
    retrievedPrice,
  }: {
    show: boolean;
    pickedDrug?: HttpPickedDrug;
    modalVoucher: MessagePriceRequest | null;
    close: () => void;
    customization: Customization;
    retrievedPrice: DrugPriceV2;
  }) => {
    const [showForm, setShowForm] = useState<'text' | 'email' | ''>('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [email, setEmail] = useState('');
    const printRef = useRef<HTMLDivElement>(null);
    const modalRef = useRef<HTMLDivElement>(null);
    const phoneNumberRef = useRef<HTMLInputElement>(null);
    const emailRef = useRef<HTMLInputElement>(null);

    const onClose = () => {
      setShowForm('');
      close();
    };

    useEffect(() => {
      if (showForm === 'text') {
        phoneNumberRef.current?.focus();
      } else if (showForm === 'email') {
        emailRef.current.focus();
      }
    }, [showForm]);

    useClickOutside(modalRef, show, onClose);

    const onSendVoucher = async (type: 'text' | 'email') => {
      if (
        (type === 'text' && !TextFormatter.valid(TextFormats.phoneNumber, phoneNumber)) ||
        (type === 'email' && !email)
      ) {
        return;
      }
      AppService.utilsClient.voucherEvent(
        {
          groupCode: customization.groupCode,
          memberId: customization.memberId,
          ndc: pickedDrug.ndc,
          zipcode: pickedDrug.zipcode,
          type: type === 'text' ? 'text-voucher' : 'email-voucher',
          sessionId: Config.sessionId,
          drugName: pickedDrug.drugName,
          price: retrievedPrice.price.toString(),
          pharmacyName: retrievedPrice.pharmacy,
          quantity: pickedDrug.quantity,
          ...(type === 'text' ? {phone: phoneNumber} : {email}),
        },
        {}
      );
      if (gtag) {
        gtag('event', type === 'text' ? 'text-voucher' : 'email-voucher', {});
      }
      const onSendError = () => {
        toast('The voucher could not be sent at this time.', {type: 'error'});
      };

      const res =
        type === 'email'
          ? await AppService.shareClient.emailPrice(
              {
                ...modalVoucher,
                group: customization.groupCode,
                memberId: customization.memberId,
                email,
              },
              {
                404: onSendError,
              }
            )
          : await AppService.shareClient.messagePrice(
              {
                ...modalVoucher,
                group: customization.groupCode,
                memberId: customization.memberId,
                phoneNumber: TextFormatter.clean(TextFormats.phoneNumber, phoneNumber),
              },
              {
                404: onSendError,
              }
            );
      if (res) {
        toast('Your voucher has been sent!', {type: 'success'});
        setShowForm('');
      }
    };

    const onOpenMap = useCallback(() => {
      window.open(
        `https://maps.google.com/?q=${encodeURIComponent(retrievedPrice.pharmacy)}&sll=${retrievedPrice.latitude},${
          retrievedPrice.longitude
        }`
      );
    }, [retrievedPrice]);

    return (
      <ModalContainer show={show}>
        {/*<div className={'absolute transform top-40 max-h-full translate-x-1/2 left-1/2'}>
          <Fireworks />
        </div>*/}
        <div
          className={
            'bg-custom-modalBackground text-custom-dropdown-text px-2 py-4 max-w-2xl w-full h-full max-h-full rounded-xl overflow-hidden shadow-lg'
          }
          ref={modalRef}
        >
          <div className={'styled-scrollbar overflow-auto w-full max-h-full p-4'}>
            <div className={'flex items-center justify-end'}>
              <button
                onClick={onClose}
                className={'w-8 h-8 p-2 clever-gradient flex items-center justify-center rounded-full'}
              >
                <svg className={'w-full h-full'} viewBox="0 0 13 13" fill="none">
                  <path
                    d="M12.5606 2.56126L8.62167 6.50059L12.5606 10.4397C13.1465 11.0259 13.1465 11.9753 12.5606 12.5614C12.2679 12.8541 11.884 13.0006 11.5004 13.0006C11.1162 13.0006 10.7323 12.8544 10.4398 12.5614L6.50003 8.62181L2.56054 12.5614C2.26783 12.8541 1.88399 13.0006 1.50004 13.0006C1.11619 13.0006 0.732613 12.8543 0.439646 12.5614C-0.14625 11.9755 -0.14625 11.026 0.439646 10.4397L4.37846 6.50056L0.439422 2.56126C-0.146474 1.97537 -0.146474 1.02571 0.439422 0.439817C1.02521 -0.145629 1.97431 -0.145629 2.56032 0.439817L6.49999 4.37915L10.4394 0.439817C11.0255 -0.145629 11.9747 -0.145629 12.5604 0.439817C13.1465 1.02571 13.1465 1.97537 12.5606 2.56126Z"
                    fill="white"
                  />
                </svg>
              </button>
            </div>
            {show && (
              <div className={'text-left'}>
                <div className={'bg-cleverGray-500 rounded-lg mt-3 glow-shadow'}>
                  <div className={'font-semibold text-2xl xs:text-3xl bg-custom-primary rounded-t-lg py-0.5 px-2'}>
                    Voucher
                  </div>
                  <div className={'p-2'}>
                    <div className={'flex'}>
                      <div style={{flex: 1}}>
                        <div className={'text-xl xs:text-3xl my-4'}>
                          {pickedDrug.drugName}
                          <br />
                          {pickedDrug.strengthItem}, {pickedDrug.displayQuantity} {pickedDrug.form}
                        </div>
                      </div>
                      {!retrievedPrice.uncPrice ||
                      !retrievedPrice.percentSavings ||
                      retrievedPrice.uncPrice <= retrievedPrice.price ? (
                        <div className={'text-2xl text-green-300 xs:text-4xl mr-2 mt-4'}>{modalVoucher.price}</div>
                      ) : (
                        <div className={'text-2xl xs:text-4xl mr-2 mt-4'}>
                          <div
                            className={'text-cleverGray-200 text-xl xs:text-2xl line-through'}
                            style={{textDecorationThickness: '1.5px'}}
                          >
                            ${retrievedPrice.uncPrice}
                          </div>{' '}
                          <div className={'text-3xl xs:text-4xl text-green-300'}>{modalVoucher.price}</div>{' '}
                          <div className={'text-green-300 text-lg xs:text-xl'}>
                            save {retrievedPrice.percentSavings}%
                          </div>
                        </div>
                      )}
                    </div>
                    <div className={'text-lg xs:text-xl'}>
                      <div className={'flex flex-wrap px-2'}>
                        <span className={'text-custom-primary mr-2'}>BIN:</span> {modalVoucher.bin}
                      </div>
                      <div className={'flex flex-wrap px-2'}>
                        <span className={'text-custom-primary mr-2'}>PCN:</span> {modalVoucher.pcn}
                      </div>
                      <div className={'flex flex-wrap px-2'}>
                        <span className={'text-custom-primary mr-2'}>Group:</span> {customization.groupCode}
                      </div>
                      <div className={'flex flex-wrap px-2'}>
                        <span className={'text-custom-primary mr-2'}>Member ID:</span> {customization.memberId}
                      </div>
                      <div className={'border-t border-cleverGray-400 pt-2 mt-2 px-2'}>
                        <div className={'flex flex-wrap justify-between'}>
                          <span className={'text-custom-primary mr-2'}>Pharmacist helpline:</span>{' '}
                          {modalVoucher.pharmacistPhoneNumber}
                        </div>
                        <div className={'flex flex-wrap justify-between'}>
                          <span className={'text-custom-primary mr-2'}>Member Services:</span>{' '}
                          {modalVoucher.questionsNumber}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={'text-center mb-4 mt-5 text-base'}>
                  {retrievedPrice.pharmacyType !== 'Other' ? (
                    <div>
                      Simply show this to your pharmacist at <b>{retrievedPrice.pharmacy}</b> to receive your discount!
                    </div>
                  ) : (
                    <div>
                      Simply show this to your pharmacist at <b>{retrievedPrice.pharmacy}</b> at{' '}
                      <b>{retrievedPrice.street1}</b> to receive your discount!
                    </div>
                  )}
                </div>
                <VoucherSurvey price={retrievedPrice} />
              </div>
            )}
            {showForm ? (
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                }}
              >
                <label className={'block w-full text-left my-5'}>
                  <div className={'font-semibold text-lg ml-1 mb-0.5'}>
                    {showForm === 'text' ? 'phone number' : 'email address'}
                  </div>
                  {showForm === 'text' ? (
                    <input
                      ref={phoneNumberRef}
                      className="clh-input text-base bg-custom-foreground rounded-2xl w-full block p-4 placeholder-cleverGray-200 text-custom-text"
                      value={phoneNumber}
                      onChange={(e) => setPhoneNumber(TextFormatter.format(TextFormats.phoneNumber, e.target.value))}
                    />
                  ) : (
                    <input
                      ref={emailRef}
                      className="clh-input text-base bg-custom-foreground rounded-2xl w-full block p-4 placeholder-cleverGray-200 text-custom-text"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  )}
                </label>
                <PromiseButton
                  isSubmit
                  overrideClassName={'big-clever-btn relative w-full p-2.5 mb-3 text-base'}
                  onClick={async () => {
                    await onSendVoucher(showForm);
                  }}
                >
                  send voucher
                </PromiseButton>
                <button
                  className="btn bg-transparent border-2 hover:border-cleverGray-200 text-custom-dropdown-text border-white font-semibold p-2.5 text-base rounded-full w-full"
                  onClick={(e) => {
                    e.stopPropagation();
                    setShowForm('');
                  }}
                >
                  back
                </button>
              </form>
            ) : (
              <div className="md:flex items-center mt-10">
                <button
                  className="clever-btn mb-3 font-semibold p-2.5 text-base rounded-full w-full md:mr-3"
                  onClick={(e) => {
                    e.stopPropagation();
                    setShowForm('text');
                  }}
                >
                  <svg className={'w-6 h-6 fill-current mr-2'} viewBox="0 0 16 16">
                    <path d="M14 1a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H4.414A2 2 0 0 0 3 11.586l-2 2V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12.793a.5.5 0 0 0 .854.353l2.853-2.853A1 1 0 0 1 4.414 12H14a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z" />
                  </svg>
                  <span className={'w-40 md:w-auto'}>Text Voucher</span>
                </button>
                <button
                  className="btn bg-transparent border-2 text-custom-dropdown-text border-custom-dropdown-text p-2.5 text-base rounded-full w-full mb-3 md:mr-3"
                  onClick={(e) => {
                    e.stopPropagation();
                    setShowForm('email');
                  }}
                >
                  <svg className={'w-6 h-6 fill-current mr-2'} viewBox="0 0 16 16">
                    <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4Zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2Zm13 2.383-4.708 2.825L15 11.105V5.383Zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741ZM1 11.105l4.708-2.897L1 5.383v5.722Z" />
                  </svg>
                  <span className={'w-40 md:w-auto'}>Email Voucher</span>
                </button>
                {!!modalVoucher && (
                  <>
                    <div className={'w-full mb-3'}>
                      <ReactToPrint
                        trigger={() => (
                          <button className="btn bg-transparent border-2 text-custom-dropdown-text border-custom-dropdown-text p-2.5 text-base rounded-full w-full">
                            <svg className={'w-6 h-6 fill-current mr-2'} viewBox="0 0 16 16">
                              <path d="M2.5 8a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1z" />
                              <path d="M5 1a2 2 0 0 0-2 2v2H2a2 2 0 0 0-2 2v3a2 2 0 0 0 2 2h1v1a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2v-1h1a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2h-1V3a2 2 0 0 0-2-2H5zM4 3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v2H4V3zm1 5a2 2 0 0 0-2 2v1H2a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1h-1v-1a2 2 0 0 0-2-2H5zm7 2v3a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1z" />
                            </svg>
                            <span className={'w-40 md:w-auto'}>Print Voucher</span>
                          </button>
                        )}
                        content={() => printRef.current}
                      />
                    </div>
                    <div className="d-none d-print-block">
                      <div ref={printRef}>
                        <PrintVoucher
                          modalVoucher={{
                            ...modalVoucher,
                            group: customization.groupCode,
                            memberId: customization.memberId,
                          }}
                        />
                      </div>
                    </div>
                  </>
                )}
              </div>
            )}
            {show && (
              <>
                <div className={'flex justify-center'}>
                  <div onClick={onOpenMap} className={'font-semibold my-5 text-base cursor-pointer'}>
                    View <span className={'underline font-bold'}>{modalVoucher.pharmacy}</span> on the map
                  </div>
                </div>
                <div className={'block text-left p-2 shadow-lg rounded-md border border-custom-foreground'}>
                  IMPORTANT:
                  <br />
                  Clever RX is contracted with {modalVoucher.pharmacy}. Some pharmacy employees may NOT know this. If
                  you have any issues with this voucher call {retrievedPrice.coupon.memberPhoneNumber} immediately. They
                  will help you!
                </div>
              </>
            )}
          </div>
        </div>
      </ModalContainer>
    );
  }
);
