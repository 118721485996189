import React from 'react';

export function Badges() {
  function installIos() {
    window.location.href = 'https://apps.apple.com/us/app/clever-rx/id1443448353?mt=8';
  }
  function installAndroid() {
    window.location.href = 'http://play.google.com/store/apps/details?id=com.ventures317.cleverrx';
  }
  return (
    <div className={'row badges'}>
      <button onClick={installIos} className={'badge-button'}>
        <img src={'/static/images/badges/apple.svg'} className={'install-badge'} alt={'Install with Apple'} />
      </button>
      <button onClick={installAndroid} className={'badge-button'}>
        <img
          onClick={installAndroid}
          src={'/static/images/badges/google.png'}
          className={'install-badge'}
          alt={'Install with Android'}
        />
      </button>
    </div>
  );
}
