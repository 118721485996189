import React from 'react';
export const bashas = (
  <svg
    enableBackground="new 0 0 2500 664"
    version="1.1"
    viewBox="0 0 2500 664"
    xmlSpace="preserve"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g fill="currentColor">
      <path d="m614.4 327.6s115.5-36.7 123.4-180.8c7.1-129.2-217.9-207.7-391.5 51-96.1 143.2-179.4 390-225.1 390-16 0-14 28.1 3.8 28 17.8-0.2 121.1 0.1 138.9 0 17.8-0.2 23.1-7.8 38.9-32.9 15.8-25 152-276.2 207.4-377.3 64.2-117.3 112.6-69.6 104.8-45.5-3.9 11.9-20.6 111.9-127.3 132.7-28.5 5.6-33.5 44.8-8.4 67.7 25.1 23 48.4 35.3 48.7 83.4 0.4 48.1-36.9 71.5-60 71.7-23.2 0.2-50-17.5-62.6-29.8-12.6-12.4-28.8-5.3-28.5 5.6 3.8 151.4 290.2 218.7 302.5-39.6 4.1-83.7-65-124.2-65-124.2z" />
      <path d="m1273 508c-0.5-73-49.1-135-49.5-193.8s-34-22.9-46.4-13.9-30.1 23.4-60.4 21.8c0 0-72 139.5-91.4 171.7s-35.6 9.2-14.5-33.7c13.7-27.9 65-135.2 65-135.2h-115.7l-19.5 28.8s-9.2-39.1-73.3-38.6-189.9 94-188.9 225.8c0.9 121.1 126.9 57.9 146.4 41.7s21.3-7.3 30.3 5.1 132.4 88.1 193.7-62c27.7-67.9 54.3-121.5 82.7-136 0 0 7.8 33.3 19.2 59.5 12.7 29.1 3 54.5-23.7 54.5-9.8 0-23.2-3.4-30.4-12.2-7.2-8.9-21.5-12.3-21.3 7.3 0.1 19.6-9.8 117.6 88.1 116.9 98-0.8 109.9-70.3 109.6-107.7zm-429.2-0.4c-24.1 0-25.1-19.4-25.2-35.4s31.4-94.6 84.8-95c14.3-0.1 17.9 12.3 18 23 0 10.6-31.3 107.4-77.6 107.4z" />
      <path d="m2327.1 206.3c-41 0.3-46.2 19.9-46 43.1 0.3 35.6 42.9 21.1 19.9 44.4-15.9 16.1-33.5 46.3-33.5 46.3h76.6s46-39.8 45.6-95c-0.3-33.9-39.5-39-62.6-38.8z" />
      <path d="m2224.6 317.5c-0.4-58.8-34-22.9-46.4-13.9s-30.1 23.4-60.4 21.8c0 0-72 139.5-91.3 171.7-19.4 32.2-35.6 9.2-14.5-33.7 13.7-27.9 65-138.5 65-138.5h-115.8l-19.5 32.2s-8.8-37.4-69.1-38.6l1.1-1.9c-98 0.7-191.4 133.2-226.6 181.6-21.3 29.1-30.3 2-23.3-14.1s49.2-96.5 48.9-126.8c-0.2-30.3-32.4-49.6-64.5-49.4s-81.7 29.1-81.7 29.1 84.3-159.1 98.4-187.8c14-28.6 38.6-71.5 54.7-71.6 16-0.1 17.7-19.4-0.2-19.2s-32.1-0.4-92.6 0c-39.2 0.3-44.4 14.9-88.3 100.7s-216.1 400.6-233.8 418.5c-7.3 7.4-26.5 30.1-1.6 29.9 25-0.2 78.4 0.2 96.2 0 17.8-0.1 35.5-11.3 56.6-48.8 21.1-37.6 74.7-138.9 82.6-152 14.1-23.3 62-41.4 39.2 6.8-22.8 48.3-59.2 128.4-66.6 146.6-8.7 21.4 56.1 122.5 178.2 5.8 12.1-11.6 23.1-5.5 30.3 8.7 3.9 7.7 8 20.9 21.1 30.2 37 31.4 110.6-6.8 125.1-18.8 19.5-16.2 21.3-7.3 30.3 5.1s117.4 93.6 193.7-62c32.3-65.8 54.3-121.5 82.7-136 0 0 10.9 33.6 20.8 58.7 11.6 29.5 1.4 55.4-25.3 55.4-11.6 0-23.2-3.4-30.3-12.2-7.2-8.8-21.5-12.3-21.3 7.3s-9.8 117.6 88.1 116.9c98-0.7 109.9-70.3 109.6-107.7-0.5-73.2-49.1-135.2-49.5-194zm-379.8 193.4c-27.2 0-25.1-19.4-25.2-35.4s31.3-94.6 84.8-95c14.3-0.1 17.9 12.3 18 23 0.1 10.6-31.3 107.4-77.6 107.4z" />
    </g>
  </svg>
);
