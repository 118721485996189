import React from 'react';
import {Link} from 'react-router-dom';
import {Button} from 'reactstrap';

const CardImage = () => (
  <img
    alt="Digital Card"
    title="Digital Card"
    className="img-fluid"
    src="/static/images/card.png"
    srcSet="/static/images/card.png,
            /static/images/card@2x.png 2x,
            /static/images/card@3x.png 3x"
  />
);

const TopSection = ({title, subtitle, joinUsButton}) => (
  <section className="top text-center">
    <div className="img-wrap">
      <CardImage />
    </div>
    <div className="text-wrap">
      <h1>{title}</h1>
      <h3>{subtitle}</h3>
      {joinUsButton && (
        <Button tag={Link} to="/for-partners" size="lg" color="primary" block>
          I Want to be Clever Too
        </Button>
      )}
    </div>
    <style jsx>{`
      .top {
        padding: 0 0 30px 0;
      }
      .img-wrap {
        padding: 0 30px;
        background: 1px solid #fff;
        position: relative;
        z-index: 1;
        width: 100%;
      }
      .img-wrap:before {
        background: #24292d;
        top: 25%;
        content: '';
        display: block;
        height: 100%;
        left: 0;
        position: absolute;
        right: 0;
        transform: skewY(-11deg);
        transform-origin: 100% 0;
        z-index: -1;
        -webkit-backface-visibility: hidden;
        padding-bottom: 100%;
      }
      .text-wrap {
        padding: 0 20px 30px 20px;
        position: relative;
        background: #24292d;
        z-index: 20;
      }
      .text-wrap:after {
        content: '';
        background: #24292d;
        bottom: -100%;
        display: block;
        height: 100%;
        width: 100%;
        left: 0;
        position: absolute;
        right: 0;
        z-index: -10;
      }
      .top :global(img) {
        margin: 0 auto 15px auto;
        position: relative;
        z-index: 2;
      }
      h1 {
        margin: 0 auto;
        font-weight: 300;
        width: 100%;
        max-width: 990px;
        font-size: 30px;
        line-height: 1.4;
        position: relative;
        z-index: 2;
      }
      h3 {
        margin: 0 auto 20px auto;
      }
      .top :global(.btn) {
        max-width: 300px;
        margin: 0 auto;
      }

      @media (min-width: 768px) {
        .top {
          border: none;
        }
        .img-wrap:before {
          top: 15%;
          padding-bottom: 50%;
        }
      }
      @media (min-width: 990px) {
        .img-wrap:before {
          top: 0;
        }
      }
      @media (min-width: 1200px) {
        .img-wrap:before {
          top: 0;
        }
        .text-wrap {
          background: transparent;
        }
      }
    `}</style>
  </section>
);

export default TopSection;
