import React from 'react';
import {Config} from '../../config';

const LogoPrint = () => <img alt="Clever RX logo" src="/static/images/logo-print.svg" className="img-fluid" />;

class PrintCard extends React.Component {
  render() {
    const {card} = this.props;
    return (
      <section className="print-card d-print-block">
        <div className="top-card">
          <LogoPrint />
          <div className="text-primary text-uppercase">PHARMACY SAVINGS CARD</div>
          <div className="text-norm">Group: {card.group_number}</div>
          <div className="text-norm">Member ID: {card.member_id}</div>
          <div className="text-norm">RxBIN: {Config.rxBin}</div>
          <div className="text-norm">PCN: {Config.pcn}</div>
          <div className="not-ins text-uppercase">THIS CARD IS NOT INSURANCE</div>
          <div className="accepted">Accepted at all major pharmacies</div>
        </div>
        <div className="bottom-card text-right">
          <div className="text-uppercase company-name">{card.company_name}</div>
          <div className="discount">Get huge discounts on tons of RX drugs.</div>
          <div className="phone">Pharmacist Help Line: (800) 974-3135</div>
          <div className="phone">Customer Help Line: (800) 873-1195</div>
          <div className="text-uppercase info">MORE INFO AT CLEVERRX.COM</div>
        </div>
        <style jsx>{`
          @import url('https://fonts.googleapis.com/css?family=Nunito:300,400,600,700');

          .print-card {
            width: 100%;
            height: 100%;
            background: transparent;
            padding: 20px;
            font-family: Nunito, -apple-system, Roboto, 'Helvetica Neue', Arial, sans-serif;
          }
          .print-card :global(svg) {
            width: 258px;
            height: 43px;
            display: block;
            margin: 0;
            padding: 0;
          }
          .top-card,
          .bottom-card {
            color: #2a2d30;
            width: 540px;
            height: 324px;
            margin: 40px auto;
            display: block;
            padding: 30px 50px;
            letter-spacing: 0.2px;
            line-height: normal;
          }
          .top-card div,
          .bottom-card div {
            display: block;
            width: 100%;
          }
          .top-card {
            background: url('/static/images/front-background.svg') no-repeat;
            background-size: cover;
          }
          .bottom-card {
            color: white;
            background: url('/static/images/back-background.svg') no-repeat;
            background-size: cover;
          }
          .text-primary {
            font-size: 18px;
            letter-spacing: 0.4px;
            color: #f6a300;
            font-weight: 400;
            margin-top: 6px;
            margin-bottom: 12px;
          }
          .text-norm {
            font-size: 16px;
            font-weight: 300;
            color: #2a2d30;
            display: block;
            width: 100%;
          }
          .not-ins {
            color: #8f8c8f;
            font-size: 8px;
            font-weight: 600;
            margin: 18px 0 22px 0;
          }
          .accepted {
            color: #2a2d30;
            font-size: 12px;
            font-weight: 700;
          }
          .company-name {
            font-size: 14px;
            margin-top: 60px;
            font-weight: 700;
            color: white;
          }
          .discount {
            font-size: 14px;
            font-weight: 700;
            margin-bottom: 16px;
            color: white;
          }
          .phone {
            font-size: 14px;
            font-weight: 400;
          }
          .info {
            font-size: 14px;
            font-weight: 700;
            margin-top: 16px;
            color: #f6a300;
          }
        `}</style>
      </section>
    );
  }
}

export default PrintCard;
