import React from 'react';
import {ToastContainer as ToastifyContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './toastContainer.css';

export const ToastContainer = () => (
  <ToastifyContainer
    position={'bottom-right'}
    hideProgressBar
    theme={'colored'}
    icon={false}
    draggable={false}
    autoClose={5000}
    toastClassName={'custom-toast-style'}
  />
);
