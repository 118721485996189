// import Head from 'next/head'
import React from 'react';
import Layout from '../components/Layout';
import OpenGraphMeta from '../components/OpenGraphMeta';
import {Container} from 'reactstrap';

export const StripeExpired = (props) => {
  return (
    <Layout headerStyle="card">
      <OpenGraphMeta title={'Clever RX'} />
      <div className="error-block">
        <Container className="text-center">
          <h1>Stripe Link Expired</h1>
          <p>Sorry, this link has expired. Please email <a href={'mailto:partnersuccess@cleverrx.com'}>partnersuccess@cleverrx.com</a> or call <a href={'tel:14803868907'}>(480) 386-8907</a> to obtain a new link.</p>
        </Container>
      </div>
    </Layout>
  );
};
