/*
import { configure } from "mobx";
import { create } from "mobx-persist";
*/
import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter, Route, Switch} from 'react-router-dom';
import './styles/app.scss';
import Home from './pages/home';
import ScrollToTop from './components/scrollToTop';
// import {stores} from './store/stores';

import PrivacyPolicy from './pages/privacy-policy';
import Terms from './pages/terms';
import DrugPriceLookup from './pages/drug-price-lookup';
import Card from './pages/card';
import ForPartners from './pages/for-partners';
import Register from './pages/contact';
import {StripeSuccess} from './pages/stripeSuccess';
import {StripeExpired} from './pages/stripeExpired';
import {AdDrugPriceLookup} from './pages/ad-drug-price-lookup';
import {ToastContainer} from './components/ToastContainer';

// configure({ enforceActions: "always" });
const rootElement = document.getElementById('root');
ReactDOM.render(
  <BrowserRouter>
    <ScrollToTop>
      <Switch>
        <Route exact component={PrivacyPolicy} path={'/privacy-policy'} />
        <Route exact component={Terms} path={'/terms'} />
        <Route exact component={Register} path={'/contact'} />
        <Route exact component={DrugPriceLookup} path={'/drug-price-lookup'} />
        <Route exact component={AdDrugPriceLookup} path={'/discounts'} />
        <Route exact component={StripeExpired} path={'/stripe-expired'} />
        <Route exact component={StripeSuccess} path={'/stripe-success'} />
        <Route exact component={ForPartners} path={'/for-partners'} />
        <Route exact component={Home} path="/" />
        <Route exact component={Home} path="/index.html" />
        <Route component={Card} path={'/:urlSlug'} />
      </Switch>
    </ScrollToTop>
    <ToastContainer />
  </BrowserRouter>,
  rootElement
);
