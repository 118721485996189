import {DrugPriceV2} from '../dataServices/app.generated';
import {DrugUtils} from '../utils/drugUtils';
import {images} from '../assets/pharmacy-logos';
import {PromiseButton} from './PromiseButton';
import React, {useRef} from 'react';
import {useScrollIntoView} from '../hooks/useScrollIntoView';
import PapLogo from '../assets/pap-logo.png';

export const VoucherCard = ({
  loadingVoucher,
  price,
  isShown,
  onShowVoucher,
  className,
  noDesc,
}: {
  loadingVoucher: boolean;
  price: DrugPriceV2;
  isShown: boolean;
  onShowVoucher: () => void;
  className?: string;
  noDesc?: boolean;
}) => {
  const street = price.street2 ? `${price.street1} ${price.street2},` : `${price.street1},`;
  const pharmacyName = DrugUtils.cleanPharmacyName(price.pharmacy);
  const desc = noDesc ? '' : DrugUtils.couponDescription(price);
  const cardRef = useRef<HTMLDivElement>(null);
  const {isVisible} = useScrollIntoView(cardRef);
  return (
    <div
      className={`bg-custom-dropdown rounded-3xl p-2 ${!isShown ? 'hidden' : 'flex'} ${
        desc ? 'mb-10' : ''
      } flex-col relative overflow-hidden ${className ?? ''} fade-in-section ${isVisible ? 'fade-is-visible' : ''}`}
      ref={cardRef}
    >
      {!!desc && (
        <div
          className={
            'absolute top-0 p-1 px-4 text-left left-0 w-full font-semibold bg-gradient-to-b from-custom-primary to-custom-accent'
          }
        >
          {desc}
        </div>
      )}
      <div className={`flex items-center ${desc ? 'mt-8' : 'mt-2'}`}>
        <div
          className={
            'mr-2 bg-custom-icon rounded-full w-14 h-14 flex-shrink-0 p-2 flex justify-center items-center RxLookup__pharmacyImg'
          }
        >
          {images[`${pharmacyName}`]}
        </div>
        <div className={'font-semibold px-2 text-left flex-1'}>{price.pharmacy}</div>
        <div className={'flex flex-col items-end'}>
          {price.uncPrice > 0 && price.uncPrice !== price.price && (
            <div
              className={'font-semibold text-lg text-cleverGray-200 line-through'}
              style={{textDecorationThickness: '1.5px'}}
            >
              {price.uncPrice.toLocaleString('US', {
                style: 'currency',
                currency: 'USD',
              })}
            </div>
          )}
          <div className={'font-bold text-xl text-green-300 whitespace-nowrap flex-shrink-0 pr-1'}>
            {price.price.toLocaleString('US', {
              style: 'currency',
              currency: 'USD',
            })}
          </div>
        </div>
      </div>
      <div className={'font-semibold text-base text-custom-primary my-2 px-2 mt-auto'}>
        {pharmacyName !== 'other' ? (
          'Valid at all locations'
        ) : (
          <>
            {street} {price.city} {price.state} {price.zipCode}
          </>
        )}
      </div>
      <PromiseButton
        overrideClassName={'relative rounded-full font-bold w-full px-5 clever-btn py-1.5'}
        disabled={loadingVoucher}
        onClick={onShowVoucher}
      >
        Claim Voucher
      </PromiseButton>
    </div>
  );
};

export const PapCard = ({
  onShow,
  papResult,
  isLowestPrice,
}: {
  onShow: () => void;
  papResult: {isEligible: boolean; papPrice: string};
  isLowestPrice: boolean;
}) => {
  const cardRef = useRef<HTMLDivElement>(null);
  const {isVisible} = useScrollIntoView(cardRef);
  return (
    <div
      className={`lg:max-w-[500px] bg-custom-dropdown rounded-3xl p-2 flex mb-10 flex-col relative overflow-hidden glow-shadow fade-in-section ${
        isVisible ? 'fade-is-visible' : ''
      } ${isLowestPrice ? '' : 'mx-auto'}`}
      ref={cardRef}
    >
      <div
        className={
          'absolute top-0 p-1 px-4 text-left left-0 w-full font-semibold bg-gradient-to-b from-custom-primary to-custom-accent'
        }
      >
        {isLowestPrice ? 'lowest price' : 'patient assistance'}
      </div>
      <div className={`flex items-center mt-12 mb-4`}>
        <div
          className={
            'mr-2 bg-custom-icon rounded-full w-14 h-14 flex-shrink-0 p-3 flex justify-center items-center RxLookup__pharmacyImg'
          }
        >
          <img src={PapLogo} alt={''} className={'w-full h-full'} />
        </div>
        <div className={'font-semibold px-2 text-left flex-1'}>Patient Assistance Program</div>
        <div className={'flex flex-col items-end'}>
          <div className={'font-bold text-xl text-green-300 whitespace-nowrap flex-shrink-0 pr-1'}>
            ${papResult.papPrice}
          </div>
        </div>
      </div>
      <PromiseButton
        overrideClassName={'relative rounded-full font-bold w-full px-5 clever-btn py-1.5'}
        onClick={onShow}
      >
        Get Started
      </PromiseButton>
    </div>
  );
};
