import React, {Component} from 'react';
import {Collapse, Button} from 'reactstrap';
import FAQ from '../home/FAQ';
import Pharmacies from '../home/Pharmacies';
import {Element} from 'react-scroll';

class BottomSectionCard extends Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = {collapse: false};
  }

  toggle() {
    this.setState({collapse: !this.state.collapse});
  }

  render() {
    return (
      <Element name="More">
        <div className="bottom d-print-none">
          <Collapse isOpen={this.state.collapse}>
            <Pharmacies />
            <FAQ />
          </Collapse>
          <Button color="primary" style={{display: 'block'}} block size="lg" onClick={this.toggle}>
            {this.state.collapse ? 'Less Info' : 'More Info'}
          </Button>

          <style jsx>{`
            .bottom {
              padding: 30px 20px 0 20px;
            }
            .bottom :global(.btn) {
              margin: 0 auto 0 auto;
              max-width: 780px;
            }
            @media (min-width: 768px) {
              .bottom {
                margin: 80px auto 0 auto;
              }
            }
          `}</style>
        </div>
      </Element>
    );
  }
}

export default BottomSectionCard;
