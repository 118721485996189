import React from 'react';
import {Row, Col, Container} from 'reactstrap';
import {Badges} from '../badges';

const Phone = () => (
  <img
    alt="Clever RX Phone app"
    title="Clever RX Phone app"
    className="img-fluid"
    src="/static/images/phone.png"
    srcSet="/static/images/phone.png,
            /static/images/phone@2x.png 2x,
            /static/images/phone@3x.png 3x"
  />
);

const PhoneCTA = () => (
  <section className="phone-cta">
    <Container fluid>
      <Row>
        <Col xs="12" md="6">
          <Phone />
        </Col>

        <Col xs="12" md="6">
          <h3>Clever RX Mobile App Is Now Available On iOS And Android</h3>
          <h4>Help your patients and employees save thousands while staying healthy.</h4>
          <ul>
            <li>Unlock the lowest prices around you</li>
            <li>One place for all your prescriptions</li>
            <li>Notifications for potential drug interactions</li>
            <li>Independent drug reviews</li>
            <li>Alternative drug recommendations</li>
            <li>Unlock exclusive savings on health and wellness products you already buy</li>
          </ul>
          <Badges />
        </Col>
      </Row>
    </Container>
    <style jsx>{`
      .phone-cta {
        position: relative;
        z-index: 1;
        padding: 60px 30px;
        overflow: hidden;
      }
      .phone-cta:before {
        background: #24292d;
        top: 15%;
        content: '';
        display: block;
        height: 100%;
        left: 0;
        position: absolute;
        right: 0;
        transform: skewY(-11deg);
        transform-origin: 100% 0;
        z-index: -1;
        -webkit-backface-visibility: hidden;
        padding-top: 100%;
      }
      .phone-cta :global(.row) {
        align-items: center;
      }
      h3 {
        font-size: 26px;
        font-weight: 600;
        text-transform: uppercase;
        line-height: 1.42;
        letter-spacing: 3.6px;
        padding: 30px 0 20px 0;
        margin: 0;
        text-align: center;
      }
      @media (min-width: 768px) {
        .phone-cta {
          margin-top: 0;
        }
        .phone-cta:before {
          top: 0;
        }
        h3 {
          text-align: left;
          margin-top: 60px;
        }
      }
    `}</style>
  </section>
);

export default PhoneCTA;
