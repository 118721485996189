import React from 'react';

const ModalCloseIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40">
    <g fill="none" fillRule="evenodd">
      <path fill="#FFA500" d="M0 0h30c5.523 0 10 4.477 10 10v30H10C4.477 40 0 35.523 0 30V0z" />
      <g stroke="#1E2126" strokeLinecap="round" strokeWidth="4">
        <path d="M14.815 14.815l10.37 10.37M14.815 25.185l10.37-10.37" />
      </g>
    </g>
  </svg>
);

export default ModalCloseIcon;
