export const FormCheckbox = ({isChecked, onClick}: {isChecked: boolean; onClick?: () => void}) => {
  return (
    <div
      className={`cursor-pointer rounded shadow-sm border w-6 h-6 flex items-center justify-center text-white transition-colors duration-200 ${
        isChecked ? 'bg-primary-500 border-primary-600' : 'bg-transparentBlack border-cleverGray-250'
      }`}
      onClick={(e) => {
        e.stopPropagation();
        !!onClick && onClick();
      }}
    >
      {isChecked && (
        <svg className={''} width={16} height={16} fill="currentColor" viewBox="0 0 16 16">
          <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
        </svg>
      )}
    </div>
  );
};
