export class RxValetUtils {
  static get bestTimesToCall() {
    return [
      {value: '07 : 00 AM', label: '07 : 00 AM'},
      {value: '07 : 30 AM', label: '07 : 30 AM'},
      {value: '08 : 00 AM', label: '08 : 00 AM'},
      {value: '08 : 30 AM', label: '08 : 30 AM'},
      {value: '09 : 00 AM', label: '09 : 00 AM'},
      {value: '09 : 30 AM', label: '09 : 30 AM'},
      {value: '10 : 00 AM', label: '10 : 00 AM'},
      {value: '10 : 30 AM', label: '10 : 30 AM'},
      {value: '11 : 00 AM', label: '11 : 00 AM'},
      {value: '11 : 30 AM', label: '11 : 30 AM'},
      {value: '12 : 00 PM', label: '12 : 00 PM'},
      {value: '12 : 30 PM', label: '12 : 30 PM'},
      {value: '01 : 00 PM', label: '01 : 00 PM'},
      {value: '01 : 30 PM', label: '01 : 30 PM'},
      {value: '02 : 00 PM', label: '02 : 00 PM'},
      {value: '02 : 30 PM', label: '02 : 30 PM'},
      {value: '03 : 00 PM', label: '03 : 00 PM'},
      {value: '03 : 30 PM', label: '03 : 30 PM'},
      {value: '04 : 00 PM', label: '04 : 00 PM'},
      {value: '04 : 30 PM', label: '04 : 30 PM'},
      {value: '05 : 00 PM', label: '05 : 00 PM'},
      {value: '05 : 30 PM', label: '05 : 30 PM'},
      {value: '06 : 00 PM', label: '06 : 00 PM'},
    ];
  }

  static get annualIncomeLevels() {
    return [
      {value: '$1 to $5,000', label: '$1 to $5,000'},
      {value: '$5,001 to $10,000', label: '$5,001 to $10,000'},
      {value: '$10,001 to $15,000', label: '$10,001 to $15,000'},
      {value: '$15,001 to $20,000', label: '$15,001 to $20,000'},
      {value: '$20,001 to $25,000', label: '$20,001 to $25,000'},
      {value: '$25,001 to $30,000', label: '$25,001 to $30,000'},
      {value: '$30,001 to $35,000', label: '$30,001 to $35,000'},
      {value: '$35,001 to $40,000', label: '$35,001 to $40,000'},
      {value: '$40,001 to $45,000', label: '$40,001 to $45,000'},
      {value: '$45,001 to $50,000', label: '$45,001 to $50,000'},
      {value: '$50,001 to $55,000', label: '$50,001 to $55,000'},
      {value: '$55,001 to $60,000', label: '$55,001 to $60,000'},
      {value: '$60,001 to $65,000', label: '$60,001 to $65,000'},
      {value: '$65,001 to $70,000', label: '$65,001 to $70,000'},
      {value: '$70,001 to $75,000', label: '$70,001 to $75,000'},
      {value: '$75,001 to $80,000', label: '$75,001 to $80,000'},
      {value: '$80,001 to $85,000', label: '$80,001 to $85,000'},
      {value: '$85,001 to $90,000', label: '$85,001 to $90,000'},
      {value: '$90,001 to $95,000', label: '$90,001 to $95,000'},
      {value: '$95,001 to $100,000', label: '$95,001 to $100,000'},
      {value: '$100,000+', label: '$100,000+'},
    ];
  }

  static get householdMembers() {
    return [
      {value: '1', label: '1'},
      {value: '2', label: '2'},
      {value: '3', label: '3'},
      {value: '4', label: '4'},
      {value: '5', label: '5'},
      {value: '6', label: '6'},
      {value: '7', label: '7'},
      {value: '8', label: '8'},
      {value: '9', label: '9'},
      {value: '10', label: '10'},
    ];
  }
}
