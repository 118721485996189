import React from 'react';

export const albertsons = (
  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    width="650.000000pt"
    height="650.000000pt"
    viewBox="0 0 650.000000 650.000000"
    preserveAspectRatio="xMidYMid meet"
  >
    <g transform="translate(0.000000,650.000000) scale(0.100000,-0.100000)" fill="currentColor" stroke="none">
      <path
        d="M2600 5413 c-8 -4 -28 -20 -44 -37 -94 -100 -938 -1092 -1314 -1546
-57 -69 -123 -147 -147 -175 -23 -27 -116 -138 -206 -245 -89 -107 -182 -217
-206 -243 -62 -70 -108 -151 -135 -237 -29 -96 -32 -270 -5 -370 25 -93 89
-216 145 -278 l47 -53 557 3 557 3 -55 85 c-96 151 -130 302 -101 461 24 139
55 191 265 447 318 389 982 1182 1252 1496 53 61 107 125 120 142 40 50 329
391 397 468 35 39 63 74 63 78 0 9 -1168 9 -1190 1z"
      />
      <path
        d="M3805 5293 c-55 -65 -112 -131 -127 -148 -16 -16 -26 -36 -23 -43 3
-7 1 -11 -4 -8 -5 3 -42 -33 -82 -82 -41 -48 -113 -133 -162 -189 -53 -61 -85
-108 -81 -114 4 -7 3 -9 -3 -6 -6 4 -16 0 -23 -7 -9 -11 -9 -20 1 -38 7 -13
15 -25 18 -28 3 -3 31 -34 61 -70 30 -36 57 -67 60 -70 3 -3 23 -27 45 -55 22
-27 74 -88 115 -136 111 -127 163 -189 185 -219 11 -15 30 -38 42 -51 12 -13
105 -123 206 -244 100 -121 190 -229 199 -240 10 -11 47 -56 83 -100 37 -44
71 -79 77 -77 5 1 7 -2 4 -7 -4 -5 8 -27 26 -48 18 -21 38 -43 43 -50 6 -6 30
-37 55 -68 25 -32 59 -74 76 -94 119 -138 167 -258 167 -416 0 -149 -31 -245
-127 -395 l-35 -55 552 -3 551 -2 34 32 c113 109 184 290 184 473 1 168 -46
298 -150 417 -68 78 -177 208 -213 254 -14 18 -45 55 -70 82 -24 27 -89 105
-144 172 -55 67 -181 220 -280 339 -99 119 -206 249 -239 289 -32 39 -62 70
-66 67 -5 -3 -12 0 -16 6 -5 8 -3 9 6 4 21 -13 8 9 -37 61 -40 47 -108 129
-378 459 -66 81 -145 177 -175 214 -30 37 -68 85 -85 106 -35 45 -142 174
-159 193 -10 10 -32 -11 -111 -105z"
      />
      <path
        d="M3175 4339 c-28 -41 -83 -121 -123 -178 -88 -127 -166 -279 -182
-357 -17 -79 -8 -223 18 -304 25 -76 100 -211 185 -330 33 -47 82 -120 108
-163 40 -65 48 -74 54 -57 4 12 63 105 133 208 177 263 222 369 222 527 0 169
-50 274 -291 617 -30 42 -59 84 -64 93 -9 14 -18 5 -60 -56z"
      />
      <path
        d="M2239 3313 l-116 -4 -7 -46 c-27 -199 18 -346 155 -504 102 -118 219
-172 409 -190 323 -30 352 -37 426 -102 33 -29 58 -65 84 -119 l36 -77 29 67
c38 87 102 156 169 180 55 21 165 37 315 47 198 14 309 61 427 182 81 84 145
196 164 289 12 57 9 241 -4 262 -10 16 -361 25 -455 12 -176 -24 -307 -84
-428 -194 -72 -66 -120 -134 -178 -248 -21 -43 -40 -78 -41 -78 -2 0 -29 51
-61 113 -48 94 -70 125 -138 192 -44 44 -106 95 -138 114 -121 71 -297 113
-455 109 -42 -1 -129 -4 -193 -5z"
      />
      <path
        d="M620 2078 c-25 -14 -27 -22 -14 -84 6 -33 -28 -134 -176 -519 -39
-100 -70 -190 -70 -200 0 -11 7 -29 16 -40 21 -28 144 -29 171 -2 17 16 17 19
-1 51 -22 40 -18 71 21 186 41 120 41 120 73 80 26 -32 30 -33 115 -39 l89 -6
24 -80 c23 -75 23 -82 8 -108 -24 -42 -20 -71 11 -88 20 -11 77 -14 262 -14
207 0 240 2 269 18 39 21 40 33 6 78 l-26 34 3 370 4 370 -40 -1 c-45 -1 -214
-71 -222 -91 -3 -8 8 -32 23 -55 l29 -41 0 -266 c0 -146 -3 -278 -8 -293 -9
-32 -43 -46 -64 -26 -7 8 -31 82 -53 164 -21 82 -46 176 -55 209 -9 33 -27
101 -40 150 -56 209 -61 222 -86 236 -33 17 -240 22 -269 7z m148 -223 c6 -14
19 -63 29 -111 18 -84 18 -86 -2 -101 -15 -11 -35 -13 -85 -8 -36 3 -69 10
-74 15 -15 15 55 217 79 228 26 12 40 6 53 -23z"
      />
      <path
        d="M1600 2033 c-39 -21 -86 -50 -103 -64 l-31 -26 27 -29 27 -29 0 -255
c0 -151 -5 -271 -11 -295 -19 -67 -17 -97 6 -102 120 -27 319 -26 411 2 122
36 163 186 110 398 -28 110 -43 142 -89 191 -42 44 -75 47 -149 11 -28 -14
-58 -25 -65 -25 -10 0 -13 27 -13 114 0 63 -3 121 -6 130 -9 24 -35 19 -114
-21z m191 -336 c25 -19 37 -49 60 -148 27 -120 22 -184 -15 -216 -33 -28 -79
-30 -106 -3 -18 18 -20 33 -20 178 0 160 4 182 40 195 22 8 23 8 41 -6z"
      />
      <path
        d="M3280 1944 c-36 -30 -64 -60 -62 -65 1 -5 -1 -8 -5 -7 -5 1 -19 -3
-33 -9 -14 -6 -59 -14 -100 -18 -85 -8 -120 -23 -144 -60 -20 -31 -36 -24 -36
15 0 52 -23 63 -115 56 -103 -8 -123 -14 -131 -38 -4 -13 2 -27 20 -43 l26
-24 0 -198 c0 -180 -2 -202 -20 -238 -24 -47 -19 -80 12 -89 30 -8 177 -8 217
0 38 8 51 42 27 76 -13 18 -16 52 -16 185 0 154 1 163 23 188 27 32 93 47 163
37 l49 -7 2 -197 c2 -229 7 -246 78 -273 33 -13 64 -16 120 -13 112 7 134 38
68 96 l-38 32 -3 174 c-4 194 0 206 62 206 43 0 56 16 56 66 0 45 -10 54 -60
54 -38 0 -49 23 -43 84 6 49 -3 66 -37 66 -8 0 -44 -25 -80 -56z"
      />
      <path
        d="M6007 1939 c37 -11 62 -42 64 -79 1 -18 3 -20 6 -7 11 44 -37 99 -85
96 -9 0 -3 -5 15 -10z"
      />
      <path
        d="M5931 1916 c-16 -17 -21 -32 -18 -52 l4 -29 2 29 c0 17 11 40 23 53
12 13 19 23 16 23 -2 0 -15 -11 -27 -24z"
      />
      <path
        d="M6030 1882 c0 -7 -15 -12 -32 -13 -18 0 -26 -3 -18 -6 8 -3 23 -15
33 -27 16 -18 17 -19 9 -1 -8 14 -6 22 6 31 10 7 14 16 9 20 -4 4 -7 2 -7 -4z"
      />
      <path
        d="M3695 1870 c-72 -15 -134 -49 -152 -83 -9 -18 -18 -63 -20 -102 -7
-115 33 -162 184 -219 92 -35 123 -59 123 -96 0 -38 -25 -55 -79 -54 -70 3
-114 26 -151 79 -24 34 -37 45 -53 43 -28 -4 -42 -57 -33 -121 10 -63 39 -83
148 -97 126 -17 288 11 281 48 -2 6 3 9 11 6 20 -8 56 85 56 146 0 63 -22 113
-64 145 -31 24 -137 67 -168 68 -9 1 -15 3 -13 6 3 2 -5 9 -18 15 -12 7 -28
24 -35 39 -12 24 -11 32 3 53 14 22 22 25 72 22 70 -4 83 -19 63 -78 -12 -36
-12 -43 1 -51 24 -16 74 -7 73 13 -1 10 2 15 7 12 23 -14 59 58 59 116 0 48
-18 62 -105 84 -63 17 -126 19 -190 6z"
      />
      <path
        d="M4215 1865 c-27 -9 -55 -16 -62 -17 -7 -1 -26 -15 -42 -32 -43 -44
-63 -133 -64 -276 0 -183 41 -280 132 -312 40 -14 259 -18 303 -5 39 10 95 63
121 111 17 33 22 63 25 156 9 272 -66 379 -273 386 -61 3 -106 -1 -140 -11z
m186 -124 c28 -34 44 -127 44 -252 0 -131 -5 -143 -65 -159 -86 -23 -125 39
-136 217 -8 127 -2 171 28 202 18 20 28 22 67 18 30 -3 51 -12 62 -26z"
      />
      <path
        d="M5045 1866 c-22 -7 -62 -25 -88 -39 -38 -21 -51 -24 -62 -14 -7 6
-11 17 -8 23 2 6 -3 17 -12 24 -16 14 -95 9 -167 -11 -38 -10 -69 -46 -51 -58
31 -20 38 -60 41 -247 4 -178 2 -194 -17 -232 -20 -40 -20 -41 -2 -64 17 -21
29 -23 113 -26 139 -4 169 19 130 101 -14 31 -21 73 -25 162 -8 157 0 207 33
215 29 8 56 -3 69 -28 11 -21 50 -169 81 -304 29 -133 45 -148 151 -148 94 0
126 11 135 44 4 19 -1 34 -20 56 -26 32 -46 89 -91 265 -61 237 -82 281 -140
290 -16 2 -48 -2 -70 -9z"
      />
      <path
        d="M5563 1866 c-124 -29 -153 -65 -153 -191 0 -112 30 -151 151 -196 70
-26 132 -58 141 -73 13 -19 9 -63 -7 -76 -24 -20 -111 -13 -153 11 -21 11 -49
38 -64 60 -28 40 -42 46 -67 30 -21 -14 -20 -131 2 -164 22 -33 96 -51 217
-51 184 0 250 48 258 186 5 82 0 104 -32 141 -27 31 -169 92 -188 81 -6 -4 -8
-3 -5 3 4 6 -6 16 -22 22 -59 25 -67 100 -12 119 34 12 100 0 112 -20 5 -7 6
-20 3 -28 -29 -69 -29 -71 -14 -80 30 -19 62 -10 96 25 41 44 62 105 46 139
-26 58 -184 90 -309 62z"
      />
      <path
        d="M2250 1850 c-106 -36 -135 -107 -135 -330 0 -135 2 -149 25 -195 35
-71 87 -97 198 -103 120 -6 185 10 233 59 64 65 76 143 28 184 -46 38 -60 30
-82 -45 -23 -82 -52 -110 -111 -110 -58 0 -87 31 -89 96 l-2 48 100 22 c203
46 215 54 215 148 0 116 -60 214 -146 235 -66 17 -172 13 -234 -9z m177 -94
c22 -21 38 -102 27 -143 -11 -44 -40 -63 -101 -63 -40 0 -42 2 -48 33 -7 43 0
116 15 148 21 45 73 57 107 25z"
      />
      <path d="M5920 1821 c0 -6 4 -13 10 -16 6 -3 7 1 4 9 -7 18 -14 21 -14 7z" />
      <path
        d="M6034 1799 c-4 -7 -22 -9 -47 -6 -27 4 -36 2 -26 -4 21 -13 63 -11
85 6 10 8 14 15 7 15 -6 0 -15 -5 -19 -11z"
      />
    </g>
  </svg>
);
