import React from 'react';
import {Row, Col, Container} from 'reactstrap';
//import Collapsible from 'react-collapsible'
import styles from './collapsible-orange.css.js';

const CVSSVG = () => (
  <img alt="CVS Logo" title="CVS Logo" src="/static/images/pharmacy-logos/cvs.svg" className="img-fluid" />
);

const KrogerSVG = () => (
  <img alt="Kroger Logo" title="Kroger Logo" src="/static/images/pharmacy-logos/kroger.svg" className="img-fluid" />
);

const RiteAidSVG = () => (
  <img alt="RiteAid Logo" title="RiteAid Logo" src="/static/images/pharmacy-logos/rite-aid.svg" className="img-fluid" />
);

const WalgreensSVG = () => (
  <img
    alt="Walgreens Logo"
    title="Walgreens Logo"
    src="/static/images/pharmacy-logos/walgreens.svg"
    className="img-fluid"
  />
);

const WalmartSVG = () => (
  <img alt="Walmart Logo" title="Walmart Logo" src="/static/images/pharmacy-logos/walmart.svg" className="img-fluid" />
);

const SafewaySVG = () => (
  <img alt="Safeway Logo" title="Safeway Logo" src="/static/images/pharmacy-logos/safeway.svg" className="img-fluid" />
);

const Pharmacies = () => (
  <section className="pharmacies text-center">
    <Container fluid>
      <h2>Accepted at most pharmacies nationwide</h2>
      <Row>
        <Col xs="12" sm="4" md="2">
          <CVSSVG />
        </Col>
        <Col xs="12" sm="4" md="2">
          <RiteAidSVG />
        </Col>
        <Col xs="12" sm="4" md="2">
          <WalgreensSVG />
        </Col>
        <Col xs="12" sm="4" md="2">
          <KrogerSVG />
        </Col>
        <Col xs="12" sm="4" md="2">
          <WalmartSVG />
        </Col>
        <Col xs="12" sm="4" md="2">
          <SafewaySVG />
        </Col>
      </Row>
      {/*<Collapsible trigger="View Full Pharmacy List" easing="ease-in-out">*/}
      {/*<Row>*/}
      {/*<Col xs="12" sm="6">*/}
      {/*<ul className="list-unstyled">*/}
      {/*<li className="TrT0Xe">Bartell Drugs.</li>*/}
      {/*<li className="TrT0Xe">Benzer Pharmacy</li>*/}
      {/*<li className="TrT0Xe">Boone Drug</li>*/}
      {/*<li className="TrT0Xe">CVS Pharmacy</li>*/}
      {/*<li className="TrT0Xe">Discount Drug Mart</li>*/}
      {/*<li className="TrT0Xe">Drug Emporium</li>*/}
      {/*<li className="TrT0Xe">Walmart Pharmacy</li>*/}
      {/*<li className="TrT0Xe">Family Pharmacy</li>*/}
      {/*</ul>*/}
      {/*</Col>*/}
      {/*<Col xs="12" sm="6">*/}
      {/*<ul className="list-unstyled">*/}
      {/*<li className="TrT0Xe">Bartell Drugs.</li>*/}
      {/*<li className="TrT0Xe">Benzer Pharmacy</li>*/}
      {/*<li className="TrT0Xe">Boone Drug</li>*/}
      {/*<li className="TrT0Xe">CVS Pharmacy</li>*/}
      {/*<li className="TrT0Xe">Discount Drug Mart</li>*/}
      {/*<li className="TrT0Xe">Drug Emporium</li>*/}
      {/*<li className="TrT0Xe">Walmart Pharmacy</li>*/}
      {/*<li className="TrT0Xe">Family Pharmacy</li>*/}
      {/*</ul>*/}
      {/*</Col>*/}
      {/*</Row>*/}
      {/*</Collapsible>*/}
    </Container>
    <style jsx>{`
      .pharmacies {
        padding: 60px 0 0 0;
      }
      .pharmacies :global(.row) {
        align-items: center;
      }
      .pharmacies :global(.col-6) {
        margin-bottom: 40px;
      }
      .pharmacies :global(img) {
        margin: 0 auto 60px auto;
        text-align: center;
        vertical-align: middle;
      }
      h2 {
        margin: 0 auto 60px auto;
      }
      @media (min-width: 768px) {
        .pharmacies {
          padding: 120px 0 40px 0;
        }
      }
    `}</style>
    <style jsx global>
      {styles}
    </style>
  </section>
);

export default Pharmacies;
