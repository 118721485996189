import React from 'react';
export const frys = (
  <svg width="49.671mm" height="28.504mm" version="1.1" viewBox="0 0 176 101" xmlns="http://www.w3.org/2000/svg">
    <g transform="translate(-212 -839.01)" fillRule="evenodd">
      <path d="m215.44 888.03 7.5-11.85h12.436c1.3222-22.333 16.292-25.669 22.029-26.171 7.1576-0.6265 18.418 5.2064 16.696 13.304-0.64717 5.1261-3.438 6.3298-6.6964 6.6071-9.0448 0.7697-8.8242-5.0198-9.375-7.5446-0.35552-1.6295-0.8861-1.6936-2.0536-0.0446-2.4317 3.6961-2.4313 12.066-2.5 13.85h126.88l-6.9543 12h-119.62l-0.0993 35.8-18-0.05v-35.9z" />
      <path
        d="m213.96 886.96 7.5-11.85h12.436c1.3222-22.333 16.292-25.669 22.029-26.171 7.1576-0.6265 18.418 5.2064 16.696 13.304-0.64717 5.1261-3.438 6.3298-6.6964 6.6071-9.0448 0.7697-8.8242-5.0198-9.375-7.5446-0.35552-1.6295-0.8861-1.6936-2.0536-0.0446-2.4317 3.6961-2.4313 12.066-2.5 13.85h126.88l-6.9543 12h-119.62l-0.0993 35.8-18-0.05v-35.9z"
        fill="currentColor"
      />
      <path d="m287.34 906.38c3.9538 0.0239 7.4084-3.9382 7.4499-7.4499 0.0446-3.7806-2.5892-8.0659-7.8287-8.2075-1.7143-0.0463-6.2025 0.34422-8.7126 2.5254h-2.0203v-0.93175h-14.521l-2.6e-4 31.614h15.606s0.24725-20.06 0.0895-21.923c-0.0906-1.0698 1.5051-3.6368 1.8787-2.6164 2.0125 5.4965 5.367 6.9725 8.0589 6.9888z" />
      <path
        d="m285.74 905.15c3.9538 0.0239 7.4084-3.9382 7.4499-7.4499 0.0446-3.7806-2.5892-8.0659-7.8287-8.2075-1.7143-0.0463-6.2025 0.34422-8.7126 2.5254h-2.0203v-0.93175h-14.521l-2.6e-4 31.614h15.606s0.24725-20.06 0.0895-21.923c-0.0906-1.0698 1.5051-3.6368 1.8787-2.6164 2.0125 5.4965 5.367 6.9725 8.0589 6.9888z"
        fill="currentColor"
      />
      <path d="m296.24 893h15.004v19.71c0.75712 0.70763 1.9942 1.4277 3.1367 1.3871 1.0984-0.039 2.0414-0.23642 2.9133-1.544v-19.643h14.75v35.804l-14.5 3.3928v-11.696c-2.4179 2.3744-5.1234 4.8321-9.5179 4.7322-7.1799-0.16334-11.43-5.4846-11.533-13.242z" />
      <path
        d="m294.95 891.26h15.004v19.71c0.75712 0.70763 1.9942 1.4277 3.1367 1.3871 1.0984-0.039 2.0414-0.23642 2.9133-1.544v-19.643h14.75v35.804l-14.5 3.3928v-11.696c-2.4179 2.3744-5.1234 4.8321-9.5179 4.7322-7.1799-0.16334-11.43-5.4846-11.533-13.242z"
        fill="currentColor"
      />
      <path d="m337.5 892.32 7.513 11.932 7.1973-12.059z" />
      <path d="m336 891.27 7.513 11.932 7.1973-12.059z" fill="currentColor" />
      <path d="m359.23 915.94c-2.4374-0.45545-5.4808-1.9196-6.8304-2.9464l-2.6339 6.8304c5.3698 2.4309 11.478 5.2394 17.589 5.1786 6.9699-0.0694 16.012-4.6881 16.161-11.25 0.25457-11.232-10.513-12.127-16.462-13.511-0.68399-0.15915-1.3992-0.53974-1.5371-1.1684-0.15051-0.68611 0.30895-1.6876 0.9898-1.8603 4.5871-1.1639 10.134-0.28312 15.759 2.1652l1.25-5.0893c-4.5983-1.8569-9.7108-3.8594-14.821-3.9286-6.7497-0.0913-15.313 2.8283-17.411 9.5536-1.2055 3.8658-1.2579 8.5952 4.6875 10.938 3.8355 1.5111 7.6511 2.0477 12.009 2.5446 0.80887 0.0922 2.0164 1.3411 1.875 2.1429-0.2506 1.421-7.1411 1.0528-10.625 0.40179z" />
      <path
        d="m357.62 914.69c-2.4374-0.45545-5.4808-1.9196-6.8304-2.9464l-2.6339 6.8304c5.3698 2.4309 11.478 5.2394 17.589 5.1786 6.9699-0.0694 16.012-4.6881 16.161-11.25 0.25457-11.232-10.513-12.127-16.462-13.511-0.68399-0.15915-1.3992-0.53974-1.5371-1.1684-0.15051-0.68611 0.30895-1.6876 0.9898-1.8603 4.5871-1.1639 10.134-0.28312 15.759 2.1652l1.25-5.0893c-4.5983-1.8569-9.7108-3.8594-14.821-3.9286-6.7497-0.0913-15.313 2.8283-17.411 9.5536-1.2055 3.8658-1.2579 8.5952 4.6875 10.938 3.8355 1.5111 7.6511 2.0477 12.009 2.5446 0.80887 0.0922 2.0164 1.3411 1.875 2.1429-0.2506 1.421-7.1411 1.0528-10.625 0.40179z"
        fill="currentColor"
      />
    </g>
  </svg>
);
