import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {
  DrugPriceV2,
  HttpDrugResultV2,
  HttpDrugV2,
  HttpPickedDrug,
  MessagePriceRequest,
  SinglecareDrugSearchResult,
} from '../dataServices/app.generated';
import {useClickOutside} from '../hooks/useClickOutside';
import {Utils} from '../utils/utils';
import {DebounceUtils} from '../utils/debounceUtils';
import {AppService} from '../dataServices/appService';
import {toast} from 'react-toastify';
import {DrugUtils} from '../utils/drugUtils';
import {Config} from '../config';
import {PromiseButton} from '../components/PromiseButton';
import {VoucherModal} from './VoucherModal';
import '../styles.css';
import {PapCard, VoucherCard} from '../components/VoucherCard';
import {PapSubmission} from '../components/PapSubmission';
declare const gtag: any;

export const defaultCustomization = {
  groupCode: '3170',
  memberId: '10002W',
  hasBorder: false,
  title: 'RX Price Lookup',
  subTitle: 'Search for rx prices in your area!',
};

export type Customization = typeof defaultCustomization;

export const RxPricing = ({customization}: {customization?: Customization}) => {
  customization = customization ?? defaultCustomization;
  const [drugResult, setDrugResult] = useState<HttpDrugResultV2>();
  const [showMore, setShowMore] = useState(false);
  const [drug, setDrug] = useState<HttpDrugV2>();
  const [zipCode, setZipCode] = useState('');
  const [visibleAutoComplete, setVisibleAutoComplete] = useState(false);
  const [autoCompleteFocus, setAutoCompleteFocus] = useState(false);
  const [modalVoucher, setModalVoucher] = useState<MessagePriceRequest | null>(null);
  const [modalPrice, setModalPrice] = useState<DrugPriceV2>();
  const [pickedDrug, setPickedDrug] = useState<HttpPickedDrug>();
  const [loadingVoucherPharmacy, setLoadingVoucherPharmacy] = useState<string>();
  const [autoCompleteResults, setAutoCompleteResults] = useState<SinglecareDrugSearchResult[]>();
  const [query, setQuery] = useState('');
  const [selectedDrug, setSelectedDrug] = useState<SinglecareDrugSearchResult>();
  const [autoCompleteLoading, setAutoCompleteLoading] = useState(false);
  const [prices, setPrices] = useState<DrugPriceV2[]>();
  const [searching, setSearching] = useState(false);
  const [forceFreshSearch, setForceFreshSearch] = useState(false);
  const topResults = useRef<HTMLDivElement>(null);
  const autoCompleteRef = useRef<HTMLDivElement>(null);
  useClickOutside(autoCompleteRef, visibleAutoComplete && autoCompleteFocus, () => setVisibleAutoComplete(false));
  const searchBtnRef = useRef<HTMLButtonElement>(null);
  const [papResult, setPapResult] = useState({isEligible: false, papPrice: '0'});
  const [showPapForm, setShowPapForm] = useState(false);

  useEffect(() => {
    AppService.utilsClient.voucherEvent(
      {
        groupCode: customization.groupCode,
        memberId: customization.memberId,
        type: 'initial',
        sessionId: Config.sessionId,
      },
      {}
    );
    gtag && gtag('event', 'start-pricing', {});
  }, []);
  const drugNameUUID = useMemo(() => {
    return Utils.smallUUID();
  }, []);

  const handleAutoComplete = useCallback(
    (drugQuery: string, autoSetSelected?: boolean) => {
      setAutoCompleteLoading(true);
      setVisibleAutoComplete(true);
      DebounceUtils.debounce('auto-complete', 800, async () => {
        const result = await AppService.drugV2Client.search({query: drugQuery}, {});
        if (result) {
          setAutoCompleteLoading(false);
          setAutoCompleteResults(result.results);
          setVisibleAutoComplete(true);
          if (autoSetSelected && result.results.find((x) => x.display_name === drugQuery)) {
            const selected = result.results.find((x) => x.display_name === drugQuery);
            if (selected) {
              setSelectedDrug(selected);
              setQuery(selected.display_name);
              setVisibleAutoComplete(false);
              setTimeout(() => {
                searchBtnRef.current?.click();
              }, 200);
            }
          }
        }
      });
    },
    [customization]
  );

  const getDrugStructure = useCallback(async () => {
    if (!selectedDrug || !zipCode) {
      return;
    }
    if (forceFreshSearch) {
      setSearching(true);
      setForceFreshSearch(false);
      setPrices(undefined);
      const result = await AppService.drugV2Client.getDrugStructure(
        {seoName: selectedDrug.seo_name},
        {
          404: (error) => toast(error, {type: 'error'}),
        }
      );
      if (result) {
        const foundDrug = result.generics[0] || result.brands[0];
        const picked = DrugUtils.getDefaultPicked(foundDrug, zipCode);
        setDrug(foundDrug);
        setPickedDrug(picked);
        setDrugResult(result);
        await rxLookup(picked);
        setSearching(false);
      } else {
        setSearching(false);
      }
    } else {
      setSearching(true);
      await rxLookup(pickedDrug);
      setSearching(false);
    }
  }, [selectedDrug, zipCode, forceFreshSearch, pickedDrug, customization]);

  const rxLookup = useCallback(
    async (picked: HttpPickedDrug | undefined) => {
      if (!picked) {
        return;
      }
      let lookupPicked = picked;
      if (zipCode !== lookupPicked.zipcode) {
        lookupPicked = {...picked, zipcode: zipCode};
        setPickedDrug(lookupPicked);
      }
      AppService.utilsClient.voucherEvent(
        {
          groupCode: customization.groupCode,
          memberId: customization.memberId,
          ndc: lookupPicked.ndc,
          zipcode: lookupPicked.zipcode,
          type: 'price',
          sessionId: Config.sessionId,
          drugName: lookupPicked.drugName,
          quantity: lookupPicked.quantity,
        },
        {}
      );
      gtag && gtag('event', 'price-lookup', {});

      const res = await AppService.drugV2Client.price(
        {
          quantity: parseFloat(lookupPicked.quantity),
          zipcode: lookupPicked.zipcode,
          ndc: lookupPicked.ndc,
          platform: 'clx-web',
        },
        {
          400: (error) => toast(error, {type: 'error'}),
        }
      );
      if (res) {
        setPrices(res.prices);
        if (res.papEligibility) {
          setPapResult(res.papEligibility);
        } else {
          setPapResult({isEligible: false, papPrice: '0'});
        }
      }
    },
    [customization, zipCode, selectedDrug]
  );

  const toggleResults = () => {
    if (showMore) {
      topResults.current?.scrollIntoView();
    }
    setShowMore((x) => !x);
  };

  const showVoucherModal = useCallback(
    async (price: DrugPriceV2, overridePicked?: HttpPickedDrug) => {
      const picked = overridePicked ?? pickedDrug;
      if (!picked) return;
      if (!price.coupon.retrieved) {
        setLoadingVoucherPharmacy(price.pharmacy);

        AppService.utilsClient.voucherEvent(
          {
            groupCode: customization.groupCode,
            memberId: customization.memberId,
            ndc: pickedDrug.ndc,
            zipcode: pickedDrug.zipcode,
            type: 'load-voucher',
            sessionId: Config.sessionId,
            drugName: pickedDrug.drugName,
            price: price.price.toString(),
            pharmacyName: price.pharmacy,
            quantity: pickedDrug.quantity,
          },
          {}
        );
        gtag && gtag('event', 'select-voucher', {});

        const voucherResult = await AppService.drugV2Client.getVoucher({price: price}, {});
        if (voucherResult) {
          price = voucherResult.price;
        }
        setLoadingVoucherPharmacy(undefined);
      }

      setModalVoucher({
        drugName: picked.drugName,
        pharmacy: price.pharmacy,
        price: price.price.toLocaleString('US', {
          style: 'currency',
          currency: 'USD',
        }),
        bin: price.coupon.rxBin ? price.coupon.rxBin : Config.rxBin,
        pcn: price.coupon.pcn ? price.coupon.pcn : Config.pcn,
        group: price.coupon.groupNumber,
        memberId: price.coupon.memberId,
        questionsNumber: price.coupon.memberPhoneNumber,
        pharmacistPhoneNumber: price.coupon.pharmacistPhoneNumber,
        provider: price.coupon.provider,
        phoneNumber: '',
      });
      setModalPrice(price);
    },
    [customization, pickedDrug]
  );

  const selectedDose = () => {
    return drug!.doseForms.find((a) => a.form === pickedDrug!.form) || drug!.doseForms[0];
  };

  const selectedDispensable = () => {
    const dose = selectedDose();
    return dose.dispensables.find((a) => a.strengthItem === pickedDrug!.strengthItem) || dose.dispensables[0];
  };

  const swapGenericBrand = async (drugName: string) => {
    const drugBody = (drugResult!.brands.find((a) => a.name === drugName) ||
      drugResult!.generics.find((a) => a.name === drugName))!;

    const picked = DrugUtils.getDefaultPicked(drugBody, pickedDrug!.zipcode, false);
    setDrug(drugBody);
    setPickedDrug(picked);
    setSearching(true);
    await rxLookup(picked);
    setSearching(false);
  };

  const onCloseModal = useCallback(() => {
    setModalVoucher(null);
    setModalPrice(undefined);
  }, []);

  const cheapestPrice = prices?.find((x) => x.cheapest);
  const closestPrice = prices?.find((x) => x.closest);
  const papIsCheapest =
    papResult.isEligible && (cheapestPrice ? parseFloat(papResult.papPrice) < cheapestPrice.price : true);

  const onClosePap = (submitted: boolean) => {
    if (submitted) {
    }
    setShowPapForm(false);
  };

  if (showPapForm) {
    return <PapSubmission ndc={pickedDrug.ndc} drugName={pickedDrug.drugName} goBack={onClosePap} />;
  }

  return (
    <>
      <div className={'text-center text-custom-text max-w-6xl p-2.5 md:p-10 mx-auto'} style={{zIndex: 101}}>
        <div className={`rounded-3xl p-3`}>
          <div className={'p-4 bg-custom-background w-full h-full rounded-3xl'}>
            <h2 className={'font-semibold text-3xl'}>{customization.title}</h2>
            <p className={'text-lg'}>{customization.subTitle}</p>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                getDrugStructure();
              }}
              onFocus={(e: any) => {
                if (e.target.name === drugNameUUID) {
                  setAutoCompleteFocus(true);
                } else {
                  setVisibleAutoComplete(false);
                }
              }}
              onKeyPress={(e: any) => {
                if (e.key === 'Enter') {
                  e.target.blur();
                  setVisibleAutoComplete(false);
                }
              }}
            >
              <div className={'mt-5 text-left sm:flex items-start'}>
                <div className="relative w-full">
                  <label className={'block w-full'}>
                    <div className={'font-semibold text-lg ml-1 mb-0.5'}>Drug name</div>
                    <input
                      className="clh-input text-base bg-custom-foreground rounded-2xl w-full block p-4 placeholder-cleverGray-200 text-custom-text"
                      placeholder="e.g. Lipitor"
                      id={drugNameUUID}
                      name={drugNameUUID}
                      autoComplete={'off'}
                      value={query}
                      onChange={(e) => {
                        setSelectedDrug(undefined);
                        setQuery(e.target.value);
                        setForceFreshSearch(true);
                        setPickedDrug(undefined);
                        handleAutoComplete(e.target.value);
                      }}
                    />
                  </label>
                  {visibleAutoComplete && autoCompleteFocus && (
                    <div
                      className={
                        'mt-2 absolute z-20 top-full left-0 w-full bg-custom-dropdown rounded-lg overflow-hidden'
                      }
                      ref={autoCompleteRef}
                    >
                      {autoCompleteLoading ? (
                        <div className={'p-3'}>Loading...</div>
                      ) : (
                        autoCompleteResults?.map((item) => (
                          <div
                            key={item.id}
                            onClick={(e) => {
                              e.stopPropagation();
                              setSelectedDrug(item);
                              setQuery(item.display_name);
                              setVisibleAutoComplete(false);
                            }}
                            className={
                              'p-3 hover:text-custom-dropdown-text cursor-pointer transition-colors hover:bg-custom-modalBackground'
                            }
                          >
                            {item.display_name}
                          </div>
                        ))
                      )}
                    </div>
                  )}
                </div>
                <label className={'block sm:ml-4 mt-2 sm:mt-0 w-full sm:w-auto'}>
                  <div className={'font-semibold text-lg ml-1 mb-0.5'}>Zip Code</div>
                  <input
                    className="clh-input text-base bg-custom-foreground rounded-2xl w-full p-4 placeholder-cleverGray-200 text-custom-text"
                    placeholder="Zip Code"
                    value={zipCode}
                    onChange={(e) => setZipCode(e.target.value)}
                  />
                </label>
              </div>
              {pickedDrug && drug && drugResult && prices && (
                <>
                  <label className={'w-full block text-left mt-5'}>
                    <div className={'font-semibold text-lg ml-1 mb-0.5'}>Brand</div>
                    <select
                      className="form-select text-base border-custom-foreground bg-custom-foreground rounded-2xl w-full p-4 text-custom-text hover:border-cleverGray-250 focus:border-custom-primary"
                      value={pickedDrug.drugName}
                      onChange={(e) => swapGenericBrand(e.target.value)}
                    >
                      {[
                        ...drugResult.generics.map((r) => ({
                          value: r.name,
                          label: r.name + ' (generic)',
                        })),
                        ...drugResult.brands.map((r) => ({
                          value: r.name,
                          label: r.name + ' (brand)',
                        })),
                      ].map((opt) => (
                        <option key={opt.value} value={opt.value}>
                          {opt.label}
                        </option>
                      ))}
                    </select>
                  </label>
                  <div className={'text-left sm:flex items-start relative z-10'}>
                    <label className={'mt-1 block flex-1 sm:mr-2'}>
                      <div className={'font-semibold text-lg ml-1 mb-0.5'}>Form</div>
                      <select
                        className="form-select text-base border-custom-foreground bg-custom-foreground rounded-2xl w-full p-4 text-custom-text hover:border-cleverGray-250 focus:border-custom-primary"
                        value={pickedDrug.form}
                        onChange={(e) => {
                          setPickedDrug(DrugUtils.getPickedFromForm(drug!, pickedDrug!, e.target.value));
                        }}
                      >
                        {drug.doseForms
                          .map((a) => ({
                            value: a.form,
                            label: a.form,
                          }))
                          .map((opt) => (
                            <option key={opt.value} value={opt.value}>
                              {opt.label}
                            </option>
                          ))}
                      </select>
                    </label>
                    <label className={'mt-1 block flex-1 sm:mr-2'}>
                      <div className={'font-semibold text-lg ml-1 mb-0.5'}>Dosage</div>
                      <select
                        className="form-select text-base border-custom-foreground bg-custom-foreground rounded-2xl w-full p-4 text-custom-text hover:border-cleverGray-250 focus:border-custom-primary"
                        value={pickedDrug.strengthItem}
                        onChange={(e) => {
                          setPickedDrug(DrugUtils.getPickedFromStrengthItem(drug!, pickedDrug!, e.target.value));
                        }}
                      >
                        {selectedDose()
                          .dispensables.map((a) => ({
                            value: a.strengthItem,
                            label: a.strengthItem,
                          }))
                          .map((opt) => (
                            <option key={opt.value} value={opt.value}>
                              {opt.label}
                            </option>
                          ))}
                      </select>
                    </label>
                    <label className={'mt-1 block flex-1'}>
                      <div className={'font-semibold text-lg ml-1 mb-0.5'}>Quantity</div>
                      <select
                        className="form-select text-base border-custom-foreground bg-custom-foreground rounded-2xl w-full p-4 text-custom-text hover:border-cleverGray-250 focus:border-custom-primary"
                        value={pickedDrug.quantity}
                        onChange={(e) => {
                          if (e.target.value === 'other') {
                            const value = prompt('What quantity would you like?', '30');
                            if (value) {
                              selectedDispensable().dispensedQuantities.unshift({
                                ndc: {
                                  ...selectedDispensable().dispensedQuantities[0].ndc,
                                  quantity: parseFloat(value),
                                  displayQuantity: parseFloat(value),
                                },
                                quantity: parseFloat(value),
                              });
                              setPickedDrug({
                                ...pickedDrug,
                                quantity: value,
                                displayQuantity: value,
                              });
                            }
                          } else {
                            setPickedDrug({
                              ...pickedDrug,
                              quantity: e.target.value,
                              displayQuantity: e.target.options[e.target.options.selectedIndex].label,
                            });
                          }
                        }}
                      >
                        {[
                          ...selectedDispensable().dispensedQuantities.map((a) => ({
                            value: a.quantity,
                            label: a.ndc.displayQuantity,
                          })),
                          {
                            value: 'other',
                            label: 'Other',
                          },
                        ].map((opt) => (
                          <option key={opt.value} value={opt.value}>
                            {opt.label}
                          </option>
                        ))}
                      </select>
                    </label>
                  </div>
                </>
              )}
              <PromiseButton
                ref={searchBtnRef}
                overrideClassName={`relative mt-4 big-clever-btn w-full ${prices && pickedDrug ? 'mb-10' : ''}`}
                disabled={searching}
                isSubmit
                onClick={getDrugStructure}
              >
                Find Savings
              </PromiseButton>
              {prices && pickedDrug && (
                <>
                  {papResult.isEligible && !papIsCheapest && (
                    <div className={`px-8 my-4`}>
                      <PapCard
                        isLowestPrice={false}
                        papResult={papResult}
                        onShow={() => {
                          setShowPapForm(true);
                          window.scrollTo(0, 0);
                        }}
                      />
                    </div>
                  )}
                  <div
                    className={
                      (cheapestPrice || papIsCheapest) && closestPrice
                        ? 'lg:grid-cols-2 grid grid-cols-1 lg:gap-12 px-8 my-4'
                        : 'mx-auto max-w-[500px]'
                    }
                  >
                    {papIsCheapest ? (
                      <PapCard
                        isLowestPrice={true}
                        papResult={papResult}
                        onShow={() => {
                          setShowPapForm(true);
                          window.scrollTo(0, 0);
                        }}
                      />
                    ) : cheapestPrice ? (
                      <VoucherCard
                        className={'glow-shadow'}
                        price={cheapestPrice}
                        onShowVoucher={() => showVoucherModal(cheapestPrice)}
                        loadingVoucher={loadingVoucherPharmacy === cheapestPrice.pharmacy}
                        isShown
                      />
                    ) : null}
                    {closestPrice && (
                      <VoucherCard
                        className={'glow-shadow'}
                        price={closestPrice}
                        onShowVoucher={() => showVoucherModal(closestPrice)}
                        loadingVoucher={loadingVoucherPharmacy === closestPrice.pharmacy}
                        isShown
                      />
                    )}
                  </div>
                  <div className={'grid grid-cols-1 gap-4 md:grid-cols-2'} ref={topResults}>
                    {prices
                      .filter((result) => (papIsCheapest ? !result.closest : !result.cheapest && !result.closest))
                      .map((result, idx) => {
                        return (
                          <VoucherCard
                            className={'shadow-lg'}
                            key={idx}
                            loadingVoucher={loadingVoucherPharmacy === result.pharmacy}
                            price={result}
                            isShown={showMore || idx <= 5}
                            onShowVoucher={() => showVoucherModal(result)}
                            noDesc={papResult.isEligible}
                          />
                        );
                      })}
                  </div>
                  <button className={'clever-btn px-10 rounded-full my-5'} onClick={toggleResults}>
                    {showMore ? 'view less' : 'view more'}
                  </button>
                </>
              )}
            </form>
          </div>
        </div>
      </div>
      <VoucherModal
        customization={customization}
        show={!!modalVoucher && !!pickedDrug && !!modalPrice}
        modalVoucher={modalVoucher}
        close={onCloseModal}
        pickedDrug={pickedDrug}
        retrievedPrice={modalPrice}
      />
    </>
  );
};
