// import Head from 'next/head'
import React from 'react';
import Layout from '../components/Layout';
import CardContainer from '../containers/CardContainer';
import OpenGraphMeta from '../components/OpenGraphMeta';

const Card = (props) => {
  const {urlSlug} = props.match.params;
  return (
    <Layout headerStyle="card">
      <OpenGraphMeta title={'Digital Card | Clever RX'} />
      <CardContainer urlSlug={urlSlug} />
    </Layout>
  );
};

export default Card;
