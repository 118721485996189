import React from 'react';
import {Container, Button} from 'reactstrap';
import BottomSectionCard from '../components/card/BottomSectionCard';
import CardInfo from '../components/card/CardInfo';
import TopSectionCard from '../components/card/TopSectionCard';
import {Link, RouteComponentProps} from 'react-router-dom';
import Loading from '../components/Loading';
import {AppService} from '../dataServices/appService';
import {BrokerDetails} from '../dataServices/app.generated';
import {defaultCustomization, RxPricing} from './RxPricing';
import {Element} from 'react-scroll';

type Props = {
  urlSlug: string;
} & RouteComponentProps<any>;
type State = {
  loading: boolean;
  card?: BrokerDetails;
};

class CardContainer extends React.Component<Props, State> {
  constructor(props: Props, context: any) {
    super(props, context);

    this.state = {
      loading: true,
      card: undefined,
    };
  }

  async componentDidMount() {
    const {urlSlug} = this.props;
    if (urlSlug === null) {
      this.props.history.push('/');
    } else {
      const response = await AppService.userClient.getBrokerDetailsBySlug(
        {slug: urlSlug},
        {
          404: () => {
            this.setState({loading: false});
          },
        }
      );

      if (response) {
        this.setState({
          card: response.brokerDetails,
          loading: false,
        });
      }
    }
  }

  render() {
    const {loading, card} = this.state;

    if (loading) {
      return <Loading />;
    }

    return (
      <React.Fragment>
        {card ? (
          <div>
            <TopSectionCard companyName={card.company_name} urlSlug={card.url_slug} />
            <Element name={'Lookup'}>
              <div style={{margin: 'auto', backgroundColor: '#24292d', zIndex: 100, position: 'relative'}}>
                <RxPricing
                  customization={{
                    ...defaultCustomization,
                    groupCode: card.group_number,
                    memberId: card.member_id + 'W',
                  }}
                />
              </div>
            </Element>
            <CardInfo card={card} />
            <BottomSectionCard />
          </div>
        ) : (
          <div className="error-block">
            <Container className="text-center">
              <h1>Card Not Found</h1>
              <p>Please check the url is correct.</p>
              <Button tag={Link} to="/" block size="lg" color="primary">
                Back to Home
              </Button>
            </Container>
          </div>
        )}
      </React.Fragment>
    );
  }
}

export default CardContainer;
