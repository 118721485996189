import React from 'react';
export const rite = (
  <svg xmlns="http://www.w3.org/2000/svg" width="66" height="72" viewBox="0 0 66 72">
    <defs>
      <path id="a" d="M.548.172h64.91v21.735H.547z" />
      <path id="c" d="M0 .368h66v12.841H0z" />
    </defs>
    <g fill="none" fillRule="evenodd" opacity=".5">
      <mask id="b" fill="#fff">
        <use xlinkHref="#a" />
      </mask>
      <path
        fill="currentColor"
        d="M9.897 3.02h8.37c4.069 0 5.735 2.34 5.735 5.444 0 1.829-1.115 3.944-3.4 4.843.02-.007 3.669 5.66 3.6 5.604h-5.047c.016.008-3.735-5.063-5.401-7.303v7.303H9.897V3.02zm15.592 15.864h4.235V2.993h-4.235v15.891zm5.473-15.891h12.254v3.565h-4.031v12.326H35.05V6.558h-4.088V2.993zm13.715 0H56.85v3.565h-8.304V9.31h7.06v3.363h-7.06v2.847h8.304v3.364H44.677V2.993zM.551 21.907h64.904l.002-.002V3.534c0-1.583-1.332-3.362-3.492-3.362H4.273C1.881.172.548 1.4.548 3.65v12.072c.017.045.025 6.184.003 6.184z"
        mask="url(#b)"
      />
      <path
        fill="currentColor"
        d="M17.517 10.564c.495 0 2.343-.082 2.36-2.045.017-1.972-1.661-2.14-2.366-2.14h-3.757v4.187c1.297.001 3.756-.002 3.763-.002M21.318 33.33l-.052-.067-2.102 5.87H23.5zM49.75 36.676c-.035-3.383-.854-5.41-4.494-5.41H42.43v10.812h2.568c3.876 0 4.78-2.587 4.752-5.402"
      />
      <path
        fill="currentColor"
        d="M19.099 27.963h4.091c.025.007 6.755 17.226 6.695 17.25h-4.083l-1.045-2.755h-6.942l-.991 2.756h-4.028c.018.011 6.34-17.217 6.303-17.25zm12.525 17.218h4.049V28.064h-4.05v17.117zm6.953-17.117h8.432c2.514 0 6.521 2.11 6.556 8.21.027 4.812-2.086 8.906-7.985 8.906h-7.003V28.064zM.547 39.697c.134 5.44 6.537 7.07 6.548 7.07l25.799 8.696 25.883-8.712c-.006.016 6.68-1.63 6.68-7.17V23.998l-.002-.003H.567l-.014-.009c.015.009-.022 6.76-.005 6.745v8.965zM59.072 50.738h.476c.242 0 .5.053.5.338 0 .354-.263.375-.558.375h-.418v-.713zm-.348 2.006h.348v-.998h.4l.607.998h.391l-.653-1.013c.337-.042.596-.222.596-.634 0-.454-.27-.655-.813-.655h-.876v2.302z"
      />
      <path
        fill="currentColor"
        d="M61.109 51.589c0 .96-.712 1.668-1.615 1.668-.913 0-1.626-.707-1.626-1.668 0-.946.713-1.66 1.626-1.66.903 0 1.615.714 1.615 1.66m-3.64 0c0 1.156.927 2 2.025 2 1.087 0 2.016-.844 2.016-2 0-1.147-.929-1.992-2.016-1.992-1.098 0-2.026.845-2.026 1.992"
      />
      <g transform="translate(0 57.79)">
        <mask id="d" fill="#fff">
          <use xlinkHref="#c" />
        </mask>
        <path
          fill="currentColor"
          d="M59.907 7.546v3.465h-1.498V7.546l-2.845-4.979h1.59l1.999 3.602 2.003-3.602h1.585l-2.834 4.98zm-7.401 3.61c-1.18 0-2.095-.389-2.748-1.165-.654-.776-.979-1.835-.979-3.177 0-1.248.362-2.293 1.085-3.132.724-.84 1.651-1.26 2.78-1.26.967 0 1.742.199 2.32.595l-.615 1.239c-.334-.335-.872-.502-1.613-.502-.703 0-1.279.295-1.728.885-.449.59-.674 1.336-.674 2.239 0 .903.208 1.62.625 2.15.416.53.972.795 1.667.795.795 0 1.418-.284 1.867-.852l.697 1.21c-.611.65-1.506.974-2.684.974zm-5.87-.145l-.616-1.712h-2.891l-.588 1.712h-1.665l3.364-8.56h.657l3.392 8.56h-1.653zm-7.297.006l-.875-4.554-1.7 4.663h-.536l-1.699-4.663-.91 4.554h-1.446l.001-.006h-1.7l-2.251-3.487a25.55 25.55 0 0 1-.95-.041v3.528h-1.556V2.567c.084 0 .409-.015.973-.044.565-.028 1.02-.043 1.365-.043 2.143 0 3.215.83 3.215 2.49 0 .5-.148.955-.444 1.366a2.393 2.393 0 0 1-1.117.87l2.472 3.772 1.691-8.411h.795l1.827 5.689 1.785-5.689h.79l1.721 8.45h-1.45zm-16.168-.006l-.617-1.712h-2.891l-.587 1.712h-1.665l3.364-8.56h.657l3.392 8.56h-1.653zm-6.653 0h-1.48V7.207H11.68v3.804h-1.497V2.567h1.497v3.308h3.358V2.567h1.48v8.444zM5.436 7.944c-.17 0-.395-.013-.68-.04v3.107H3.26V2.572a75.4 75.4 0 0 1 1.75-.063c1.333 0 2.308.204 2.924.614.617.41.925 1.048.925 1.916 0 1.937-1.141 2.905-3.422 2.905zM0 13.21h66V.368H0v12.841z"
          mask="url(#d)"
        />
      </g>
      <path
        fill="currentColor"
        d="M43.554 65.942h2.029l-1.015-3.118zM5.212 61.632c-.158 0-.31.008-.455.028v2.703c.253.027.453.04.599.04.683 0 1.182-.114 1.497-.342.315-.229.472-.601.472-1.116 0-.876-.704-1.313-2.113-1.313M29.248 61.873c-.312-.165-.801-.248-1.47-.248-.16 0-.33.012-.506.034v2.347c.276.023.488.035.634.035.625 0 1.083-.089 1.374-.266.289-.176.434-.52.434-1.032 0-.415-.156-.705-.466-.87M20.089 65.942h2.028l-1.014-3.118z"
      />
      <path fill="currentColor" d="M32.18 68.769l-.006.033h.028z" />
    </g>
  </svg>
);
