// import Head from 'next/head'
import React from 'react';
import Layout from '../components/Layout';
import OpenGraphMeta from '../components/OpenGraphMeta';
import {Container} from 'reactstrap';

export const StripeSuccess = (props) => {
  return (
    <Layout headerStyle="card">
      <OpenGraphMeta title={'Clever RX'} />
      <div className="error-block">
        <Container className="text-center">
          <h1>Stripe Account Setup!</h1>
          <p>
            Your Stripe account is now configured. <a href={"https://portal.cleverrx.com/"}>Click here</a> to log into the Partner Portal and finalize your Clever RX account.
          </p>
        </Container>
      </div>
    </Layout>
  );
};
