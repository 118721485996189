import React from 'react';
import {Row, Col, Container} from 'reactstrap';

const PartnerIconA = () => (
  <img alt="Partner Icon" title="Partner Icon" src="/static/images/for-partners-icon-a.svg" className="img-fluid" />
);
const PartnerIconB = () => (
  <img alt="Partner Icon" title="Partner Icon" src="/static/images/for-partners-icon-b.svg" className="img-fluid" />
);
const PartnerIconC = () => (
  <img alt="Partner Icon" title="Partner Icon" src="/static/images/for-partners-icon-c.svg" className="img-fluid" />
);

const ThreeCol = () => (
  <section className="three-col text-center">
    <Container fluid>
      <h2>Partner today and get access to benefits exclusive to Clever RX: </h2>
      <div className="row">
        <Col xs="12" sm="6">
          <ul>
            <li>State-of-the-art tools, including AI-based app</li>
            <li>Partner portal</li>
          </ul>
        </Col>
        <Col xs="12" sm="6">
          <ul>
            <li>Co-branded cards, website and app</li>
            <li>The most competitive compensation plan</li>
            <li>Viral sharing features to gain more clients</li>
          </ul>
        </Col>
      </div>
      <h2>GET STARTED IN 3 EASY STEPS</h2>
      <Row>
        <Col xs="12" md="4">
          <div className="img-wrap">
            <PartnerIconA />
          </div>
          <h4>JOIN US:</h4>
          <p>
            Create your profile, claim your branded website and order your personalized Clever RX prescription savings
            cards.
          </p>
        </Col>
        <Col xs="12" md="4">
          <div className="img-wrap">
            <PartnerIconB />
          </div>
          <h4>ENGAGE:</h4>
          <p>
            Distribute your partner-branded savings cards and app to your clients, employees and healthcare providers.
          </p>
        </Col>
        <Col xs="12" md="4">
          <div className="img-wrap">
            <PartnerIconC />
          </div>
          <h4>BUILD:</h4>
          <p>
            Build a great business and earn compensation every time patients use your branded cards or app to save money
            on prescriptions.
          </p>
        </Col>
      </Row>
    </Container>
    <style jsx>{`
      .three-col {
        padding: 100px 0 0 0;
      }
      .three-col :global(img) {
        max-width: 100%;
        margin: 0 auto;
        vertical-align: middle;
      }
      .img-wrap {
        min-height: 84px;
        display: flex;
        align-items: center;
      }
      .row {
        margin: 0 auto 30px auto;
        max-width: 780px;
      }
      ul {
        text-align: left;
      }
      h2 {
        margin: 0 auto 60px auto;
      }
      h4 {
        margin: 30px auto 100px auto;
        max-width: 260px;
      }
      @media (min-width: 768px) {
        .three-col {
          padding: 150px 0;
        }
        h4 {
          margin: 30px auto 0 auto;
        }
      }
    `}</style>
  </section>
);

export default ThreeCol;
