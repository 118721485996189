import React from 'react';
import Collapsible from 'react-collapsible';
import styles from './collapsible-faq.css.js';

const FAQ = () => {
  return (
    <section className="faq">
      <h2>Frequently Asked Questions</h2>
      <div className="faq-wrap">
        <Collapsible trigger="Is Clever RX insurance?" easing="ease-in-out">
          <p>
            No, Clever RX prescription savings card is not insurance. However, you can use the Clever RX card regardless
            if you have insurance or not. Prescription drug prices can be a lot more than you expect even if you do have
            insurance. With Clever RX you can save extra money on your prescriptions at no additional cost to you. Now
            that's clever!{' '}
          </p>
        </Collapsible>
        <Collapsible trigger="Why use a Clever RX savings card when you have insurance?" easing="ease-in-out">
          <p>
            Think about it, even with the best health insurance plan, when you go to the doctor you still have some
            out-of-pocket costs. Clever RX has negotiated prescription discounts on your behalf to save you additional
            money. Also, some pharmaceuticals may not be covered by your insurance, or your deductible may be too high.
            Clever RX to the rescue!{' '}
          </p>
        </Collapsible>
        <Collapsible trigger="Where is Clever RX accepted?" easing="ease-in-out">
          <p>
            Clever RX is accepted at exceptional pharmacies nationwide – including major chains like CVS, Walgreens,
            Kroger, Rite Aid, Safeway, Walmart and many more!
          </p>
        </Collapsible>
        <Collapsible trigger="How much money can I save off prescriptions with Clever RX?" easing="ease-in-out">
          <p>
            Clever RX has negotiated exclusive discounts on RX prescriptions and pharmaceuticals saving you up to 80%
            off retail.
          </p>
        </Collapsible>
        <Collapsible trigger="What if the pharmacist won't accept Clever RX?" easing="ease-in-out">
          <p>
            Let's give the pharmacist the benefit of the doubt. Mistakes happen, but they can accept your savings card
            for applicable RX discounts. If you run into any issues, have them give us a call at 800-873-1195. We're
            here to advocate on your behalf.
          </p>
        </Collapsible>
      </div>
      <style jsx>{`
        .faq {
          width: 100%;
          margin: 0;
          padding: 80px 0 60px 0;
        }
        .faq-wrap {
          border: solid 2px #24292d;
        }
        h2 {
          margin: 0 auto 30px auto;
          text-align: center;
        }
        @media (min-width: 768px) {
          .faq-wrap {
            width: 100%;
            max-width: 780px;
            margin: 0 auto;
          }
          h2 {
            margin: 0 auto 50px auto;
          }
        }
      `}</style>
      <style jsx global>
        {styles}
      </style>
    </section>
  );
};

export default FAQ;
