import React from 'react';
import {Element} from 'react-scroll';
import HowItWorks from '../home/HowItWorks';
import TopSection from '../home/TopSection';
import {TextApp} from '../TextApp';

const TopSectionCard = ({companyName, urlSlug}: {companyName: string; urlSlug: string}) => {
  return (
    <div className="d-print-none">
      {companyName && <h1>{companyName}</h1>}
      <TopSection
        subtitle={undefined}
        joinUsButton={undefined}
        title={
          (urlSlug === 'pet'
            ? 'Never overpay for prescriptions for you or your pet again. '
            : 'Never Overpay for Prescriptions Again. ') + "Now That's Clever!"
        }
      />
      {urlSlug && <TextApp urlSlug={urlSlug} containerStyle={{zIndex: 100, position: 'relative'}} />}
      <div className="bg-fill">
        <Element name="How">
          <HowItWorks />
        </Element>
      </div>
      <style jsx>{`
        h1 {
          margin: 0 auto 10px auto;
          font-weight: 300;
          width: 100%;
          max-width: 570px;
          font-size: 22px;
          line-height: 1.4;
          text-align: center;
        }
        .bg-fill {
          background: #24292d;
        }
        @media (min-width: 768px) {
          h1 {
            font-size: 35px;
            letter-spacing: 5.6px;
            margin: 60px auto 10px auto;
            letter-spacing: 5.6px;
          }
        }
      `}</style>

      <style jsx>{`
        .clearfix {
          margin: 0;
          padding: 20px 0 0 0;
        }
        .rx-header {
          text-align: center;
          margin-bottom: 30px;
        }
      `}</style>
      <style jsx>{`
        .rx-lookup-wrap {
          width: 100%;
          box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.15);
          padding: 20px 20px;
          margin: 0 0 60px 0;
          background: #1e2126;
          z-index: 100;
          position: relative;
        }
        .results-header {
          margin: 60px 0 30px 0;
          color: #fff;
          font-size: 20px;
          text-align: center;
          font-weight: 600;
          letter-spacing: 2.5px;
        }
        .drugs-auto-wrap {
          position: relative;
        }
        .drugs-auto-list {
          position: absolute;
          top: 152px;
          left: 0;
          padding: 2px;
          color: #fff;
          width: 100%;
          box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.15);
          z-index: 100;
        }
        .drug-item {
          border-bottom: 1px solid #3b4045;
          padding: 10px;
          background: #24292d;
        }
        .drug-item:hover {
          background: #444;
          cursor: pointer;
        }

        .toggle-results {
          display: block;
          margin-top: 60px;
          text-align: center;
        }
        .toggle-results a {
          padding: 10px;
          cursor: pointer;
        }
        @media (min-width: 768px) {
          .results-table :global(.row) {
            border: none;
          }
          .results-table :global(.col) {
            border-top: 2px dotted #3b4045;
            padding: 20px 10px;
            flex: 1;
          }
          .rx-lookup-bg {
            padding: 60px 0;
            background: #24292d;
          }
          .rx-lookup-wrap {
            margin: 0 auto;
            max-width: 940px;
            padding: 80px 120px;
            border-radius: 60px;
          }
        }
        .results-table :global(.pharmacy-name) {
          //text-transform: uppercase;
        }
        .results-table :global(.pharmacy-name img) {
          max-width: 100%;
          margin-top: 10px;
          display: block;
          clear: both;
        }
        .modal-link {
          cursor: pointer;
        }
        :global(.modal-btn) {
          min-width: 100px;
          margin-right: 20px;
        }
      `}</style>
    </div>
  );
};

export default TopSectionCard;
