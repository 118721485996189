import React from 'react';
import Layout from '../components/Layout';
import OpenGraphMeta from '../components/OpenGraphMeta';
import HowItWorks from '../components/home/HowItWorks';
import {Element} from 'react-scroll';
import {defaultCustomization, RxPricing} from '../containers/RxPricing';
import {TextApp} from '../components/TextApp';

const DrugPriceLookup = () => (
  <div className="rx">
    <Layout>
      <OpenGraphMeta title={'Drug Price Lookup | Clever RX'} />
      <section className="rx-lookup-bg">
        <div className="form-wrapper">
          <RxPricing customization={{...defaultCustomization, groupCode: '3170', memberId: '10002W'}} />
        </div>
      </section>
      <TextApp urlSlug={'3170-10001'} containerStyle={{zIndex: 1, position: 'static'}} />
      <Element name="How">
        <HowItWorks />
      </Element>
    </Layout>
    <style jsx>{`
      .rx-lookup-bg {
        background: #24292d;
        padding: 0;
        width: 100%;
        display: block;
        position: relative;
        z-index: 100;
      }
      h1 {
        text-align: center;
        padding-top: 60px;
        margin: 0 auto;
      }
    `}</style>
  </div>
);

export default DrugPriceLookup;
