import React from 'react';

const Step2 = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="297" height="16" viewBox="0 0 297 16">
    <g fill="none" fillRule="evenodd">
      <path stroke="#383E42" strokeLinecap="square" strokeWidth="3" d="M16.288 8h277.433" />
      <circle cx="150" cy="8" r="5" fill="#383E42" />
      <g transform="translate(71)">
        <circle cx="8" cy="8" r="8" fill="#FFA500" />
        <circle cx="8" cy="8" r="3" fill="#24292D" />
      </g>
      <path stroke="#FFA500" strokeLinecap="square" strokeWidth="3" d="M10.16 8.184h63.753" />
      <circle cx="221" cy="8" r="5" fill="#383E42" />
      <circle cx="292" cy="8" r="5" fill="#383E42" />
      <circle cx="8" cy="8" r="8" fill="#FFA500" />
    </g>
  </svg>
);

export default Step2;
