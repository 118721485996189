import React from 'react';
import {Button, Form, Alert} from 'reactstrap';
import {Field} from 'react-final-form';
import FieldGroup from './FieldGroup';
import {required} from '../lib/validators';
import SelectAdapter from './SelectAdapter';
import partnerTypes from '../data/partnerTypes';
import {Link} from 'react-router-dom';

const RegisterForm = (props) => (
  <Form onSubmit={props.handleSubmit}>
    {props.message && (
      <Alert color="success" className="text-center">
        {props.message}
      </Alert>
    )}
    <Field
      id="firstName"
      name="firstName"
      label="First Name *"
      type="text"
      component={FieldGroup}
      validate={required}
    />
    <Field id="lastName" name="lastName" label="Last Name *" type="text" component={FieldGroup} validate={required} />
    <Field id="email" name="email" label="Email *" type="email" component={FieldGroup} validate={required} />
    <Field id="phone" name="phoneNumber" label="Phone Number" type="tel" pattern="[0-9]*" component={FieldGroup} />
    <Field id="referredBy" name="referredBy" label="Who Referred You?" type="text" component={FieldGroup} />
    <Field
      id="partnerType"
      name="partnerType"
      type="select"
      label="I am a"
      component={SelectAdapter}
      options={partnerTypes}
    />
    <Field
      id="notes"
      name="notes"
      label="Notes"
      placeholder={'Please provide a short description of how Clever RX can help you?'}
      type="textarea"
      component={FieldGroup}
    />
    <p className="text-center">
      By signing up, you agree to our <Link to="/terms">Terms & Conditions</Link> and{' '}
      <Link to="/privacy-policy">Privacy Policy</Link>.
    </p>
    {props.submitFailed && <div className="text-danger">{props.submitError}</div>}
    <div className="clearfix">
      <Button block size="lg" color="primary" disabled={props.submitting} type="submit">
        {props.submitting ? 'Submitting...' : 'Submit'}
      </Button>
    </div>
    <style jsx>{`
      .clearfix {
        border-top: solid 2px #24292d;
        margin: 40px 0 0 0;
        padding: 40px 0 0 0;
      }
      p {
        color: #8c99a7;
        font-size: 14px;
        line-height: 1.89;
        margin: 25px 0 0 0;
      }
      h1 {
        margin: 60px 0;
      }
    `}</style>
  </Form>
);

export default RegisterForm;
