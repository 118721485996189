import React from 'react';

const Step5 = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="300" height="16" viewBox="0 0 300 16">
    <g fill="none" fillRule="evenodd">
      <path stroke="#383E42" strokeLinecap="square" strokeWidth="3" d="M10.71 8.184h282.906" />
      <path stroke="#FFA500" strokeLinecap="square" strokeWidth="3" d="M10.698 8.184h277.925" />
      <circle cx="79" cy="8" r="5" fill="#383E42" />
      <circle cx="150" cy="8" r="5" fill="#383E42" />
      <circle cx="221" cy="8" r="5" fill="#383E42" />
      <circle cx="292" cy="8" r="5" fill="#383E42" />
      <circle cx="8" cy="8" r="8" fill="#FFA500" />
      <circle cx="79" cy="8" r="8" fill="#FFA500" />
      <circle cx="150" cy="8" r="8" fill="#FFA500" />
      <circle cx="221" cy="8" r="8" fill="#FFA500" />
      <g transform="translate(284)">
        <circle cx="8" cy="8" r="8" fill="#FFA500" />
        <circle cx="8" cy="8" r="3" fill="#24292D" />
      </g>
    </g>
  </svg>
);

export default Step5;
