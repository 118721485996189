import React from 'react';

const ForPartnersMobile = () => (
  <section className="for-partners">
    <h2>With healthcare costs out of control, the time for Clever RX is now.</h2>
    <p>
      Patients and employees need your help! Until now, prescription saving was an afterthought. With high deductible
      health plans, high copays and the underinsured population – over 70% of Americans can save money using our
      prescription drug savings card. Clever RX partners with the healthcare community to bring state-of-the-art,
      money-saving tools to patients and employees.
    </p>
    <p>
      Clever RX saves up to 80% off prescription drugs and often beats the average copay. You can help your clients,
      build a great business and drive down healthcare costs. Now that’s clever!
    </p>
    <style jsx>{`
      .for-partners {
        padding: 0 20px 20px 20px;
        background: #24292d;
        text-align: center;
        position: relative;
        z-index: 100;
        border-bottom: 2px solid #383e42;
      }
      ul {
        text-align: left;
        margin-bottom: 0px;
      }
      h2 {
        margin: 0 auto 20px auto;
        text-transform: uppercase;
        letter-spacing: 2.8px;
      }
      p,
      .row {
        line-height: 1.89;
        margin: 0 auto 30px auto;
        max-width: 860px;
      }
      .for-partners :global(.btn) {
        max-width: 300px;
        margin: 0 auto;
      }
      @media (min-width: 768px) {
        .for-partners {
          padding: 80px 0 20px 0;
          border: none;
        }
      }
    `}</style>
  </section>
);

export default ForPartnersMobile;
