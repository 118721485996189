export class TextFormatter {
  private static number = '#';
  private static letter = 'A';
  private static numberOrLetter = '@';

  static format(textFormat: TextFormat, text: string): string {
    const {format, prefix} = textFormat;
    const formatArr = format.split('');

    const specials = formatArr.filter((value, index, self) => {
      return self.indexOf(value) === index;
    });
    text = text.replace(prefix, '').replace(new RegExp(`[${specials.join('')}]`, 'g'), '');

    const textArr = text.split('');
    let newText = prefix;
    let textIndex = 0;
    loop: for (const formatElement of formatArr) {
      if (!textArr[textIndex]) {
        break;
      }

      switch (formatElement) {
        case TextFormatter.number:
          if (textArr[textIndex].match(/[0-9]/)) {
            newText += textArr[textIndex];
          } else {
            break loop;
          }
          textIndex++;
          break;
        case TextFormatter.letter:
          if (textArr[textIndex].match(/[a-zA-Z]/)) {
            newText += textArr[textIndex].toUpperCase();
          } else {
            break loop;
          }
          textIndex++;
          break;
        case TextFormatter.numberOrLetter:
          if (textArr[textIndex].match(/[a-zA-Z0-9]/)) {
            newText += textArr[textIndex].toUpperCase();
          } else {
            break loop;
          }
          textIndex++;
          break;
        default:
          newText += formatElement;
          break;
      }
    }
    return newText;
  }

  static clean({format, prefix}: TextFormat, text: string): string {
    text = text.replace(prefix, '');
    const formatArr = format.split('');
    const specials = formatArr.filter((value, index, self) => {
      return self.indexOf(value) === index;
    });
    text = text.replace(new RegExp(`[${specials.join('')}]`, 'g'), '');
    return text;
  }

  static valid({format, prefix}: TextFormat, text: string): boolean {
    const formatArr = format.split('');

    const specials = formatArr.filter((value, index, self) => {
      return self.indexOf(value) === index;
    });
    text = text.replace(prefix, '').replace(new RegExp(`[${specials.join('')}]`, 'g'), '');

    const textArr = text.split('');
    let textIndex = 0;
    loop: for (const formatElement of formatArr) {
      if (!textArr[textIndex]) {
        return false;
      }

      switch (formatElement) {
        case TextFormatter.number:
          if (textArr[textIndex].match(/[0-9]/)) {
            textIndex++;
          } else {
            return false;
          }
          break;
        case TextFormatter.letter:
          if (textArr[textIndex].match(/[a-zA-Z]/)) {
            textIndex++;
          } else {
            return false;
          }
          break;
        case TextFormatter.numberOrLetter:
          if (textArr[textIndex].match(/[a-zA-Z0-9]/)) {
            textIndex++;
          } else {
            return false;
          }
          break;
      }
    }
    return true;
  }
}

type TextFormat = {prefix: string; format: string};

export const TextFormats = {
  groupCode: {prefix: '', format: '@@@@@@@@@@'},
  zipcode: {prefix: '', format: '#####'},
  activationCode: {prefix: '', format: '#####'},
  phoneNumber: {
    prefix: '+1 ',
    format: '(###) ###-####',
  },
  dob: {
    prefix: '',
    format: '##/##/####',
  },
};
