import React from 'react';
export const qfc = (
  <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="250px" height="196.37px">
    <path
      fill="currentColor"
      d="M247.3,193.78l-0.07-146.66C110.67-53.73,2.69,47.15,2.69,47.15
	l0.08,146.52L247.3,193.78"
    />
    <path
      fill="none"
      d="M249.5,195.98L0.58,195.86L0.5,46.19l0.7-0.65c1.09-1.02,110.9-100.96,247.34-0.18l0.89,0.66L249.5,195.98z
	 M4.97,191.48l240.14,0.11l-0.07-143.35C117.63-45.12,14.34,39.89,4.89,48.13L4.97,191.48z M67.52,40.21
	c-1.62,0-2.94-1.31-2.94-2.94c0-1.63,1.32-2.94,2.94-2.94c1.62,0,2.94,1.31,2.94,2.94C70.45,38.9,69.14,40.21,67.52,40.21
	 M79.6,41.6c1.62,0,2.94-1.32,2.94-2.94c0-1.62-1.32-2.94-2.94-2.94c-1.62,0-2.94,1.32-2.94,2.94C76.66,40.28,77.98,41.6,79.6,41.6
	 M91.51,45.37c1.62,0,2.94-1.31,2.94-2.94c0-1.62-1.32-2.94-2.94-2.94c-1.62,0-2.94,1.32-2.94,2.94
	C88.57,44.06,89.89,45.37,91.51,45.37 M102.42,50.83c1.62,0,2.94-1.32,2.94-2.94c0-1.62-1.32-2.94-2.94-2.94
	c-1.62,0-2.94,1.32-2.94,2.94C99.49,49.51,100.8,50.83,102.42,50.83 M55.39,41.6c1.63,0,2.94-1.32,2.94-2.94
	c0-1.62-1.31-2.94-2.94-2.94c-1.62,0-2.94,1.32-2.94,2.94C52.45,40.28,53.77,41.6,55.39,41.6 M43.48,45.37
	c1.62,0,2.94-1.31,2.94-2.94c0-1.62-1.32-2.94-2.94-2.94c-1.62,0-2.94,1.32-2.94,2.94C40.55,44.06,41.86,45.37,43.48,45.37
	 M32.57,50.83c1.62,0,2.94-1.32,2.94-2.94c0-1.62-1.32-2.94-2.94-2.94c-1.62,0-2.94,1.32-2.94,2.94
	C29.63,49.51,30.95,50.83,32.57,50.83 M67.4,40.08l-1.05-0.17c0.3,7.94-9.52,8.51-9.56,0.96l-2.32-0.13
	c2.58,7.51-7.29,11.02-9.5,3.81l-2.1,0.13c3.23,7.26-5.69,11.43-8.52,4.43l-1.71,0.89c0,0,6.87,12.58,9.44,17.19
	c6.93-5.36,16.04-7.96,25.32-7.8h0.1c9.28-0.16,18.38,2.44,25.32,7.8c2.58-4.61,9.45-17.19,9.45-17.19l-1.72-0.89
	c-2.83,6.99-11.74,2.83-8.52-4.43l-2.1-0.13c-2.22,7.22-12.08,3.71-9.5-3.81l-2.33,0.13c-0.04,7.55-9.85,6.98-9.55-0.96l-1.05,0.17
	H67.4 M218.09,120.32l-0.64,1.94c-2.81,8.86-13.15,12.88-18.48,12.02c-11.17-1.81-14.44-9.94-14.44-23.77
	c0-11.67,2.37-20.87,12.79-22.3c1.71-0.23,4.36,0.81,4.36,0.81c0.21,0,4.03,1.4,7.27,7.01c0.65,1.3,3.67,5.4,4.75,8.64l0.86,0.44
	h5.62l0.65-0.44V86.32l-0.65-0.43h-5.4l-0.43,0.43c0,0-3.67,4.11-6.05,4.11c-1.09,0-1.09,0-5.84-3.02
	c-9.5-6.05-32.59,0.43-32.84,23.33c-0.23,21.73,14.25,26.26,24.2,27c9.49,0.71,23.63-4.24,26-17.42l-1.08-0.22h0.43L218.09,120.32
	 M28.81,102.51c0,40.43,39.2,38.79,39.2,38.79l5.74-0.21c0.42,0,6.98,12.93,16.63,12.93c18.47,0,16.01-22.16,16.01-22.16
	s-6.15,6.77-11.29,6.77c-2.88,0-5.74-2.25-5.74-2.25s17.44-8,17.44-33.87C106.8,51.2,28.81,51.2,28.81,102.51z M87.1,102.51
	c0,17.44-8.62,27.7-8.62,27.7s-3.89-1.03-7.39-1.03c-5.13,0-12.52,2.67-12.52,2.67s-9.24-7.39-9.24-29.35
	C49.34,56.12,87.1,56.12,87.1,102.51z M61.24,134.52c0,0,2.25-0.82,4.31-0.82c3.28,0,6.16,3.28,6.16,3.28s-1.44,1.64-4.11,1.64
	C64.73,138.63,61.24,134.52,61.24,134.52z M27.65,150.1h50.94c0,0-1.08-1.08-2.15-2.17c-1.09-1.08-2.17-2.37-2.17-2.37H27.65V150.1z
	 M106.54,145.56c0,0-0.65,1.29-1.51,2.37c-0.65,0.86-1.51,2.17-1.51,2.17h119.42v-4.54H106.54z M110.87,85.89v1.73
	c0,0,7.13,0,7.99,0.64c0.86,0.65,0.65,3.89,0.65,3.89v38.02c0,2.16-1.94,5.18-4.54,5.18c-2.16,0-4.1,0-4.1,0v1.73h33.05v-1.73
	c0,0-1.94,0-3.02,0c-3.24,0-4.75-3.24-4.75-5.62v-18.36l0.44-0.43c0,0,1.72-0.21,3.45-0.21c4.54,0,8,9.29,8,9.29l0.86,0.43h5.18
	l0.65-0.43V99.07l-0.65-0.44h-5.18l-0.86,0.44c0,0-3.68,9.94-8,9.94c-1.29,0-3.45-0.21-3.45-0.21l-0.44-0.44v-16.2
	c0,0,0-2.81,0.65-3.89c0.21-0.64,2.59-0.64,2.59-0.64s8.65,0,9.51,0c3.89,0,11.23,16.85,11.23,16.85l0.65,0.43h4.97l0.64-0.43V86.54
	l-0.64-0.65H110.87 M29.29,173.12c1.08,1.7,2.27,2.29,3.83,2.8c0.17,0.06,0.45,0.06,0.45,0.38c0,0.08-0.02,0.19-0.04,0.24
	c-0.02,0.22-0.04,0.38-0.04,0.6c0,1.43,0.92,2.61,1.79,3.21c1,0.64,2.13,0.92,3.26,0.92c0.53,0,3.25-0.21,3.25-1.26
	c0-0.3-0.15-0.41-0.35-0.41c-0.31,0-0.72,0.22-1.12,0.22c-1.25,0-1.66-0.6-2.37-1.86c-0.37-0.7-0.69-1.14-1.14-1.7
	c-0.04-0.03-0.12-0.13-0.12-0.21c0-0.13,0.24-0.24,0.33-0.27c2.83-1.05,4.87-4.37,4.87-8.47c0-5.59-3.38-9.01-7.22-9.01
	c-1.96,0-3.57,0.78-4.98,2.7c-1.28,1.75-1.96,3.96-1.96,6.47C27.72,169.53,28.28,171.58,29.29,173.12 M37.88,172.34
	c-0.47,1.43-1.36,2.83-2.57,2.83c-1.47,0-2.53-1.46-3.18-3.13c-0.65-1.65-0.95-3.75-0.95-5.64c0-2.78,0.78-7.15,3.34-7.15
	c2.88,0,3.96,5.64,3.96,8.79C38.48,169.4,38.27,171.13,37.88,172.34 M52.73,172.72v-8.6c0-0.38,0.09-0.92-0.26-0.92
	c-0.61,0-1.21,0.05-1.82,0.05c-0.61,0-1.22-0.05-1.83-0.05c-0.25,0-0.59-0.03-0.59,0.45c0,0.35,0.23,0.49,0.45,0.54
	c0.37,0.08,0.76,0.16,0.98,0.59c0.21,0.46,0.16,1.53,0.16,2.05v4.42c0,0.65,0.06,1.16-0.3,1.65c-0.35,0.49-0.96,0.92-1.47,0.92
	c-0.45,0-0.92-0.3-1.16-0.83c-0.24-0.54-0.3-1.48-0.3-2.11v-7.26c0-0.37-0.06-0.42-0.32-0.42c-0.61,0-1.19,0.05-1.81,0.05
	c-0.61,0-1.19-0.05-1.81-0.05c-0.25,0-0.59-0.03-0.59,0.42c0,0.39,0.23,0.52,0.45,0.57c0.38,0.11,0.77,0.13,0.98,0.59
	c0.21,0.46,0.18,1.53,0.18,2.02v4.32c0,1.16,0.08,2.4,0.62,3.35c0.51,0.86,1.43,1.29,2.22,1.29c1.12,0,1.99-0.7,2.88-1.6
	c0.08-0.08,0.25-0.27,0.37-0.27c0.25,0,0.18,0.81,0.18,0.97c0,0.3-0.12,1,0.23,1c0.21,0,0.62-0.25,0.82-0.32l2.05-0.81
	c0.23-0.05,0.9-0.19,0.9-0.64c0-0.33-0.18-0.4-0.37-0.44C53.06,173.58,52.71,173.42,52.73,172.72 M62.81,171.8v-5.88
	c0-0.89-0.04-1.73-0.66-2.27c-0.69-0.57-1.71-0.76-2.51-0.76c-1.28,0-2.61,0.4-3.67,1.43c-0.47,0.43-1.33,1.64-1.33,2.51
	c0,0.46,0.32,0.62,0.61,0.62c0.47,0,1.19-0.3,1.61-0.59c0.52-0.3,0.64-0.68,0.84-1.32c0.25-0.81,0.71-1.7,1.41-1.7
	c1.02,0,0.88,1.89,0.88,2.8v0.89c0,0.08-0.08,0.11-0.21,0.27c-0.47,0.49-1.18,0.97-1.87,1.24c-0.66,0.27-1.72,0.92-2.25,1.46
	c-0.69,0.68-1.06,1.46-1.06,2.62c0,1.59,0.88,2.64,2.04,2.64c1.94,0,3.16-2.16,3.4-2.16c0.18,0,0.29,0.54,0.33,0.62
	c0.31,0.92,0.69,1.29,1.45,1.29c0.82,0,1.55-0.54,2.16-1.24c0.2-0.21,0.51-0.64,0.51-1.02c0-0.19-0.09-0.38-0.25-0.38
	c-0.16,0-0.35,0.25-0.47,0.32c-0.18,0.16-0.27,0.27-0.49,0.27C62.71,173.47,62.81,172.2,62.81,171.8 M59.98,170.02v1.29
	c0,0.46-0.08,0.92-0.2,1.22c-0.26,0.59-0.9,1.02-1.41,1.02c-0.67,0-1.06-0.73-1.06-1.57c0-0.97,0.47-1.56,1.04-2.05
	c0.2-0.16,1.21-1.05,1.41-1.05C60.02,168.89,59.98,169.94,59.98,170.02 M69.18,173.31v-15.54c0-0.37,0.09-1.29-0.33-1.29
	c-0.21,0-0.53,0.18-0.73,0.3c-0.86,0.43-1.69,0.81-2.59,0.97c-0.28,0.05-0.85,0.11-0.85,0.62c0,0.3,0.23,0.37,0.39,0.4
	c1.1,0.21,1.04,0.48,1.04,1.96v12.57c0,0.08,0,1-1.2,1.19c-0.23,0.05-0.53,0.13-0.53,0.62c0,0.41,0.2,0.46,0.47,0.46
	c0.94,0,1.89-0.05,2.83-0.05c0.9,0,1.81,0.05,2.75,0.05c0.25,0,0.45-0.05,0.45-0.46c0-0.49-0.37-0.57-0.62-0.62
	C69.18,174.31,69.18,173.4,69.18,173.31 M75.36,171.88v-8.34c0-0.27,0.04-0.97-0.27-0.97c-0.42,0-0.59,0.3-0.71,0.48
	c-0.61,0.9-1.69,1.54-2.54,1.84c-0.21,0.08-0.84,0.16-0.84,0.65c0,0.45,0.32,0.43,0.59,0.45c0.92,0.16,0.86,0.78,0.86,1.91v3.97
	c0,0.62,0,1.59-0.14,2.16c-0.09,0.37-0.7,0.4-0.98,0.45c-0.23,0.06-0.47,0.21-0.47,0.57c0,0.46,0.34,0.51,0.59,0.51
	c0.82,0,1.61-0.05,2.43-0.05c0.83,0,1.65,0.05,2.47,0.05c0.25,0,0.61-0.05,0.61-0.51c0-0.35-0.26-0.51-0.47-0.57
	c-0.36-0.08-0.97-0.05-1.07-0.45C75.3,173.47,75.36,172.5,75.36,171.88 M72.14,159.58c0,1.35,0.77,2.18,1.71,2.18
	c0.94,0,1.76-0.83,1.76-2.21c0-1.33-0.84-2.05-1.72-2.05C73.02,157.5,72.14,158.29,72.14,159.58 M81.09,170.05v-4.48
	c0-1.09,0-1.05,0.64-1.05h0.97c0.52,0,0.67,0.03,0.67-0.73c0-0.68-0.3-0.54-0.71-0.54h-1.02c-0.51,0-0.63,0.03-0.63-0.73v-1.86
	c0-0.38,0.08-1-0.33-1c-0.33,0-0.53,0.54-0.66,0.89c-0.57,1.38-1.55,2.34-2.61,2.94c-0.21,0.11-0.62,0.32-0.62,0.73
	c0,0.43,0.62,0.3,0.88,0.3c0.2,0,0.43,0.08,0.47,0.35c0.04,0.22,0.06,0.92,0.06,1.14v5.64c0,1,0.12,2.16,0.57,2.99
	c0.39,0.73,1.16,1.14,1.81,1.14c0.9,0,1.88-0.52,2.55-1.38c0.16-0.22,0.55-0.76,0.55-1.1c0-0.22-0.1-0.38-0.23-0.38
	c-0.3,0-0.73,0.78-1.38,0.78C81,173.69,81.09,171.05,81.09,170.05 M91.82,166.16l-1.29,4.05c-0.04,0.19-0.22,0.86-0.42,0.86
	c-0.16,0-0.35-0.7-0.41-0.84l-1.45-4.15c-0.09-0.27-0.32-0.86-0.32-1.19c0-0.54,0.51-0.57,0.77-0.64c0.23-0.08,0.51-0.19,0.51-0.59
	c0-0.46-0.45-0.46-0.7-0.46c-0.77,0-1.55,0.05-2.3,0.05c-0.86,0-1.77-0.05-2.63-0.05c-0.29,0-0.66,0-0.66,0.46
	c0,0.45,0.33,0.54,0.57,0.59c0.37,0.08,0.55,0.08,0.8,0.51c0.32,0.51,0.63,1.4,0.86,1.99l2.9,8.06c0.14,0.41,0.37,1.05,0.37,1.49
	c0,0.4-0.23,0.99-0.41,1.29c-0.41,0.67-0.88,0.51-1.47,0.49c-0.41-0.03-0.86-0.11-1.16,0.43c-0.18,0.35-0.25,0.89-0.25,1.32
	c0,1.43,0.86,1.51,1.72,1.51c1.3,0,1.22-0.41,1.61-2l1.06-3.5l2.97-8.63c0.21-0.65,0.59-1.81,0.92-2.35
	c0.33-0.57,0.49-0.51,0.98-0.62c0.27-0.06,0.55-0.16,0.55-0.62c0-0.43-0.25-0.43-0.51-0.43c-0.57,0-1.12,0.05-1.69,0.05
	c-0.57,0-1.12-0.05-1.69-0.05c-0.23,0-0.62,0-0.62,0.43c0,0.57,0.45,0.59,0.74,0.62c0.37,0.03,0.88,0.21,0.88,0.83
	C92.05,165.46,91.91,165.84,91.82,166.16 M105.09,164.97v-3.86c0-0.3,0-0.75,0.2-0.95c0.23-0.21,0.86-0.16,1.1-0.16
	c0.62,0,1.34-0.03,1.91,0.35c0.53,0.35,0.68,0.75,0.98,1.43c0.18,0.37,0.39,1.02,0.76,1.02c0.3,0,0.39-0.38,0.39-0.73v-2.59
	c0-0.76-0.13-0.78-0.64-0.78c-1.26,0-2.55,0.11-3.81,0.11c-1.81,0-3.65-0.11-5.45-0.11c-0.28,0-0.47,0.11-0.47,0.54
	c0,0.46,0.37,0.46,0.63,0.51c1.25,0.27,1.17,1,1.17,2.62v9.6c0,1.75,0.06,2.21-1.24,2.51c-0.23,0.06-0.52,0.19-0.52,0.62
	c0,0.4,0.2,0.5,0.47,0.5c0.94,0,1.88-0.1,2.82-0.1c1.02,0,2.01,0.1,3.02,0.1c0.25,0,0.47-0.1,0.47-0.5c0-0.49-0.37-0.57-0.62-0.62
	c-1.24-0.27-1.16-0.92-1.16-2.51v-3.45c0-0.73-0.04-1.1,0.61-1.16l0.85-0.06c2.08,0,1.4,2.59,2.34,2.59c0.33,0,0.41-0.4,0.41-0.76
	c-0.04-0.75-0.08-1.57-0.08-2.32c0.04-0.76,0.04-1.57,0.08-2.32c0-0.37-0.08-0.7-0.39-0.7c-0.35,0-0.49,0.54-0.57,0.89
	c-0.16,0.7-0.39,1.29-0.94,1.43c-0.38,0.11-1.24,0.11-1.65,0.11C105.05,166.24,105.09,165.78,105.09,164.97 M111.21,169.35
	c0,4.04,2.57,6.42,5.39,6.42c2.85,0,5.61-2.56,5.61-6.63c0-1.84-0.72-3.56-1.8-4.66c-1.04-1.09-2.34-1.57-3.65-1.57
	C114.05,162.9,111.21,165.41,111.21,169.35 M119.07,169.78c0,1.78-0.39,5.07-2.12,5.07c-1.96,0-2.59-4.23-2.59-6.22
	c0-1.81,0.51-4.8,2.2-4.8c0.86,0,1.55,1.05,1.91,2.03C118.88,166.94,119.07,168.53,119.07,169.78 M122.98,169.35
	c0,4.04,2.58,6.42,5.4,6.42c2.85,0,5.61-2.56,5.61-6.63c0-1.84-0.73-3.56-1.81-4.66c-1.04-1.09-2.34-1.57-3.65-1.57
	C125.82,162.9,122.98,165.41,122.98,169.35 M130.85,169.78c0,1.78-0.39,5.07-2.12,5.07c-1.96,0-2.59-4.23-2.59-6.22
	c0-1.81,0.51-4.8,2.2-4.8c0.86,0,1.55,1.05,1.91,2.03C130.65,166.94,130.85,168.53,130.85,169.78 M144.76,172.37v-14.76
	c0-0.37-0.02-1.13-0.42-1.13c-0.11,0-0.23,0.05-0.33,0.13c-0.83,0.57-1.73,1-2.65,1.19c-0.28,0.08-1.1,0.08-1.1,0.68
	c0,0.49,0.45,0.54,0.71,0.54c0.9,0,0.73,0.86,0.73,1.89v2.4c-0.21,0-0.55-0.14-0.65-0.19c-0.37-0.16-0.76-0.22-1.16-0.22
	c-2.78,0-5.14,2.76-5.14,6.7c0,3.37,1.67,6.17,4.2,6.17c1.69,0,2.61-1.53,2.82-1.53c0.1,0,0.12,0.24,0.12,0.32l0.04,0.57
	c0.04,0.35,0.1,0.64,0.39,0.64c0.2,0,0.61-0.21,0.79-0.3l2.64-1.24c0.57-0.27,0.47-0.21,0.53-1.11c0-0.27,0.03-0.67-0.27-0.67
	c-0.38,0-0.65,0.99-1.02,0.99C144.66,173.44,144.76,172.63,144.76,172.37 M141.7,167.43v5.85c-0.3,0.35-0.83,0.43-1.14,0.43
	c-1.82,0-2.67-2.67-2.67-4.85c0-1.99,0.55-4.9,2.35-4.9c0.51,0,0.96,0.4,1.24,0.99C141.76,165.54,141.7,166.72,141.7,167.43
	 M164.11,172.96l0.06-1.14c0.03-0.4,0.04-1.02-0.39-1.02c-0.25,0-0.39,0.27-0.49,0.57c-0.35,0.92-0.71,1.81-1.3,2.48
	c-0.59,0.65-1.29,1.03-2.06,1.03c-1.41,0-2.59-1.11-3.33-2.7c-0.69-1.48-0.98-3.42-0.98-5.18c0-1.78,0.32-3.76,1.08-5.23
	c0.74-1.43,1.94-2.43,3.24-2.43c2.65,0,2.71,3.21,3.61,3.21c0.21,0,0.37-0.16,0.37-0.46c0-0.27-0.04-0.51-0.08-0.78l-0.16-1.38
	c-0.1-0.75-0.1-0.86-0.62-1.08c-0.91-0.35-2.03-0.56-2.95-0.56c-1.88,0-3.98,0.73-5.51,2.27c-1.55,1.59-2.53,4.01-2.53,6.68
	c0,2.59,1,5.15,2.49,6.69c1.41,1.46,3.43,1.99,5.16,1.99c1.28,0,2.67-0.32,3.77-1.24C164.09,174.2,164.08,173.85,164.11,172.96
	 M168.78,168.02h4.59c0.67,0,1.1-0.02,1.1-1.02c0-1.16-0.56-2.16-1.22-2.83c-0.83-0.86-1.94-1.28-2.97-1.28
	c-2.68,0-5.33,2.41-5.33,6.26c0,3.83,2.37,6.61,5.14,6.61c1.17,0,2.29-0.46,3.25-1.35c0.3-0.24,1.02-0.97,1.02-1.51
	c0-0.24-0.14-0.4-0.3-0.4c-0.47,0-1.09,1.4-2.59,1.4c-2.11,0-3.4-2.59-3.4-5.2C168.1,167.95,168.3,168.02,168.78,168.02 M171,166.87
	c-0.49,0.16-1.53,0.16-2.06,0.16c-0.3,0-0.64,0.05-0.64-0.44c0-0.57,0.29-1.38,0.54-1.81c0.39-0.59,1.02-0.97,1.61-0.97
	c0.74,0,1.28,0.7,1.28,1.7C171.73,166.19,171.53,166.7,171,166.87 M179.75,164.73v-1.19c0-0.27,0.04-0.97-0.27-0.97
	c-0.41,0-0.59,0.3-0.71,0.49c-0.61,0.89-1.69,1.53-2.53,1.84c-0.21,0.08-0.85,0.16-0.85,0.64c0,0.46,0.32,0.43,0.59,0.46
	c0.92,0.16,0.86,0.78,0.86,1.91v3.96c0,0.65,0.08,1.65-0.2,2.16c-0.2,0.35-0.62,0.38-0.92,0.46c-0.21,0.05-0.47,0.21-0.47,0.59
	c0,0.45,0.35,0.49,0.61,0.49c0.71,0,1.37-0.06,2.08-0.06c0.9,0,1.8,0.06,2.7,0.06c0.22,0,0.45-0.08,0.45-0.49
	c0-0.35-0.18-0.51-0.39-0.59c-0.3-0.11-0.63-0.19-0.83-0.59c-0.2-0.4-0.14-1.51-0.14-2.03v-4.39c0-0.92-0.08-1.48,0.45-2.1
	c0.39-0.46,0.96-0.79,1.47-0.79c0.53,0,1.06,0.35,1.33,0.97c0.27,0.62,0.33,1.65,0.33,2.35v3.96c0,0.51,0.06,1.62-0.14,2.03
	c-0.2,0.4-0.53,0.48-0.82,0.59c-0.21,0.08-0.39,0.24-0.39,0.59c0,0.4,0.23,0.49,0.45,0.49c0.9,0,1.81-0.06,2.71-0.06
	c0.71,0,1.38,0.06,2.08,0.06c0.26,0,0.61-0.03,0.61-0.49c0-0.38-0.25-0.54-0.46-0.59c-0.3-0.08-0.73-0.11-0.92-0.46
	c-0.28-0.51-0.2-1.51-0.2-2.16v-4.5c0-1.32,0.02-2.46-0.8-3.37c-0.71-0.78-1.59-1.11-2.47-1.11
	C181.05,162.9,180.06,164.73,179.75,164.73 M192.12,170.05v-4.48c0-1.09,0-1.05,0.64-1.05h0.96c0.53,0,0.67,0.03,0.67-0.73
	c0-0.68-0.29-0.54-0.71-0.54h-1.02c-0.51,0-0.62,0.03-0.62-0.73v-1.86c0-0.38,0.08-1-0.33-1c-0.33,0-0.52,0.54-0.66,0.89
	c-0.57,1.38-1.55,2.34-2.61,2.94c-0.21,0.11-0.62,0.32-0.62,0.73c0,0.43,0.62,0.3,0.88,0.3c0.2,0,0.43,0.08,0.47,0.35
	c0.03,0.22,0.06,0.92,0.06,1.14v5.64c0,1,0.12,2.16,0.57,2.99c0.39,0.73,1.16,1.14,1.81,1.14c0.9,0,1.88-0.52,2.55-1.38
	c0.16-0.22,0.55-0.76,0.55-1.1c0-0.22-0.1-0.38-0.23-0.38c-0.3,0-0.73,0.78-1.38,0.78C192.03,173.69,192.12,171.05,192.12,170.05
	 M198.6,168.02h4.59c0.67,0,1.1-0.02,1.1-1.02c0-1.16-0.55-2.16-1.22-2.83c-0.82-0.86-1.94-1.28-2.96-1.28
	c-2.69,0-5.33,2.41-5.33,6.26c0,3.83,2.37,6.61,5.14,6.61c1.17,0,2.3-0.46,3.26-1.35c0.3-0.24,1.02-0.97,1.02-1.51
	c0-0.24-0.14-0.4-0.3-0.4c-0.47,0-1.1,1.4-2.59,1.4c-2.12,0-3.4-2.59-3.4-5.2C197.92,167.95,198.11,168.02,198.6,168.02
	 M200.82,166.87c-0.49,0.16-1.53,0.16-2.06,0.16c-0.3,0-0.65,0.05-0.65-0.44c0-0.57,0.3-1.38,0.55-1.81
	c0.39-0.59,1.02-0.97,1.61-0.97c0.74,0,1.28,0.7,1.28,1.7C201.55,166.19,201.35,166.7,200.82,166.87 M209.43,164.95v-1.4
	c0-0.27,0.04-0.97-0.27-0.97c-0.42,0-0.59,0.3-0.71,0.49c-0.61,0.89-1.69,1.53-2.53,1.84c-0.22,0.08-0.85,0.16-0.85,0.64
	c0,0.46,0.32,0.43,0.59,0.46c0.92,0.16,0.86,0.78,0.86,1.91v3.96c0,0.62,0,1.59-0.14,2.16c-0.09,0.38-0.71,0.4-0.98,0.46
	c-0.24,0.05-0.47,0.21-0.47,0.56c0,0.46,0.33,0.52,0.59,0.52c0.83,0,1.61-0.06,2.44-0.06c0.82,0,1.65,0.06,2.47,0.06
	c0.25,0,0.61-0.06,0.61-0.52c0-0.35-0.25-0.51-0.47-0.56c-0.35-0.08-0.96-0.06-1.06-0.46c-0.13-0.57-0.08-1.54-0.08-2.16v-2.67
	c0-0.83-0.16-3.64,0.83-3.64c0.59,0,0.72,1.57,1.98,1.57c0.88,0,1.63-0.98,1.63-2.19c0-1.22-0.85-2.05-1.67-2.05
	C210.59,162.9,209.76,164.95,209.43,164.95 M214.22,172.42l-0.04,1.14c-0.02,0.73,0.02,1.05,0.5,1.43c0.76,0.59,1.91,0.78,2.76,0.78
	c1.06,0,2.24-0.27,3.1-1.1c0.88-0.84,1.48-2.1,1.48-3.59c0-2.26-1.4-3.21-2.81-3.8l-1.07-0.46c-0.56-0.21-1.44-0.59-1.44-1.53
	c0-1.14,0.92-1.46,1.59-1.46c1.16,0,1.41,0.7,2.05,1.84c0.13,0.24,0.39,0.64,0.66,0.64c0.41,0,0.37-0.83,0.43-1.21v-0.65
	c0-0.75-0.04-0.89-0.57-1.05c-0.78-0.27-1.92-0.49-2.71-0.49c-1.74,0-3.94,1.38-3.94,4.08c0,2.56,2.45,3.5,3.93,4.12
	c0.72,0.33,1.6,0.81,1.6,2.03c0,1.22-1.17,1.7-1.93,1.7c-2.55,0-2.31-3.34-3.18-3.34C214.22,171.48,214.24,171.99,214.22,172.42"
    />
  </svg>
);
