import React from 'react';

const LogoSvg = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="373" height="55" viewBox="0 0 373 55" version="1.1" x="0px" y="0px">
    <defs>
      <path id="a" d="M.4.03h54.96V55H.39z" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <path
        fill="#FFF"
        d="M105.88 39.67V16.63c0-.33.26-.59.58-.59h14.22c.3 0 .55.25.55.55v.8a.55.55 0 0 1-.55.54h-12.74v8.97h9.92c.3 0 .55.24.55.54v.8a.55.55 0 0 1-.55.54h-9.92v9.59h12.74c.3 0 .55.24.55.54v.8a.55.55 0 0 1-.55.54h-14.22a.59.59 0 0 1-.59-.58m59 .25l-9.45-23.07a.59.59 0 0 1 .54-.8h1.13c.24 0 .45.14.54.36l8.64 21.52 8.67-21.52a.59.59 0 0 1 .55-.37h1.12c.42 0 .7.42.55.81l-9.45 23.07a.53.53 0 0 1-.49.33h-1.86a.53.53 0 0 1-.5-.33m47.35-.25V16.63c0-.33.26-.59.58-.59h14.23c.3 0 .54.25.54.55v.8a.55.55 0 0 1-.54.54h-12.74v8.97h9.91c.3 0 .55.24.55.54v.8a.55.55 0 0 1-.55.54h-9.91v9.59h12.74c.3 0 .54.24.54.54v.8a.55.55 0 0 1-.54.54h-14.23a.59.59 0 0 1-.58-.58m66.02-16.55c0-3.05-2.18-5.19-5.37-5.19h-6.8v10.42h6.8c3.2 0 5.37-2.18 5.37-5.23m-.2 16.9l-6.62-9.79h-5.34v9.48a.55.55 0 0 1-.55.54h-.97a.55.55 0 0 1-.55-.54V16.6c0-.31.25-.56.56-.56h8.48c4.17 0 7.37 2.65 7.37 7.08 0 4.4-3.13 6.83-6.72 6.97l6.42 9.3a.55.55 0 0 1-.45.86h-1.18a.55.55 0 0 1-.45-.24"
      />
      <path fill="#ffa500" d="M336.17 21.54l-.67 5.31h.4c1.64 0 2.77-1.63 2.77-3.17 0-1.67-1-2.14-2.5-2.14" />
      <g transform="translate(316.7)">
        <path
          fill="#ffa500"
          d="M44.62 17.64l-5.6 9.46 3.59 10.45a.83.83 0 0 1-.79 1.1h-4.75a.83.83 0 0 1-.81-.64c-.27-1.16-.5-2.34-.72-3.5-.13-.6-.27-1.23-.33-1.83a90.73 90.73 0 0 1-2.54 5.5.83.83 0 0 1-.75.47h-10.4a.83.83 0 0 1-.81-.63c-.5-2.02-1.03-4.02-1.49-6.04-.2-.87-.36-1.7-.56-2.57-.25 2.86-.6 5.69-.98 8.52a.83.83 0 0 1-.82.72h-4.24a.83.83 0 0 1-.83-.93l2.54-20.6a.83.83 0 0 1 .82-.74h5.3c4.38 0 7.69 1.5 7.69 6.38 0 3.04-1.27 5.44-4.14 6.64l2.79 7.89 5.95-10.19-3.33-9.61a.83.83 0 0 1 .79-1.1h4.72c.4 0 .74.27.82.66l.6 3c.1.54.17 1.07.3 1.6.51-1.62 1.19-3.2 1.86-4.76a.83.83 0 0 1 .76-.5h4.64c.64 0 1.04.7.71 1.25M27.89.04a27.48 27.48 0 1 0 0 54.96 27.48 27.48 0 0 0 0-54.97"
        />
      </g>
      <path
        fill="#FFF"
        d="M0 28.1c0-7.43 5.4-12.52 12.27-12.52 3.6 0 6.37 1.5 8.4 3.8a.55.55 0 0 1-.1.82l-.8.52a.54.54 0 0 1-.71-.1 8.84 8.84 0 0 0-6.79-3.15c-5.7 0-10.1 4.32-10.1 10.64 0 6.24 4.4 10.63 10.1 10.63a8.84 8.84 0 0 0 6.8-3.15.54.54 0 0 1 .7-.1l.78.49a.55.55 0 0 1 .12.83 10.74 10.74 0 0 1-8.4 3.82C5.41 40.63 0 35.55 0 28.11m56.38 11.6V16.58c0-.3.24-.55.54-.55h.98c.3 0 .54.25.54.55v21.78h11.18c.3 0 .55.24.55.54v.8a.55.55 0 0 1-.55.54h-12.7a.55.55 0 0 1-.55-.55"
      />
    </g>
  </svg>
);

export default LogoSvg;
