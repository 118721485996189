import React from 'react';
import {Row, Col, Container} from 'reactstrap';

const HowItWorksA = () => (
  <img
    alt="How It Works Icon"
    title="How It Works Icon"
    src="/static/images/how-it-works-icon-a.svg"
    className="img-fluid"
  />
);
const HowItWorksB = () => (
  <img
    alt="How It Works Icon"
    title="How It Works Icon"
    src="/static/images/how-it-works-icon-b.svg"
    className="img-fluid"
  />
);
const HowItWorksC = () => (
  <img
    alt="How It Works Icon"
    title="How It Works Icon"
    src="/static/images/how-it-works-icon-c.svg"
    className="img-fluid"
  />
);
const HowItWorksD = () => (
  <img
    alt="How It Works Icon"
    title="How It Works Icon"
    src="/static/images/how-it-works-icon-d.svg"
    className="img-fluid"
  />
);

const HowItWorks = () => (
  <section className="how text-center">
    <Container fluid>
      <h2>How It Works</h2>
      <Row>
        <Col xs="12" md="3">
          <div className="img-wrap">
            <HowItWorksA />
          </div>
          <h4>Download your Clever RX card or app to unlock exclusive savings.</h4>
        </Col>
        <Col xs="12" md="3">
          <div className="img-wrap">
            <HowItWorksB />
          </div>
          <h4>Present your Clever RX savings card or app to your pharmacist.</h4>
        </Col>
        <Col xs="12" md="3">
          <div className="img-wrap">
            <HowItWorksC />
          </div>
          <h4>FREE to use. Save up to 80% off prescription drugs and beat copay prices.</h4>
        </Col>
        <Col xs="12" md="3">
          <div className="img-wrap">
            <HowItWorksD />
          </div>
          <h4>Share with family, friends or anyone who wants a lower price on their prescriptions.</h4>
        </Col>
      </Row>
    </Container>
    <style jsx>{`
      .how {
        padding: 30px 0 0 0;
        position: relative;
        z-index: 50;
      }
      h2 {
        margin: 0 auto 60px auto;
      }
      .how :global(img) {
        max-width: 100%;
        margin: 0 auto;
        vertical-align: middle;
      }
      .img-wrap {
        min-height: 100px;
        display: flex;
        align-items: center;
      }
      h4 {
        margin: 30px auto 100px auto;
        max-width: 230px;
      }
      @media (min-width: 768px) {
        h2 {
          margin: 0 auto 60px auto;
        }
      }
    `}</style>
  </section>
);

export default HowItWorks;
