import React from 'react';
import {Element} from 'react-scroll';
import TextBlock from '../components/for-partners/TextBlock';
import OrangeCTA from '../components/for-partners/OrangeCTA';
import Testimonials from '../components/for-partners/Testimonials';
import ThreeCol from '../components/for-partners/ThreeCol';
import TopCTA from '../components/for-partners/TopCTA';
import Layout from '../components/Layout';
import OpenGraphMeta from '../components/OpenGraphMeta';
import {setInviteToken} from '../lib/utils';

class ForPartners extends React.Component {
  componentDidMount() {
    let {inviteToken} = this.props;
    setInviteToken(inviteToken);
  }

  render() {
    return (
      <div className="for-partners">
        <OpenGraphMeta title={'For Partners | Clever RX'} />
        <Layout headerStyle="filled">
          <TopCTA />
          <Element name="How">
            <ThreeCol />
          </Element>
          <TextBlock />
          {<Testimonials />}
          <OrangeCTA />
        </Layout>
      </div>
    );
  }
}

ForPartners.getInitialProps = ({query, req}) => {
  return {
    inviteToken: query.inviteToken,
  };
};

export default ForPartners;
