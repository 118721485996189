import React, {useEffect, useRef} from 'react';

export const useClickOutside = (
  ref: React.MutableRefObject<any>,
  show: boolean,
  onClose: () => void,
) => {
  const firstClick = useRef(false);

  useEffect(() => {
    const clickHandler = (e: MouseEvent) => {
      if (!firstClick.current) {
        return (firstClick.current = true);
      }
      if (!ref.current || !show || ref.current.contains(e.target)) {
        return;
      }
      onClose();
    };
    if (show) {
      document.addEventListener('click', clickHandler);
    }
    return () => {
      document.removeEventListener('click', clickHandler);
      firstClick.current = false;
    };
  }, [show]);
};
