import css from 'styled-jsx/css';
// language=CSS
export default css`
  .faq .Collapsible {
    margin: 0;
    width: 100%;
    border-bottom: solid 2px #24292d;
    padding: 10px 0;
  }
  .faq .Collapsible:last-child {
    border: none;
  }
  .faq .Collapsible__trigger {
    color: #8c99a7;
    font-size: 18px;
    margin: 0;
    padding: 22px 35px 22px 30px;
    display: block;
    font-weight: 600;
    line-height: 1.5;
    cursor: pointer;
    position: relative;
    width: 100%;
    transition: all 0.2s ease;
  }

  @media (min-width: 768px) {
    .faq .Collapsible__trigger {
      padding: 34px 50px 34px 50px;
    }
  }

  .faq .Collapsible__trigger.is-closed:after,
  .faq .Collapsible__trigger.is-open:after {
    display: block;
    content: '';
    width: 10px;
    height: 10px;
    cursor: pointer;
    margin-top: -5px;
    position: absolute;
    top: 50%;
    right: 30px;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 10px 10px;
  }

  .faq .Collapsible__trigger.is-open {
    color: #ffa500;
  }

  .faq .Collapsible__trigger.is-open:after {
    background-image: url(/static/images/arrowopen.svg);
  }

  .faq .Collapsible__trigger.is-closed:after {
    background-image: url(/static/images/arrowright.svg);
  }

  .faq .Collapsible__contentInner {
    padding: 0 30px 20px 30px;
    color: #fff;
  }

  @media (min-width: 768px) {
    .faq .Collapsible__contentInner {
      padding: 0 50px 30px 50px;
    }
  }

  /*end*/
`;
