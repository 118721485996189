import React, {Component} from 'react';
import {Carousel, CarouselControl, CarouselItem, Container} from 'reactstrap';

const QuoteIcon = () => (
  <img alt="Quote Icon" title="Quote Icon" src="/static/images/quote-icon.svg" className="quote-icon" />
);

const items = [
  {
    quote:
      'We have all heard about Prescription Savings Cards for years but have never really given it much attention. Now that nearly 70% of all Americans could benefit from such a card we felt it was time to provide more to our clients and build a great business in the process. The only logical next step was to partner with Clever RX who made it so easy to start.',
    source: 'Danny Sullivan, LaPorte Insurance',
  },
  {
    quote:
      'Clever RX made it so easy for our brokerage to build a great business around saving our clients money on Prescription costs.',
    source: 'Colin Shea, Gilsbar',
  },
  {
    quote:
      'Clever RX is now part of our benefit plans for all our clients because it’s needed, it’s easy to use, it works great and our clients love it.',
    source: 'Chris Keel, Rogers Benefits',
  },
  {
    quote:
      'I really had no idea how many people could benefit from these cards. Now I hand out a Prescription Savings card with every script I write. My patients are so thankful and saving them money is the right thing to do.',
    source: 'Day Keller, Physician Assistant',
  },
];

class Testimonials extends Component {
  constructor(props) {
    super(props);
    this.state = {activeIndex: 0};
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
    this.goToIndex = this.goToIndex.bind(this);
    this.onExiting = this.onExiting.bind(this);
    this.onExited = this.onExited.bind(this);
  }

  onExiting() {
    this.animating = true;
  }

  onExited() {
    this.animating = false;
  }

  next() {
    if (this.animating) return;
    const nextIndex = this.state.activeIndex === items.length - 1 ? 0 : this.state.activeIndex + 1;
    this.setState({activeIndex: nextIndex});
  }

  previous() {
    if (this.animating) return;
    const nextIndex = this.state.activeIndex === 0 ? items.length - 1 : this.state.activeIndex - 1;
    this.setState({activeIndex: nextIndex});
  }

  goToIndex(newIndex) {
    if (this.animating) return;
    this.setState({activeIndex: newIndex});
  }

  render() {
    const {activeIndex} = this.state;

    const slides = items.map((item) => {
      return (
        <CarouselItem onExiting={this.onExiting} onExited={this.onExited} key={item.quote}>
          <p>{item.quote}</p>
          <div className="source">{item.source}</div>
        </CarouselItem>
      );
    });

    return (
      <section className="testimonials text-center">
        <Container fluid>
          <QuoteIcon />
          <Carousel activeIndex={activeIndex} next={this.next} previous={this.previous}>
            {slides}
            <CarouselControl direction="prev" directionText="Previous" onClickHandler={this.previous} />
            <CarouselControl direction="next" directionText="Next" onClickHandler={this.next} />
          </Carousel>
        </Container>
        <style jsx>{`
          .testimonials {
            padding: 60px 0;
          }
          @media (min-width: 768px) {
            .testimonials {
              display: flex;
              align-items: center;
              height: 400px;
            }
          }
          @media (min-width: 992px) {
            .testimonials {
              min-height: 600px;
            }
          }
        `}</style>
      </section>
    );
  }
}

export default Testimonials;
