import React from 'react';
export const ralphs = (
  <svg
    width="187.09"
    height="66.434"
    enableBackground="new 0 0 187.086 66.434"
    overflow="visible"
    version="1.1"
    viewBox="0 0 187.086 66.434"
    xmlSpace="preserve"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipRule="evenodd" fillRule="evenodd">
      <path
        d="m0.493 33.217c0-9.544 7.952-16.956 24.311-22.66 9.653-3.389 19.788-5.846 30.982-7.511 11.55-1.718 23.947-2.553 37.901-2.553 40.961 0 74.487 8.079 87.493 21.085 3.593 3.593 5.414 7.412 5.414 11.351 0 4.902-1.825 9.102-5.579 12.837-3.822 3.806-9.526 7.019-17.438 9.823-19.302 6.966-42.21 10.352-70.034 10.352-28.668 0-48.959-3.04-70.035-10.496-15.056-5.315-23.015-13.001-23.015-22.228z"
        fill="currentColor"
      />
      <path
        d="m23.345 55.91c21.132 7.476 41.47 10.524 70.199 10.524 27.882 0 50.845-3.396 70.2-10.381 15.707-5.568 23.343-13.133 23.343-23.124 0-4.075-1.87-8.011-5.558-11.699-6.263-6.262-17.519-11.551-32.552-15.294-15.596-3.883-34.715-5.936-55.289-5.936-13.979 0-26.4 0.837-37.974 2.558-11.226 1.67-21.39 4.134-31.072 7.534-16.581 5.781-24.642 13.345-24.642 23.125 0 9.455 8.073 17.303 23.345 22.693z"
        fill="currentColor"
      />
      <path
        d="m122 16.788l-6.479 26.491c-1.728 6.623-3.6 5.76-3.6 6.623 0 0.864 1.729 1.439 2.448 1.439 0.863 0 2.159-0.287 3.312-0.287 1.296 0 3.312 0 4.463-4.751l1.872-7.487c2.016-7.775 5.759-13.39 10.222-13.39 1.152 0 1.584 0.72 1.584 1.728 0 0.864-0.288 2.592-1.008 5.039l-2.879 8.782c-0.721 2.448-1.152 4.464-1.152 5.76 0 3.455 2.16 4.895 4.464 4.895 1.728 0 3.023-0.432 5.902-2.735 1.152 1.584 2.448 2.592 6.623 2.592 9.358 0 17.134-6.335 17.134-12.382 0-9.071-13.534-6.191-13.534-12.526 0-2.016 2.447-3.888 5.615-3.888 5.902 0 3.023 6.335 6.047 6.335 1.584 0 4.607-3.311 4.607-6.047 0-2.448-6.191-3.887-8.783-3.887-7.486 0-14.397 5.183-14.397 11.23 0 3.456 2.304 5.183 8.207 7.775l3.455 1.584c0.864 0.863 1.151 1.295 1.151 2.159 0 1.872-1.439 5.472-5.902 5.472-4.031 0-4.031-2.736-4.031-5.76 0-0.864-0.864-1.728-1.439-1.728-2.16 0-3.456 4.751-5.615 4.751-0.721 0-0.864-0.432-0.864-1.296 0-0.432 0-0.863 0.72-3.023l2.736-9.502c0.863-3.023 1.438-5.615 1.438-6.91 0-3.024-2.015-5.04-5.614-5.04-3.312 0-6.479 2.304-10.942 6.047l3.6-14.542c0-1.296-1.296-2.016-1.872-2.016-2.304 0-10.51 4.319-10.51 5.759-3e-3 1.152 3.021 1.44 3.021 2.736z"
        fill="none"
      />
      <path
        d="m28.766 16.233l-6.623 27.499c-1.44 5.615-4.175 4.319-4.175 6.047 0 0.864 1.296 1.439 2.16 1.439 7.487 0 7.774-0.287 9.358-6.767l6.047-24.044c1.727-6.479 2.735-9.358 9.358-9.358 4.032 0 5.471 2.592 5.471 6.047 0 7.199-5.471 12.958-10.798 12.958-1.151 0-2.448-0.144-3.023-0.144-1.728 0-2.591 0.72-2.591 2.16 0 2.735 7.199 18.717 10.079 22.604 3.023 4.031 6.911 6.191 12.094 6.191 1.583 0 2.159-0.433 2.159-1.44 0-1.871-10.51-6.047-16.125-25.916 9.502-1.728 16.701-8.063 16.701-16.845 0-5.615-3.599-9.214-9.934-9.214-1.296 0-22.461 2.447-22.461 5.903-1e-3 1.44 2.303 1.008 2.303 2.88z"
        fill="none"
      />
      <path
        d="m56.639 51.797c3.887 0 6.335-2.016 10.367-6.479 0 4.176 1.151 6.191 4.031 6.191 2.447 0 4.031-1.152 6.911-4.751 0.432 3.455 1.727 5.039 4.175 5.039 2.304 0 4.032-1.297 6.767-4.752l-2.16 9.215c-1.152 4.751-4.032 3.888-4.032 5.471 0 1.152 1.584 1.44 4.896 1.44 4.607 0 5.183-2.016 6.191-5.903l1.872-7.486c1.871 1.295 3.312 1.871 5.326 1.871 8.063 0 16.702-13.102 16.702-25.483 0-3.888-2.16-7.343-6.335-7.343-2.735 0-5.327 1.728-9.358 5.471 0.432-1.295 0.72-2.735 0.72-3.599 0-1.152-0.576-1.584-1.296-1.584-3.167 0-10.798 4.319-10.798 5.759 0 1.296 2.879 0.864 2.879 2.592l-2.735 11.95c-0.864 3.167-2.159 5.183-3.6 5.183-0.72 0-1.295-0.72-1.295-1.872l8.206-34.987c0-1.152-0.72-1.728-1.584-1.728-1.44 0-10.798 3.887-10.798 5.903 0 1.439 3.167 1.008 3.167 3.167l-5.471 22.892c-1.152 4.463-1.872 6.479-3.456 6.479-0.72 0-1.584-0.575-1.584-1.583l5.471-21.597c0-1.44-1.008-1.44-2.159-1.44l-4.032 0.576c-3.312-1.008-5.039-1.584-6.767-1.584-7.487 0-16.126 15.118-16.126 25.627 1e-3 4.034 2.305 7.345 5.905 7.345z"
        fill="none"
      />
      <path
        d="m95.975 59.616c0-2.916 2.365-5.067 5.161-5.067 2.77 0 5.135 2.151 5.135 5.067 0 2.943-2.365 5.095-5.135 5.095-2.796-1e-3 -5.161-2.152-5.161-5.095z"
        fill="none"
      />
      <path
        d="m101.14 63.864c2.299 0 4.113-1.802 4.113-4.248 0-2.405-1.814-4.22-4.113-4.22-2.325 0-4.14 1.814-4.14 4.22 1e-3 2.446 1.815 4.248 4.14 4.248z"
        fill="currentColor"
      />
      <path
        d="m100.06 62.559h-0.888v-5.859h2.231c1.385 0 2.07 0.511 2.07 1.666 0 1.049-0.659 1.506-1.52 1.613l1.667 2.58h-0.995l-1.545-2.54h-1.021v2.54z"
        fill="none"
      />
      <path
        d="m101.12 59.26c0.753 0 1.426-0.054 1.426-0.954 0-0.727-0.659-0.86-1.277-0.86h-1.21v1.814h1.061z"
        fill="currentColor"
      />
      <path
        d="m67.77 22.132c1.872 0 2.735 1.583 2.735 3.599 0 11.518-6.623 19.005-9.79 19.005-1.44 0-2.304-1.009-2.304-3.024 0-3.022 1.728-9.07 4.607-15.117 1.44-2.879 2.88-4.463 4.752-4.463z"
        fill="currentColor"
      />
      <path
        d="m107.13 25.315c1.584 0 2.447 1.584 2.447 3.312 0 11.662-7.199 19.292-9.214 19.292-1.584 0-2.88-1.728-2.88-4.463 1e-3 -9.934 6.479-18.141 9.647-18.141z"
        fill="currentColor"
      />
    </g>
  </svg>
);
