import React from 'react';

const Loading = () => (
  <div className="center-wrap">
    <div className="component-centered">
      <img alt="Loading" src="/static/images/logo.svg" className="img-fluid" />
      <h1>Loading</h1>
    </div>
    <style jsx>{`
      .center-wrap {
        display: flex;
        width: 100%;
        flex-flow: row wrap;
        min-height: calc(100vh - 100px);
        align-items: center;
        text-align: center;
      }
      img {
        max-height: 50px;
        margin: 0 auto 30px auto;
      }
      h1 {
        margin: 0;
      }
      .component-centered {
        flex: 1 100%;
        padding: 0 20px;
      }
    `}</style>
  </div>
);

export default Loading;
