import React from 'react';
import MediaQuery from 'react-responsive';
import Time from 'react-time';
import {Link} from 'react-router-dom';
import FacebookSvg from './images/facebook';
import InstagramSvg from './images/instagram';
import LinkedInSvg from './images/linkedin';
import FooterLogoSvg from './images/logo-footer';
import TwitterSvg from './images/twitter';

const now = new Date();

const SiteFooter = ({footerStyle}) => (
  <footer className="d-print-none">
    <MediaQuery maxWidth={991}>
      <Link to={'/'} className="footer-logo" title="Footer Logo" style={{display: 'flex', justifyContent: 'center'}}>
        <FooterLogoSvg />
      </Link>
    </MediaQuery>

    <MediaQuery minWidth={992}>
      <div className="copyright">
        ©<Time value={now} format="YYYY" /> Clever RX LLC. All Rights Reserved
      </div>
    </MediaQuery>

    <ul className="list-unstyled menu-links">
      <li className={'unstyled-dots'}>
        <Link to={'/privacy-policy'}>Privacy Policy</Link>
      </li>
      <li className={'unstyled-dots'}>
        <Link to="/terms">Terms &amp; Conditions</Link>
      </li>
    </ul>

    <ul className="list-unstyled menu-social">
      <li>
        <a href="https://www.facebook.com/Cleverrx-2079140825657656" target="_blank" rel="nofollow" title="Facebook">
          <FacebookSvg />
        </a>
      </li>
      <li>
        <a href="https://www.linkedin.com/company/cleverrx" target="_blank" rel="nofollow" title="LinkedIn">
          <LinkedInSvg />
        </a>
      </li>
      <li>
        <a href="https://twitter.com/CleverRX" target="_blank" rel="nofollow" title="Twitter">
          <TwitterSvg />
        </a>
      </li>
      <li>
        <a href="https://www.instagram.com/CleverRX" target="_blank" rel="nofollow" title="Instagram">
          <InstagramSvg />
        </a>
      </li>
    </ul>

    <MediaQuery maxWidth={991}>
      <div className="copyright">
        ©<Time value={now} format="YYYY" /> Clever RX LLC. All Rights Reserved
      </div>
    </MediaQuery>
  </footer>
);

export default SiteFooter;
