import {Button, Col, Form as RSForm, Row} from 'reactstrap';
import {AppService} from '../dataServices/appService';
import FieldGroup from './FieldGroup';
import React, {CSSProperties, useCallback, useState} from 'react';

declare const EF: any;
export const TextApp = ({urlSlug, containerStyle}: {urlSlug: string; containerStyle: CSSProperties}) => {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [loading, setLoading] = useState(false);
  const [done, setDone] = useState(false);

  const onAppSent = useCallback(async () => {
    if (urlSlug === 'fbc') {
      const script = document.createElement('script');
      script.src = 'https://www.tp88trk.com/scripts/sdk/everflow.js';
      document.body.appendChild(script);

      script.onload = () => {
        EF.click({
          offer_id: EF.urlParameter('oid'),
          affiliate_id: EF.urlParameter('affid'),
          sub1: EF.urlParameter('sub1'),
          sub2: EF.urlParameter('sub2'),
          sub3: EF.urlParameter('sub3'),
          sub4: EF.urlParameter('sub4'),
          sub5: EF.urlParameter('sub5'),
          uid: EF.urlParameter('uid'),
          transaction_id: EF.urlParameter('utm_term'),
        });
      };
    }
  }, [urlSlug]);

  return (
    <div>
      <section className="rx-lookup-bg d-print-none">
        <div className="rx-lookup-wrap" style={containerStyle}>
          <RSForm
            onSubmit={(e) => {
              setLoading(true);
              AppService.shareClient.messageApp(
                {urlSlug, phoneNumber},
                {
                  '200': () => {
                    setLoading(false);
                    setDone(true);
                    onAppSent();
                  },
                  400: (result) => {
                    setLoading(false);
                    alert(result.error);
                  },
                  500: (result) => {
                    setLoading(false);
                    alert('An error has occurred');
                  },
                }
              );
              e.preventDefault();
              return false;
            }}
            id="Search-Form"
          >
            <h2 className="rx-header">Text Me The App!</h2>
            <Row>
              <Col xs="12" sm="12">
                <FieldGroup
                  id="drugName"
                  name="drugName"
                  label="Your Phone Number"
                  type="text"
                  input={{
                    value: phoneNumber,
                    onChange: (ev: any) => {
                      setDone(false);
                      setPhoneNumber(ev.target.value);
                    },
                    autoComplete: 'off',
                    className: 'clh-input text-base bg-custom-foreground text-custom-text',
                  }}
                  hideLabel={false}
                  meta={{}}
                  children={null}
                />
              </Col>
            </Row>
            <div className="clearfix">
              <Button block disabled={loading || done} size="lg" color="primary" type="submit">
                {loading ? 'Sending now...' : done ? 'Sent!' : 'Text Me Now!'}
              </Button>
              <span style={{color: '#999', textAlign: 'center', display: 'block'}}>
                (Message & data rates may apply)
              </span>
            </div>
          </RSForm>
        </div>
      </section>
      <style jsx>{`
        h1 {
          margin: 0 auto 10px auto;
          font-weight: 300;
          width: 100%;
          max-width: 570px;
          font-size: 22px;
          line-height: 1.4;
          text-align: center;
        }
        .bg-fill {
          background: #24292d;
        }
        @media (min-width: 768px) {
          h1 {
            font-size: 35px;
            letter-spacing: 5.6px;
            margin: 60px auto 10px auto;
            letter-spacing: 5.6px;
          }
        }
      `}</style>

      <style jsx>{`
        .clearfix {
          margin: 0;
          padding: 20px 0 0 0;
        }
        .rx-header {
          text-align: center;
          margin-bottom: 30px;
        }
      `}</style>
      <style jsx>{`
        .rx-lookup-wrap {
          width: 100%;
          box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.15);
          padding: 20px 20px;
          margin: 0 0 60px 0;
          background: #1e2126;
          z-index: 100;
          position: relative;
        }
        .results-header {
          margin: 60px 0 30px 0;
          color: #fff;
          font-size: 20px;
          text-align: center;
          font-weight: 600;
          letter-spacing: 2.5px;
        }
        .drugs-auto-wrap {
          position: relative;
        }
        .drugs-auto-list {
          position: absolute;
          top: 152px;
          left: 0;
          padding: 2px;
          color: #fff;
          width: 100%;
          box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.15);
          z-index: 100;
        }
        .drug-item {
          border-bottom: 1px solid #3b4045;
          padding: 10px;
          background: #24292d;
        }
        .drug-item:hover {
          background: #444;
          cursor: pointer;
        }

        .toggle-results {
          display: block;
          margin-top: 60px;
          text-align: center;
        }
        .toggle-results a {
          padding: 10px;
          cursor: pointer;
        }
        @media (min-width: 768px) {
          .results-table :global(.row) {
            border: none;
          }
          .results-table :global(.col) {
            border-top: 2px dotted #3b4045;
            padding: 20px 10px;
            flex: 1;
          }
          .rx-lookup-bg {
            padding: 60px 0;
            background: #24292d;
          }
          .rx-lookup-wrap {
            margin: 0 auto;
            max-width: 940px;
            padding: 80px 120px;
            border-radius: 60px;
          }
        }
        .results-table :global(.pharmacy-name) {
          //text-transform: uppercase;
        }
        .results-table :global(.pharmacy-name img) {
          max-width: 100%;
          margin-top: 10px;
          display: block;
          clear: both;
        }
        .modal-link {
          cursor: pointer;
        }
        :global(.modal-btn) {
          min-width: 100px;
          margin-right: 20px;
        }
      `}</style>
    </div>
  );
};
