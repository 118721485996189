import React from 'react';

const TextBlock = () => (
  <section className="about">
    <div className="about-wrap">
      <h3>
        It’s time to get rewarded by adding a prescription savings benefit to your business. Your clients will thank
        you!
      </h3>
      <p>
        <span className="text-primary">
          Prescription drug costs are at an all-time high and employers are scaling back health insurance benefits due
          to out of control costs.
        </span>{' '}
        This results in higher costs to the employee, and as a healthcare professional, we should work together to
        provide solutions to alleviate these mounting problems.
      </p>
      <br />
      <p>
        Partnering with Clever RX provides needed prescription drug cost relief. We built Clever RX to help you address
        rising drug costs, higher deductibles, and decreasing compensation.{' '}
        <span className="text-primary">
          Together we can save our clients thousands, lower prescription drug costs while building a business and
          helping our communities.
        </span>
      </p>
    </div>
    <style jsx>{`
      .about {
        padding: 60px 20px;
        background: #24292d;
        text-align: center;
      }
      .about-wrap {
        max-width: 780px;
        display: block;
        margin: 0 auto;
      }
      h3 {
        margin: 0 auto 20px auto;
        text-transform: uppercase;
        font-size: 20px;
        line-height: 1.3;
        letter-spacing: 2.8px;
      }
      p {
        line-height: 1.89;
        margin: 0;
      }
      @media (min-width: 768px) {
        .about {
          text-align: center;
          padding: 130px 0;
        }
        h3 {
          font-size: 26px;
          margin: 0 auto 40px auto;
          line-height: 1.42;
          letter-spacing: 3.6px;
          text-align: center;
        }
      }
    `}</style>
  </section>
);

export default TextBlock;
