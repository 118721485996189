import React from 'react';
export const kroger = (
  <svg
    width="375"
    height="309.23"
    version="1.1"
    viewBox="0 0 374.99998 309.22937"
    xmlSpace="preserve"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g transform="matrix(1.25 0 0 -1.25 -329.97 534.07)">
      <g>
        <g transform="matrix(.54144 0 0 .54144 189.83 139.2)">
          <g transform="translate(413.98 501.21)" fill="#3f3f3f">
            <path
              d="m0 0c135.84 0 245.95-88.367 245.95-197.38 0-108.98-110.11-197.36-245.95-197.36-135.86 0-245.96 88.384-245.96 197.36 0 109.01 110.1 197.38 245.96 197.38"
              fill="#3f3f3f"
            />
          </g>
          <g transform="translate(413.98 509.84)" fill="currentColor">
            <path
              d="m0 0c-67.641 0-131.33-21.205-179.32-59.701-23.511-18.872-42.002-40.895-54.935-65.44-13.481-25.588-20.317-52.793-20.317-80.87 0-28.04 6.836-55.245 20.317-80.853 12.933-24.543 31.424-46.549 54.935-65.422 47.992-38.531 111.68-59.682 179.32-59.682 67.65 0 131.33 21.151 179.31 59.682 23.518 18.873 41.991 40.879 54.968 65.422 13.464 25.608 20.318 52.813 20.318 80.853 0 28.077-6.854 55.282-20.318 80.87-12.977 24.545-31.45 46.568-54.968 65.44-47.98 38.495-111.66 59.7-179.31 59.7"
              fill="currentColor"
            />
          </g>
          <g transform="translate(413.98 532.02)" fill="currentColor">
            <path
              d="m0 0c-73.693 0-143.02-23.554-195.27-66.327-25.58-20.944-45.669-45.349-59.726-72.59-14.637-28.337-22.048-58.447-22.048-89.516 0-31.067 7.411-61.196 22.048-89.549 14.057-27.189 34.146-51.613 59.726-72.591 52.23-42.75 121.58-66.32 195.27-66.32 73.686 0 143.02 23.569 195.26 66.327 25.553 20.978 45.662 45.402 59.753 72.591 14.611 28.353 22.022 58.482 22.022 89.549 0 31.069-7.411 61.179-22.022 89.516-14.091 27.241-34.2 51.646-59.753 72.59-52.24 42.766-121.57 66.32-195.26 66.32m0-8.628c148.24 0 268.41-98.422 268.41-219.8 0-121.44-120.17-219.82-268.41-219.82-148.26 0-268.42 98.387-268.42 219.82 0 121.38 120.17 219.8 268.42 219.8"
              fill="currentColor"
            />
          </g>
          <g fill="#3f3f3f">
            <g transform="translate(341.5 339.16)">
              <path
                d="m0 0s-3.026 1.564-8.471 1.477c-9.636-0.139-16.335-6.314-16.335-6.314l-2.46 6.401h-18.666l-0.112-99.152h23.23v60.03c0 5.464 2.601 8.124 4.707 10.037 6.175 5.603 18.107 5.776 18.107 5.776v21.745z"
                fill="#3f3f3f"
              />
            </g>
            <g transform="translate(631.83 339.16)">
              <path
                d="m0 0s-3.061 1.564-8.488 1.477c-9.673-0.139-16.334-6.314-16.334-6.314l-2.453 6.401h-18.681l-0.105-99.152h23.24v60.03c0 5.464 2.574 8.124 4.66 10.037 6.229 5.603 18.161 5.776 18.161 5.776v21.745z"
                fill="#3f3f3f"
              />
            </g>
            <g transform="translate(512.4 224.66)">
              <path
                d="m0 0h0.522l0.626-0.034c0.418-0.018 0.698-0.088 0.941-0.158 0.364-0.156 0.626-0.417 0.73-0.748 0.071-0.209 0.173-0.644 0.173-1.288 0.054-0.607 0.071-1.078 0.157-1.425h2.592l-0.068 0.279c-0.036 0.104-0.07 0.189-0.07 0.313-0.035 0.085-0.035 0.19-0.035 0.312v0.922c0 1.045-0.296 1.827-0.905 2.314-0.313 0.243-0.816 0.452-1.479 0.608 0.731 0.07 1.34 0.297 1.793 0.679 0.504 0.399 0.73 1.026 0.73 1.895 0 1.149-0.452 1.967-1.357 2.489-0.556 0.294-1.235 0.452-2.052 0.523-0.175 0.016-0.87 0.016-2.14 0.016h-2.887v-10.35h2.729v3.653zm2.506 2.243c-0.347-0.26-0.852-0.364-1.462-0.364h-1.044v3.009h0.627c0.696 0 1.201-0.07 1.601-0.226 0.557-0.245 0.835-0.592 0.835-1.166 0-0.592-0.173-0.974-0.557-1.253m5.462 5.914c-1.826 1.828-4.019 2.715-6.611 2.715-2.435 0-4.591-0.835-6.401-2.54-1.982-1.792-2.938-4.054-2.938-6.803 0-2.608 0.885-4.818 2.729-6.627 1.775-1.807 3.985-2.696 6.61-2.696 2.558 0 4.732 0.87 6.54 2.61 1.88 1.861 2.784 4.088 2.784 6.713 0 2.594-0.886 4.784-2.713 6.628m-1.149-1.182c1.496-1.531 2.228-3.357 2.228-5.446 0-2.138-0.748-3.946-2.244-5.496-1.514-1.512-3.288-2.277-5.446-2.277-2.156 0-3.931 0.765-5.427 2.261-1.547 1.512-2.279 3.356-2.279 5.512 0 2.089 0.732 3.933 2.228 5.446 1.513 1.532 3.322 2.314 5.478 2.314 2.158 0 3.932-0.782 5.462-2.314"
                fill="#3f3f3f"
              />
            </g>
            <g transform="translate(363.81 291.18)">
              <path
                d="m0 0c0 17.743 4.41 30.598 15.586 30.598 11.221 0 15.587-12.855 15.587-30.598 0-17.761-4.366-30.615-15.587-30.615-11.176 0-15.586 12.854-15.586 30.615m53.63-0.087c0 27.433-12.985 49.628-38.121 49.628-24.546 0-38.114-22.195-38.114-49.628 0-27.38 11.681-49.629 38.114-49.629 25.935 0 38.121 22.249 38.121 49.629"
                fill="#3f3f3f"
              />
            </g>
            <g transform="translate(541.01 321.78)">
              <path
                d="m0 0c7.602 0 12.02-5.879 14.142-15.115h-28.179c2.086 9.236 6.54 15.115 14.037 15.115m16.611-40.355c-1.425-12.508-5.983-20.858-14.994-20.858-10.924 0-15.412 12.386-15.584 29.571h31.137v-0.017h22.475v0.017c0.276 9.063-1.497 16.527-1.497 16.527h-0.036c-4.104 19.795-16.42 34.058-36.599 34.058-24.561 0-38.112-22.195-38.112-49.628 0-27.379 11.706-49.628 38.112-49.628 22.804 0 34.965 17.169 37.575 39.958h-22.477z"
                fill="#3f3f3f"
              />
            </g>
            <g transform="translate(461.05 260.49)">
              <path
                d="m0 0c-11.201 0-15.689 12.908-15.689 30.739 0 17.845 4.488 30.787 15.689 30.787 11.272 0 15.656-12.942 15.656-30.787 0-17.831-4.384-30.739-15.656-30.739m38.026-22.16c-0.07 7.236 0 102.6 0 102.6h-24.63v-5.428c-2.993 3.149-9.168 5.114-13.5 5.219-24.683 0.609-38.304-21.97-38.304-49.541 0-27.555 11.759-49.628 38.34-49.628 5.182 0 9.741 2.173 13.828 3.773 1.132-19.394 1.792-83.373-40.703-84.714-46.046-1.042-41.054 65.65-41.454 67.546-3.13 0-25.075-0.069-25.075-0.069 0-0.122-3.366-84.158 63.796-84.158 72.78 0 67.807 87.167 67.702 94.404"
                fill="#3f3f3f"
              />
            </g>
            <g transform="translate(329.79 444.73)">
              <path
                d="m0 0c-32.329 0-50.655-18.144-59.631-39.486l-0.061 0.017c-0.139-0.401-0.296-0.784-0.425-1.202-0.071-0.085-0.105-0.207-0.141-0.294-4.296-11.325-4.808-20.84-4.808-22.006 1.495-53.402-24.119-56.724-24.119-56.724-0.427 4.556 0 62.257 0 62.257h-24.597v-145.74h24.684v60.743c0 2.47 21.805 0.139 23.301-27.64 0.138-2.471 0-33.103 0-33.103h26.058c-0.549 3.982 5.304 69.006-27.302 69.51-5.185 0.088 26.858 7.41 25.892 55.37-0.244 10.715 0.983 20.369 3.314 28.718 5.384 17.153 15.108 32.582 35.096 32.842 45.06 0.575 39.938-72.833 40.521-73.98 3.14 0 25.084 0.017 25.084 0.017 0.023 0.106 4.457 90.7-62.861 90.7"
                fill="#3f3f3f"
              />
            </g>
          </g>
        </g>
        <path
          transform="matrix(.8 0 0 -.8 263.97 427.26)"
          d="m172.26 302.52c-69.64-5.63-127.76-41.16-153.31-93.71-14.196-29.19-16.404-62.31-6.19-92.83 7.163-21.41 17.409-37.572 34.868-55.006 13.51-13.489 26.129-22.755 42.397-31.131 20.325-10.462 39.135-16.725 63.065-20.992 14.199-2.5319 54.156-2.5424 68.572-0.017955 43.723 7.6561 77.992 24.565 105.57 52.088 13.364 13.34 20.762 23.371 28.099 38.102 18.839 37.825 17.433 81.357-3.8265 118.51-22.127 38.673-65.049 68.378-115.67 80.049-16.51 3.8068-47.647 6.2242-63.57 4.9354zm51.406-11.382c39.959-7.136 72.957-23.7 98.472-49.43 50.96-51.39 50.73-123.98-0.57-175.18-12.95-12.928-25.64-21.941-43.36-30.791-61.22-30.59-137.84-27.755-194.98 7.216-57.132 34.97-81.456 95.005-61.262 151.22 18.281 50.882 71.912 89.187 137.68 98.336 15.535 2.1611 48.17 1.465 64.02-1.3655z"
          fill="#3f3f3f"
        />
      </g>
    </g>
  </svg>
);
