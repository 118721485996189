import {bashas} from './bashas-united-drug';
import {costco} from './costco-pharmacy';
import {cvs} from './cvs-pharmacy';
import {fred} from './fred-meyer-pharmacy';
import {frys} from './frys-food-and-drug';
import {kroger} from './kroger-pharmacy';
import {other} from './other';
import {qfc} from './qfc-pharmacy';
import {ralphs} from './ralphs-pharmacy';
import {rite} from './rite-aid-pharmacy';
import {safeway} from './safeway-pharmacy';
import {smiths} from './smiths-pharmacy';
import {target} from './target';
import {walgreens} from './walgreens';
import {walmart} from './walmart-pharmacy';
import {albertsons} from './albertsons';

export const images: {[key: string]: any} = {
  'bashas-united-drug': bashas,
  'costco-pharmacy': costco,
  costco: costco,
  'cvs-pharmacy': cvs,
  'fred-meyer-pharmacy': fred,
  'frys-food-and-drug': frys,
  'kroger-pharmacy': kroger,
  other: other,
  'qfc-pharmacy': qfc,
  'ralphs-pharmacy': ralphs,
  'rite-aid-pharmacy': rite,
  'safeway-pharmacy': safeway,
  'smiths-pharmacy': smiths,
  target: target,
  walgreens: walgreens,
  'walmart-pharmacy': walmart,
  'albertsons-pharmacy': albertsons,
};
